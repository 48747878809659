import React, { useState, useEffect } from "react";
import CadastrarCooperadorModal from "./CadastrarCooperadorModal";
import CadastrarFazendaModal from "./CadastrarFazendaModal";
import CadastrarAnimalModal from "./CadastrarAnimalModal";

const CadasroProgressivo = (props) => {
   
  const [mudar, setmudar] = useState('cooperador')
  function Alterar (m){
    setmudar(m)
    props.tituloHome(m)
  }


  //CooperadorId
  // const [cooperadorId, setCooperadorId] = useState([]);
  // const result = response.data.codigo;
  // setCooperadorId(result)cooperadorId, setCooperadorId
  const [cooperadorId, setCooperadorId] = useState(0)
  function VerCooperadorId (id){
    setCooperadorId(id)
  }
  // 
  const [idCooperador, setidCooperador] = useState(0)
  function VerIdCooperador (id){
    setidCooperador(id)
  }

  //FAzendaId
  const [idFazenda, setidFazenda] = useState(0)
  function verIdFazenda (id){
    setidFazenda(id)
  }


  //
  return (
    <>
      <div className="card-body">
        <div className="default-tab">
          <div className="tab-content pl-3 pt-2" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >

            {
              mudar === 'cooperador' ? 
              <div>
                <CadastrarCooperadorModal mudarPai={Alterar} verIdCop={VerIdCooperador}/>
              </div> : ''
            }
            {
              mudar === 'fazenda' ? 
              <div>
                <CadastrarFazendaModal mudarPai={Alterar} addIdCooperador={idCooperador} verIdFazenda={verIdFazenda}/>
              </div> : ''
            }

            {
              mudar === 'animal' ? 
              <div>
                {/* <CadastrarAnimalModal mudarPai={Alterar} addIdFazenda={idFazenda}/> */}
                <CadastrarAnimalModal mudarPai={Alterar} addIdFazenda={idFazenda}/>
              </div> : ''
            }
             
              
              {/*  */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CadasroProgressivo;
