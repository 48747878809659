import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { getCooperadores } from "../../service/CooperadoresService";
import logo from "../../assets/img/logo/logo1.jpg";
import redirects from "../../components/redirect";

function App() {
  const { cooperadores } = redirects();
  const componentRef = useRef();
  const [dados, setDados] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Relatorios",
  });

  useEffect(() => {
    const busca = async () => {
      const { data } = await getCooperadores();
      setDados(data);
    };
    busca();
  }, []);

  const [data, setData] = useState("");
  const dataTime = () => {
    setData(new Date().toLocaleString());
  };
  const [totalTime, setTotalTime] = useState([60]);
  useEffect(() => {
    setTimeout(() => {
      setTotalTime(totalTime - 1);
    }, 1000);
    dataTime();
  }, [totalTime]);

  return (
    <>
      <div className="Formatacao-Relatorio" ref={componentRef}>
        <div className="App_header">
          <div className="data_esq">
            <img src={logo} />
            <h6>Vagne Zau Lelo</h6>
            <small>{data}</small>
          </div>
          <div className="data_dir">
            <h6>Cooperativa Wapoma Agro-pecuária e Mineira</h6>
            <small>Huíla Lubango</small>
            <small>cooperativacwamahuila@gmail.com</small>
            <small>www.cwam.com</small>
          </div>
        </div>
        <header className="App_h1">
          <h4 className="txt-centro">Relatório de Cooperadores</h4>
        </header>
        <div className="data_header">
          <div className="App_body">
            <div className="table_data">
              <table className="table">
                <thead>
                  <th className="th">Codigo</th>
                  <th className="th">Nome Completo</th>
                  <th className="th">B.I</th>
                  <th className="th">Email</th>
                  <th className="th">Genero</th>
                  <th className="th">Telefone</th>
                </thead>
                {dados.map((row) => (
                  <tbody className="control" key={row.codigo}>
                    <tr className="tr">
                      <td className="td">{row.userid}</td>
                      <td className="td">{row.nome}</td>
                      <td className="td">{row.bi}</td>
                      <td className="td">{row.email}</td>
                      <td className="td">{row.gender === "MASCULINO" ? "M": "F"}</td>
                      <td className="td">{row.telefone}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
              <div className="data_total">
                <small>Total: {dados.length} Cooperadores </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="data_funny">
        <button onClick={cooperadores} className="btn botao_return">
          <span className="fa fa-reply"></span>
        </button>
        <button onClick={handlePrint} className="btn botao_print">
          <span className="fa fa-print"></span>
        </button>
      </div>
    </>
  );
}

export default App;
