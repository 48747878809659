import api from "./api";

export const getProprietarios = async () => {
  try {
    return await api.get("proprietarios");
  } catch (error) {
    console.log(error.message);
  }
};
export const createProprietario = async (data) => {
  try {
    return await api.get("proprietario");
  } catch (error) {
    console.log(error.message);
  }
  return await api.post("proprietario", data);
};
export const getProprietarioCodigo = async (codigo) => {
  try {
    return await api.get(`proprietario/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateProprietario = async (data) => {
  try {
    return await api.put("proprietario", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeProprietario = async (codigo) => {
  try {
    return await api.delete(`proprietario/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
