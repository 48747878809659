import api from "./api";

export const getRapidos = async () => {
  try {
    return await api.get("lotesRapidos");
  } catch (error) {
    console.log(error.message);
  }
};

export const getLoteAnimalRapido = async (codigo) => {
  try {
    return await api.get("loteRapidoByLote/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};

export const getLoteMudarLoteGeral = async (codigo) => {
  try {
    return await api.get("loteGeral/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};

export const createRapido = async (data) => {
  try {
    return await api.post("loteRapido", data);
  } catch (error) {
    console.log(error.message);
  }
};
