import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../../components/layout/Menu";
import {
  createAnimal,
  getAnimais,
  getAnimalCodigo,
  removeAnimal,
  updateAnimal,
} from "../../service/AnimalService";
import { getPaises } from "../../service/PaisService";
import { getEspecies } from "../../service/EspecieService";
import { getRacas, getRacasByEspecie } from "../../service/RacaService";
import { getFazendas } from "../../service/FazendaService";
import * as messages from "../../components/message/toastr";
import usePagination from "../../components/pagination/Pagination";
import { Pagination } from '@mui/material';
import Swal from "sweetalert2";

import * as yup from "yup";


import { i18n } from "../../Traducao/translate/i18n";

// const I18N_STORAGE_KEY = "i18nextLng";

const ListAnimal = () => {
  // Buscar por ID
  const { codigo } = useParams();
  const [animais, setAnimais] = useState([]);

   //PAGINACAO
   let [page, setPage] = useState(1);
   const PER_PAGE = 5;
 
   const count = Math.ceil(animais.length / PER_PAGE);
   const _DATA = usePagination(animais, PER_PAGE);
   //PESQUISAR
   const [search, setSearch] = useState("");

  //
  useEffect(() => {
    getAllanimal();
  }, []);

  //LISTAR
  const getAllanimal = () => {
    getAnimais()
      .then((response) => {
        setAnimais(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deleteAnimais = (codigo) => {
    Swal.fire({
      title: "Deseja eliminar?",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeAnimal(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllanimal();
        });
      }
    });
  };
  //

  //SALVAR
  const [animal, setAnimal] = useState({
    imgUrl: "",
    peso: "",
    raca: "",
    pais: "",
    cod: "",
    sexoAnimal: "",
    especie: "",
    data: "",
    descricao: "",
    idade: "",
    fazenda: "",
  });

  const [paises, setPaises] = useState([]);
  const [verFazenda, setverFazenda] = useState([]);
  const [verEspecie, setverEspecie] = useState([]);
  const [verRaca, setverRaca] = useState([]);
  useEffect(() => {
    getAllPais();
    getAllFazenda();
    getAllEspecie();
  }, []);

  //LISTAR PAIS
  const getAllPais = () => {
    getPaises()
      .then((response) => {
        setPaises(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const getAllFazenda = () => {
    getFazendas()
      .then((response) => {
        setverFazenda(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  // Listar Especies
  const getAllEspecie = () => {
    getEspecies()
      .then((response) => {
        setverEspecie(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  // Listar Raca
  const getAllRaca = (codigo) => {
    getRacasByEspecie(codigo)
      .then((response) => {
        setverRaca(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const [imgUrl, setImgUrl] = useState("");

  const {
    peso,
    raca,
    pais,
    sexoAnimal,
    cod,
    especies,
    idade,
    data,
    descricao,
    fazenda,
  } = animal;
  //

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //


  const onInputChange = (e) => {
    setAnimal({ ...animal, [e.target.name]: e.target.value });

    if (e.target.name === "especies") {
      getAllRaca(e.target.value);
    }
  };

  //Botao Salvar
  const saveUpdateAnimal = async (e) => {
    e.preventDefault();


    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("peso", peso);
    formData.append("raca", raca);
    formData.append("pais", pais);
    formData.append("sexoAnimal", sexoAnimal);
    formData.append("especies", especies);
    formData.append("idade", idade);
    formData.append("fazenda", fazenda);
    formData.append("data", data);
    formData.append("cod", cod);
    formData.append("descricao", descricao);

    //
    if (codigo) {
      await updateAnimal(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllanimal();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createAnimal(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllanimal();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadAnimal();
  }, []);
  //
  const loadAnimal = () => {
    if (codigo) {
      getAnimalCodigo(codigo)
        .then((response) => {
          setAnimal(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };
  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setImgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };
  //


  //
  async function validate() {
    let schema = yup.object().shape({

      descricao: yup
        .string("Adicione uma descrição ao animal!")
        .required("Adicione uma descrição ao animal!"),

      pais: yup
        .string("Selecionar o pais é obrigatório!")
        .required("Selecionar o pais é obrigatório!"),

      sexoAnimal: yup
        .string("Informar o sexo do animal é obrigatório!")
        .required("Informar o sexo do animal é obrigatório!"),

      idade: yup
        .string("Adicionar a idade é obrigatório!")
        .required("Adicionar a idade é obrigatório!"),

      peso: yup
        .string("Informar o peso é obrigatório!")
        .required("Informar o peso é obrigatório!"),

      raca: yup
        .string("Selecionar a raca é obrigatório!")
        .required("Selecionar a raca é obrigatório!"),

      especies: yup
        .string("Selecionar a especie é obrigatório!")
        .required("Selecionar a especie é obrigatório!"),

      fazenda: yup
        .string("Selecionar a Fazenda pertencente ao Animal é obrigatório!")
        .required("Selecionar a Fazenda pertencente ao Animal é obrigatório!"),

      cod: yup
        .string("O código é obrigatório!")
        .required("O código do Animal é obrigatório!"),

    });
    try {
      await schema.validate(animal);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
    //
    const handleChange = (e, p) => {
      setPage(p);
      _DATA.jump(p);
    };
    //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/addanimais" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus"></span>
                  </button>
                </Link>
                <Link to="/relatorioanimais" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="fa fa-print">Relatórios</span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  {/* <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button> */}
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.animais")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th className="th">Imagem</th>
                          <th scope="col">{i18n.t("messages.especie")} </th>
                          <th scope="col">{i18n.t("messages.raca")} </th>
                          <th scope="col">{i18n.t("messages.sexo")} </th>
                          <th scope="col">{i18n.t("messages.opcao")} </th>
                        </tr>
                      </thead>
                      <tbody>
                      {_DATA
                          .currentData()
                          .filter((row) => {
                            const descricao = row.descricao
                              .toLowerCase()
                              .includes(search.toLocaleLowerCase());
                            if (search === 0) {
                              return row;
                            } else if (descricao) {
                              return row;
                            }
                          }).map((animal) => (
                          <tr key={animal.codigo}>
                            <td className="td">
                              <img
                                src={`${animal.imgUrl}`}
                                className="IMGListBig"
                                alt="Imagem do Animal"
                                title="Imagem do Animal"
                              />
                            </td>
                            <td data-label="Especie:">{animal.raca.especie.especieAnimal}</td>
                            <td data-label="Raça:">{animal.raca.racaAnimal}</td>
                            <td data-label="Sexo:">{animal.sexoAnimal}</td>
                            <td data-label="Adicionais:">
                              <Link
                                to={`/viewanimal/${animal.codigo}`}
                                className="btn btn-primary"
                              >
                                <span className="las la-eye link-add"></span>
                              </Link>
                              {/*  */}
                              <Link
                                to={`/editanimal/${animal.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>
                              <Link
                                to={`/relatorioanimal/${animal.codigo}`}
                                className="btn btn-primary"
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="fa fa-print link-add-print"></span>
                              </Link>
                              {/*  */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deleteAnimais(animal.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                        <Pagination
                            count={count}
                            size="small"
                            // className="page-link"
                            page={page}
                            variant="text"
                            color="success"
                            shape="circular"
                            onChange={handleChange}
                          />
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
              {/* ANIMAIS */}

              {/* <!-- .content --> */}

              <form
                enctype="multipart/form-data"
                onSubmit={(e) => saveUpdateAnimal(e)}
              >

                {status.type === "error" ? (
                  <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
                ) : (
                  ""
                )}

                {/* ANIMAL */}
                <div className="row">
                  <div className="col-6">
                    <div className="input-group">
                      <div className="preview">
                        <img id="file-ip-1-preview" />
                      </div>
                      {/* </div> */}
                      <label className="custom-btn" for="file-ip-1">
                        {i18n.t("messages.carregar_foto")}
                      </label>
                      <input
                        type="file"
                        id="file-ip-1"
                        accept="image/*"
                        name="imgUrl"
                        className="form-control cc-cvc"
                        onChange={(e) => showPreview(e)}
                        hidden
                      ></input>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <input
                        id=""
                        name="cod"
                        type="text"
                        className="form-control cc-exp"
                        value={cod}
                        placeholder={i18n.t("messages.codigo")}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-4">
                    <div className="input-group">
                      <select
                        data-placeholder="Selecionar..."
                        className="form-control cc-exp"
                        tabindex="1"
                        name="fazenda"
                        value={fazenda}
                        onChange={(e) => onInputChange(e)}
                        placeholder={i18n.t("messages.fazenda")}
                      >
                        <option>Selecionar Fazenda</option>
                        {verFazenda.map((fazenda) => (
                          <option value={fazenda.codigo}>{fazenda.nome}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/*  */}
                  <div className="col-4">
                    <div className="input-group">
                      <select
                        data-placeholder="Selecionar..."
                        className="form-control cc-exp"
                        tabindex="1"
                        name="especies"
                        value={especies}
                        onChange={(e) => onInputChange(e)}
                        placeholder={i18n.t("messages.especie")}
                      >
                        <option>Selecionar Especie</option>
                        {verEspecie.map((especie) => (
                          <option value={especie.codigo}>
                            {especie.especieAnimal}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/*  */}
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-4">
                    <div className="input-group">
                      <select
                        data-placeholder="Selecionar..."
                        className="form-control cc-exp"
                        tabindex="1"
                        name="raca"
                        value={raca}
                        onChange={(e) => onInputChange(e)}
                        placeholder={i18n.t("messages.raca")}
                      >
                        <option>Selecionar a Raça</option>
                        {verRaca.map((raca) => (
                          <option value={raca.codigo}>{raca.racaAnimal}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="form-group">
                      <input
                        id=""
                        name="peso"
                        type="number"
                        className="form-control cc-exp"
                        value={peso}
                        // placeholder={i18n.t("messages.peso")}
                        placeholder="Peso Kg"
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <input
                        id=""
                        name="idade"
                        type="number"
                        className="form-control cc-exp"
                        value={idade}
                        // placeholder={i18n.t("messages.idade")}
                        placeholder="Idade Meses"
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-6">
                    <div className="input-group">
                      <select
                        id=""
                        name="sexoAnimal"
                        className="form-control cc-exp"
                        value={sexoAnimal}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option>Selecionar Sexo</option>
                        <option value="MACHO">Macho</option>
                        <option value="FEMEA">Fémea</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="input-group">
                      <select
                        data-placeholder="Selecionar Proveniência..."
                        className="form-control cc-exp"
                        tabindex="1"
                        name="pais"
                        value={pais}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option
                          value=""
                          label={i18n.t("messages.selecionar_pais")}
                        ></option>
                        {paises.map((pais) => (
                          <option key={pais.codigo} value={pais.codigo}>
                            {pais.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/*  */}
                <br />
                {/*  */}

                <div className="row">
                  <div className="col-12">
                    <div className="row form-group">
                      <div className="col-12 col-md-12">
                        <textarea
                          name="descricao"
                          id="textarea-input"
                          rows="6"
                          value={descricao}
                          onChange={(e) => onInputChange(e)}
                          placeholder={i18n.t("messages.descricao")}
                          className="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  */}

                {/*  */}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "#b46739",
                      color: "#fff",
                    }}
                  >
                    {i18n.t("buttons.cancelar")}
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      backgroundColor: "#9ba239",
                      color: "#fff",
                    }}
                  >
                    <span className="lar la-save">
                      {i18n.t("buttons.save")}
                    </span>
                  </button>
                </div>
                {/*  */}
              </form>
              {/* FIM ANIMAIS */}
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListAnimal;
