import React from "react";
import HeaderMenu from "../../components/layout/header/HeaderMenu";
import Sidebar from "../../components/layout/sliderbar/Sidebar";

const Menu = () => {
  return (
    <div className="body">
      <HeaderMenu />
      <Sidebar />
    </div>
  );
};

export default Menu;
