import React from "react"; 



import { useState } from 'react';
import { i18n } from '../../Traducao/translate/i18n';




import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


          //total de animais leiluados por especie

const options = {
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "left",
    },
    title: {
      display: true,
      text: "TOTAL DE ANIMAIS LEILUADOS POR FAZENDA 2009 A 2013",
    },
  },
};

const labels = [
  'FAZ.ACHOR',
  'FAZ.CRESCENTE',
  'FAZ.KAMPADA',
  'FAZ.3N',
  'FAZ.TCHINCOMBE',
  'FAZ.ANGOSTRICH',
  'FAZ.ASSUNÇÃO',
  'FAZ.KAVIOMBO', 
  'FAZ.TREVO',
  'FAZ.CAIMONE',
  'FAZ.GONGOLOLOLO',
  'FAZ.MABOQUEIROS',
  'FAZ.STºMARIA',
  'FAZ.UTALALA', 
  'FAZ.CD',
  'FAZ.H.S',
  'FAZ.NHALUQUEQUETE',
  'FAZ.TCHISOLA K',
  'FAZ.SULCARNES',
  'FAZ.VIMI',
  'FAZ.AGROSOL', 
  'FAZ.AJC',
  'FAZ.CARMAN',
  'FAZ.GENIAL',
  'FAZ.TCHIMBOLOLELO',
  'FAZ.KATEKEMBWA',
  'FAZ.PAU DO CAÇADOR',
  'FAS. STº ANTÓNIO',
  'FAZ.TANDO',
  'FAZ.JANBAA',
];
const data = {
  labels,
  datasets: [
    {
      label: "",
      backgroundColor: ['#9ba236'],
      borderColor: '#424616',
      data: [44, 48, 28, 430, 68, 521, 33, 46, 117, 71, 114, 46, 105, 24, 29, 52, 317, 12, 60, 163, 42, 12, 3, 8, 34, 61, 25, 45, 8, 51, 39],
    },
  ],
};
const Graficoanimais_2 = () => {
  return (
    <div id="right-panel" className="right-panel">
      {/*  */}
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
              

                <div className="col-xs-12 col-sm-12">
                  <div className="row">
                    {/* <div style={{ width: "800", height: "500" }}> */}
                    <Bar data={data} options={options} /> 
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- .animated --> */}
      </div>
      {/* <!-- .content --> */}

      <div className="clearfix"></div>
      {/*  */}
    </div>


  );
};

 



                        

export default Graficoanimais_2;
