import api from "./api";

export const getComissoes = async () => {
  try {
    return await api.get("comissaos");
  } catch (error) {
    console.log(error.message);
  }
};
export const createComissao = async (data) => {
  try {
    return await api.post("comissao", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getComissaoCodigo = async (codigo) => {
  try {
    return await api.get(`comissao/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateComissao = async (data) => {
  try {
    return await api.put("comissao", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeComissao = async (codigo) => {
  try {
    return await api.delete(`comissao/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
