import Menu from "../../components/layout/Menu";
import ListParticipantes from "../../components/listTable/ListParticipantes";

const Usuarios = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListParticipantes />
      </div>
      {/*  */}
    </>
  );
};

export default Usuarios;
