import api from "./api";

export const getProvincias = async () => {
  try {
    return await api.get("Provincia");
  } catch (error) {
    console.log(error.message);
  }
};
export const getProvinciaPais = async (codigo) => {
  try {
    return await api.get("provinciaPais/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const createProvincia = async (data) => {
  try {
    return await api.post("criar_provincia", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getProvinciaCodigo = async (codigo) => {
  try {
    return await api.get(`agenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateProvincia = async (data) => {
  try {
    return await api.put("agenda", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeProvincia = async (codigo) => {
  try {
    return await api.delete(`Agenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
