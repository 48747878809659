import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import ImpostoService, {
  createImposto,
  getImpostoCodigo,
  getImpostos,
  removeImposto,
  updateImposto,
} from "../../service/ImpostoService";
import * as messages from "../message/toastr";
import Swal from "sweetalert2";
import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const ListImposto = () => {
  const { codigo } = useParams();
  const [impostos, setImpostos] = useState([]);

  //
  useEffect(() => {
    sgetAllImpostos();
  }, []);

  //LISTAR
  const sgetAllImpostos = () => {
    getImpostos()
      .then((response) => {
        setImpostos(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deleteimposto = (codigo) => {
    Swal.fire({
      // title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeImposto(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          sgetAllImpostos();
        });
      }
    });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  //SALVAR
  const [descricao, setDescricao] = useState("");
  const [iva, setIva] = useState("");

  //BOTAO SALVAR E ACTUALIZAR
  const saveUpdateImposto = (e) => {
    e.preventDefault();

    const imposto = {
      descricao,
      iva,
      codigo,
    };
    //

    // if (!(await validate())) return;
    //
    if (codigo) {
      updateImposto(imposto)
        .then((response) => {
          sgetAllImpostos();
          messages.mensagemSucesso("Actualizado!", response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Error ao actualizado!", error.response.data);
        });
    } else {
      createImposto(imposto)
        .then((response) => {
          sgetAllImpostos();

          messages.mensagemSucesso("Cadastrado!", response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Error ao cadastrado!", error.response.data);
        });
    }
  };
  //
  useEffect(() => {
    if (codigo) {
      getImpostoCodigo(codigo)
        .then((response) => {
          setIva(response.data.nome);
        })
        .catch((error) => {
          messages.mensagemErro(error.response.data);
        });
    }
  }, []);

  //
  // async function validate() {
  //   let schema = yup.object().shape({

  //     descricao: yup
  //       .string("Por favor, Informe a descrição do Imposto!")
  //       .required("Por favor, Informe a descrição do Imposto!"),

  //     iva: yup
  //       .string("Por favor, Informe o valor do Imposto!")
  //       .required("Por favor, Informe o valor do Imposto!"),

  //   });
  //   try {
  //     await schema.validate(iva);
  //     return true;
  //   } catch (err) {
  //     setStatus({
  //       type: "error",
  //       mensagem: err.errors,
  //     });
  //     return false;
  //   }
  // }
  //

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/addimpostos" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus">
                      {i18n.t("messages.novo")}
                    </span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_iva")}
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button>
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.imposto")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.iva")}</th>

                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {impostos.map((imposto) => (
                          <tr key={imposto.codigo}>
                            <td data-label="Imposto:">{imposto.iva} %</td>
                            <td data-label="Adicionais:">
                              {/*  */}
                              <Link
                                to={`/imposto/editImposto/${imposto.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>
                              {/*  */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deleteimposto(imposto.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                              {/*  */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
              <div className="tab-content pl-3 pt-2" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  {/*  */}

                  <form onSubmit={(e) => saveUpdateImposto(e)}>

                    {/* {status.type === "error" ? (
                      <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
                    ) : (
                      ""
                    )} */}

                    {/* imposto */}

                    {/*  */}
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            name="descricao"
                            type="text"
                            className="form-control cc-exp"
                            // placeholder={i18n.t("messages.iva")}
                            placeholder="Descrição"
                            value={descricao}
                            // onChange={(e) => onInputChange(e)}
                            onChange={(e) => setDescricao(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="form-group">
                          <input
                            name="iva"
                            type="number"
                            className="form-control cc-exp"
                            // placeholder={i18n.t("messages.iva")}
                            placeholder="Imposto %"
                            value={iva}
                            // onChange={(e) => onInputChange(e)}
                            onChange={(e) => setIva(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    {/*  */}

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                        style={{
                          backgroundColor: "#b46739",
                          color: "#fff",
                        }}
                      >
                        {i18n.t("buttons.cancelar")}
                      </button>
                      <button
                        type="submit"
                        className="btn"
                        style={{
                          backgroundColor: "#9ba239",
                          color: "#fff",
                        }}
                      >
                        <span className="lar la-save">
                          {i18n.t("buttons.save")}
                        </span>
                      </button>
                    </div>
                    {/*  */}
                    {/* FIM imposto */}
                  </form>

                  {/*  */}
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListImposto;
