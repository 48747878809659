import React, { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  //Barar as rotas
  const navigate = useNavigate();
  //
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  //
  useEffect(() => {
    //recuper o usuario logado no localStorege da aplicacao
    const recoveredUser = localStorage.getItem("user");
    //
    if (recoveredUser) {
      setUser(JSON.parse(recoveredUser));
    }
    setLoading(false);
  }, []);
  //
  const login = (email, senha) => {
    console.log("login auth", { email, senha });
    // Api criar uma session
    const loggedUser = {
      id: "123",
      email,
    };
    //gravar o usuario logado(JSON.stringify para transformar em string)
    localStorage.setItem("user", JSON.stringify(loggedUser));
    //
    if (senha === "cwam") {
      setUser(loggedUser);
      navigate("/");
    }
  };

  const logout = () => {
    console.log("logout");
    // Remover o usuario logado
    localStorage.removeItem("user");
    //
    setUser(null);
    navigate("/login");
  };

  //
  return (
    <AuthContext.Provider
      value={{ authenticated: !!user, user, loading, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};