import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getLanceCodigo } from "../../service/LanceService";
import Menu from "../../components/layout/Menu";

const ViewLance = (props) => {
  //
  const [lance, setLance] = useState({
    dataDoLance: "",
    valorLance: "",
    leilaoItem: "",
    visitante: "",
  });

  const { codigo } = useParams();

  useEffect(() => {
    viewLance();
  }, []);
  //
  const viewLance = () => {
    if (codigo) {
      getLanceCodigo(codigo).then((response) => {
        setLance(response.data);
      });
    }
  };

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">
                <Link
                  to="/lances"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="fa fa-reply"></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">Informações do Lance</strong>
                  </div>

                  <div className="col-xs-12 col-sm-12">
                    <div className="row">
                      <div className="col-sm-8">
                        <div className="card">
                          <div className="card" style={{ width: "100%" }}>
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item">
                                <strong>Código do Licitador:</strong>{" "}
                                {lance.visitante.visitanteid}
                              </li>

                              <li className="list-group-item">
                                <strong>Nome:</strong> {lance.visitante.nome}
                              </li>

                              <li className="list-group-item">
                                <strong>E-mail:</strong> {lance.visitante.email}
                              </li>

                              <li className="list-group-item">
                                <strong>Telefone:</strong>{" "}
                                {lance.visitante.telefone}
                              </li>

                              <li className="list-group-item">
                                <strong>BI:</strong> {lance.visitante.bi}
                              </li>

                              <li className="list-group-item">
                                <strong>Data do Lance:</strong>{" "}
                                {lance.dataDoLance}
                              </li>

                              <li className="list-group-item">
                                <strong>Valor Inicial do Leilão:</strong>{" "}
                                {lance.leilaoItem.preco} KZ
                              </li>

                              <li className="list-group-item">
                                <strong>Valor do Lance do Licitador:</strong>{" "}
                                {lance.valorLance} KZ
                              </li>

                              {/* <li className="list-group-item">
                                <strong>Leilão:</strong> {lance.leilaoItem.leilao.nome}
                              </li> */}

                              {/* <li className="list-group-item">
                                <strong>Leilão:</strong>
                                {lances.map((lance) => (
                                  <tr key={lance.codigo}>
                                    <td>{lance.leilaoItem.leilao.nome}</td>
                                  </tr>
                                ))}
                              </li>

                              <li className="list-group-item">
                                <strong>Leilão</strong>
                                {lances.map((lance) => (
                                  <tr key={lance.codigo}>
                                    <td>{lance.leilaoItem.animal.nome}</td>
                                  </tr>
                                ))}
                              </li> */}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}
    </>
  );
};

export default ViewLance;
