import Menu from "../../components/layout/Menu";
import ListMensagem from "../../components/listTable/ListMensagem";

const Mensagem = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListMensagem />
      </div>
      {/*  */}
    </>
  );
};

export default Mensagem;
