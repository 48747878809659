import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getLoteCodigo } from "../../service/LoteService";
import { } from "../../service/RapidoService";
import { } from "../../service/LoteItemService";
import Menu from "../../components/layout/Menu";

import {
  getAnimalLote,
  getloteRapidoByLote,
} from "../../service/LoteItemService";

const ViewAnimal = (props) => {
  const [lote, setLote] = useState({
    imgUrl: "",
    nome: "",
    cod: "",
    fazenda: "",
    loteVendaLeilao: "",
    especie: "",
  });

  const { codigo } = useParams();

  useEffect(() => {
    viewLote();
  }, []);

  //
  const viewLote = () => {
    if (codigo) {
      getLoteCodigo(codigo).then((response) => {
        setLote(response.data);
      });
    }
  };

  const [VerLoteRapido, setVerLoteRapido] = useState([]);
  const [VerLoteExistente, setVerLoteExistente] = useState([]);
  const [ItemLote, setItemLote] = useState();

  if (lote.existente == true && lote.geral == false) {
    console.log("este lote foi cadastrado como existente");
  } else {
    console.log("este lote foi cadastrado como geral");
  }

  getloteRapidoByLote(lote.codigo)
    .then((response) => {
      setVerLoteRapido(response.data);
    })
    .catch((err) => {
      console.log("Ocorreu um erro ==> " + err);
    });

  getAnimalLote(lote.codigo)
    .then((response) => {
      setVerLoteExistente(response.data);
    })
    .catch((err) => {
      console.log("Ocorreu um erro ==> " + err);
    });

  //
  const onInputChange = (e) => {
    setLote({ ...lote, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">
                <Link
                  to="/lotes"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="fa fa-reply"></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">Informações do Lote</strong>
                  </div>

                  <div className="col-xs-12 col-sm-12">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="card">
                          <div className="card-body">
                            <img
                              src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirImagemAnimal/${lote.imgUrl}`}
                              className="foto-animal"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-9">
                        <div className="card">
                          <div className="card" style={{ width: "100%" }}>
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item">
                                <strong>Fazenda:</strong> {lote.fazenda.nome}
                              </li>
                              <li className="list-group-item">
                                <strong>Código:</strong> {lote.cod}
                              </li>
                              <li className="list-group-item">
                                <strong>Lote:</strong> {lote.nome}
                              </li>
                              <li className="list-group-item">
                                <strong>Estado:</strong> {lote.loteVendaLeilao}
                              </li>
                              <li className="list-group-item">
                                <strong>Quantidade:</strong> {lote.quantidade}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="card-header"
                      style={{ background: "#b46739", color: "#fff" }}
                    >
                      <strong className="card-title">
                        Animais Adicionados ao Lote
                      </strong>
                    </div>

                    {lote.geral == true ? (
                      <div>
                        <table className="table">
                          <tr>
                            <tr>
                              <th>Quantidade</th>
                              <th>Machos</th>
                              <th>Fêmeas</th>
                              <th>Peso Mínimo</th>
                              <th>Peso Máximo</th>
                              <th>Idade Mínima</th>
                              <th>Idade Máxima</th>
                              <th>Apto para</th>
                            </tr>
                            {VerLoteRapido.map((loteItem) => (
                              <tr>
                                <td>{loteItem.qtd}</td>
                                <td>{loteItem.numerosexoM}</td>
                                <td>{loteItem.numerosexoF}</td>
                                <td>{loteItem.pesomin}Kg</td>
                                <td>{loteItem.pesomaxi}Kg</td>
                                <td>{loteItem.idademin} Mêses</td>
                                <td>{loteItem.idademax} Mêses</td>
                                <td>{loteItem.apto}</td>
                              </tr>
                            ))}
                          </tr>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}

                    {lote.existente === true ? (
                      <div>
                        <table className="table">
                          <tr>
                            <tr>
                              <th>Imagem</th>
                              <th>Raça</th>
                              <th>Peso Kg</th>
                              <th>Idade</th>
                              <th>Sexo</th>
                              <th>Proveniência</th>
                            </tr>
                            {VerLoteExistente.map((loteItem) => (
                              <tr>
                                <td>
                                  <img
                                    src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirImagemAnimal/${loteItem.animal.imgUrl}`}
                                    style={{
                                      width: "100px",
                                      borderRadius: "8px",
                                    }}
                                  />
                                </td>
                                <td>{loteItem.animal.raca.racaAnimal}</td>
                                <td>{loteItem.animal.peso}</td>
                                <td>{loteItem.animal.idade}</td>
                                <td>{loteItem.animal.sexoAnimal}</td>
                                <td>{loteItem.animal.pais.nome}</td>
                              </tr>
                            ))}
                          </tr>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}
    </>
  );

  function newFunction() {
    setItemLote("existente");
  }
};

export default ViewAnimal;
