import Menu from "../../components/layout/Menu";
import ListImposto from "../../components/listTable/ListImposto";

const Imposto = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListImposto />
      </div>
      {/*  */}
    </>
  );
};

export default Imposto;
