import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getFuncionarios,
  createFuncionario,
  getCodigoFuncionario,
  updateFuncionario,
} from "../../service/FuncionarioService";
import {
  getPermissoes
} from "../../service/PermissaoService";
import * as messages from "../message/toastr";

import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const TabFuncionario = () => {
  const { codigo } = useParams();
  const [funcionarios, setFuncionarioes] = useState([]);
  const [permissoes, setPermissoes] = useState(null);


  //
  useEffect(() => {
    getAllFuncionario();
    getAllPermissoes();
  }, []);

  //LISTAR
  const getAllFuncionario = () => {
    getFuncionarios()
      .then((response) => {
        setFuncionarioes(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  //PERMISSOES
  const getAllPermissoes = () => {
    getPermissoes()
      .then((response) => {
        const permissaoTemporarias = [];
        response.data.forEach(element => {
          permissaoTemporarias.push({ permissao: element })
        });
        setPermissoes(permissaoTemporarias);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //SALVAR
  const [funcionario, setFuncionario] = useState({
    imgUrl: "",
    nome: "",
    sobrenome: "",
    email: "",
    telefone: "",
    bi: "",
    gender: "",
    nascimento: "",
    senha: "",
    endereco: "",
    permissaoFuncionarios: []
  });

  //Limpar
  const handleClear = () => {
    setFuncionario({
      imgUrl: "",
      nome: "",
      sobrenome: "",
      email: "",
      telefone: "",
      bi: "",
      gender: "",
      nascimento: "",
      senha: "",
      endereco: "",
      //   permissaoFuncionarios:[]
    });
  };
  const [imgUrl, setimgUrl] = useState("");

  const {
    nome,
    sobrenome,
    email,
    telefone,
    bi,
    gender,
    nascimento,
    senha,
    endereco,
    permissaoFuncionarios,
  } = funcionario;
  //
  const onInputChange = (e) => {
    setFuncionario({ ...funcionario, [e.target.name]: e.target.value });
  };

  //Botao Salvar
  const saveUpdatefuncionario = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("nome", nome);
    formData.append("sobrenome", sobrenome);
    formData.append("email", email);
    formData.append("telefone", telefone);
    formData.append("bi", bi);
    formData.append("gender", gender);
    formData.append("nascimento", nascimento);
    formData.append("senha", senha);
    formData.append("endereco", endereco);
    formData.append("permissaoFuncionarios", permissaoFuncionarios);
    //
    if (codigo) {
      await updateFuncionario(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllFuncionario();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createFuncionario(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllFuncionario();
          handleClear();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadfuncionario();
  }, []);
  //
  const loadfuncionario = () => {
    if (codigo) {
      getCodigoFuncionario(codigo)
        .then((response) => {
          setFuncionarioes(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };
  //
  //

  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setimgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };
  //

  return (
    <div>
      <form
        enctype="multipart/form-data"
        onSubmit={(e) => saveUpdatefuncionario(e)}
      >
        {/* funcionario */}

        <div className="row">
          <div className="col-6">
            <div className="input-group">
              <div className="preview">
                <img id="file-ip-1-preview" />
              </div>
              {/* </div> */}
              <label className="custom-btn" for="file-ip-1">
                {i18n.t("messages.carregar_foto")}
              </label>
              <input
                type="file"
                id="file-ip-1"
                accept="image/*"
                name="imgUrl"
                className="form-control cc-cvc"
                hidden
                onChange={(e) => showPreview(e)}
              ></input>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                id=""
                name="nome"
                type="text"
                className="form-control cc-exp"
                value={nome}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.nome")}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <input
                id=""
                name="sobrenome"
                type="text"
                className="form-control cc-exp"
                value={sobrenome}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.sobrenome")}
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                id=""
                name="email"
                type="text"
                className="form-control cc-exp"
                value={email}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.email")}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <input
                id=""
                name="telefone"
                type="text"
                className="form-control cc-exp"
                value={telefone}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.telefone")}
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-4">
            <div className="input-group">
              <input
                id=""
                name="bi"
                type="text"
                className="form-control cc-exp"
                value={bi}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.bi")}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="input-group">
              <input
                id=""
                name="email"
                type="email"
                className="form-control cc-exp"
                value={email}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.email")}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <input
                id=""
                name="endereco"
                type="text"
                className="form-control cc-exp"
                value={endereco}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.endereco")}
              />
            </div>
          </div>
        </div>

        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span>{i18n.t("buttons.save")}</span>
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabFuncionario;
