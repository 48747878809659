import React, { useState, useEffect } from "react";
import { i18n } from "../../Traducao/translate/i18n";
import { Link, useNavigate, useParams } from "react-router-dom";
import Menu from "../layout/Menu";

import * as messages from "../message/toastr";
import Swal from "sweetalert2";

import { getArtigos, removeArtigo } from "../../service/VendaService";

import StokLote from "../tabModalStock/StokLote";
import StokAnimal from "../tabModalStock/StokAnimal";
import StokProduto from "../tabModalStock/StokProduto";

const I18N_STORAGE_KEY = "i18nextLng";

const ListVenda = () => {
  const { codigo } = useParams();
  const [vendas, setvendas] = useState([]);

  //
  useEffect(() => {
    getAllVendas();
  }, []);

  //LISTAR
  const getAllVendas = () => {
    getArtigos()
      .then((response) => {
        setvendas(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deletevendas = (codigo) => {
    Swal.fire({
      // title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeArtigo(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllVendas();
        });
      }
    });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        // <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
        <h3 style={{ color: "#fff" }}> Entrada ao Stock </h3>
      );
    }
  };
  //

  const [EscolhaCAtegoria, setEscolhaCAtegoria] = useState(0);

  const verCategoriaTrocar = (e) => {
    if (e.target.value === "lote") {
      setEscolhaCAtegoria("lote");
    }
    if (e.target.value === "animal") {
      setEscolhaCAtegoria("animal");
    }
    if (e.target.value === "produto") {
      setEscolhaCAtegoria("produto");
    }
  };

  const [TrocarModal, setTrocarModal] = useState(false);
  //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-6 col-sm-6">
                <Link to="/addvendas" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus"></span>
                  </button>
                </Link>

                <Link to="/relatoriostocks" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="fa fa-print">Relatórios</span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                  {/* <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button> */}
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header "
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.stock")}
                    </strong>
                    {/*  */}
                    {/* <Link to="/stockAnimal" id="event-modal">
                      <span
                        className="btn btn-flat m-b-30 m-t-30"
                        style={{
                          background: "#b46739",
                          color: "#fff",
                          marginLeft: "10px",
                        }}
                      >
                        Stock de Animais
                      </span>
                    </Link> */}
                    {/*  */}
                    <Link to="/stockProduto" id="event-modal">
                      <span
                        className="btn btn-flat m-b-30 m-t-30"
                        style={{
                          background: "#b46739",
                          color: "#fff",
                          marginLeft: "10px",
                        }}
                      >
                        Stock de Produtos
                      </span>
                    </Link>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          {/* <th scope="col">{i18n.t("messages.lote")}</th> */}
                          <th scope="col">
                            {i18n.t("messages.preco_inicial")}
                          </th>
                          <th scope="col">{i18n.t("messages.iva")}</th>
                          <th scope="col">{i18n.t("messages.comissao")}</th>
                          {/* <th scope="col">{i18n.t("messages.cooperador")}</th> */}
                          <th scope="col">
                            {i18n.t("messages.preco_de_venda")}
                          </th>
                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vendas.map((venda) => (
                          <tr key={venda.codigo}>
                            {/* <td>{venda.animalLote.animal.nome}</td> */}
                            <td data-label="Preço:">{venda.preco}kz</td>
                            <td data-label="Iva:">
                              {venda.totalValorIva == 0
                                ? "Sem Iva"
                                : `${venda.totalValorIva}kz (${venda.imposto.iva}%)`}
                            </td>
                            <td data-label="Comissão:">
                              {venda.totalCooperativa}kz ({venda.comissao.quota}
                              %)
                            </td>
                            {/* <td data-label="Valor Coop:">{venda.totalCooperador}kz</td> */}
                            <td data-label="Valor Coop:">{venda.totalValorCrescinto}kz</td>
                            <td data-label="Adicionais:">
                              <Link 
                                to={`/viewvenda/${venda.codigo}`}
                                className="btn btn-primary"
                              >
                                <span className="las la-eye link-add"></span>
                              </Link>
                              {/*  */}
                              <Link
                                to={`/editvenda/${venda.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>

                              <Link
                                to={`/relatoriostock/${venda.codigo}`}
                                className="btn btn-primary"
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="fa fa-print link-add-print"></span>
                              </Link>

                              {/*  */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deletevendas(venda.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/*  */}
        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            {TrocarModal === false ? (
              //
              <div>
                <div className="card-body">
                  <div className="row" id="labelStock">
                    <div className="col-3">
                      <input
                        type="radio"
                        name="categoriatroca"
                        value="lote"
                        id="lote"
                        style={{ display: "none" }}
                        onChange={(e) => verCategoriaTrocar(e)}
                      />
                      <label
                        id="labelLote"
                        for="lote"
                        data-toggle="modal"
                        data-target="#modallote"
                        className="btn"
                      >
                        Lote
                      </label>
                    </div>
                    {/*  */}
                    {/* <div className="col-3">
                      <input
                        type="radio"
                        name="categoriatroca"
                        value="animal"
                        id="animal"
                        style={{ display: "none" }}
                        onChange={(e) => verCategoriaTrocar(e)}
                      />
                      <label
                        id="labelAnimal"
                        for="animal"
                        data-toggle="modal"
                        data-target="#modallote"
                        className="btn "
                      >
                        Animal
                      </label>
                    </div> */}
                    {/*  */}

                    <div className="col-3">
                      <input
                        type="radio"
                        name="categoriatroca"
                        value="produto"
                        id="produto"
                        style={{ display: "none" }}
                        onChange={(e) => verCategoriaTrocar(e)}
                      />
                      <label
                        id="labelProduto"
                        for="produto"
                        data-toggle="modal"
                        data-target="#modallote"
                        className="btn"
                      >
                        Produto
                      </label>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="modal-footer" style={{ marginTop: "50px" }}>
                  <button
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "#ccc",
                      color: "#000",
                    }}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            ) : (
              //
              <div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-3">
                      <input
                        type="radio"
                        name="categoriatroca"
                        value="lote"
                        id="lote"
                        style={{ display: "none" }}
                        onChange={(e) => verCategoriaTrocar(e)}
                      />
                      <label
                        for="lote"
                        data-toggle="modal"
                        data-target="#modallote"
                        class="btn btn-secondary mb-1"
                        style={{ marginRight: "10px", width: "140px" }}
                      >
                        Lote Geral
                      </label>
                    </div>

                    <div className="col-3">
                      <input
                        type="radio"
                        name="categoriatroca"
                        value="produto"
                        id="produto"
                        style={{ display: "none" }}
                        onChange={(e) => verCategoriaTrocar(e)}
                      />
                      <label
                        for="produto"
                        data-toggle="modal"
                        data-target="#modallote"
                        class="btn btn-secondary"
                        style={{ marginLeft: "90px", width: "273px" }}
                      >
                        Adicionar Animais existentes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer" style={{ marginTop: "50px" }}>
                  <button
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "#ccc",
                      color: "#000",
                    }}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            )}

            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Inicio Item Lote BOTAO------> */}

      {/* <!-------- Cadastro lote, EXTENSIVO e EXTENSIVO ------> */}
      <div
        className="modal fade"
        id="modallote"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modallote"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/* Escolha de categorias */}
            <div className="card-body">
              {EscolhaCAtegoria === "lote" ? <StokLote /> : ""}

              {EscolhaCAtegoria === "animal" ? <StokAnimal /> : ""}

              {EscolhaCAtegoria === "produto" ? <StokProduto /> : ""}
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListVenda;