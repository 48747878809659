import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  createFornecedor,
  getFornecedorCodigo,
  getFornecedores,
  updateFornecedor,
} from "../../service/FornecedoresService";
import * as messages from "../message/toastr";

import * as yup from "yup";

import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const TabFornecedor = () => {
  const { codigo } = useParams();
  const [fornecedor, setFornecedor] = useState([]);

  //
  useEffect(() => {
    getAllFornecedores();
  }, []); 

  //LISTAR
  const getAllFornecedores = () => {
    getFornecedores()
      .then((response) => {
        setFornecedor(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //SALVAR
  const [Fornecedor, setFornecedores] = useState({
    imgUrl: "",
    nome: "",
    telefone: "",
    email: "",
    bi: "",
    morada: "",
  });

  //Limpar
  const handleClear = () => {
    setFornecedores({
      imgUrl: "",
      nome: "",
      telefone: "",
      email: "",
      bi: "",
      morada: "",
    })
  }
  const [imgUrl, setimgUrl] = useState("");

  const { nome, telefone, email, bi, morada } = Fornecedor;
  //

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  // 


  const onInputChange = (e) => {
    setFornecedores({ ...Fornecedor, [e.target.name]: e.target.value });
  };

  //Botao Salvar
  const saveUpdateFornecedor = async (e) => {
    e.preventDefault();

    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("nome", nome);
    formData.append("telefone", telefone);
    formData.append("email", email);
    formData.append("bi", bi);
    formData.append("morada", morada);
    //

    if (codigo) {
      await updateFornecedor(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllFornecedores();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createFornecedor(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllFornecedores();
          handleClear();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadFornecedor();
  }, []);
  //
  const loadFornecedor = () => {
    if (codigo) {
      getFornecedorCodigo(codigo)
        .then((response) => {
          setFornecedores(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };
  //
  //

  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setimgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };
  //

  //
  async function validate() {
    let schema = yup.object().shape({

      morada: yup
        .string("Por favor, Informe a Morada do Fornecedor!")
        .required("Por favor, Informe a Morada do Fornecedor!"),

      email: yup
        .string("Preencha o campo de Email!")
        .required("Preencha o campo de Email!"),

      bi: yup
        .string("Preencer o Bilhete de Identidade é obrigatório!")
        .required("Preencer o Bilhete de Identidade é obrigatório!"),

      telefone: yup
        .string("Por favor, informe  o Telefone!")
        .required("Por favor, informe  o Telefone!"),

      nome: yup
        .string("Por favor, Preencer o nome é obrigatório!")
        .required("Por favor, Preencer o nome é obrigatório!"),

    });
    try {
      await schema.validate(Fornecedor);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //
  
  return (
    <div> 
      <form
        enctype="multipart/form-data"
        onSubmit={(e) => saveUpdateFornecedor(e)}
      >
        {status.type === "error" ? (
          <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
        ) : (
          ""
        )}

        {/* Fornecedor */}

        <div className="row">
          <div className="col-6">
            <div className="input-group">
              <div className="preview">
                <img id="file-ip-1-preview" />
              </div>
              {/* </div> */}
              <label className="custom-btn" for="file-ip-1">
                {i18n.t("messages.carregar_foto")}
              </label>
              <input
                type="file"
                id="file-ip-1"
                accept="image/*"
                name="imgUrl"
                className="form-control cc-cvc"
                hidden
                onChange={(e) => showPreview(e)}
              ></input>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                id=""
                name="nome"
                type="text"
                className="form-control cc-exp"
                value={nome}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.nome")}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <input
                id=""
                name="telefone"
                type="text"
                className="form-control cc-exp"
                value={telefone}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.telefone")}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="input-group">
              <input
                id=""
                name="bi"
                type="text"
                className="form-control cc-exp"
                value={bi}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.bi")}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="input-group">
              <input
                id=""
                name="email"
                type="email"
                className="form-control cc-exp"
                value={email}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.email")}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <input
                id=""
                name="morada"
                type="text"
                className="form-control cc-exp"
                value={morada}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.morada")}
              />
            </div>
          </div>
        </div>

        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span>{i18n.t("buttons.save")}</span>
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabFornecedor;
