import api from "./api";

export const getMunicipios = async () => {
  try {
    return await api.get("Municipio");
  } catch (error) {
    console.log(error.message);
  }
};
export const getMunicipioProvinca = async (codigo) => {
  try {
    return await api.get("municipioProvincia/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const createMunicipio = async (data) => {
  try {
    return api.post("criar_municipio", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getMunicipioCodigo = async (codigo) => {
  try {
    return await api.get(`agenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateMunicipio = async (data) => {
  try {
    return await api.put("agenda", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeMunicipio = async (codigo) => {
  try {
    return await api.delete(`Agenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
