import api from "./api";

export const getLances = async () => {
  try {
    return await api.get("lances");
  } catch (error) {
    console.log(error.message);
  }
};
export const getLanceCodigo = async (id) => {
  try {
    return await api.get(`lances/${id}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getLicitadores = async (id) => {
  try {
    return await api.get(`lances/todoslances/${id}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeLance = async (id) => {
  try {
    return await api.delete(`lances/${id}`);
  } catch (error) {
    console.log(error.message);
  }
};