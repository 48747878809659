import Menu from "../../components/layout/Menu"; 
import ListEntrega from "../../components/listTable/ListEntrega";

const Entrega = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListEntrega />
      </div>
      {/*  */}
    </>
  );
};

export default Entrega;
