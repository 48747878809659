import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import {
  createTag,
  getTagCodigo,
  getTag,
  removeTag,
  updateTag,
} from "../../service/TagService";
import * as messages from "../message/toastr";
import * as yup from "yup";
import Swal from "sweetalert2";
import usePagination from "../../components/pagination/Pagination";
import { Pagination } from '@mui/material';
import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";


const ListTag = () => {
  const { codigo } = useParams();
  const [tags, setTag] = useState([]);

  //PAGINACAO
  let [page, setPage] = useState(1);
  const PER_PAGE = 5;

  const count = Math.ceil(tags.length / PER_PAGE);
  const _DATA = usePagination(tags, PER_PAGE);
  //PESQUISAR
  const [search, setSearch] = useState("");


  //
  useEffect(() => {
    getAllTag();
  }, []);

  //LISTAR
  const getAllTag = () => {
    getTag()
      .then((response) => {
        setTag(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };


  //SALVAR  
  const [Tag, setTags] = useState({
    titulo: "",
  });

  //Limpar
  const handleClear = () => {
    setTags({
      titulo: "",
    })
  }
  const { titulo } = Tag;
  //

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //

  const onInputChange = (e) => {
    setTags({ ...Tag, [e.target.name]: e.target.value });
  };


  //Botao Salvar
  const saveUpdateTag = async (e) => {
    e.preventDefault();


    if (!(await validate())) return;

    const data = {
      titulo,
    }

    //
    if (codigo) {
      await updateTag(data)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllTag();
          handleClear();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createTag(data)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllTag();
          handleClear();

        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadCategoria();
  }, []);
  //
  const loadCategoria = () => {
    if (codigo) {
      getTagCodigo(codigo)
        .then((response) => {
          setTags(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };

  //
  async function validate() {
    let schema = yup.object().shape({
      titulo: yup
        .string("Por favor, informe o Título!")
        .required("Por favor, informe o Título!"),

    });
    try {
      await schema.validate(Tag);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  //

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/addTag" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus"></span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {/* {i18n.t("messages.especie")} */}
                      Categoria de Tags
                    </strong>
                  </div>
                  <div className="card-body">

                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.titulo")}</th>
                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {_DATA
                          .currentData()
                          .filter((row) => {
                            const titulo = row.titulo
                              .toLowerCase()
                              .includes(search.toLocaleLowerCase());
                            if (search === 0) {
                              return row;
                            } else if (titulo) {
                              return row;
                            }
                          }).map((tag) => (
                          <tr key={tag.codigo}>
                            <td data-label="Tag:">{tag.titulo}</td>
                            <td data-label="Adicionais:">
                              <Link
                                to={`/editTag/${tag.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "10px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>

                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() =>
                                  removeTag(tag.codigo)
                                }
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                        <Pagination
                            count={count}
                            size="small"
                            // className="page-link"
                            page={page}
                            variant="text"
                            color="success"
                            shape="circular"
                            onChange={handleChange}
                          />
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {/* {titulo()}  */}
              </h5>
            </div>

            <div className="card-body">
              <div className="default-tab">
                <form
                  onSubmit={(e) => saveUpdateTag(e)}
                >
                  {status.type === "error" ? (
                    <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
                  ) : (
                    ""
                  )}

                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          id=""
                          name="titulo"
                          type="text"
                          className="form-control cc-exp"
                          value={titulo}
                          onChange={(e) => onInputChange(e)}
                          placeholder={i18n.t("messages.titulo")}
                        />
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                      style={{
                        backgroundColor: "#b46739",
                        color: "#fff",
                      }}
                    >
                      {i18n.t("buttons.cancelar")}
                    </button>
                    <button
                      type="submit"
                      className="btn"
                      style={{
                        backgroundColor: "#9ba239",
                        color: "#fff",
                      }}
                    >
                      {i18n.t("buttons.save")}
                    </button>
                  </div>
                  {/*  */}
                  {/* FIM distancia */}
                </form>

                {/*  */}
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListTag;
