import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getLeilaoCodigo } from "../../service/LeilaoService";
import { getLeilaoItemCodigo } from "../../service/LeilaoItemService";
import { getParticipanteCodigo } from "../../service/ParticipanteService";
import { getAprovarParticipanteCodigo } from "../../service/ParticipanteService";
import { getReprovarParticipanteCodigo } from "../../service/ParticipanteService";
import { getPendenteParticipanteCodigo2 } from "../../service/ParticipanteService";
import Menu from "../../components/layout/Menu";
import * as messages from "../../components/message/toastr";
import Swal from "sweetalert2";

const ViewLeilao = (props) => {
  //
  const [leilao, setLeilao] = useState({
    data: "",
    fazenda: "",
    lote: "",
    caucao: "",
    hora: "",
    dia: "",
    minuto: "",
    segundo: "",
    status: "",
    valorInicial: "",
    codLeilao: "",
    nome: "",
  });

  const { codigo } = useParams();
  const [itensLeilao, setItensLeiloes] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [Dadosparticipantes, setDadosParticipantes] = useState();
  const [Dados, setDados] = useState({});
  const [comprovativoVisitante, setcomprovativoVisitante] = useState({});
  const [alterar, setAlterarEstado] = useState([]);

  useEffect(() => {
    viewLeilao();
    viewItemLeilao();
    viewParticipantes();
    mudarEstado();
  }, []);

  //LISTAR
  const viewItemLeilaos = () => {
    getLeilaoItemCodigo()
      .then((response) => {
        setItensLeiloes(response.data);
      })
  };

  const viewLeilao = () => {
    if (codigo) {
      getLeilaoCodigo(codigo).then((response) => {
        setLeilao(response.data);
      });
    }
  };

  const viewItemLeilaoc = () => {
    if (codigo) {
      getLeilaoItemCodigo(codigo).then((response) => {
        setLeilao(response.data);
      });
    }
  };

  /* lista de participantes que fizeram a inscricao para o leilao*/

  const viewParticipantes = () => {
    getParticipanteCodigo(codigo)
      .then((response) => {
        setParticipantes(response.data);
      })
  };
  // Ver Particpante por codigo
  const viewParticipantesCodigo = async (id, nome, email, cod) => {
    await getPendenteParticipanteCodigo2(id)
      .then((response) => {
        console.log(response.data)
        setcomprovativoVisitante(response.data)
      })

    setDados({ nome, email, cod });

  };

  /* mudar o estado do pedido de participacao */

  const mudarEstado = () => {
    getAprovarParticipanteCodigo(codigo)
      .then((response) => {
        setAlterarEstado(response.data);
      })
  }

  const [itemleilao, setItemLeilao] = useState({
    animal: "",
    preco: "",
    data: "",
    quantidade_fixa: "",
    leilao: "",
    artigo: "",
  });

  //
  useEffect(() => {
    viewItemLeilao();
  }, []);
  //
  const viewItemLeilao = () => {
    if (codigo) {
      getLeilaoItemCodigo(codigo).then((response) => {
        setItemLeilao(response.data);
      });
    }
  };
  //
  const provarParticipante = async (codigo) => {
    const datas = {
      visitante: {
        codigo: codigo,
      },
    };
    getAprovarParticipanteCodigo(datas)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
        console.log(error);
      });
  };

  const aprovarParticipante = async (cod) => {
    await getAprovarParticipanteCodigo(cod)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        viewParticipantes();
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
        console.log(error);
      });
  };

  const reprovarParticipante = async (cod) => {
    await getReprovarParticipanteCodigo(cod)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        viewParticipantes();
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
        console.log(error);
      });
  };
  //


  // Dados do Participante
  const alterarEstado = async(id) => {
    await Swal.fire({
      text: 'Como pretende manter o pedido do participante?',
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Aceitar',
      denyButtonText: `Negar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        aprovarParticipante(id)

      } else if (result.isDenied) {
        reprovarParticipante(id)
      }
    })
  }


  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">
                <Link
                  to="/leiloes"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="fa fa-reply"></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">Informações do Leilão</strong>
                  </div>

                  <div className="col-xs-12 col-sm-12">
                    <div className="row">

                      <div className="col-sm-4">
                        <div className="card">
                          <div className="card" style={{ width: "100%" }}>
                            <ul className="list-group list-group-flush">

                              <li className="list-group-item">
                                <strong>Fazenda:</strong> {leilao.fazenda.nome}
                              </li>

                              <li className="list-group-item">
                                <strong>Data:</strong> {leilao.dataLeilao}
                              </li>

                              <li className="list-group-item">
                                <strong>Status:</strong> {leilao.status}
                              </li>

                              <li className="list-group-item">
                                <strong>Caução:</strong> {leilao.caucao}Kz
                              </li>

                              <li className="list-group-item">
                                <strong>codLeilao:</strong> {leilao.codLeilao}
                              </li>

                            </ul>
                          </div>
                        </div>
                      </div>

                      {/* Item do Leilao */}

                      <div className="col-sm-8">
                        <div className="card">
                          <div className="card" style={{ width: "100%" }}>
                            <h3 style={{ textAlign: "center", padding: "15px" }}>Ítem do Leilão</h3>

                            <div className="card-body">
                              <div class="content">
                                <div class="animated fadeIn">
                                  <div class="row">

                                    {itensLeilao.map((itemleilao) => (

                                      <div className="col-md-4">
                                        <aside className="profile-nav alt">
                                          <section className="card">
                                            <div className="card-header alt" style={{ background: "#9ba236" }}>
                                              <div className="media">
                                                Item
                                              </div>
                                            </div>
                                            <ul className="list-group list-group-flush" key={itemleilao.codigo}>
                                              <li className="list-group-item">
                                                <strong style={{ marginLeft: "50px" }}>Proço:</strong>
                                                <a href="#"> {itemleilao.preco} </a>
                                              </li>

                                              <li className="list-group-item">
                                                <strong style={{ marginLeft: "50px" }}>Data:</strong>
                                                <a href="#"> {itemleilao.data} </a>
                                              </li>

                                              <li className="list-group-item">
                                                <strong style={{ marginLeft: "50px" }}>Animal:</strong>
                                                <a href="#"> {itemleilao.animal} </a>
                                              </li>

                                            </ul>
                                          </section>
                                        </aside>
                                      </div>

                                    ))}

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*  */}

                    </div>
                  </div>

                  {/*  */}

                  {/* Lista de Participantes do Leilao */}

                  <div
                    className="card-header dd-eri"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title" id="s-1">
                      Participantes para este Leilao
                    </strong>

                    <Link to={`/participantes/${leilao.codigo}`} id="event-modal">
                      <strong className="card-title" id="s-2"
                        style={{
                          color: "#fff",
                        }}
                      >
                        Participantes aceites para este Leilao
                      </strong>
                    </Link>
                  </div>

                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">Nome</th>
                          <th scope="col">E-mail</th>
                          <th scope="col">Licitador</th>
                          <th scope="col">Comprovativo</th>
                          <th scope="col">Estado do Pedido</th>
                        </tr>
                      </thead>
                      <tbody>

                        {participantes.map((participante) => (
                          <tr key={participante.codigo}>
                            <td data-label="Nome:">{participante.visitante.nome}</td>
                            <td data-label="Email:">{participante.visitante.email}</td>
                            <td data-label="Licitador:">{participante.visitante.visitanteid}</td>
                            <td data-label="Comprovativo:" data-toggle="modal" data-target="#largeModal">


                              <button className="open-comprov"
                                onClick={() => viewParticipantesCodigo(participante.visitante.codigo, participante.visitante.nome, participante.visitante.email, participante.visitante.visitanteid)}
                              >Abrir
                              </button></td>

                            <td data-label="Status:">
                              <button id={`${participante.status == "PENDENTE" ? 'estado-pendente' : '' || participante.status == "APROVADO" ? 'estado-aprovado' : '' || participante.status == "REPROVADO" ? 'estado-reprovado' : ''}`}>{participante.status}</button>
                            </td>

                            {/* Selecionar o Estado  */}

                            <td data-label="Alterar:" scope="col">
                              {/* <button className="btn-estado" data-toggle="modal" data-target="#staticModal">Alterar Estado</button> */}
                              {/*  */}
                              <Link
                                to={participante.codigo}
                                id="event-modal"
                                style={{ marginLeft: "10px" }}
                              >
                                <button className="btn-estado" onClick={()=>alterarEstado(participante.codigo)}>Alterar Estado</button>
                              </Link>
                              {/*  */}

                            </td>
                            <div className="modal fade" id="staticModal" tabindex="-1" role="dialog" aria-labelledby="staticModalLabel" aria-hidden="true">
                              <div className="modal-dialog modal-sm" role="document">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title" id="staticModalLabel">Alterar Estado do Pedido de Participação</h5>
                                    <button type="button" class="close fechar-modal" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </div>

                                  <div className="modal-body" id="btns-estado">
                                    <button onClick={() => aprovarParticipante(participante.codigo)} type="submit" className="btn btn-app">Aprovar</button>
                                    <button onClick={() => reprovarParticipante(participante.codigo)} type="submit" className="btn btn-rr">Reprovar</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>


                  {/* Comprovativo de Pagamento */}

                  <div className="modal fade" id="largeModal" tabindex="-1" role="dialog" aria-labelledby="largeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                      <div className="modal-content">

                        <div
                          className="modal-header"
                          style={{ backgroundColor: "#9ba236", color: "white", textAlign: "center" }}
                        >
                          <h4 className="modal-title infor-to-ad" id="largeModalLabel">
                            Certifique-se de que o Pagamento foi efetuado!
                          </h4>

                          <div className="info-licitador">
                            <table className="responsiveTable">
                              <thead>
                                <tr>
                                  <th>Nome</th>
                                  <th>E-mail</th>
                                  <th>Id do Licitador</th>
                                </tr>
                              </thead>
                              <tbody>
                                <td data-label="Nome:">{Dados.nome}</td>
                                <td data-label="Email:">{Dados.email}</td>
                                <td data-label="Licitador:">{Dados.cod}</td>
                              </tbody>
                            </table>
                          </div>

                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        <div className="modal-body" id="btns-estado">

                          <div className="card-body">
                            {/* ABRIR COMPROVATIVO DO PARTICIPANTE DO LEILÃO */}
                            <iframe
                              src={`https://webccgsa.fly.dev/exibirpdfComprovativo/${comprovativoVisitante.comprovativo}`}
                              style={{ marginLeft: "30px" }}
                              width="700px"
                              height="500px"
                              frameborder="0"
                            ></iframe>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}
    </>
  );
};

export default ViewLeilao;