import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getItemLeilaoCodigo, getStartLeilaoItemCodigo, getSopLeilaoItemCodigo } from "../../service/LeilaoItemService";

import { getLicitadores } from "../../service/LanceService";
import Menu from "../../components/layout/Menu";

const ViewLeilao = (props) => {
  //
  const [itemleilao, setItemLeilao] = useState({
    animal: "",
    preco: "",
    data: "",
    quantidade_fixa: "",
    leilao: "",
    artigo: "",
  });

  const { codigo } = useParams();
  const [VerLicitadores, setVerLicitadores] = useState([]);
  const [verHora, setverHora] = useState();
  const [verSegundos, setverSegundos] = useState();
  const [verMinutos, setverMinutos] = useState();
  const [totalTime, settotalTime] = useState(
    verMinutos === 0 ? 2 * 60 : verMinutos
  );
  // const [totalTime, settotalTime] = useState(2*60)

  const minutosFinal = Math.floor((verSegundos % 3600) / 60);
  // const minutosFinal = Math.floor((10351 % 3600) / 60)

  const segundosFinal = verSegundos % 60;
  // const segundosFinal = 10351 % 60
  //
  useEffect(() => {
    if (totalTime === 0) {
      alert("O Tempo Terminou");
    } else {
      setTimeout(() => {
        VerOsLicitadores();
        console.log(totalTime);
        settotalTime(totalTime - 1);
      }, 1000);
    }

    viewItemLeilao();
    VerOsLicitadores();
  }, []);
  //
  const viewItemLeilao = () => {
    if (codigo) {
      getItemLeilaoCodigo(codigo).then((response) => {
        setItemLeilao(response.data);
        setverHora(response.data.hora);
        setverMinutos(response.data.minuto);
        setverSegundos(response.data.segundo);
      });
    }
  };
  const VerOsLicitadores = () => {
    if (codigo) {
      getLicitadores(codigo).then((response) => {
        setVerLicitadores(response.data);
      });
      setTimeout(VerOsLicitadores, 1)

    }
  };
  //StartLeilao
  // const getAnimal = (codigo) => {
  //   getAnimais_Fazenda_anime(codigo)
  //     .then((response) => {
  //       setVerAnimal(response.data);
  //     })
  //     .catch((error) => {
  //       messages.mensagemErro(error);
  //     });
  // };
  //APAGAR
  const startLeilao = () => {
    getStartLeilaoItemCodigo(codigo).then((response) => { });
    setTimeout(startLeilao, 1)
  };

  // Parar Leilao
  const stopLeilao = () => {
    getSopLeilaoItemCodigo(codigo).then((response) => { });
    setTimeout(startLeilao, 1)
  };

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">
                <Link
                  to="/leiloes"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="fa fa-reply"></span>
                </Link>
              </div>
            </div>
          </div>  
        </div>

        {/*  */}

        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <strong className="card-title">
                      Informações do Item do Leilão
                    </strong>
                    <h3 className="card-title">
                      {itemleilao.minuto}:{itemleilao.segundo}
                    </h3>
                  </div>

                  <div className="col-xs-12 col-sm-12">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="card">
                          <div className="card-body">
                            <img
                              src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirImagemFazenda/${itemleilao.lote?.imgUrl}`}
                              className="foto-fazenda"
                            />
                          </div>
                        </div>
                        {/*  */}
                        <button
                          type="submit"
                          style={{
                            background: "#12b212",
                            outline: "none",
                            border: "none",
                            width: "311px",
                            height: "40px",
                            color: "#fff",
                            borderRadius: "6px",
                            marginTop: "-100px",
                            fontFamily: "Times New Roman",
                            cursor: "pointer",
                            marginBottom: "20px"
                          }}
                          // onClick={() => startLeilao(codigo)}
                          onClick={() => startLeilao()}
                        >
                          Iniciar Leilão
                        </button>

                        <button
                          type="submit"
                          style={{
                            background: "#c94b4b",
                            outline: "none",
                            border: "none",
                            width: "311px",
                            height: "40px",
                            color: "#fff",
                            borderRadius: "6px",
                            marginTop: "-100px",
                            fontFamily: "Times New Roman",
                            cursor: "pointer",
                          }}
                          // onClick={() => startLeilao(codigo)}
                          onClick={() => stopLeilao()}
                        >
                          Parar Leilão
                        </button>

                        <div className="App"></div>
                        {/*  */}
                      </div>
                      <div className="col-sm-8">
                        <div className="card">
                          {/* <div className="card" style="width: 100%"> */}
                          <div className="card" style={{ width: "100%" }}>
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item">
                                <strong>Fazenda:</strong>{" "}
                                {itemleilao.lote?.fazenda?.nome}
                              </li>
                              <li className="list-group-item">
                                <strong>Especie:</strong>{" "}
                                {itemleilao.lote?.especie?.especieAnimal}
                              </li>

                              <li className="list-group-item">
                                <strong>Valor Inicial:</strong>{" "}
                                {itemleilao.preco}Kz
                              </li>
                              <li className="list-group-item">
                                <strong>Data:</strong> {itemleilao.data}
                              </li>
                              <li className="list-group-item">
                                <strong>Duração:</strong> {itemleilao.hora}h
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h1 style={{ textAlign: "center" }}>Licitadores</h1>
                  <table className="table table-striped responsiveTable">
                    <thead>
                      <tr>
                        <th scope="col">Posição</th>
                        <th scope="col">Codigo</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Valor do Lance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {VerLicitadores.map((licitador, index) => (
                        <tr key={licitador.codigo}>
                          <td data-label="Posição:">{index + 1}</td>
                          <td data-label="Licitador:">Lici-0{licitador.visitante?.codigo}</td>
                          <td data-label="Nome:">{licitador.visitante?.nome}</td>
                          <td data-label="Lance Dado:">{licitador.valorLance}Kz</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}
    </>
  );
};

export default ViewLeilao;
