import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { getNotificacoes } from "../../service/NotificacoesService";
import * as messages from "../message/toastr";

import Swal from "sweetalert2";

import { i18n } from "../../Traducao/translate/i18n";

const I18N_STORAGE_KEY = "i18nextLng";

const ListPermissaoFuncionario = () => {
  const { codigo } = useParams();
  const [notificacoes, setNotificacoes] = useState([]);

  //
  useEffect(() => {
    getAllNotificacoes();
  }, []);

  //LISTAR
  const getAllNotificacoes = () => {
    getNotificacoes()
      .then((response) => {
        setNotificacoes(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.notificacoes")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="list-notification">
                      {notificacoes.map((notificacao) => (
                        <ul key={notificacao.codigo}>
                          <div className="icon-not">N</div>

                          <Link
                            to={`/viewnotificacoes/${notificacao.codigo}`}
                          >
                            <li className="posicao">{notificacao.title}</li>
                            <li className="posicao" id="conteudo">
                              {notificacao.conteudo}
                            </li>
                            <li className="posicao" id="data">
                              {notificacao.dataN}
                            </li>
                          </Link>
                        </ul>
                      ))}
                    </div>

                   {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
    </>
  );
};

export default ListPermissaoFuncionario;
