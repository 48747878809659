import api from "./api";

export const getVisitantes = async () => {
  try {
    return await api.get("Visitante");
    // return await api.get("visitante");
  } catch (error) {
    console.log(error.message);
  }
};
export const createVisitante = async (data) => {
  try {
    return api.post("criar_visitante", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getVisitanteCodigo = async (codigo) => {
  try {
    return await api.get(`isitant/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getCarrinhoVisitante = async (codigo) => {
  try {
    return await api.get(`carrinho_visitante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateVisitante = async (data) => {
  try {
    return await api.put("visitante", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeVisitante = async (codigo) => {
  try {
    return await api.delete(`visitante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
