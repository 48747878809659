import { data } from "jquery";
import api from "./api";

export const getDistancias = async () => {
  try {
    return await api.get("Distancia");
  } catch (error) {
    console.log(error.message);
  }
};
export const createDistancia = async (data) => {
  try {
    return await api.post("criar_distancia", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getDistanciaCodigo = async (codigo) => {
  try {
    return await api.get(`distancia/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateDistancia = async (codigo, data) => {
  try {
    return await api.put(`cooperador/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeDistancia = async (codigo) => {
  try {
    return await api.delete(`distancia/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
