import React from "react"; 



import { useState } from 'react';
import { i18n } from '../../Traducao/translate/i18n';




import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2"; 
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

//total de animais leiluados por especie


const options = {
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "left",
    },
    title: {
      display: true,
      text: "TOTAL DE ANIMAIS LEILUADOS POR RAÇA BOVINA 2009 A 2013",
    },
  },
};

const labels = [  
'TOURO',
'CABRITO',
'PORCO',
'BUFFALO',
'ESQUIDEO',
'ASININO',
  ];
const data = {
  labels,
  datasets: [
    {
      label: "",
      backgroundColor: ['#5ad806d0', '#7de429', '#9ab32f', '#607018', '#45520f', '#303a09', '#232b05', '#0f1302', '#41520a', '#4a5e0a', '#2c5e0ae3', '#2c5e0a', '#428f0f', '#82b310', '#75a10dd7', '#94b447fb', '#b5dd57fb', '#799b2afb', '#58701efb', '#38490ffb', '#232e09fb', '#54b116fb', '#3d830ffb', '#4aa110fb', '#5fd113fb', '#64c721fb', '#4fbb08fb'],
      borderColor: '#424616',
      data: [15, 242, 238, 199, 179, 98, 94, 67, 62, 58, 56, 38, 37, 32, 30, 29, 27, 25, 24, 23, 21, 20, 20, 18, 18, 17, 16],
    },
  ],
};
const Graficoanimais_3 = () => {
  return (
    <div id="right-panel" className="right-panel">
      {/*  */}
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
              
                <div className="col-xs-9 col-sm-9">
                  <div className="row">
                    {/* <div style={{ width: "800", height: "500" }}> */}
                    <Bar data={data} options={options} /> 
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- .animated --> */}
      </div>
      {/* <!-- .content --> */}

      <div className="clearfix"></div>
      {/*  */}
    </div>


  );
};

           


export default Graficoanimais_3;