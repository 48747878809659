import Menu from "../../components/layout/Menu";
import ListNoticias from "../../components/listTable/ListNoticias";

const Noticias = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListNoticias />
      </div>
      {/*  */}
    </>
  );
};

export default Noticias;
 