import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { getFornecedores, removeFornecedor } from "../../service/FornecedoresService";
import * as messages from "../message/toastr";
import TabFornecedor from "../tab/TabFornecedor";
import Swal from "sweetalert2";

import { i18n } from '../../Traducao/translate/i18n';
const I18N_STORAGE_KEY = 'i18nextLng';

const List_Fornecedor = () => {
  const { codigo } = useParams();
  const [fornecedores, setFornecedores] = useState([]);
  
  useEffect(() => {
    getAllfornecedores();
  }, []);

  //LISTAR
  const getAllfornecedores = () => {
    getFornecedores()
      .then((response) => {
        setFornecedores(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deleteFornecedor = (codigo) => {
    Swal.fire({
      title: "Deseja eliminar?",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeFornecedor(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllfornecedores();
        });
      }
    });
  };

  const titulo = () => {
    if (codigo) {
      return <h3 style={{ color: "#fff" }}> {i18n.t('messages.atualizar')} </h3>;
    } else {
      return <h3 style={{ color: "#fff" }}> {i18n.t('messages.cadastrar')} </h3>;
    }
  };

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/addfornecedores" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus"></span>
                  </button>
                </Link>
 
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t('messages.Pesquisar_por_nome')}
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-success" type="submit">
                    {i18n.t('buttons.pesquisar')}
                  </button>
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t('messages.fornecedores')}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t('messages.nome')} </th>
                          <th scope="col">{i18n.t('messages.telefone')} </th>
                          <th scope="col">{i18n.t('messages.bi')} </th>
                          <th scope="col">{i18n.t('messages.email')} </th>
                          <th scope="col">{i18n.t('messages.morada')} </th>
                          <th scope="col">{i18n.t('messages.opcao')} </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fornecedores.map((fornecedor) => (
                          <tr key={fornecedor.codigo}>
                            <td data-label="Nome:">{fornecedor.nome}</td>
                            <td data-label="Telefone:">{fornecedor.telefone}</td>
                            <td data-label="BI:">{fornecedor.bi}</td>
                            <td data-label="Email:">{fornecedor.email}</td>
                            <td data-label="Endereço:">{fornecedor.morada}</td>
                            <td data-label="Adicionais:">
                              <Link
                                to={`/viewfornecedor/${fornecedor.codigo}`}
                                className="btn btn-primary"
                              >
                                <span className="las la-eye link-add"></span>
                              </Link>
                              {/*  */}
                              <Link
                                to={`/editfornecedor/${fornecedor.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                  style={{ background: "#b46739" }}
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>
                              {/*  */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deleteFornecedor(fornecedor.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
 

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
            <TabFornecedor />
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default List_Fornecedor;
