import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import {
  createComissao,
  getComissaoCodigo,
  getComissoes,
  removeComissao,
  updateComissao,
} from "../../service/ComissaoService";
import * as messages from "../message/toastr";

import Swal from "sweetalert2";

import { i18n } from "../../Traducao/translate/i18n";

const I18N_STORAGE_KEY = "i18nextLng";

const ListComissao = () => {
  const { codigo } = useParams();
  const [comissaos, setComissaos] = useState([]);

  //
  useEffect(() => {
    getAllComissao();
  }, []);

  //LISTAR
  const getAllComissao = () => {
    getComissoes()
      .then((response) => {
        setComissaos(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deletecomissao = (codigo) => {
    Swal.fire({
      title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeComissao(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllComissao();
        });
      }
    });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  //SALVAR
  const [quota, setQuota] = useState("");

  //BOTAO SALVAR E ACTUALIZAR
  const saveUpdateComissao = (e) => {
    e.preventDefault();

    const comissao = {
      quota,
      codigo,
    };
    //

    //
    if (codigo) {
      updateComissao(comissao)
        .then((response) => {
          getAllComissao();
          messages.mensagemSucesso("Actualizado!", response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Error ao actualizado!", error.response.data);
        });
    } else {
      createComissao(comissao)
        .then((response) => {
          getAllComissao();
          messages.mensagemSucesso("Cadastrado!", response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Error ao cadastrado!", error.response.data);
        });
    }
  };
  //
  useEffect(() => {
    if (codigo) {
      getComissaoCodigo(codigo)
        .then((response) => {
          setQuota(response.data.nome);
        })
        .catch((error) => {
          messages.mensagemErro(error.response.data);
        });
    }
  }, []);

  //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/addcomissoes" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus">
                      {i18n.t("messages.novo")}
                    </span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_quota")}
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button>
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.comissao")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.quota")}</th>

                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {comissaos.map((comissao) => (
                          <tr key={comissao.codigo}>
                            <td data-label="Quota:">{comissao.quota} %</td>
                            <td data-label="Adicionais:">
                              {/*  */}
                              <Link
                                to={`/comissao/editComissao/${comissao.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>
                              {/*  */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deletecomissao(comissao.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                              {/*  */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
              <div className="tab-content pl-3 pt-2" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  {/*  */}

                  <form onSubmit={(e) => saveUpdateComissao(e)}>
                    {/* comissao */}

                    {/*  */}
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group">
                          <input
                            name="quota"
                            type="text"
                            className="form-control cc-exp"
                            // placeholder={i18n.t("messages.quota")}
                            placeholder="Comissao"
                            value={quota}
                            // onChange={(e) => onInputChange(e)}
                            onChange={(e) => setQuota(e.target.value)}
                          />
                        </div>
                      </div>
                      <span>%</span>
                    </div>
                    {/*  */}

                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn"
                        data-dismiss="modal"
                        style={{
                          backgroundColor: "#b46739",
                          color: "#fff",
                        }}
                      >
                        {i18n.t("buttons.cancelar")}
                      </button>
                      <button
                        type="submit"
                        className="btn"
                        style={{
                          backgroundColor: "#9ba239",
                          color: "#fff",
                        }}
                      >
                        <span className="lar la-save">
                          {i18n.t("buttons.save")}
                        </span>
                      </button>
                    </div>
                    {/*  */}
                    {/* FIM comissao */}
                  </form>

                  {/*  */}
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListComissao;
