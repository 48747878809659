import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { getLances, removeLance } from "../../service/LanceService";
import * as messages from "../message/toastr";
import Swal from "sweetalert2";

import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const ListLance = () => {
  const { codigo } = useParams();
  const [lances, setLances] = useState([]);

  //
  useEffect(() => {
    getAllLance();
  }, []);

  //LISTAR
  const getAllLance = () => {
    getLances()
      .then((response) => {
        setLances(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deleteLance = (codigo) => {
    Swal.fire({
      title: "Deseja eliminar?",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeLance(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllLance();
        });
      }
    });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button>
                </form>
              </div>
              <Link to="/relatoriolances" id="event-modal">
                <button
                  type="submit"
                  className="btn btn-flat m-b-30 m-t-30"
                  data-toggle="modal"
                  data-target="#largeModal"
                  style={{
                    background: "#b46739",
                    color: "#fff",
                    marginLeft: "10px",
                  }}
                >
                  <span className="fa fa-print"></span>
                </button>
              </Link>
              {/*  */}
            </div>
          </div>
        </div>
        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.lance")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.data")}</th>

                          <th scope="col">{i18n.t("messages.valor")}</th>

                          <th scope="col">{i18n.t("messages.item")}</th>

                          <th scope="col">{i18n.t("messages.licitador")}</th>

                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lances.map((lance) => (
                          <tr key={lance.codigo}>
                            <td data-label="Data:">{lance.dataDoLance}</td>
                            <td data-label="Valor:">{lance.valorLance}</td>
                            <td data-label="Animal:">{lance.leilaoItem.animal.nome}</td>
                            <td data-label="Usuário:">{lance.visitante.nome}</td>
                            <td data-label="Adicionais:">
                              <Link
                                to={`/viewlance/${lance.codigo}`}
                                className="btn btn-primary"
                              >
                                <span className="las la-eye link-add"></span>
                              </Link>
                              <Link
                                to={`/relatoriolance/${lance.codigo}`}
                                className="btn btn-primary"
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="fa fa-print link-add-print"></span>
                              </Link>

                              {/*  */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deleteLance(lance.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                              {/*  */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}

      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListLance;
