import Menu from "../../components/layout/Menu";
import ListVendas from "../../components/listTable/ListVenda";

const Venda = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListVendas />
      </div>
      {/*  */}
    </>
  );
};

export default Venda;

