import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { createLeilao, getLeiloes } from "../../service/LeilaoService";
import * as messages from "../../components/message/toastr";
import { getFazendas } from "../../service/FazendaService";

import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const TabLeilao = (props) => {
  const { codigo } = useParams();
  const [leilao, setLeilao] = useState([]);

  useEffect(() => {
    getAllLeilao();
  }, []);

  //LISTAR
  const getAllLeilao = () => {
    getLeiloes()
      .then((response) => {
        setLeilao(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //SALVAR
  const [Leilao, setLeilaos] = useState({
    data: "",
    caucao:"",
    fazenda: {
      codigo: "",
    },
    // tipo: "",
    // valorInicial: "",
    // dia: "",
  });
  //Limpar
  const handleClean = () => {
    setLeilaos({
      data: "",
      fazenda: "",
      caucao: "",
    })
  }

  const [verFazenda, setverFazenda] = useState([]);
  useEffect(() => {
    getAllFazenda();
  }, []);

  const getAllFazenda = () => {
    getFazendas()
      .then((response) => {
        setverFazenda(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  // const { data, fazenda, tipo, valorInicial, dia } = Leilao;
  const { data, fazenda, caucao } = Leilao;
  
  const onInputChange = (e) => {
    setLeilaos({ ...Leilao, [e.target.name]: e.target.value });
    if (e.target.name == "data") {
      const data = e.target.value;
    }
    if (e.target.name == "fazenda") {
      const fazenda = e.target.value;
    }
    if (e.target.name == "caucao") {
      const caucao = e.target.value;
    }
    // if (e.target.name == "tipo") {
    //   const tipo = e.target.value;
    // }
    // if (e.target.name == "valorInicial") {
    //   const valorInicial = e.target.value;
    // }
    // if (e.target.name == "dia") {
    //   const dia = e.target.value;
    // }
  };

  //Botao Salvar
  const saveUpdateLeilao = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("data", data);
    formData.append("fazenda", fazenda);
    formData.append("caucao", caucao);
    // formData.append("tipo", tipo);
    // formData.append("valorInicial", valorInicial);
    // formData.append("dia", dia);

    const datas = {
      data: data,
      caucao: caucao,
      fazenda: {
        codigo: fazenda,
      },
      // tipo: tipo,
      // valorInicial: valorInicial,
      // dia: dia,
    };
    console.log(datas);
    await createLeilao(datas)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        getAllLeilao();
        handleClean();
        props.verOsleiloes()
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
      });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  return (
    <div>
      {/* Leilao */}
      <form onSubmit={(e) => saveUpdateLeilao(e)}>
        <div className="row">
          <div className="col-4">
            <div className="input-group">
              <select
                className="form-control cc-exp"
                tabindex="1"
                name="fazenda"
                value={fazenda}
                onChange={(e) => onInputChange(e)}
              >
                <option>Selecione a Fazenda</option>
                {verFazenda.map((fazenda) => (
                  <option key={fazenda.codigo} value={fazenda.codigo}>
                    {fazenda.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="input-group">
              <input
                id=""
                name="data"
                type="date"
                className="form-control cc-exp"
                value={data}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.data")}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="input-group">
              <input
                id=""
                name="caucao"
                type="number"
                className="form-control cc-exp"
                value={caucao}
                onChange={(e) => onInputChange(e)}
                // placeholder={i18n.t("messages.data")}
                placeholder="Caução"
              />
            </div>
          </div>
         
        </div>

        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span>{i18n.t("buttons.save")}</span>
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabLeilao;
