import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { createExclusivo } from "../../service/LeilaoExclusivoService";
import * as messages from "../message/toastr";
import { getFazendas } from "../../service/FazendaService";

import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const TabLeilaoExclusivo = (props) => {
  const { codigo } = useParams();

  //SALVAR
  const [Leilao, setLeilaos] = useState({
    dataLeilaoEx: "",
    fazenda: {
      codigo: "",
    },
    link: "",
  });

  //Limpar
  const handClear = () => {
    setLeilaos({
      dataLeilaoEx: "",
      fazenda: {
        codigo: "",
      },
      link: "",
    });
  };

  const [verFazenda, setverFazenda] = useState([]);
  useEffect(() => {
    getAllFazenda();
  }, []);

  const getAllFazenda = () => {
    getFazendas()
      .then((response) => {
        setverFazenda(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const { dataLeilaoEx, fazenda, link } = Leilao;

  const onInputChange = (e) => {
    setLeilaos({ ...Leilao, [e.target.name]: e.target.value });
    if (e.target.name === "dataLeilaoEx") {
      const dataLeilaoEx = e.target.value;
    }
    if (e.target.name === "fazenda") {
      const fazenda = e.target.value;
    }
    };

  const saveUpdateLeilao = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("dataLeilaoEx", dataLeilaoEx);
    formData.append("fazenda", fazenda);
    formData.append("link", link);

    const datas = {
      dataLeilaoEx: dataLeilaoEx,
      fazenda: {
        codigo: fazenda,
      },
      link: link,
    };
    console.log(datas);
    await createExclusivo(datas)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        handClear();
        props.VerOsLeiloesExclusivos()
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
      });
  };

  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  return (
    <div>
      <form onSubmit={(e) => saveUpdateLeilao(e)}>
        <div className="row">
          <div className="col-4">
            <div className="input-group">
              <select
                className="form-control cc-exp"
                tabindex="1"
                name="fazenda"
                //value={fazenda}
                onChange={(e) => onInputChange(e)}
              >
                <option>Selecione a Fazenda</option>
                {verFazenda.map((fazenda) => (
                  <option key={fazenda.codigo} value={fazenda.codigo}>
                    {fazenda.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-4">
            <div className="input-group">
              <input
                id=""
                name="dataLeilaoEx"
                type="date"
                className="form-control cc-exp"
                value={dataLeilaoEx}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.data")}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="input-group">
              <input
                id=""
                name="link"
                type="text"
                className="form-control cc-exp"
                value={link}
                onChange={(e) => onInputChange(e)}
                placeholder="Link da live"
              />
            </div>
          </div>
        </div>

        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span>{i18n.t("buttons.save")}</span>
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabLeilaoExclusivo;
