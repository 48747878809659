import Menu from "../../components/layout/Menu";
import List_Lote from "../../components/listTable/List_Lote";

const Lote = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <List_Lote />
      </div>
      {/*  */}
    </>
  );
};

export default Lote;