import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getCooperadorCodigo } from "../../service/CooperadoresService";
import Menu from "../../components/layout/Menu";

const ViewCooperador = (props) => {
  const [cooperador, setCooperador] = useState({
    imgUrl: "",
    nome: "",
    email: "",
    telefone: "",
    senha: "",
    endereco: "",
    website: "",
    userid: "",
    is_verify: "",
  });

  const { codigo } = useParams();

  useEffect(() => {
    viewCooperador();
  }, []);

  const viewCooperador = () => {
    if (codigo) {
      getCooperadorCodigo(codigo).then((response) => {
        setCooperador(response.data);
      });
    }
  };

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">
                <Link
                  to="/cooperadores"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="fa fa-reply"></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">Dados do Cooperador</strong>
                  </div>

                  <div className="col-xs-12 col-sm-12">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="card">
                          <div className="card-body">
                            <img
                              src={`${cooperador.imgUrl}`}
                              className="foto-cooperador"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="card">
                          <div className="card" style={{ width: "100%" }}>
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item">
                                <strong>Nome:</strong> {cooperador.nome}
                              </li>
                              <li className="list-group-item">
                                <strong>E-mail:</strong> {cooperador.email}
                              </li>
                              <li className="list-group-item">
                                <strong>Telefone:</strong> {cooperador.telefone}
                              </li>
                              <li className="list-group-item">
                                <strong>Endereço:</strong> {cooperador.endereco}
                              </li>
                              <li className="list-group-item">
                                <strong>website:</strong> {cooperador.website}
                              </li>
                              <li className="list-group-item">
                                <strong>Codigo: </strong> {cooperador.userid}
                              </li>
                              <li className="list-group-item">
                                <strong>Conta: </strong> {cooperador.is_verify}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}
    </>
  );
};

export default ViewCooperador;
