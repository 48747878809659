import api from "./api";

export const getLeiloes = async () => {
  try {
    return await api.get("leilaos");
  } catch (error) {
    console.log(error.message);
  }
};
export const getFazendaLeilao = async (codigo) => {
  try {
    return await api.get("pegarLeilaoFazenda/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const createLeilao = async (data) => {
  try {
    return await api.post("leilao", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getLeilaoCodigo = async (codigo) => {
  try {
    return await api.get(`leilao/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateLeilao = async (data) => {
  try {
    return await api.put("leilaos", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeLeilao = async (codigo) => {
  try {
    return await api.delete(`leilaos/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
