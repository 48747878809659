import React, { useState, useEffect, useMemo, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { getCodigo } from "../../service/VendaProdutoService";

import Menu from "../../components/layout/Menu";

const ViewProduto = (props) => {
  //
  const [produto, setProduto] = useState({
    produto: "",
    preco: "",
    quantidade_fixa: "",
    imposto: "",
    comissao: "",
    totalDescontoIva: "",
    totalCooperativa: "",
    totalValorCrescinto: "",
    totalCooperador: "",
    totalValorIva: "",
    dataArtigoProduto: "",
    artigoId: "",
    vendido: "",
  });

  const { codigo } = useParams();

  //
  useEffect(() => {
    ViewProduto();
  }, []);
  //
  const ViewProduto = () => {
    if (codigo) {
      getCodigo(codigo).then((response) => {
        setProduto(response.data);
      });
    }
  };

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">
                <Link
                  to="/stockProduto"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="las la-plus">Voltar</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">Dados do Stock do Produto</strong>
                  </div>
                  <div className="card-body">
                    {/*  */}

                    <div className="card" style={{ width: "100%" }}>
                      <ul className="list-group list-group-flush">

                        <li className="list-group-item">
                          <strong>Data de Cadastro:</strong>
                          {produto.dataArtigoProduto}
                        </li>

                        <li className="list-group-item">
                          <strong>Código do Produto:</strong> {produto.produto.cod}
                        </li>

                        <li className="list-group-item">
                          <strong>Produto:</strong> {produto.produto.nome}
                        </li>

                        <li className="list-group-item">
                          <strong>Quantidade:</strong> {produto.quantidade_fixa}
                        </li>

                        <li className="list-group-item">
                          <strong>Descrição: </strong>
                          {produto.produto.descricao}
                        </li>

                        <li className="list-group-item">
                          <strong>Preço:</strong> {produto.preco} Kz
                        </li>

                        <li className="list-group-item">
                          <strong>Tipo de Imposto:</strong>{" "}
                          {produto.imposto.descricao}
                        </li>
                        <li className="list-group-item">
                          <strong>IVA:</strong> {produto.imposto.iva}%
                        </li>
                        <li className="list-group-item">
                          <strong>Comissão:</strong> {produto.comissao.quota}%
                        </li>

                        <li className="list-group-item">
                          <strong>Total de Desconto com IVA:</strong> {produto.totalDescontoIva}Kz
                        </li>

                        <li className="list-group-item">
                          <strong>Total de IVA:</strong> {produto.totalValorIva}Kz
                        </li>

                        <li className="list-group-item">
                          <strong>Total da Cooperativa:</strong>{" "}
                          {produto.totalCooperativa}Kz
                        </li>

                        <li className="list-group-item">
                          <strong>Preço do Venda:</strong>{" "}
                          {produto.totalValorCrescinto}Kz
                        </li>
                      </ul>
                    </div>

                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}
    </>
  );
};

export default ViewProduto;
