import React from "react";
import boi from "../../assets/img/icon/boi.png";
import cooperativa from "../../assets/img/icon/cooperativo.png";
import fazenda from "../../assets/img/icon/fazenda.png";
import leilao from "../../assets/img/icon/leilao.png";
import livrodevisitas from "../../assets/img/icon/livro-de-visitas.png";
import valor from "../../assets/img/icon/valor.png";
import vendarapida from "../../assets/img/icon/venda-rapida.png";
import servicosdigitais from "../../assets/img/icon/servicos-digitais.png";
import Menu from "../../components/layout/Menu"; 

import { Link, useParams } from "react-router-dom";
import $ from "jquery";

// Multi Linguagens
import { useState } from 'react';
import { i18n } from '../../Traducao/translate/i18n';
const I18N_STORAGE_KEY = 'i18nextLng';

const Home = () => {
  const [language] = useState(localStorage.getItem(I18N_STORAGE_KEY))
  const handleSelectChange = event => {
    localStorage.setItem(
      I18N_STORAGE_KEY,
      event.target.value
    )
    window.location = window.location
  }

  const count = async () => {
    // Counter Number
    await $(".count").each(function () {
      $(this)
        .prop("Counter", 0)
        .animate(
          {
            Counter: $(this).text(),
          },
          {
            duration: 3000,
            easing: "swing",
            step: function (now) {
              $(this).text(Math.ceil(now));
            },
          }
        );
    });
  };
  return (
    <>
      <Menu />
      {/* <!-- Right Panel --> */}
      <div id="right-panel" className="right-panel">
       
        {/* <!-- Content --> */}
        <div className="content">  
        
        <div className="col-lg-12 col-md-12 relatorios_txt">
          <p>{i18n.t('messages.texto_relatorio')}</p>
        </div>


          {/* <!-- Animated --> */}
          <div className="animated fadeIn">
            {/* <!-- Widgets  --> */}
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="stat-widget-five">
                      <div className="stat-icon dib flat-color-1">
                        <i className="">
                          <img src={leilao} className="icon" />
                        </i>
                      </div>
                      <div className="stat-content">
                        <div className="text-left dib">
                  
                          <div className="stat-heading">                                       
                              {i18n.t('messages.relatorio_leilao')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="stat-widget-five">
                      <div className="stat-icon dib flat-color-2">
                        <i className="">
                          <img src={vendarapida} className="icon" />
                        </i>
                      </div>
                      <div className="stat-content">
                        <div className="text-left dib">
                         
                          <div className="stat-heading">                                       
                              {i18n.t('messages.relatorio_vendas')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="stat-widget-five">
                      <div className="stat-icon dib flat-color-3">
                        <i className="">
                          <img src={cooperativa} className="icon" />
                        </i>
                      </div>
                      <div className="stat-content">
                        <div className="text-left dib">
                          
                          <div className="stat-heading">                                       
                              {i18n.t('messages.relatorio_cooperador')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="stat-widget-five">
                      <div className="stat-icon dib flat-color-4">
                        <i className="">
                          <img src={valor} className="icon" />
                        </i>
                      </div>
                      <div className="stat-content">
                        <div className="text-left dib">
                        
                          <div className="stat-heading"> 
                              {i18n.t('messages.relatorio_clientes')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /Widgets --> */}
          </div>
          {/* <!-- .row --> */}
        </div>
        {/*  */}
        <div className="content">
          {/* <!-- Animated --> */}
          <div className="animated fadeIn">
            {/* <!-- Widgets  --> */}
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="stat-widget-five">
                      <div className="stat-icon dib flat-color-1">
                        <i className="">
                          <img src={servicosdigitais} className="icon" />
                        </i>
                      </div>
                      <div className="stat-content">
                        <div className="text-left dib">
                       
                          <div className="stat-heading">                                       
                              {i18n.t('messages.relatorio_servicos')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Link to="/relatorio" id="event-modal">                  
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="stat-widget-five">
                      <div className="stat-icon dib flat-color-2">
                        <i className="">
                          <img src={boi} className="icon" />
                        </i>
                      </div>
                      <div className="stat-content">
                        <div className="text-left dib">
                       
                          <div className="stat-heading">                                       
                              {i18n.t('messages.relatorio_animais')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                </Link>


              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="stat-widget-five">
                      <div className="stat-icon dib flat-color-3">
                        <i className="">
                          <img src={livrodevisitas} className="icon" />
                        </i>
                      </div>
                      <div className="stat-content">
                        <div className="text-left dib">
                          <div className="stat-text">
                            <span className="count">19678</span>
                          </div>
                          <div className="stat-heading">                                        
                              {i18n.t('messages.relatorio_agendas')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="stat-widget-five">
                      <div className="stat-icon dib flat-color-4">
                        <i className="">
                          <img src={fazenda} className="icon" />
                        </i>
                      </div>
                      <div className="stat-content">
                        <div className="text-left dib">
                          <div className="stat-heading">                     
                               {i18n.t('messages.relatorio_fazendas')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Gráficos */}
 
              {/*  */}
   
            </div>
            {/* <!-- /Widgets --> */}

            {/* <!-- .animated --> */}
          </div>
          {/* <!-- /.content --> */}
          <div className="clearfix"></div>
        </div>
        {/* <!-- /#right-panel --> */}
      </div>
    </>
  );
};

export default Home;
