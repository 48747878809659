import api from "./api";

export const getNoticias = async () => {
  try {
    return await api.get("Noticia");
  } catch (error) {
    console.log(error.message);
  }
};

export const createNoticia = async (data) => {
  try {
    return await api.post("criar_noticia", data);
  } catch (error) {
    console.log(error.message);
  } 
}; 

export const getImagem = async (imagem) => {
  try {
    return await api.get(`exibirImagemNoticia/${imagem}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getNoticiaCodigo = async (codigo) => {
  try {
    return await api.get(`noticia/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
 
export const updateNoticia = async (data) => {
  try {
    return await api.put("noticia", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeNoticia = async (codigo) => {
  try {
    return await api.delete(`noticia/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
