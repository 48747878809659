import Menu from "../../components/layout/Menu";
import ListStockProduto from "../../components/listTable/ListStockProduto";

const StockProduto = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListStockProduto />
      </div>
      {/*  */}
    </>
  );
};

export default StockProduto;


