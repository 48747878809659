import React, { useState, useEffect } from "react";
import { i18n } from "../../Traducao/translate/i18n";
import { getFazendas } from "../../service/FazendaService";
import { getComissaoCodigo, getComissoes } from "../../service/ComissaoService";
import { getImpostoCodigo, getImpostos } from "../../service/ImpostoService";
import { getFazendaAnimal } from "../../service/LoteItemService";
import { getAnimal_individual } from "../../service/AnimalService";
import * as messages from "../message/toastr";
import { Link, useParams } from "react-router-dom";
import { createStockAnimal, updateStockAnimal } from "../../service/StockAnimalService";

const I18N_STORAGE_KEY = "i18nextLng";

const StokAnimal = () => {
  // Buscar por ID
  const { codigo } = useParams();
  //LISTAR
  const [verFazenda, setverFazenda] = useState([]);
  const [verComissao, setverComissao] = useState([]);
  const [verImposto, setverImposto] = useState([]);
  useEffect(() => {
    getAllFazenda();
    getAllComissao();
    getAllImposto();
  }, []);

  const getAllFazenda = () => {
    getFazendas()
      .then((response) => {
        setverFazenda(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllComissao = () => {
    getComissoes()
      .then((response) => {
        setverComissao(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const getAllImposto = () => {
    getImpostos()
      .then((response) => {
        setverImposto(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const [verAnimal, setverAnimal] = useState([]);
  const [verFazendaAnimal, setVerFazendaAnimal] = useState([]);
  const verOsAniamis = (e) => {
    getFazendaAnimal(e.target.value).then((response) => {
      setverAnimal(response.data);
    });
  };

  const [verFichaAnimal, setverFichaAnimal] = useState({});

  const verIrformacaoAnimal = async (codigo) => {
    await getAnimal_individual(codigo)
    .then((response) => {
      setverFichaAnimal(response.data);
      console.log(response.data);
    })
    .catch((error) => {
      messages.mensagemErro(error);
    });
  }

  const [verPrecoS, setverPrecoS] = useState();
  const [verImpostoS, setverImpostoS] = useState(0);
  const [verImpostoSelect, setverImpostoSelect] = useState(0);
  const [verComissaoS, setverComissaoS] = useState(0);
  const [verComissaoSelect, setverComissaoSelect] = useState(0);
  const [vertotalS, setvertotalS] = useState(0);
  const [EscolhaCAtegoria, setEscolhaCAtegoria] = useState(0);

  //
  const onInputChange = (e) => {
    setVenda({ ...venda, [e.target.name]: e.target.value });
    if (e.target.name === "animal") {
      getFazendaAnimal(e.target.value).then((response) => {
        setVerFazendaAnimal(response.data);
        verIrformacaoAnimal(e.target.value)
      });
    }
    if (e.target.name === "preco") {
      setverPrecoS(parseInt(e.target.value));
      if (verImpostoS === 0 && verComissaoS === 0) {
        setvertotalS(parseInt(e.target.value));
      } else {
        const impostoN = parseInt(
          (parseInt(e.target.value) * verImpostoSelect) / 100
        );
        setverImpostoS(impostoN);

        const comissaoN = parseInt(
          ((parseInt(e.target.value) + impostoN) * verComissaoSelect) / 100
        );
        setverComissaoS(comissaoN);

        setvertotalS(parseInt(parseInt(e.target.value + parseInt(impostoN))));
      }
    }

    if (e.target.name === "imposto") {
      getImpostoCodigo(e.target.value)
        .then((response) => {
          const imposto = parseInt((verPrecoS * response.data.iva) / 100);
          setverImpostoS(imposto);
          setverImpostoSelect(response.data.iva);
          setvertotalS(parseInt(verPrecoS + imposto));
        })
        .catch((error) => {
          messages.mensagemErro(error);
        });
    }

    if (e.target.name === "comissao") {
      getComissaoCodigo(e.target.value)
        .then((response) => {
          if (verImpostoS === 0) {
            const comissao = parseInt((verPrecoS * response.data.quota) / 100);
            setverComissaoS(comissao);
          } else {
            const comissao = parseInt(
              ((verPrecoS + verImpostoS) * response.data.quota) / 100
            );
            setverComissaoS(comissao);
            setverComissaoSelect(response.data.quota);
            setvertotalS(parseInt(verPrecoS + verImposto));
          }
        })
        .catch((error) => {
          messages.mensagemErro(error);
        });
    }
  };

  //SALVAR
  const [venda, setVenda] = useState({
    preco: "",
    quantidade_fixa: "",
    comissao: "",
    imposto: "",
    animal: "",
  });

  const { preco, quantidade_fixa, comissao, imposto, animal } = venda;

  //Botao Salvar
  const saveUpdateVenda = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("preco", preco);
    formData.append("quantidade_fixa", quantidade_fixa);
    formData.append("comissao", comissao);
    formData.append("imposto", imposto);
    formData.append("animal", animal);

    const datas = {
      animal: {
        codigo: animal,
      },
      preco: preco,
      quantidade_fixa: quantidade_fixa,
      comissao: comissao,
      imposto: imposto,
    };

    //
    if (codigo) {
      updateStockAnimal(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          // retrievecooperadores();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //

      await createStockAnimal(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          // retrievecooperadores();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  // const loadVenda = () => {
  //   if (codigo) {
  //     StockAnimalService.get(codigo)
  //       .then((response) => {
  //         setVenda(response.data);
  //       })
  //       .catch((error) => {
  //         messages.mensagemErro("Erro ao buscar dados");
  //       });
  //   }
  // };
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  //

  return (
    <div>
      {/* LOTE */}
      <div>
        <form
          //enctype="multipart/form-data"
          onSubmit={(e) => saveUpdateVenda(e)}
        >
          <div className="row">
            <div className="col-6">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar..."
                  className="form-control cc-exp"
                  tabindex="1"
                  name="fazenda"
                  // value={fazenda}
                  onChange={(e) => verOsAniamis(e)}
                  placeholder={i18n.t("messages.fazenda")}
                >
                  <option>Selecionar Fazenda</option>
                  {verFazenda.map((fazenda) => (
                    <option value={fazenda.codigo}>{fazenda.nome}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-6">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar..."
                  className="form-control cc-exp"
                  // tabindex="1"
                  name="animal"
                  // value={lote}
                  onChange={(e) => onInputChange(e)}
                  placeholder={i18n.t("messages.animal")}
                >
                  <option>Selecione o animal</option>
                  {verAnimal.map((animal) => (
                    <option key={animal.codigo} value={animal.codigo}>
                      {animal.raca.racaAnimal}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {verFazendaAnimal === [] ? (
            ""
          ) : (
            <div>
              <h5 style={{ padding: "10px" }}>ANIMAIS DESTE LOTE</h5>

              <table className="table">
                <tr>
                  <tr>
                    <th>Raça</th>
                    <th>Peso</th>
                    <th>Especie</th>
                    <th>Proveniencia</th>
                    <th>Idade</th>
                  </tr>
                    <tr>
                      <td>{verFichaAnimal.raca?.racaAnimal}</td>
                      <td>{verFichaAnimal.peso}</td>
                      <td>{verFichaAnimal.raca?.especie?.especieAnimal}</td>
                      <td>{verFichaAnimal.pais?.nome}</td>
                      <td>{verFichaAnimal.idade}</td>
                      
                    </tr>
                </tr>
              </table>
              {/* <div className="row">
                <div className="col-6">
                  <div className="input-group">
                    <select
                      data-placeholder="Selecionar..."
                      className="form-control cc-exp"
                      // tabindex="1"
                      name="animal"
                      value={animal}
                      onChange={(e) => onInputChange(e)}
                      placeholder={i18n.t("messages.animal")}
                    >
                      <option>Selecionar o Animal</option>
                      {verFazendaAnimal.map((animal) => (
                        <option key={animal.codigo} value={animal.codigo}>
                          {animal.animal.raca.racaAnimal}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div> */}

              <h5 style={{ padding: "10px" }}>CADASTRAR STOCK DO ANIMAL</h5>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <input
                      id=""
                      name="preco"
                      type="number"
                      className="form-control cc-exp"
                      value={preco}
                      placeholder={i18n.t("messages.preco")}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <input
                      id=""
                      name="quantidade_fixa"
                      type="number"
                      className="form-control cc-exp"
                      value={quantidade_fixa}
                      // placeholder={i18n.t("messages.quantidade_fixa")}
                      placeholder="Quantidade"
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
              </div>

              {/*  */}

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <select
                      data-placeholder="Selecionar..."
                      className="form-control cc-exp"
                      tabindex="1"
                      name="imposto"
                      // value={fazenda}
                      onChange={(e) => onInputChange(e)}
                      // placeholder={i18n.t("messages.fazenda")}
                    >
                      <option>Selecionar Imposto</option>
                      {verImposto.map((imposto) => (
                        <option value={imposto.codigo}>
                          {imposto.iva} % <span></span>
                          {imposto.descricao}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <div className="input-group">
                      <select
                        data-placeholder="Selecionar..."
                        className="form-control cc-exp"
                        tabindex="1"
                        name="comissao"
                        // value={fazenda}
                        onChange={(e) => onInputChange(e)}
                        // placeholder={i18n.t("messages.fazenda")}
                      >
                        <option>Selecionar Comissão</option>
                        {verComissao.map((comissao) => (
                          <option value={comissao.codigo}>
                            {comissao.quota} %
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <h5 style={{ padding: "10px" }}>Calculos</h5>
              <div className="row">
                <div className="col-3">
                  <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                    {" "}
                    Preco inicial
                  </h6>
                  <div className="form-group">
                    <input
                      id=""
                      type="number"
                      className="form-control cc-exp"
                      value={verPrecoS}
                      // onChange={(e) => onInputChange(e)}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-3">
                  <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                    {" "}
                    Imposto
                  </h6>
                  <div className="form-group">
                    <input
                      id=""
                      type="text"
                      className="form-control cc-exp"
                      value={verImpostoS}
                      // onChange={(e) => onInputChange(e)}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-3">
                  <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                    {" "}
                    Comissao
                  </h6>
                  <div className="form-group">
                    <input
                      id=""
                      type="text"
                      className="form-control cc-exp"
                      value={verComissaoS}
                      // onChange={(e) => onInputChange(e)}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-3">
                  <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                    {" "}
                    Valor de Venda
                  </h6>
                  <div className="form-group">
                    <input
                      id=""
                      type="text"
                      className="form-control cc-exp"
                      value={vertotalS}
                      // onChange={(e) => onInputChange(e)}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {/*  */}
            </div>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              style={{
                backgroundColor: "#b46739",
                color: "#fff",
              }}
            >
              {i18n.t("buttons.cancelar")}
            </button>
            <button
              type="submit"
              className="btn"
              style={{
                backgroundColor: "#9ba239",
                color: "#fff",
              }}
            >
              <span>{i18n.t("buttons.save")}</span>
            </button>
          </div>
        </form>
      </div>
      {/* FIM LOTE */}
    </div>
  );
};

export default StokAnimal;
