import Menu from "../../components/layout/Menu";
import List_Fornecedor from "../../components/listTable/List_Fornecedor";

const Fornecedor = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <List_Fornecedor /> 
      </div>
      {/*  */}
    </>
  );
};

export default Fornecedor;