import api from "./api";

export const getComprovativos = async () => {
  try {
    return await api.get("Comprovativo");
  } catch (error) {
    console.log(error.message);
  }
};

// CARRINHO GLOBAL
export const getCarrinhoGlobal = async () => {
  try {
    return await api.get("CarrinhoGlobal");
  } catch (error) {
    console.log(error.message);
  }
};

export const getCarrinhoCodigo = async (codigo) => {
  try {
    return await api.get(`carrinhoByCarrinhoGlobal/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};


// Pendente Compra do Visitante
export const getPendenteCarrinhoCodigo = async (codigo) => {
  try {
    return await api.get(`pendenteCarrinho/${codigo}`);
  } catch (error) {
    console.log(error.message);
  } 
};

// Aprovar Compra do Visitante
export const getAprovarCarrinhoCodigo = async (codigo) => {
  try {
    return await api.get(`AprovarCarrinho/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};


// Negar Compra do Visitante
export const getNegarCarrinhoCodigo = async (codigo) => {
  try {
    return await api.get(`ReprovarCarrinho/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};


//


export const createComprovativo = async (data) => {
  try {
    return await api.post("comprovativo", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getComprovativoCodigo = async (codigo) => {
  try {
    return await api.get(`comprovativo/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateComprovativo = async (data) => {
  try {
    return await api.put("comprovativo", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeComprovativo = async (codigo) => {
  try {
    return await api.delete(`comprovativo/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getComprovativo = async (nome) => {
  try {
    return await api.get(`exibirpdfComprovativo/${nome}`);
  } catch (error) {
    console.log(error.message);
  }
};
