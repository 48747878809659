import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  createPermissao,
  getPermissaoCodigo,
  getPermissoes,
  updatePermissao,
} from "../../service/PermissaoService";
import * as messages from "../message/toastr";

import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const TabPermissao = ({ vetor }) => {
  const { codigo } = useParams();
  const [permissaos, setPermissaos] = useState([]);

  //
  useEffect(() => {
    getAllpermissao();
  }, []);

  //LISTAR
  const getAllpermissao = () => {
    getPermissoes()
      .then((response) => {
        setPermissaos(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //SALVAR
  const [permissao, setPermissao] = useState({
    nome: "",
  });

  //Limpar
  const handleClear = () => {
    setPermissaos({
      nome: "",
    });
  };
  const { nome } = permissao;
  //
  const onInputChange = (e) => {
    setPermissao({ ...permissao, [e.target.name]: e.target.value });
  };

  //Botao Salvar
  const saveUpdatepermissao = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nome", nome);

    //
    if (codigo) {
      await updatePermissao(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllpermissao();
          handleClear();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createPermissao(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllpermissao();
          handleClear();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadpermissao();
  }, []);
  //
  const loadpermissao = () => {
    if (codigo) {
      getPermissaoCodigo(codigo)
        .then((response) => {
          setPermissao(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };

  return (
    <div>
      <form onSubmit={(e) => saveUpdatepermissao(e)}>
        <div className="row">
          <div className="col-12">
            {/* <div className="form-group"> */}
            <input
              id=""
              name="nome"
              type="text"
              className="form-control cc-exp"
              value={nome}
              onChange={(e) => onInputChange(e)}
              // placeholder={i18n.t('messages.nome')}
              placeholder="Descriçao"
            />
            {/* </div> */}
          </div>
        </div>

        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.save")}
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabPermissao;
