import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getProdutoCodigo } from "../../service/ProdutoService";
import Menu from "../../components/layout/Menu";

const ViewProduto = (props) => {
  //
  const [produto, setProduto] = useState({
    imgUrl: "",
    nome: "",
    cod: "",
    categoria: "",
    descricao: "",
 
  });

  const { codigo } = useParams();

  //
  useEffect(() => {
    viewProduto();
  }, []);


  //
  const viewProduto = () => {
    if (codigo) {
      getProdutoCodigo(codigo).then((response) => {
        setProduto(response.data);
      });
    }
  };
  //


  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">
                <Link
                  to="/produtos"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="las la-plus">Voltar</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">Informações do Produto</strong>
                  </div>

                  <div className="col-xs-12 col-sm-12">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="card">
                          <div className="card-body">
                            <img

                              src={`${produto.imgUrl}`}
                              className="foto-animal"
                            />

                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="card">
                          <div className="card" style={{ width: "100%" }}>
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <strong>Produto:</strong> {produto.nome}
                              </li>
                              <li className="list-group-item">
                                <strong>Código:</strong> {produto.cod}
                              </li>
                              <li className="list-group-item">
                                <strong>Categoria:</strong> {produto.categoria.nome}
                              </li>
                              <li className="list-group-item">
                                <strong>Descrição:</strong> {produto.descricao}
                              </li>
                            
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}
    </>
  );
};

export default ViewProduto;
