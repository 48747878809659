import React, { useState, useEffect } from "react";
import {
  createRapido,
  getLoteAnimalRapido,
  getLoteMudarLoteGeral,
} from "../../service/RapidoService";
import * as messages from "../message/toastr";
import { getPaises } from "../../service/PaisService";
import { i18n } from "../../Traducao/translate/i18n";
import { useParams } from "react-router-dom";
import { getLoteCodigo } from "../../service/LoteService";
import {
  getRacaRapido_individual,
  racaRapidosLote,
  getRacaRapidoLote,
} from "../../service/AdicionarRacaRapidoService.js";

import * as yup from "yup";

const Rapido = ({ loteId, loteEspecieId }) => {
  const I18N_STORAGE_KEY = "i18nextLng";
  // Buscar por ID
  const { codigo } = useParams();
  const [verPais, setVerPais] = useState([]);
  const [verRapidos, setVerRapidos] = useState([]);
  const [verRacaRapidos, setVerRacaRapidos] = useState([]);
  const [verRapidoRacas, setVerRapidoRacas] = useState([]);
  const [verCodLote, setverCodLote] = useState({});

  const [TrocarModal, setTrocarModal] = useState(false);

  function VerAsRacas(){
    setTrocarModal(!TrocarModal)
  }
  const { getEspecieCodigo } = useParams();
  //STATUS
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });

  //
  useEffect(() => {
    getCodigoLote();
    getAllPais();
    getAllRapidos();
    getAllRacaRapidos();
    getAllRapidoRacas();
  }, []);

  //LISTAR LOTES RAPIDO, PAIS RACA
  const getAllPais = () => {
    getPaises()
      .then((response) => {
        setVerPais(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const getCodigoLote = async () => {
    await getLoteCodigo(loteId)
      .then((response) => {
        setverCodLote(response.data.especie.especieAnimal);
      })
      .catch((error) => {
        // messages.mensagemErro("Erro LOTES", error);
      });
  };

  const getAllRapidos = () => {
    getLoteAnimalRapido(loteId)
      .then((response) => {
        setVerRapidos(response.data);
      })
      .catch((error) => {
        // messages.mensagemErro("Erro LOTES", error);
      });
  };
  //
  const getAllRapidoRacas = () => {
    getRacaRapidoLote(loteId)
      .then((response) => {
        setVerRapidoRacas(response.data);
      })
      .catch((error) => {
        // messages.mensagemErro("Erro LOTES", error);
      });
  };
  //

  const getAllRacaRapidos = () => {
    getRacaRapido_individual(loteEspecieId)
      .then((response) => {
        // console.log(loteEspecieId.data);
        setVerRacaRapidos(response.data);
      })
      .catch((error) => {
        // messages.mensagemErro("Erro LOTES", error);
      });
  };

  // LOTES RAPIDO SALVAR
  const [rapido, setRapido] = useState({
    pesomin: "",
    pesomaxi: "",
    idademin: "",
    idademax: "",
    numerosexoM: "",
    numerosexoF: "",
    apto: "",
    pais: "",
    obs: "",
    // raca: "",
    lote: { loteId },
  });

  const {
    pesomin,
    pesomaxi,
    idademin,
    idademax,
    numerosexoM,
    numerosexoF,
    apto,
    pais,
    obs,
    // raca,
  } = rapido;

  const onInputChange = (e) => {
    setRapido({ ...rapido, [e.target.name]: e.target.value });
  };

  //Botao Salvar
  const saveUpdaterapido = async (e) => {
    e.preventDefault();
    // if (!(await validate())) return;
    const formData = new FormData();
    formData.append("pesomin", pesomin);
    formData.append("pesomaxi", pesomaxi);
    formData.append("idademin", idademin);
    formData.append("idademax", idademax);
    formData.append("numerosexoM", numerosexoM);
    formData.append("numerosexoF", numerosexoF);
    formData.append("apto", apto);
    formData.append("pais", pais);
    formData.append("obs", obs);
    // formData.append("raca", raca);
    formData.append("lote", loteId);

    await createRapido(formData)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        MudarLoteGeral(loteId);
        getAllRapidos();
        setRapido({
          pesomin: "",
          pesomaxi: "",
          idademin: "",
          idademax: "",
          numerosexoM: "",
          numerosexoF: "",
          apto: "",
          qtd: "",
          pais: "",
        });
        setverForm(false);
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
        console.log(error);
      });
  };
  const MudarLoteGeral = (codigo) => {
    getLoteMudarLoteGeral(codigo);
  };
  //VAlidacao
  async function validate() {
    let schema = yup.object().shape({
      titulo: yup
        .string("E Necessário preencher o nome!")
        .required("E Necessário preencher o nome!"),
    });
    try {
      await schema.validate(pais);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  const [verForm, setverForm] = useState(true);

  const AddRacaRapidoLote = async (cod) => {
    const datas = {
      lote: {
        codigo: loteId,
      },
      raca: {
        codigo: cod,
      },
    };
    racaRapidosLote(datas)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        getAllRapidoRacas();
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
        console.log(error);
      });
  };

  //
  const [EscolhaCAtegoria, setEscolhaCAtegoria] = useState(0);

  const verCategoriaTrocar = (e) => {
    if (e.target.value === "rapido") {
      setEscolhaCAtegoria("rapido");
    }
    if (e.target.value === "especifico") {
      setEscolhaCAtegoria("especifico");
    }
    if (e.target.value === "buscar") {
      setEscolhaCAtegoria("buscar");
    }
  };
  //
  return (
    <div>
      <form
        // enctype="multipart/form-data"
        onSubmit={(e) => saveUpdaterapido(e)}
      >
        <div className="card-body">
          <h3>Nº do Lote Criador {loteId}</h3>
          <br></br>
          <div className="row">
            {/*  */}
            <div className="col-3">
              <div className="input-group">
                <input
                  name="pesomin"
                  type="number"
                  className="form-control cc-exp"
                  placeholder="Peso Mínimo kg"
                  value={rapido.pesomin}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            {/*  */}
            <div className="col-3">
              <div className="input-group">
                <input
                  name="pesomaxi"
                  type="number"
                  className="form-control cc-exp"
                  placeholder="Peso Máximo kg"
                  value={rapido.pesomaxi}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            {/*  */}
            <div className="col-3">
              <div className="input-group">
                <input
                  name="idademin"
                  type="number"
                  className="form-control cc-exp"
                  placeholder="Idade Mínima"
                  value={rapido.idademin}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                <input
                  name="idademax"
                  type="number"
                  className="form-control cc-exp"
                  placeholder="Idade Máxima"
                  value={rapido.idademax}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>

            <br />
          </div>
          <br />
          {/*  */}
          <div className="row">
            <div className="col-3">
              <div className="input-group">
                <input
                  name="numerosexoM"
                  type="number"
                  className="form-control cc-exp"
                  placeholder="Nº de Macho"
                  value={rapido.numerosexoM}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="input-group">
                <input
                  name="numerosexoF"
                  type="number"
                  className="form-control cc-exp"
                  placeholder="Nº de Fêmea"
                  value={rapido.numerosexoF}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>

            <div className="col-3">
              <div className="input-group">
                {/* {rapido.apto === 0 ? "Abate" : "Reprodução"} */}
                <select
                  name="apto"
                  className="form-control cc-exp"
                  onChange={(e) => onInputChange(e)}
                >
                  <option>Apto para ...</option>
                  <option value="REPRODUÇÃO">Reprodução</option>
                  <option value="ABATE">Abate</option>
                </select>
              </div>
            </div>
            {/*  */}
            <div className="col-3">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar..."
                  className="form-control cc-exp"
                  tabindex="1"
                  name="pais"
                  value={rapido.pais}
                  onChange={(e) => onInputChange(e)}
                  placeholder={i18n.t("messages.pais")}
                >
                  <option>Selecionar Pais</option>
                  {verPais.map((pais) => (
                    <option value={pais.codigo}>{pais.nome}</option>
                  ))}
                </select>
              </div>
            </div>
            {/*  */}
          </div>
          <br />
          {/*  */}
          <div className="row">
            <div className="col-12">
              <div className="row form-group">
                <div className="col-12 col-md-12">
                  <textarea
                    name="obs"
                    id="textarea-input"
                    rows="6"
                    value={obs}
                    onChange={(e) => onInputChange(e)}
                    // placeholder={i18n.t("messages.obs")}
                    className="form-control"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <br />
          <div className="row-12" style={{ display: "flex" }}>
            {/* <div className="col-12"> */}
            {verRapidoRacas.map((racaL) => (
              <tr
                key={racaL.codigo}
                style={{
                  background: "#ddd",
                  padding: "6px",
                  border: "none",
                  outline: "none",
                  borderRadius: "3px",
                  margin: "5px",
                }}
              >
                <td>
                  {racaL.raca.racaAnimal}{" "}
                  <span
                    style={{
                      background: "#fff",
                      padding: "6px",
                      border: "none",
                      outline: "none",
                      borderRadius: "50%",
                      margin: "5px",
                      fontSize: "20px",
                      fontFamily: "Arial sans-serif",
                    }}
                  >
                    x
                  </span>
                </td>
              </tr>
            ))}
            {/* </div> */}
          </div>
          {/*  */}
          <div className="row">
            {/*  */}
            <div className="col-3">
              {/*  */}

              <div className="btn" onClick={()=>VerAsRacas()}>
                Adicionar Raças
              </div>

              {/* <input
                type="radio"
                name="categoriatroca"
                value="rapido"
                id="rapido"
                style={{ display: "none" }}
                onChange={() => setTrocarModal(true)}
              />
              <label
                for="rapido"
                className="btn btn-secondary mb-1"
                style={{ marginRight: "10px", width: "140px" }}
              >
                Adicionar Raças
              </label> */}
            </div>
            {/*  */}
            {TrocarModal === true ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Raça</th>
                    <th>Adicionar</th>
                  </tr>
                </thead>
                <tbody>
                  {verRacaRapidos.map((raca) => (
                    <tr key={raca.codigo}>
                      <td>{raca.racaAnimal}</td>
                      <td>
                        <a
                          onClick={() => AddRacaRapidoLote(raca.codigo)}
                          className="btn btn-success"
                          style={{
                            border: "none",
                            outline: "none",
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          Adicionar
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              ""
            )}
            {/*  */}
          </div>
          {/*  */}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#ccc",
              color: "#000",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>

          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span>{i18n.t("buttons.save")}</span>
          </button>
        </div>
      </form>

      <div className="card-body">
        {verRapidos == 0 ? (
          <h1 style={{ textAlign: "center", color: "#9ba239" }}>
            Sem lotes na tabela
          </h1>
        ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Nº de Animais</th>
                <th>Sexo</th>
                <th>Peso Kg</th>
                <th>Idade Meses</th>
                {/* <th>Raça</th> */}
                <th>País</th>
                <th>Fazenda</th>
                <th>Apto para</th>
              </tr>
            </thead>
            <tbody>
              {verRapidos.map((rapido) => (
                <tr key={rapido.codigo}>
                  <td>{rapido.qtd}</td>
                  <td>
                    {rapido.numerosexoM} M / {rapido.numerosexoF} F
                  </td>
                  <td>
                    {rapido.pesomin}Kg / {rapido.pesomaxi}Kg
                  </td>
                  <td>
                    {rapido.idademin} / {rapido.idademax}
                  </td>
                  {/* <td>{rapido.tipoRaca}</td> */}
                  <td>{rapido.pais.nome}</td>
                  <td>{rapido.lote.fazenda.nome}</td>
                  <td>{rapido.apto}</td>
                  <td>
                    {/* <Link
                  to={`/lote/ViewLote/${rapido.codigo}`}
                  className="btn btn-primary"
                >
                  <span className="las la-eye"></span>
                </Link>
        
                <Link
                  to={`/lote/editLote/:codigo/${rapido.codigo}`}
                  id="event-modal"
                  style={{ marginLeft: "10px" }}
                >
                  <button
                    type="submit"
                    className="btn btn-warning"
                    data-toggle="modal"
                    data-target="#largeModal"
                  >
                    <span className="las la-edit"></span>
                  </button>
                </Link>
                <Link
                  to={`/relatorioIdA/${rapido.codigo}`}
                  className="btn btn-primary"
                  style={{ marginLeft: "10px" }}
                >
                  <span className="fa fa-print"></span>
                </Link> */}
                    {/* <button
                      type="submit"
                      className="btn btn-danger"
                      onClick={() => deleteLote(rapido.codigo)}
                      style={{ marginLeft: "10px" }}
                    >
                      <span className="las la-trash"></span>
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {/*  */}
      </div>
    </div>
  );
};

export default Rapido;
