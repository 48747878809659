import Menu from "../../components/layout/Menu";
import ListAgenda from "../../components/listTable/ListAgenda";

const Agenda = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListAgenda />
      </div>
      {/*  */}
    </>
  );
};

export default Agenda;