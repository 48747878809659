function redirects() {
  const paises = () => {
    window.location.href = "/paises";
  };
  const cooperadores = () => {
    window.location.href = "/cooperadores";
  };
  const fazendas = () => {
    window.location.href = "/fazendas";
  };
  const animais = () => {
    window.location.href = "/animais";
  };
  const lotes = () => {
    window.location.href = "/lotes";
  };
  const usuarios = () => {
    window.location.href = "/visitantes";
  };
  const agendas = () => {
    window.location.href = "/agendas";
  };
  const lances = () => {
    window.location.href = "/lances";
  };
  const leiloes = () => {
    window.location.href = "/leiloes";
  };
  const exclusivos = () => {
    window.location.href = "/exclusivos";
  };
  const servicos = () => {
    window.location.href = "/servicos";
  };
  const stocks = () => {
    window.location.href = "/vendas";
  };
  const especies = () => {
    window.location.href = "/especies";
  };
  const racas = () => {
    window.location.href = "/racas";
  };
  const transportes = () => {
    window.location.href = "/transportes";
  };
  const produtos = () => {
    window.location.href = "/produtos";
  };
  const comprovativos = () => {
    window.location.href = "/comprovativos";
  };
   return {
    paises,
    cooperadores,
    fazendas,
    animais,
    lotes,
    usuarios,
    agendas,
    leiloes,
    exclusivos,
    lances,
    servicos,
    especies,
    racas,
    transportes,
    produtos,
    stocks,
    comprovativos
  };
}

export default redirects;
