import { data } from "jquery";
import api from "./api";

export const getEspecies = async () => {
  try {
    return await api.get("especies");
  } catch (error) {
    console.log(error.message);
  }
};
export const createEspecie = async (data) => {
  try {
    return await api.post("especie", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateEspecie = async (codigo, data) => {
  try {
    return await api.put(`especie/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeEspecie = async (codigo) => {
  try {
    return await api.delete(`especie/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getEspecieCodigo = async (codigo) => {
  try {
    return await api.get(`especie/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getAllListEspecie = async (params) => {
  try {
    return await api.get("especie", { params });
  } catch (error) {
    console.log(error.message);
  }
};
