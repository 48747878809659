import Menu from "../../components/layout/Menu";
import ListUsuarios from "../../components/listTable/ListUsuarios";

const Usuarios = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListUsuarios />
      </div>
      {/*  */}
    </>
  );
};

export default Usuarios;
