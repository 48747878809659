import api from "./api";

export const getCarrinhoServices = async () => {
  try {
    return await api.get("Carrinho");
  } catch (error) {
    console.log(error.message);
  }
};
export const getCodigo = async (codigo) => {
  try {
    return await api.get(`carrinho_visitante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};


export const getCodigoProduto = async (codigo) => {
  try {
    return await api.get(`carrinhoProduto_visitante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getTotalProdutosCart = async (codigo) => {
  try {
    return await api.get(`carrinhoProduto/visitante=${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

//
export const getTotalAnimaisCart = async (codigo) => {
  try {
    return await api.get(`carrinho/visitante=${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

//Carrinho PRODUTO LoteSOMA
export const getTotalProdutoCart = async (codigo) => {
  try {
    return await api.get(`carrinhoProdutoFechadoVisitante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
//Carrinho Lote SOMA
export const getTotalLoteCartVisi = async (codigo) => {
  try {
    return await api.get(`carrinhoFechadoVisitante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getTotalLoteCart = async (codigo) => {
  try {
    return await api.get(`carrinho/visitante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

//
export const findByNome = async (nome) => {
  try {
    return await api.get(`?nome=${nome}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const remove = async (codigo) => {
  try {
    return await api.delete(`carrinho/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getImagem = async (imagem) => {
  try {
    return await api.get("Carrinho");
  } catch (error) {
    console.log(error.message);
  }
};
