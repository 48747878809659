import api from "./api";

export const getFornecedores = async () => {
  try {
    return await api.get("Fornecedor");
  } catch (error) {
    console.log(error.message);
  }
};
export const createFornecedor = async (data) => {
  try {
    return await api.post("criar_fornecedor", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getFornecedorCodigo = async (codigo) => {
  try {
    return await api.get(`fornecedor/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateFornecedor = async (data) => {
  try {
    return await api.put("fornecedor", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeFornecedor = async (codigo) => {
  try {
    return await api.delete(`Fornecedor/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getImagem = async (imagem) => {
  try {
    return await api.get(`exibirImagemFornecedor/${imagem}`);
  } catch (error) {
    console.log(error.message);
  }
};
