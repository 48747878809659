import { messages as portugueseMessages } from './pt'
import { messages as englishMessages } from './en'
import { messages as franceMessages } from './fr'

const messages = {
    ...portugueseMessages,
    ...englishMessages,
    ...franceMessages,
}

export { messages }
