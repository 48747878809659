import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { listarEspecie } from "../../service/service";
import { i18n } from '../../Traducao/translate/i18n';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const options = {
  indexAxis: "x",
  elements: {
    bar: {
      borderWidthL: 1,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: true,
      text: "Dados Estatisticos dos Animais, ano 2020-2022",
    },
  },
};

const Horizontalchart = () => {
  const [data, setData] = useState({
    labels: [
      "Dataset 1",
      "Dataset 2",
      "Dataset 3",
      "Dataset 4",
      "Dataset 5",
      "Dataset 6",
      "Dataset 7",
    ],
    datasets: [
      {
        label: "Dataset 1",
        data: 1,
        borderColor: "rgb(155, 162, 54)",
        backgroundColor: "rgb(155, 162, 54)",
      },
      {
        label: "Dataset 2",
        data: 2,
        borderColor: "rgb(53,162,235)",
        backgroundColor: "rgb(53, 56, 6)",
      },
      {
        label: "Dataset 3",
        data: 3,
        borderColor: "rgb(53,162,235)",
        backgroundColor: "rgb(211, 151, 22)",
      },
      {
        label: "Dataset 4",
        data: 4,
        borderColor: "rgb(53,162,235)",
        backgroundColor: "rgb(99, 97, 12)",
      },
      {
        label: "Dataset 5",
        data: 5,
        borderColor: "rgb(53,162,235)",
        backgroundColor: "rgb(17, 106, 32)",
      },
      {
        label: "Dataset 6",
        data: 6,
        borderColor: "rgb(53,162,235)",
        backgroundColor: "rgb(112, 117, 39)",
      },
      {
        label: "Dataset 7",
        data: 7,
        borderColor: "rgb(53,162,235)",
        backgroundColor: "rgb(39, 41, 12)",
      },
    ],
  });

  const listarTudo = async () => {
    const bovinos = await listarEspecie("Bovinos");
    const ovinos = await listarEspecie("Ovinos");
    const caprinos = await listarEspecie("Caprinos");
    const equideos = await listarEspecie("Equideos");
    const assininos = await listarEspecie("Asininos");
    const suinos = await listarEspecie("Suinos");
    const buffalos = await listarEspecie("Buffalos");

    const labelSet = [];
    const dataSet1 = [];
    const dataSet2 = [];
    const dataSet3 = [];
    const dataSet4 = [];
    const dataSet5 = [];
    const dataSet6 = [];
    const dataSet7 = [];

    dataSet1.push(bovinos.data);
    dataSet2.push(caprinos.data);
    dataSet3.push(ovinos.data);
    dataSet4.push(assininos.data);
    dataSet5.push(suinos.data);
    dataSet6.push(buffalos.data);
    dataSet7.push(equideos.data);

    labelSet.push("Dados Estatisiticos");
    setData({
      labels: labelSet,
      datasets: [
        {
          label: " Bovinos",
          data: dataSet1,
          borderColor: "rgb(25,255,10)",
          backgroundColor: "rgb(155, 162, 54)",
        },
        {
          label: "Caprinos",
          data: dataSet2,
          borderColor: "rgb(253,335,10)",
          backgroundColor: "rgb(53, 56, 6)",
        },
        {
          label: "Ovinos",
          data: dataSet3,
          borderColor: "rgb(25,200,90)",
          backgroundColor: "rgb(211, 151, 22)",
        },
        {
          label: "Asininos",
          data: dataSet4,
          borderColor: "rgb(25,255,170)",
          backgroundColor: "rgb(99, 97, 12)",
        },
        {
          label: "Suinos",
          data: dataSet5,
          borderColor: "rgb(153,662,235)",
          backgroundColor: "rgb(50, 106, 32)",
        },
        {
          label: "Buffalos",
          data: dataSet6,
          borderColor: "rgb(5,12,235)",
          backgroundColor: "rgb(112, 117, 39)",
        },
        {
          label: "Equideos",
          data: dataSet7,
          borderColor: "rgb(5,102,235)",
          backgroundColor: "rgb(39, 41, 12)",
        },
      ],
    });
  };

  const [totalTime, setTotalTime] = useState([60]);

  const minutos = Math.floor(totalTime / 60);
  const segundos = totalTime % 60;

  useEffect(() => {
    // if (totalTime === 0) {
    //   alert("O seu tempo acabou");
    // } else {
    setTimeout(() => {
      listarTudo();
      setTotalTime(totalTime - 1);
    }, 1000);
    // }

    listarTudo();
  }, [totalTime]);
  
  return (
    <div id="right-panel" className="right-panel">
      {/*  */}
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">

              <div
                  className="card-header"
                  style={{ background: "#b46739", fontFamily:"arial", color: "#fff" }}
                >
                  <strong className="card-title">
                    {i18n.t('messages.titulo_grafico')}
                  </strong>
                </div>
              

                <div className="col-xs-12 col-sm-12">
                  <div className="row">
                    {/* <div style={{ width: "800", height: "500" }}> */}
                    <Bar data={data} options={options} /> 
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- .animated --> */}
      </div>
      {/* <!-- .content --> */}

      <div className="clearfix"></div>
      {/*  */}
    </div>


  );
};

export default Horizontalchart;
