import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import {
  createRaca,
  getRacaCodigo,
  getRacas,
  removeRaca,
  updateRaca,
} from "../../service/RacaService";
import { getEspecies } from "../../service/EspecieService";
import * as messages from "../message/toastr";

import Swal from "sweetalert2";

import * as yup from "yup";

import { i18n } from "../../Traducao/translate/i18n";

const I18N_STORAGE_KEY = "i18nextLng";

const ListRaca = ({ vetor }) => {
  const { codigo } = useParams();
  const [racas, setRacas] = useState([]);
  const [especies, setEspecie] = useState([]);

  //
  useEffect(() => {
    getAllracas();
    getAllEspecie();
    loadraca();
  }, []);

  //LISTAR A RACA A ESPECIE
  const getAllracas = () => {
    getRacas()
      .then((response) => {
        setRacas(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllEspecie = () => {
    getEspecies()
      .then((response) => {
        setEspecie(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deleteRaca = (codigo) => {
    Swal.fire({
      title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeRaca(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllracas();
        });
      }
    });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  //SALVAR
  const [raca, setRaca] = useState({
    racaAnimal: "",
    especie: {
      codigo: "",
    },
  });
  //Limpar os campos
  const handleClear = () => {
    setRaca({
      racaAnimal: "",
      especie: "",
    });
  };
  //
  const { racaAnimal, especie } = raca;

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //

  const [Araca, setAraca] = useState()
  const [Aespecie, setAespecie] = useState()
  //
  const onInputChange = (e) => {
    setRaca({ ...raca, [e.target.name]: e.target.value });
    if (e.target.name === 'racaAnimal') {
      setAraca(e.target.value)
    }
    if (e.target.name === 'especie') {
      setAespecie(e.target.value)
    }
  };

  //Botao Salvar
  const saveUpdateRaca = async (e) => {
    e.preventDefault();


    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("racaAnimal", racaAnimal);
    formData.append("especie", especie);

    //
    if (codigo) {
      await updateRaca(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllracas();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      const datas = {
        racaAnimal: Araca,
        especie: {
          codigo: Aespecie
        }
      }
      //
      console.log(datas)
      await createRaca(datas)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllracas();
          handleClear();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };

  //
  const loadraca = () => {
    if (codigo) {
      getRacaCodigo(codigo)
        .then((response) => {
          setRaca(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };
  //

  //
  async function validate() {
    let schema = yup.object().shape({

      racaAnimal: yup
        .string("Informe a Raça desta Espécie de Animal!")
        .required("Informe a Raça desta Espécie de Animal!"),

      especie: yup
        .string("Seleciole a Espécie!")
        .required("Seleciole a Espécie!"),

    });
    try {
      await schema.validate(raca);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/addracas" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus"></span>
                  </button>
                </Link>
                <Link to="/relatorioracas" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="fa fa-print"></span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.raca")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.raca")}</th>
                          <th scope="col">Especie</th>

                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {racas.map((raca) => (
                          <tr key={raca.codigo}>
                            <td data-label="Raça:">{raca.racaAnimal}</td>
                            {/* <td>{raca.especie.especieAnimal}</td> */}
                            <td data-label="Adicionais:">
                              <Link
                                to={`/editRaca/${raca.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>

                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deleteRaca(raca.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
              <div className="default-tab">
                {/*  */}

                <form
                  enctype="multipart/form-data"
                  onSubmit={(e) => saveUpdateRaca(e)}
                >

                  {status.type === "error" ? (
                    <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
                  ) : (
                    ""
                  )}

                  {/* raca */}
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <select
                          data-placeholder="Selecionar Proprietario..."
                          className="form-control cc-exp"
                          tabindex="1"
                          name="especie"
                          value={especie}
                          onChange={(e) => onInputChange(e)}
                        >
                          <option
                            value=""
                            label="Selecionar a provincia de destino..."
                          ></option>
                          {especies.map((especie) => (
                            <option key={especie.codigo} value={especie.codigo}>
                              {especie.especieAnimal}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="racaAnimal"
                          className="form-control cc-exp"
                          placeholder="Descrição"
                          value={racaAnimal}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>

                  </div>
                  {/*  */}
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                      style={{
                        backgroundColor: "#b46739",
                        color: "#fff",
                      }}
                    >
                      {i18n.t("buttons.cancelar")}
                    </button>
                    <button
                      type="submit"
                      className="btn"
                      style={{
                        backgroundColor: "#9ba239",
                        color: "#fff",
                      }}
                    >
                      {i18n.t("buttons.save")}
                    </button>
                  </div>
                  {/*  */}
                  {/* FIM raca */}
                </form>

                {/*  */}
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListRaca;
