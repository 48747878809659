import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getCodigo } from "../../service/TransporteService";
import Menu from "../../components/layout/Menu";

const ViewTranporte = (props) => {
  //
  const [transporte, setTransporte] = useState({
    imgUrl: "",
    matricula: "",
    precoTransporte: "",
    tipoDeCarga: "",
    marca: "",
    modelo: "",
    municipio: "",
    trajectoria: "",
    proprietario: "",
    nomeMotorista: "",
    bi: "",
    cartaDeConducao: "",
    totalComissao: "",
    totalCooperativa: "",
    comissao: "",
  });

  const { codigo } = useParams();

  //
  useEffect(() => {
    ViewTranporte();
  }, []);

  const ViewTranporte = () => {
    if (codigo) {
      getCodigo(codigo).then((response) => {
        setTransporte(response.data);
      });
    }
  };
  //
  //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">
              <Link
                  to="/transportes"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="fa fa-reply"></span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      Informações do Transporte
                    </strong>
                  </div>

                  <div className="col-xs-12 col-sm-12">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="card">
                          <div className="card-body">
                            <img
                              src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirImagemTransporte/${transporte.imgUrl}`}
                              className="foto-transporte"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-8">
                        <div className="card">
                          <div className="card" style={{ width: "100%" }}>
                            <ul className="list-group list-group-flush">
                              <li className="list-group-item">
                                <strong>Proprietario:</strong>{" "}
                                {transporte.proprietario.nome}
                              </li>

                              <li className="list-group-item">
                                <strong>email:</strong>{" "}
                                {transporte.proprietario.email}
                              </li>
                              
                              <li className="list-group-item">
                                <strong>Empresa:</strong>{" "}
                                {transporte.proprietario.empresa}
                              </li>

                              {/* <li className="list-group-item">
                                <strong>Pais:</strong>{" "}
                                {transporte.proprietario.pais.nome}
                              </li> */}


                              <li className="list-group-item">
                                <strong>Motorista:</strong>{" "}
                                {transporte.nomeMotorista}
                              </li>

                              <li className="list-group-item">
                                <strong>BI:</strong> {transporte.bi}
                              </li>

                              <li className="list-group-item">
                                <strong>Carta de Condução:</strong>{" "}
                                {transporte.cartaDeConducao}
                              </li>

                              <li className="list-group-item">
                                <strong>Matrícula:</strong>{" "}
                                {transporte.matricula}
                              </li>

                              <li className="list-group-item">
                                <strong>Marca:</strong> {transporte.marca}
                              </li>

                              <li className="list-group-item">
                                <strong>Modelo:</strong> {transporte.modelo}
                              </li>

                              <li className="list-group-item">
                                <strong>Preço do transporte:</strong>{" "}
                                {transporte.precoTransporte} kz por KM
                              </li>

                              <li className="list-group-item">
                                <strong>Comissão:</strong>{" "}
                                {transporte.comissao.quota} %
                              </li>

                              <li className="list-group-item">
                                <strong>Total da Comissão:</strong>{" "}
                                {transporte.totalComissao} Kz
                              </li>

                              <li className="list-group-item">
                                <strong>Total da Cooperativa:</strong>{" "}
                                {transporte.totalCooperativa} Kz
                              </li>

                              <li className="list-group-item">
                                <strong>Tipo de entrega:</strong>{" "}
                                {transporte.tipoDeCarga}
                              </li>

                              {/* <li className="list-group-item">
                                <strong>Trajetória:</strong>{" "}
                                {transporte.trajectoria}
                              </li> */}

                              <li className="list-group-item">
                                <strong>Município:</strong>{" "}
                                {transporte.municipio.nome}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}
    </>
  );
};

export default ViewTranporte;
