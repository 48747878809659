import api from "./api";

export const getLotes = async () => {
  try {
    return await api.get("lotes");
  } catch (error) {
    console.log(error.message);
  }
};
export const getAnimais_Fazenda = async (codigo) => {
  try {
    return await api.get("FazendaAnimais/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const getAnimal_individual = async (codigo) => {
  try {
    return api.get("animal/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const getLoteRapido = async (codigo) => {
  try {
    return api.get("lotesRapidos/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const createLote = async (data) => {
  try {
    return await api.post("lote", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getLoteCodigo = async (codigo) => {
  try {
    return await api.get(`lote/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getLoteFazenda = async (codigo) => {
  try {
    return await api.get(`loteFazenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getLoteFazendaLeilao = async (codigo) => {
  try {
    return await api.get(`https://webcwam-polished-dust-7716.fly.dev/apiloteFazendaLeilao/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getLoteFazendaVenda = async (codigo) => {
  try {
    return await api.get(`https://webcwam-polished-dust-7716.fly.dev/apiloteFazendaVenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const updateLote = async (data) => {
  try {
    return await api.put("lote", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeLote = async (codigo) => {
  try {
    return await api.delete(`lote/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
