import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { create, getCodigo } from "../../service/TransporteService";
import { getPaises } from "../../service/PaisService";
import { getProvinciaPais } from "../../service/ProvinciaService";
import { getMunicipioProvinca } from "../../service/MunicipioService";
import { getComissoes } from "../../service/ComissaoService";
import {
  getProprietarios,
  updateProprietario,
} from "../../service/ProprietarioService";
import * as messages from "../message/toastr";

import * as yup from "yup";

import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const TabTransporte = () => {
  const { codigo } = useParams();

  const [paises, setPaises] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [Comissao, setComissao] = useState([]);
  const [proprietarios, setproprietarios] = useState([]);
  useEffect(() => {
    getAllPais();
    getAllProprietario();
    getAllComissao();
  }, []);

  //LISTAR PAIS, PROVINCIA MUNICIPIO, IMPOSTO, COMISSAO E PROPRIETARIO
  //LISTAR PAIS, PROVINCIA MUNICIPIO
  const getAllPais = () => {
    getPaises()
      .then((response) => {
        setPaises(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllProvincia = (e) => {
    getProvinciaPais(e.target.value)
      .then((response) => {
        setProvincias(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllMunicipio = (e) => {
    getMunicipioProvinca(e.target.value)
      .then((response) => {
        setMunicipios(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const getAllComissao = () => {
    getComissoes()
      .then((response) => {
        setComissao(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllProprietario = () => {
    getProprietarios()
      .then((response) => {
        setproprietarios(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //SALVAR
  const [transporte, setTransporte] = useState({
    imgUrl: "",
    matricula: "",
    precoTransporte: "",
    tipoDeCarga: "",
    marca: "",
    modelo: "",
    municipio: "",
    proprietario: "",
    nomeMotorista: "",
    bi: "",
    cartaDeConducao: "",
    comissao: "",
  });

  //Limpar form
  const handleClear = () => {
    setTransporte({
      imgUrl: "",
      matricula: "",
      precoTransporte: "",
      tipoDeCarga: "",
      marca: "",
      modelo: "",
      municipio: "",
      proprietario: "",
      nomeMotorista: "",
      bi: "",
      cartaDeConducao: "",
      comissao: "",
    })
  }
  //
  const [imgUrl, setImgUrl] = useState("");
  const {
    matricula,
    precoTransporte,
    tipoDeCarga,
    marca,
    modelo,
    pais,
    provincia,
    municipio,
    proprietario,
    nomeMotorista,
    bi,
    cartaDeConducao,
    comissao,
  } = transporte;
  //

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //

  const onInputChange = (e) => {
    setTransporte({ ...transporte, [e.target.name]: e.target.value });
    if (e.target.name === "pais") {
      getAllProvincia(e.target.value);
    }
    if (e.target.name === "provincia") {
      getAllMunicipio(e.target.value);
    }
  };

  //Botao Salvar
  const saveUpdateTransporte = async (e) => {
    e.preventDefault();

    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("matricula", matricula);
    formData.append("precoTransporte", precoTransporte);
    formData.append("tipoDeCarga", tipoDeCarga);
    formData.append("marca", marca);
    formData.append("modelo", modelo);
    formData.append("pais", pais);
    formData.append("provincia", provincia);
    formData.append("municipio", municipio);
    formData.append("proprietario", proprietario);
    formData.append("comissao", comissao);
    formData.append("nomeMotorista", nomeMotorista);
    formData.append("bi", bi);
    formData.append("cartaDeConducao", cartaDeConducao);

    if (codigo) {
      await updateProprietario(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await create(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          handleClear();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadTransporte();
  }, []);
  //
  const loadTransporte = () => {
    if (codigo) {
      getCodigo(codigo)
        .then((response) => {
          setTransporte(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };

  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setImgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };
  //

  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };
  //

  //
  async function validate() {
    let schema = yup.object().shape({

      // municipio: yup
      //   .string("Selecionar o município é obrigatório!")
      //   .required("Selecionar o município é obrigatório!"),

      // provincia: yup
      //   .string("Selecionar a província é obrigatório!")
      //   .required("Selecionar a província é obrigatório!"),

      // pais: yup
      //   .string("Selecionar o país é obrigatório!")
      //   .required("Selecionar o país é obrigatório!"),

      comissao: yup
        .string("Selecione a comissão!")
        .required("Selecione a comissão!"),

      tipoDeCarga: yup
        .string("Preencher o tipo de carga é obrigatório!")
        .required("Preencher o tipo de carga é obrigatório!"),

      precoTransporte: yup
        .string("Informe o Preço do transporte!")
        .required("Informe o Preço do transporte!"),

      matricula: yup
        .string("Adicione a Matrícula do veículo!")
        .required("Adicione a Matrícula do veículo!"),

      marca: yup
        .string("Informe a marca do veículo!")
        .required("Informe a marca do veículo!"),

      modelo: yup
        .string("Informe o modelo do veículo!")
        .required("Informe o modelo do veículo!"),

      cartaDeConducao: yup
        .string("Preencher o campo de carta de condução do motorista é obrigatório!")
        .required("Preencher o campo de carta de condução do motorista é obrigatório!"),

      bi: yup
        .string("Preencher o campo de BI do motorista é obrigatório!")
        .required("Preencher o campo de BI do motorista é obrigatório!"),

      nomeMotorista: yup
        .string("Informar o nome do motorista é obrigatório!")
        .required("Informar o nome do motorista é obrigatório!"),

      proprietario: yup
        .string("Selecione o proprietário do transporte!")
        .required("Selecione o proprietário do transporte!"),

    });
    try {
      await schema.validate(transporte);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //

  return (
    <>
      <div>
        <form
          enctype="multipart/form-data"
          onSubmit={(e) => saveUpdateTransporte(e)}
        >

          {status.type === "error" ? (
            <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
          ) : (
            ""
          )}

          {/* TRANSPORTE */}
          <div className="row">
            <div className="col-6">
              <div className="input-group">
                <div className="preview">
                  <img id="file-ip-1-preview" />
                </div>
                {/* </div> */}
                <label className="custom-btn" for="file-ip-1">
                  {i18n.t("messages.carregar_foto_do_automovel")}
                </label>
                <input
                  type="file"
                  id="file-ip-1"
                  accept="image/*"
                  name="imgUrl"
                  className="form-control cc-cvc"
                  onChange={(e) => showPreview(e)}
                  hidden
                ></input>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="">
                <h2
                  style={{
                    fontSize: "1.1rem",
                    color: "#000",
                  }}
                >
                  Dados do Motorista
                </h2>
                <br></br>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <select
                  data-placeholder="Selecionar Proprietario..."
                  className="form-control cc-exp"
                  tabindex="1"
                  name="proprietario"
                  value={proprietario}
                  onChange={(e) => onInputChange(e)}
                >
                  <option value="" label="Selecionar Proprietario..."></option>
                  {proprietarios.map((proprietario) => (
                    <option
                      key={proprietario.codigo}
                      value={proprietario.codigo}
                    >
                      {proprietario.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <input
                  name="nomeMotorista"
                  type="text"
                  className="form-control cc-exp"
                  // placeholder={i18n.t("messages.nome")}
                  placeholder="Nome do Motorista"
                  value={nomeMotorista}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="input-group">
                <input
                  name="bi"
                  type="text"
                  className="form-control cc-exp"
                  placeholder={i18n.t("messages.bi")}
                  value={bi}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>

            <div className="col-6">
              <div className="input-group">
                <input
                  name="cartaDeConducao"
                  type="text"
                  className="form-control cc-exp"
                  placeholder={i18n.t("messages.carta_de_conducao")}
                  value={cartaDeConducao}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
          </div>

          <br></br>
          <div className="row">
            <div className="col-12">
              <div className="">
                <h2
                  style={{
                    fontSize: "1.1rem",
                    color: "#000",
                  }}
                >
                  Dados do Automóvel | Entrega
                </h2>
                <br></br>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <input
                  name="modelo"
                  type="text"
                  className="form-control cc-exp"
                  placeholder={i18n.t("messages.modelo")}
                  value={modelo}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="input-group">
                <input
                  name="marca"
                  type="text"
                  className="form-control cc-exp"
                  placeholder={i18n.t("messages.marca")}
                  value={marca}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>

            <div className="col-4">
              <div className="form-group">
                <input
                  type="text"
                  name="matricula"
                  className="form-control cc-exp"
                  placeholder={i18n.t("messages.matricula")}
                  value={matricula}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
          </div>
          {/*  */}

          <br></br>

          <div className="row">
            <div className="col-4">
              <div className="input-group">
                <input
                  name="precoTransporte"
                  type="text"
                  className="form-control cc-exp"
                  placeholder={i18n.t("messages.precoTransporte")}
                  value={precoTransporte}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>

            <div className="col-4">
              <div className="input-group">
                <input
                  name="tipoDeCarga"
                  type="text"
                  className="form-control cc-exp"
                  placeholder={i18n.t("messages.tipo_de_carga")}
                  value={tipoDeCarga}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>

            <div className="col-4">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar comissao..."
                  className="form-control cc-exp"
                  tabindex="1"
                  name="comissao"
                  value={comissao}
                  onChange={(e) => onInputChange(e)}
                >
                  <option value="" label="Selecionar Comissao"></option>
                  {Comissao.map((comissao) => (
                    <option key={comissao.codigo} value={comissao.codigo}>
                      {comissao.quota}%
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/*  */}
          <br></br>
          {/*  */}
          <div className="row">
            <div className="col-4">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar pais..."
                  className="form-control cc-exp"
                  tabindex="1"
                  name="pais"
                  // value={pais}
                  onChange={(e) => getAllProvincia(e)}
                >
                  {/* <option>Selecionar Cooperador</option> */}
                  <option
                    value=""
                    label={i18n.t("messages.selecionar_pais")}
                  ></option>
                  {paises.map((pais) => (
                    <option key={pais.codigo} value={pais.codigo}>
                      {pais.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-4">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar pais..."
                  className="form-control cc-exp"
                  tabindex="1"
                  name="provincia"
                  onChange={(e) => getAllMunicipio(e)}

                // value={provincia}
                >
                  <option
                    value=""
                    label={i18n.t("messages.selecionar_provincia")}
                  ></option>
                  {provincias.map((provincia) => (
                    <option key={provincia.codigo} value={provincia.codigo}>
                      {provincia.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-4">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar municipio..."
                  className="form-control cc-exp"
                  tabindex="1"
                  name="municipio"
                  value={municipio}
                  onChange={(e) => onInputChange(e)}
                >
                  <option
                    value=""
                    label={i18n.t("messages.selecionar_municipio")}
                  ></option>
                  {municipios.map((municipio) => (
                    <option key={municipio.codigo} value={municipio.codigo}>
                      {municipio.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/*  */}

          {/*  */}

          {/*  */}
          <br></br>

          {/*  */}
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              style={{
                backgroundColor: "#b46739",
                color: "#fff",
              }}
            >
              {i18n.t("buttons.cancelar")}
            </button>
            <button
              type="submit"
              className="btn"
              style={{
                backgroundColor: "#9ba239",
                color: "#fff",
              }}
            >
              {i18n.t("buttons.save")}
            </button>
          </div>
          {/*  */}
          {/* FIM COOPERADOR */}
        </form>
      </div>
    </>
  );
};

export default TabTransporte;
