import api from "./api";

export const listar = async () => {
  try {
    return await api.get(`animais`);
  } catch (error) {
    console.log(error);
  }
};
export const listarEspecie = async (params) => {
  try {
    return await api.get(`animais/${params}`);
  } catch (error) {
    console.log(error);
  }
};
