import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo/logo.jpg";
import { AuthContext } from "../../contexts/Auth";
// import { MdEmail, MdLock } from "react-icons/md";

const Login = () => {
  //
  const { authenticated, login } = useContext(AuthContext);
  //
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");

  // Botao de entrar
  const handleSumite = (e) => {
    e.preventDefault();
    console.log("submit", { email, senha });

    login(email, senha); // integracao com o meu contexto / API
  };
  //
  return (
    <div
      className="bg-Login"
      style={{
        backgroundImage: `url'../../assets/img/avatar/GD-1_Prancheta 1.png'`,
      }}
    >
      <div className="sufee-login d-flex align-content-center flex-wrap">
        <div className="container">
          <div className="login-content">
            <div className="login-logo">
              <img className="align-content" src={logo} alt="" width={150} height={150} style={{borderRadius: "50%"}} />
            </div>
            <div className="login-form" style={{ background: "#424616" }}>
              <form onSubmit={handleSumite}>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Senha"
                    value={senha}
                    onChange={(e) => setSenha(e.target.value)}
                  />
                </div>
                <div className="checkbox">
                  {/*  */}
                  {/* <label className="pull-right">
                    <Link
                      href="#"
                      style={{ color: "#9ba236" }}
                      data-toggle="modal"
                      data-target="#largeModal"
                    >
                      Esqueceu a Senha?
                    </Link>
                  </label> */}
                </div>
                <button
                  type="submit"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#9ba236", color: "#fff" }}
                >
                  Acessar
                </button>
                {/* <!--------Inicio Esqueceu a senha------> */}
                <div
                  className="modal fade"
                  id="largeModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="largeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                      <div
                        className="modal-header"
                        style={{ backgroundColor: "#9ba236" }}
                      >
                        <h5 className="modal-title" id="largeModalLabel">
                          {/* {titulo()} */}
                        </h5>
                      </div>

                      <div className="card-body">
                        <form>
                          <div className="mb-3">
                          <input
                                  id=""
                                  name="email"
                                  type="email"
                                  className="form-control cc-exp"
                                  placeholder="Digita o seu email"
                                />
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn"
                              data-dismiss="modal"
                              style={{
                                backgroundColor: "#b46739",
                                color: "#fff",
                              }}
                            >
                              {/* {i18n.t("buttons.cancelar")} */}
                              Sair
                            </button>
                            <button
                              type="submit"
                              className="btn"
                              style={{
                                backgroundColor: "#9ba239",
                                color: "#fff",
                              }}
                            >
                            Enviar
                            </button>
                          </div>
                          {/*  */}
                        </form>
                        {/* FIM ANIMAIS */}
                      </div>
                      {/*  */}
                    </div>
                  </div>
                </div>
                {/* <!--------Fim Cadastrar------> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
