import Menu from "../../components/layout/Menu";
import ChartAnimais from "../../components/chart/chartAnimais";
import GraficoVendas_1 from "../../components/chart/grafico_vendas_1";


const CharVendas = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>  
        < GraficoVendas_1/>
      </div>
      {/*  */}
    </>
  );
};

export default CharVendas;

