import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "left",
    },
    title: {
      display: true,
    },
  },
};

const labels = [
  "HUILA-BOVINO",
  "HUILA-CAPRINO",
  "HUILA-SUINO",
  "CUNENE-BOVINO",
  "CUNENE-CAPRINO",
  "CUNENE-SUINO",
  "NAMIBE-BOVINO",
  "NAMIBE-CAPRINO",
  "NAMIBE-SUINO",
  "CUANDO-CUBANGO-BOVINO",
  "CUANDO-CUBANGO-CAPRINO",
  "CUANDO-CUBANGO-SUINO",
];
const data = {
  labels,
  datasets: [
    {
      label: "",
      backgroundColor: ["#9ba429", "#9ba236c9", "#424616"],
      borderColor: "#424616",
      data: [
        1200000, 900000, 300000, 900000, 900000, 150000, 800000, 700000, 100000,
        50000, 20000, 6000,
      ],
    },
  ],
};
const barHome = () => {
  return (
    <div style={{ width: "800", height: "500" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default barHome;
