import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../../components/layout/Menu";
import {
  createAnimal,
  getAnimais,
  getAnimalCodigo,
  removeAnimal,
  updateAnimal,
} from "../../service/AnimalService";
import { getPaises } from "../../service/PaisService";
import { getEspecies } from "../../service/EspecieService";
import { getRacas, getRacasByEspecie } from "../../service/RacaService";
import { getFazendaCodigo } from "../../service/FazendaService";
import * as messages from "../../components/message/toastr";
import Swal from "sweetalert2";

import { i18n } from "../../Traducao/translate/i18n";

// const I18N_STORAGE_KEY = "i18nextLng";

const CadastrarAnimalModal = (props) => {
  // Buscar por ID
  const { codigo } = useParams();
  const [animais, setAnimais] = useState([]);

  //
  useEffect(() => {
    getAllanimal();
  }, []);

  //LISTAR
  const getAllanimal = () => {
    getAnimais()
      .then((response) => {
        setAnimais(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deleteAnimais = (codigo) => {
    Swal.fire({
      title: "Deseja eliminar?",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeAnimal(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllanimal();
        });
      }
    });
  };
  //

  //SALVAR
  const [animal, setAnimal] = useState({
    imgUrl: "",
    peso: "",
    raca: "",
    pais: "",
    cod: "",
    sexoAnimal: "",
    especie: "",
    data: "",
    descricao: "",
    idade: "",
    fazenda: props.addIdFazenda,
  });

  const [paises, setPaises] = useState([]);
  const [verFazenda, setverFazenda] = useState({});
  const [verEspecie, setverEspecie] = useState([]);
  const [verRaca, setverRaca] = useState([]);
  useEffect(() => {
    getAllPais();
    getAllFazenda();
    getAllEspecie();
  }, []);

  //LISTAR PAIS
  const getAllPais = () => {
    getPaises()
      .then((response) => {
        setPaises(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const getAllFazenda = () => {
    getFazendaCodigo(props.addIdFazenda)
      .then((response) => {
        setverFazenda(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  // Listar Especies
  const getAllEspecie = () => {
    getEspecies()
      .then((response) => {
        setverEspecie(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  // Listar Raca
  const getAllRaca = (codigo) => {
    getRacasByEspecie(codigo)
      .then((response) => {
        setverRaca(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const [imgUrl, setImgUrl] = useState("");

  const {
    peso,
    raca,
    pais,
    sexoAnimal,
    cod,
    especies,
    idade,
    data,
    descricao,
    fazenda,
  } = animal;
  //
  const onInputChange = (e) => {
    setAnimal({ ...animal, [e.target.name]: e.target.value });

    if (e.target.name === "especies") {
      getAllRaca(e.target.value);
    }
  };

  //Botao Salvar
  const saveUpdateAnimal = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("peso", peso);
    formData.append("raca", raca);
    formData.append("pais", pais);
    formData.append("sexoAnimal", sexoAnimal);
    formData.append("especies", especies);
    formData.append("idade", idade);
    formData.append("fazenda", fazenda);
    formData.append("data", data);
    formData.append("cod", cod);
    formData.append("descricao", descricao);

    //
    if (codigo) {
      await updateAnimal(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllanimal();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createAnimal(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllanimal();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadAnimal();
  }, []);
  //
  const loadAnimal = () => {
    if (codigo) {
      getAnimalCodigo(codigo)
        .then((response) => {
          setAnimal(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };
  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setImgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };
  //

  return (
    <>
      <form
        enctype="multipart/form-data"
        onSubmit={(e) => saveUpdateAnimal(e)}
      >
        {/* ANIMAL */}
        <div className="row">
          <div className="col-6">
            <div className="input-group">
              <div className="preview">
                <img id="file-ip-1-preview" />
              </div>
              {/* </div> */}
              <label className="custom-btn" for="file-ip-1">
                {i18n.t("messages.carregar_foto")}
              </label>
              <input
                type="file"
                id="file-ip-1"
                accept="image/*"
                name="imgUrl"
                className="form-control cc-cvc"
                onChange={(e) => showPreview(e)}
                hidden
              ></input>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <input
                id=""
                name="cod"
                type="text"
                className="form-control cc-exp"
                value={cod}
                placeholder={i18n.t("messages.codigo")}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
          {/*  */}
          <div className="col-4">
            <div className="input-group">
              <input
                type="text"
                className="form-control cc-exp"
                value={verFazenda.nome}
                disabled
              />
            </div>
          </div>
          {/*  */}
          <div className="col-4">
            <div className="input-group">
              <select
                data-placeholder="Selecionar..."
                className="form-control cc-exp"
                tabindex="1"
                name="especies"
                value={especies}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.especie")}
              >
                <option>Selecionar Especie</option>
                {verEspecie.map((especie) => (
                  <option value={especie.codigo}>
                    {especie.especieAnimal}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/*  */}
        </div>
        {/*  */}
        <div className="row">
          <div className="col-4">
            <div className="input-group">
              <select
                data-placeholder="Selecionar..."
                className="form-control cc-exp"
                tabindex="1"
                name="raca"
                value={raca}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.raca")}
              >
                <option>Selecionar a Raça</option>
                {verRaca.map((raca) => (
                  <option value={raca.codigo}>{raca.racaAnimal}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <input
                id=""
                name="peso"
                type="number"
                className="form-control cc-exp"
                value={peso}
                // placeholder={i18n.t("messages.peso")}
                placeholder="Peso Kg"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <input
                id=""
                name="idade"
                type="number"
                className="form-control cc-exp"
                value={idade}
                // placeholder={i18n.t("messages.idade")}
                placeholder="Idade Meses"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-4">
            <div className="input-group">
              <select
                id=""
                name="sexoAnimal"
                className="form-control cc-exp"
                value={sexoAnimal}
                onChange={(e) => onInputChange(e)}
              >
                <option>Selecionar Sexo</option>
                <option value="MACHO">Macho</option>
                <option value="FEMEA">Fémea</option>
              </select>
            </div>
          </div>
          <div className="col-4">
            <div className="input-group">
              <select
                data-placeholder="Selecionar Proveniência..."
                className="form-control cc-exp"
                tabindex="1"
                name="pais"
                value={pais}
                onChange={(e) => onInputChange(e)}
              >
                {/* <option>Selecionar Cooperador</option> */}
                <option
                  value=""
                  label={i18n.t("messages.selecionar_pais")}
                ></option>
                {paises.map((pais) => (
                  <option key={pais.codigo} value={pais.codigo}>
                    {pais.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/*  */}
        <br />
        {/*  */}

        <div className="row">
          <div className="col-12">
            <div className="row form-group">
              <div className="col-12 col-md-12">
                <textarea
                  name="descricao"
                  id="textarea-input"
                  rows="6"
                  value={descricao}
                  onChange={(e) => onInputChange(e)}
                  placeholder={i18n.t("messages.descricao")}
                  className="form-control"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        {/*  */}

        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span className="lar la-save">
              {i18n.t("buttons.save")}
            </span>
          </button>
        </div>
        {/*  */}
      </form>
    </>
  );
};

export default CadastrarAnimalModal;
