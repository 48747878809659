import Menu from "../../components/layout/Menu";
import ListCarrinhoProduto from "../../components/listTable/ListCarrinhoProduto";

const CarrinhoProduto = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListCarrinhoProduto />
      </div>
      {/*  */}
    </>
  );
};

export default CarrinhoProduto;

