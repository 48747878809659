import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { createProduto, getProdutoCodigo, getProdutos, removeProduto, updateProduto } from "../../service/ProdutoService";
import { getCategorias } from "../../service/CategoriaService";
import * as messages from "../message/toastr";
import Swal from "sweetalert2";
import * as yup from "yup";

import { i18n } from "../../Traducao/translate/i18n";
import { getFazendas } from "../../service/FazendaService";
const I18N_STORAGE_KEY = "i18nextLng";

const ListProduto = () => {
  // Buscar por ID
  const { codigo } = useParams();
  const [produtos, setProdutos] = useState([]);

  //
  useEffect(() => {
    getAllproduto();
  }, []);

  //LISTAR
  const getAllproduto = () => {
    getProdutos()
      .then((response) => {
        setProdutos(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deleteProduto = (codigo) => {
    Swal.fire({
      title: "Deseja eliminar?",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeProduto(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllproduto();
        });
      }
    });
  };

  //SALVAR
  const [produto, setProduto] = useState({
    imgUrl: "",
    nome: "",
    cod: "",
    categoria: "",
    descricao: "",
    fazenda: "",
  });
  //Limpar
  const handleClear = () => {
    setProduto({
      imgUrl: "",
      nome: "",
      cod: "",
      categoria: "",
      descricao: "",
    })
  }
  const [verCategoria, setverCartegoria] = useState([]);
  useEffect(() => {
    getAllCategoria();
  }, []);

  const getAllCategoria = () => {
    getCategorias()
      .then((response) => {
        setverCartegoria(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const [imgUrl, setImgUrl] = useState("");

  const { nome, cod, descricao, categoria, fazenda } = produto;
  //

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //

  const onInputChange = (e) => {
    setProduto({ ...produto, [e.target.name]: e.target.value });
  };

  //Botao Salvar
  const saveUpdateProduto = async (e) => {
    e.preventDefault();


    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("nome", nome);
    formData.append("cod", cod);
    formData.append("categoria", categoria);
    formData.append("descricao", descricao);
    formData.append("fazenda", fazenda);
    

    //
    if (codigo) {
      await updateProduto(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllproduto();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createProduto(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllproduto();
          handleClear();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadProduto();
    getAllFazenda();
  }, []);
  //
  const loadProduto = () => {
    if (codigo) {
      getProdutoCodigo(codigo)
        .then((response) => {
          setProduto(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };

  // 
  const [verFazenda, setverFazenda] = useState([]);
  const getAllFazenda = () => {
    getFazendas()
      .then((response) => {
        setverFazenda(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };


  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setImgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };
  //
  //
  async function validate() {
    let schema = yup.object().shape({

      descricao: yup
        .string("Por favor, dé uma descrição ao Produto!")
        .required("Por favor, dé uma descrição ao Produto!"),

      categoria: yup
        .string("Por favor, informe a categoria do Produto!")
        .required("Por favor, informe a categoria do Produto!"),

      nome: yup
        .string("Por favor, dé um nome ao Produto!")
        .required("Por favor, dé um nome ao Produto!"),

      cod: yup
        .string("Informar o código do Produto é obrigatório!")
        .required("Informar o código do Produto é obrigatório!"),

    });
    try {
      await schema.validate(produto);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/addprodutos" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus">

                    </span>
                  </button>
                </Link>
                <Link to="/relatorioprodutos" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="fa fa-print">Relatórios</span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button>
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.produto")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.codigo")} </th>
                          <th scope="col">{i18n.t("messages.produto")} </th>
                          <th scope="col">{i18n.t("messages.categoria")} </th>
                          <th scope="col">{i18n.t("messages.opcao")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        {produtos.map((produto) => (
                          <tr key={produto.codigo}>
                            <td data-label="Cod:">{produto.cod}</td>
                            <td data-label="Produto:">{produto.nome}</td>
                            {/* <td>{produto.categoria.nome}</td> */}
                            <td data-label="Adicionais:">
                              <Link
                                to={`/viewproduto/${produto.codigo}`}
                                className="btn btn-primary"
                              >
                                <span className="las la-eye link-add"></span>
                              </Link>

                              <Link
                                to={`/editproduto/${produto.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>
                              <Link
                                to={`/relatorioIdPro/${produto.codigo}`}
                                className="btn btn-primary"
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="fa fa-print link-add-print"></span>
                              </Link>

                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deleteProduto(produto.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
              {/*   */}

              {/* <!-- .content --> */}

              <form
                enctype="multipart/form-data"
                onSubmit={(e) => saveUpdateProduto(e)}
              >

                {status.type === "error" ? (
                  <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
                ) : (
                  ""
                )}

                {/*  */}
                <div className="row">
                  <div className="col-6">
                    <div className="input-group">
                      <div className="preview">
                        <img id="file-ip-1-preview" />
                      </div>
                      {/* </div> */}
                      <label className="custom-btn" for="file-ip-1">
                        {i18n.t("messages.carregar_foto")}
                      </label>
                      <input
                        type="file"
                        id="file-ip-1"
                        accept="image/*"
                        name="imgUrl"
                        className="form-control cc-cvc"
                        onChange={(e) => showPreview(e)}
                        hidden
                      ></input>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-4">
                    <div className="form-group">
                      <input
                        id=""
                        name="cod"
                        type="text"
                        className="form-control cc-exp"
                        value={cod}
                        placeholder={i18n.t("messages.codigo")}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <input
                        id=""
                        name="nome"
                        type="text"
                        className="form-control cc-exp"
                        value={nome}
                        placeholder="Descrição"
                        // placeholder={i18n.t('messages.produto')}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="input-group">
                      <select
                        data-placeholder="Selecionar..."
                        className="form-control cc-exp"
                        tabindex="1"
                        name="categoria"
                        value={categoria}
                        onChange={(e) => onInputChange(e)}
                        placeholder={i18n.t("messages.categoria")}
                      >
                        <option>Selecionar Categoria</option>
                        {verCategoria.map((categoria) => (
                          <option value={categoria.codigo}>
                            {categoria.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-12">
                    <div className="row form-group">
                      <div className="col-12 col-md-12">
                        <textarea
                          name="descricao"
                          id="textarea-input"
                          rows="4"
                          value={descricao}
                          onChange={(e) => onInputChange(e)}
                          // placeholder={i18n.t("messages.descricao")}
                          placeholder="Descrição do produto"
                          className="form-control"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                       {/*  */}
                       <div className="col-4">
                    <div className="input-group">
                      <select
                        data-placeholder="Selecionar..."
                        className="form-control cc-exp"
                        tabindex="1"
                        name="fazenda"
                        value={fazenda}
                        onChange={(e) => onInputChange(e)}
                        placeholder={i18n.t("messages.fazenda")}
                      >
                        <option>Selecionar Fazenda</option>
                        {verFazenda.map((fazenda) => (
                          <option value={fazenda.codigo}>{fazenda.nome}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/*  */}

                {/*  */}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "#b46739",
                      color: "#fff",
                    }}
                  >
                    {i18n.t("buttons.cancelar")}
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      backgroundColor: "#9ba239",
                      color: "#fff",
                    }}
                  >

                    {i18n.t("buttons.save")}
                  </button>
                </div>
                {/*  */}
              </form>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListProduto;
