import Menu from "../../components/layout/Menu";
import List_Leilao from "../../components/listTable/List_Leilao";

const Leilao = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <List_Leilao />
      </div>
      {/*  */}
    </>
  );
};

export default Leilao;