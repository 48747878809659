import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { getLoteCodigo } from "../../service/LoteService";
import { getLoteAnimalRapido } from "../../service/RapidoService";
import { getLoteAnimalExistente } from "../../service/ExistenteService";
import { useParams } from "react-router-dom";
import logo from "../../assets/img/logo/logo1.jpg";
import redirects from "../../components/redirect";

function App() {
  const componentRef = useRef();
  const { codigo } = useParams();
  const { lotes } = redirects();

  const [dados, setDados] = useState({
    imgUrl: "",
    nome: "",
    cod: "",
    fazenda: "",
    loteVendaLeilao: "",
    especie: "",
  });
  const [rapido, setRapido] = useState([]);
  const [existente, setExistente] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Relatorios",
  });

  useEffect(() => {
    busca();
  }, []);

  const busca = async () => {
    try {
      if (codigo) {
        const { data } = await getLoteCodigo(codigo);
        setDados(data);
      }
      const { data } = await getLoteAnimalRapido(codigo);
      setRapido(data);

      const existente = await getLoteAnimalExistente(codigo);
      setExistente(existente.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [data, setData] = useState("");
  const dataTime = () => {
    setData(new Date().toLocaleString());
  };
  const [totalTime, setTotalTime] = useState([60]);
  useEffect(() => {
    setTimeout(() => {
      setTotalTime(totalTime - 1);
    }, 1000);
    dataTime();
  }, [totalTime]);

  return (
    <>
      <div className="Formatacao-Relatorio" ref={componentRef}>
        <div className="App_header">
          <div className="data_esq">
            <img src={logo} />
            <h6>Vagne Zau Lelo</h6>
            <small>{data}</small>
          </div>
          <div className="data_dir">
            <h6>Cooperativa Wapoma Agro-pecuária e Mineira</h6>
            <small>Huíla Lubango</small>
            <small>cooperativacwamahuila@gmail.com</small>
            <small>www.cwam.com</small>
          </div>
        </div>
        <header className="App_h1">
          <h3>Relatório do Lote</h3>
        </header>
        <div className="data_header">
          <div className="data_cab">
            <div className="data_esq">
              <p>Dados do Lote:</p>
              <p>Lote: {dados.nome}</p>
              <p>Quantidade: {dados.quantidade}</p>
            </div>
            <div className="data_esq">
              <p>Estado: {dados.loteVendaLeilao}</p>
              <p>Especie: {dados.especie?.especieAnimal}</p>
              <p>Data: {new Date(dados.timeStamp).toLocaleDateString()}</p>
            </div>
          </div>
          <div className="App_body">
            {dados.geral ? (
              <div className="table_data">
                <table className="table">
                  <thead>
                    <th className="th">Codigo</th>
                    <th className="th">Quantidade</th>
                    <th className="th">Machos</th>
                    <th className="th">Fêmeas</th>
                    <th className="th">Peso Mínimo</th>
                    <th className="th">Peso Máximo</th>
                    <th className="th">Idade Mínimo</th>
                    <th className="th">Idade Máxima</th>
                    <th className="th">Apto para</th>
                  </thead>
                  {rapido.map((row) => (
                    <tbody>
                      <tr className="tr" key={row.codigo}>
                        <td className="td">{row.codigo}</td>
                        <td className="td">{row.qtd}</td>
                        <td className="td">{row.numerosexoM}</td>
                        <td className="td">{row.numerosexoF}</td>
                        <td className="td">{row.pesomin} Kg</td>
                        <td className="td">{row.pesomaxi} Kg</td>
                        <td className="td">{row.idademin} Meses</td>
                        <td className="td">{row.idademax} Meses</td>
                        <td className="td">{row.apto}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            ) : (
              ""
            )}
            {dados.existente ? (
              <div className="table_data">
                <table className="table">
                  <thead>
                    <th className="th">Animal</th>
                    <th className="th">Quantidade</th>
                    <th className="th">Raça</th>
                    <th className="th">Peso</th>
                    <th className="th">Proveniencia</th>
                    <th className="th">Sexo</th>
                    <th className="th">Idade</th>
                  </thead>
                  {existente.map((row) => (
                    <tbody>
                      <tr className="tr" key={row.codigo}>
                        <td className="td">{row.animal?.animalId}</td>
                        <td className="td">{row.qtd}</td>
                        <td className="td">{row.animal?.raca?.racaAnimal}</td>
                        <td className="td">{row.mediaPeso}</td>
                        <td className="td">{row.animal?.pais?.nome}</td>
                        <td className="td">{row.animal?.sexoAnimal}</td>
                        <td className="td">{row.animal?.idade}</td>
                      </tr>
                    </tbody>
                  ))}
                </table>
                <div className="data_total">
                  <small>Total: {existente.length} Itens </small>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="data_funny">
        <button onClick={lotes} className="btn botao_return">
          <span className="fa fa-reply"></span>
        </button>
        <button onClick={handlePrint} className="btn botao_print">
          <span className="fa fa-print"></span>
        </button>
      </div>
    </>
  );
}

export default App;
