import Menu from "../../components/layout/Menu";
import ListServicos from "../../components/listTable/ListServicos";

const Servicos = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListServicos />
      </div>
      {/*  */}
    </>
  );
};

export default Servicos;
