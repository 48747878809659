import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getPerfilFuncionario,
  encontrarFuncionario,
} from "../../service/LoginService";
import Menu from "../layout/Menu";
import { AuthContext } from "../../contexts/Auth";
import logo7 from "../../assets/img/avatar/7.png";

const I18N_STORAGE_KEY = "i18nextLng";

const FormPerfil = () => {
  //
  //~
  const { user } = useContext(AuthContext);
  const { email } = useParams();

  const logado = user?.codigo;

  const [VerFuncionario, setVerFuncionario] = useState([]);

  //
  useEffect(() => {
    // PerfilVisitante();
    dadosFuncionario();
  });

  const PerfilFuncionario = async () => {
    await getPerfilFuncionario(logado).then((response) => {
      setVerFuncionario(response.data);
    });
  };

  const dadosFuncionario = () => {
    if (email) {
      encontrarFuncionario(email).then((response) => {
        setVerFuncionario(response.data);
        console.log(response.data);
      });
    }
  };
  //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */} 
        <section>
          {/* <div className="container py-5"> */}
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body text-center">
                    <img
                      // src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirImagemFuncionario/${VerFuncionario.imgUrl}`}
                      src={logo7}
                      alt="avatar"
                      className="rounded-circle img-fluid"
                      style={{ width: "150px" }}
                    />

                    <h5 className="my-3">{VerFuncionario.nome}</h5>
                    <p className="text-muted mb-1">{VerFuncionario.email}</p>
                    <p className="text-muted mb-4">{VerFuncionario.endereco}</p>
                    <div className="d-flex justify-content-center mb-2">
 
                      <button
                        type="button"
                        className="btn btn-outline-primary ms-1"
                      >
                        Mensagem
                      </button>
                    </div>
                  </div>
                </div>
                {/*     */}
              </div>
              <div className="col-lg-8">
                <div className="card mb-4">
                  <div className="card-body">
                    {/*  */}
                    <form>
                      <div className="form-outline">
                        <input type="text" id="formControlLg" className="form-control form-control" placeholder="Nome" />
                      </div>
                      <br />
                      <div className="form-outline">
                        <input type="text" id="formControlDefault" className="form-control" placeholder="Email" />
                      </div>
                      <br />
                      <div className="form-outline">
                        <input type="text" id="formControlSm" className="form-control form-control" placeholder="Telefone" />
                      </div>
                      <br />
                      <div className="form-outline">
                        <input type="text" id="formControlSm" className="form-control form-control" placeholder="Telefone" />
                      </div>
                      <br />
                      {/*  */}
                      <div className="justify-content-center mb-2">
                        <button type="button" className="btn btn-primary">
                          Salvar
                        </button>
                      </div>
                    </form>
                    {/*  */}


                    {/* <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Nome</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {VerFuncionario.nome} {VerFuncionario.sobrenome}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Email</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {VerFuncionario.email}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Telefone</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {VerFuncionario.telefone}
                        </p>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-sm-3">
                        <p className="mb-0">Endereço</p>
                      </div>
                      <div className="col-sm-9">
                        <p className="text-muted mb-0">
                          {VerFuncionario.endereco}
                        </p>
                      </div>
                    </div> */}

                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-6">
                    <div className="card mb-4 mb-md-0">
                      <div className="card-body">
                        <p className="mb-4">
                          <span className="text-primary font-italic me-1">
                            assigment
                          </span>{" "}
                          Project Status
                        </p>
                        <p className="mb-1" style={{ fontSize: ".77rem" }}>
                          Web Design
                        </p>
                        <div
                          className="progress rounded"
                          style={{ height: "5px;" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "80%" }}
                            aria-valuenow="80"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <p className="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                          Website Markup
                        </p>
                        <div
                          className="progress rounded"
                          style={{ height: "5px" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "72%" }}
                            aria-valuenow="72"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <p className="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                          One Page
                        </p>
                        <div
                          className="progress rounded"
                          style={{ height: "5px" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "89%" }}
                            aria-valuenow="89"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <p className="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                          Mobile Template
                        </p>
                        <div
                          className="progress rounded"
                          style={{ height: "5px" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "55%" }}
                            aria-valuenow="55"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <p className="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                          Backend API
                        </p>
                        <div
                          className="progress rounded mb-2"
                          style={{ height: "5px;" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "66%" }}
                            aria-valuenow="66"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card mb-4 mb-md-0">
                      <div className="card-body">
                        <p className="mb-4">
                          <span className="text-primary font-italic me-1">
                            assigment
                          </span>{" "}
                          Project Status
                        </p>
                        <p className="mb-1" style={{ fontSize: ".77rem" }}>
                          Web Design
                        </p>
                        <div
                          className="progress rounded"
                          style={{ height: "5px;" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "80%" }}
                            aria-valuenow="80"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <p className="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                          Website Markup
                        </p>
                        <div
                          className="progress rounded"
                          style={{ height: "5px;" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "72%" }}
                            aria-valuenow="72"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <p className="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                          One Page
                        </p>
                        <div
                          className="progress rounded"
                          style={{ height: "5px;" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "89%" }}
                            aria-valuenow="89"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <p className="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                          Mobile Template
                        </p>
                        <div
                          className="progress rounded"
                          style={{ height: "5px;" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "55%" }}
                            aria-valuenow="55"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <p className="mt-4 mb-1" style={{ fontSize: ".77rem" }}>
                          Backend API
                        </p>
                        <div
                          className="progress rounded mb-2"
                          style={{ height: "5px" }}
                        >
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: "66%" }}
                            aria-valuenow="66"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              */}
              </div>
            </div>
          </div>
        </section>
        {/*  */}
      </div>
    </>
  );
};

export default FormPerfil;
