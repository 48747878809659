import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import {
  createProprietario,
  getProprietarioCodigo,
  getProprietarios,
  removeProprietario,
  updateProprietario,
} from "../../service/ProprietarioService";
import { getPaises } from "../../service/PaisService";

import * as messages from "../message/toastr";

import * as yup from "yup";

import Swal from "sweetalert2";

import { i18n } from "../../Traducao/translate/i18n";

const ListProprietario = () => {
  // Buscar por ID
  const { codigo } = useParams();
  const [proprietarios, setProprietarios] = useState([]);

  //
  useEffect(() => {
    getAllproprietarios();
  }, []);

  //LISTAR
  const getAllproprietarios = () => {
    getProprietarios()
      .then((response) => {
        setProprietarios(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deleteProprietario = (codigo) => {
    Swal.fire({
      title: "Deseja eliminar?",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeProprietario(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllproprietarios();
        });
      }
    });
  };
  //

  //SALVAR
  const [proprietario, setProprietario] = useState({
    nome: "",
    email: "",
    empresa: "",
    pais: "",
  });



  //Limpar
  const handleClear = () => {
    setProprietario({
      nome: "",
      email: "",
      empresa: "",
      pais: "",
    });
  };
  const [paises, setPaises] = useState([]);
  useEffect(() => {
    getAllPais();
  }, []);

  //LISTAR PAIS
  const getAllPais = () => {
    getPaises()
      .then((response) => {
        setPaises(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const { nome, email, empresa, pais } = proprietario;
  //
  const [nomeP, setnomeP] = useState("");
  const [emailP, setemailP] = useState("");
  const [empresaP, setempresaP] = useState("");
  const [paisP, setpaisP] = useState("");

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //

  const onInputChange = (e) => {
    setProprietario({ ...proprietario, [e.target.name]: e.target.value });
    if (e.target.name === "nome") {
      setnomeP(e.target.value);
    }

    if (e.target.name === "email") {
      setemailP(e.target.value);
    }

    if (e.target.name === "empresa") {
      setempresaP(e.target.value);
    }

    if (e.target.name === "pais") {
      setpaisP(e.target.value);
    }
  };

  //Botao Salvar
  const saveUpdateProprietario = async (e) => {
    e.preventDefault();

    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("nome", nome);
    formData.append("email", email);
    formData.append("empresa", empresa);
    formData.append("pais", pais);

    //
    if (codigo) {
      await updateProprietario(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllproprietarios();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      const dataP = {
        nome: nomeP,
        email: emailP,
        empresa: empresaP,
        pais: {
          codigo: paisP,
        },
      };
      //
      await createProprietario(dataP)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllproprietarios();
          handleClear();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadProprietario();
  }, []);
  //
  const loadProprietario = () => {
    if (codigo) {
      getProprietarioCodigo(codigo)
        .then((response) => {
          setProprietario(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };

  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };
  //

  //
  async function validate() {
    let schema = yup.object().shape({

      empresa: yup
        .string("Por favor, informe o nome da empresa do Proprietário!")
        .required("Por favor, informe o nome da empresa do Proprietário!"),

      email: yup
        .string("Preencha o campo de Email!")
        .required("Preencha o campo de Email!"),

      pais: yup
        .string("Por favor, selecione o país!")
        .required("Por favor, selecione o país!"),

      nome: yup
        .string("Por favor, Preencer o nome é obrigatório!")
        .required("Por favor, Preencer o nome é obrigatório!"),

    });
    try {
      await schema.validate(proprietario);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/addproprietarios" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus">
                      {i18n.t("messages.novo")}
                    </span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button>
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.proprietario")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.nome")} </th>
                          <th scope="col">{i18n.t("messages.email")} </th>
                          <th scope="col">{i18n.t("messages.empresa")} </th>
                          <th scope="col">{i18n.t("messages.pais")} </th>
                          <th scope="col">{i18n.t("messages.opcao")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        {proprietarios.map((proprietario) => (
                          <tr key={proprietario.codigo}>
                            <td data-label="Nome:">{proprietario.nome}</td>
                            <td data-label="Email:">{proprietario.email}</td>
                            <td data-label="Empresa:">{proprietario.empresa}</td>
                            <td data-label="País:">{proprietario.pais.nome}</td>
                            <td data-label="Adicionais:">
                              {/* <Link
                                to={`/proprietario/ViewProprietario/${proprietario.codigo}`}
                                className="btn btn-primary"
                              >
                                <span className="las la-eye"></span>
                              </Link> */}
                              {/*  */}
                              <Link
                                to={`/proprietario/editProprietario/:codigo/${proprietario.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>
                              {/*  */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() =>
                                  deleteProprietario(proprietario.codigo)
                                }
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            <div className="card-body">
              <form onSubmit={(e) => saveUpdateProprietario(e)}>

                {status.type === "error" ? (
                  <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
                ) : (
                  ""
                )}

                {/* <div className="row">
                  <div className="col-6">
                    <div className="input-group">
                      <div className="preview">
                        <img id="file-ip-1-preview" />
                      </div>

                      <label className="custom-btn" for="file-ip-1">

                        {i18n.t('messages.carregar_foto')}

                      </label>
                      <input
                        type="file"
                        id="file-ip-1"
                        accept="image/*"
                        name="imgUrl"
                        className="form-control cc-cvc"
                        onChange={(e) => showPreview(e)}
                        hidden
                      ></input>
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <input
                        id=""
                        name="nome"
                        type="text"
                        className="form-control cc-exp"
                        value={nome}
                        placeholder={i18n.t("messages.proprietario")}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="input-group">
                      <select
                        data-placeholder="Selecionar Pais..."
                        className="form-control cc-exp"
                        tabindex="1"
                        name="pais"
                        value={pais}
                        onChange={(e) => onInputChange(e)}
                      >
                        <option
                          value=""
                          label={i18n.t("messages.selecionar_pais")}
                        ></option>
                        {paises.map((pais) => (
                          <option key={pais.codigo} value={pais.codigo}>
                            {pais.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <input
                        id=""
                        name="email"
                        type="email"
                        className="form-control cc-exp"
                        value={email}
                        placeholder={i18n.t("messages.email")}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <input
                        id=""
                        name="empresa"
                        type="text"
                        className="form-control cc-exp"
                        value={empresa}
                        placeholder={i18n.t("messages.empresa")}
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "#b46739",
                      color: "#fff",
                    }}
                  >
                    {i18n.t("buttons.cancelar")}
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      backgroundColor: "#9ba239",
                      color: "#fff",
                    }}
                  >
                    <span className="lar la-save">
                      {i18n.t("buttons.save")}
                    </span>
                  </button>
                </div>
                {/*  */}
              </form>
              {/* FIM ANIMAIS */}
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListProprietario;
