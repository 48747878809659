import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { getLoteFazenda, getLoteRapido } from "../../service/LoteService";
import { getFazendaLeilao } from "../../service/LeilaoService";
import { getExclusivoCodigo } from "../../service/LeilaoExclusivoService";
import { createExclusivoItem, getExclusivoItemCodigo } from "../../service/LeilaoItemExclusivoService";
import { getFazendas } from "../../service/FazendaService";
import * as messages from "../message/toastr";
import Swal from "sweetalert2";

import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const ListLeilaoItemExclusivoCod = () => {
  const { codigo } = useParams();
  const [animais, setAnimais] = useState([]);
  const [verLeilao, setVerLeilao] = useState([]);
  const [leilaoCod, setLeilaoCod] = useState([]);
  const [FazendaCod, setFazendaCod] = useState([]);
  const [InfoLeilao, setInfoLeilao] = useState({});

  const leiOr = async () => {
    try {
      const { data } = await getExclusivoCodigo(codigo);
      setLeilaoCod(data);
      setInfoLeilao(data);
      setFazendaCod(data.fazenda.codigo);
      getLoteDaFazenda(data.fazenda.codigo);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllItemLeilao();
    getAllFazenda();
    leiOr();
  }, []);

  const [verFazenda, setverFazenda] = useState([]);
  const getAllFazenda = () => {
    getFazendas()
      .then((response) => {
        setverFazenda(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const [VerLeilaoFazenda, setVerLeilaoFazenda] = useState([]);
  const verOLeilo = (e) => {
    getFazendaLeilao(e.target.value)
      .then((response) => {
        setVerLeilaoFazenda(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const [VerOsLotesDestaFazenda, setVerOsLotesDestaFazenda] = useState([]);
  const getLoteDaFazenda = (codigo) => {
    getLoteFazenda(codigo)
      .then((response) => {
        setVerOsLotesDestaFazenda(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const [VerLoteAnimal, setVerLoteAnimal] = useState({});
  const VerAnimaisDoLote = async (codigo) => {
    getLoteRapido(codigo)
      .then((response) => {
        setVerLoteAnimal(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const getAllItemLeilao = () => {
    getExclusivoItemCodigo(codigo)
      .then((response) => {
        setVerLeilao(response.data);
        console.log(response.data)
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //SALVAR
  const [itemLeilao, setItemLeilao] = useState({
    lote: "",
    preco: "",
    hora: "",
    leilao: "",
  });

  const handClear = () => {
    setItemLeilao({
      preco: "",
      hora: "",
    });
  };
  const { lote, preco, hora, leilao } = itemLeilao;
  //
  const onInputChange = (e) => {
    setItemLeilao({ ...itemLeilao, [e.target.name]: e.target.value });

    if (e.target.name === "lote") {
      VerAnimaisDoLote(e.target.value);
    }
  };

  //Botao Salvar
  const saveUpdateLeilaoItem = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("lote", lote);
    formData.append("preco", preco);
    formData.append("hora", hora);
    formData.append("leilao", leilao);

    const dados = {
      lote: {
        codigo: itemLeilao.lote,
      },
      preco: itemLeilao.preco,
      hora: itemLeilao.hora,
      leilao: {
        codigo: codigo,
      },
    };

    await createExclusivoItem(dados)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        handClear();
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
      });
  };

  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}>
          {" "}
          {/*i18n.t("messages.atualizar")*/}Itens{" "}
        </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-6 col-sm-6">
                <Link to="/exclusivos" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginRight: "10px",
                    }}
                  >
                    <span className="fa fa-reply"></span>
                  </button>
                </Link>

                <Link id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus"></span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.itens_leilao")} {leilaoCod.codLeilao} - Cooperativa
                    </strong>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {verLeilao.map((leilao) => (
                        <div
                          className="card"
                          style={{ width: "18rem", margin: "10px" }}
                          key={leilao.codigo}
                        >
                          <div
                            className="card-header alt"
                            style={{ background: "#9ba236" }}
                          >
                            Fazenda {leilao.lote?.fazenda?.nome}
                          </div>
                          <div className="card-body">
                            <li className="list-group-item">
                              {" "}
                              <strong style={{ marginLeft: "50px" }}>
                                Especie:
                              </strong>
                              {leilao.lote.especie.especieAnimal}
                            </li>

                            <li className="list-group-item">
                              {" "}
                              <strong style={{ marginLeft: "50px" }}>
                                Preço:
                              </strong>
                              {leilao.preco}
                            </li>

                            <li className="list-group-item">
                              <strong style={{ marginLeft: "50px" }}>
                                Data:
                              </strong>
                              {leilao.data}
                            </li>

                            <li className="list-group-item">
                              <strong style={{ marginLeft: "50px" }}>
                                Hora:
                              </strong>

                              {leilao.hora}
                            </li>
                            <li className="list-group-item">
                              <strong style={{ marginLeft: "50px" }}></strong>

                              <a href="#" style={{ marginLeft: "10px" }}>
                                <Link
                                  to={`/viewcarrinho/${leilao.codigo}`}
                                  className="btn btn-primary"
                                >
                                  <span className="">Mais detalhes</span>
                                </Link>
                              </a>
                            </li>
                          </div>
                        </div>
                      ))}
                    </div>

                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            // tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

     
      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        // tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            <div className="card-body">
              <form onSubmit={(e) => saveUpdateLeilaoItem(e)}>
                <div className="row">
                  {/* <div className="col-4">
                    <div className="input-group">
                      <select
                        data-placeholder="Selecionar..."
                        className="form-control cc-exp"
                        tabindex="1"
                        name="fazenda"
                        onChange={(e) => verOLeilo(e)}
                        placeholder={i18n.t("messages.fazenda")}
                      >
                        <option>Selecionar Fazenda</option>
                        {verFazenda.map((fazenda) => (
                          <option value={fazenda.codigo}>{fazenda.nome}</option>
                        ))}
                      </select>
                    </div>
                  </div> */}
                  
                  <div class="col-6">
                    <div class="input-group">
                      <select
                        data-placeholder="Selecionar..."
                        class="form-control cc-exp"
                        tabindex="1"
                        name="lote"
                        value={lote}
                        onChange={(e) => onInputChange(e)}
                        placeholder={i18n.t("messages.leilao")}
                      >
                        <option>Selecionar Lote</option>
                        {VerOsLotesDestaFazenda.map((leilao) => (
                          <option value={leilao.codigo}>{leilao.nome}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {InfoLeilao === "" ? (
                    ""
                  ) : (
                    <div>
                      <h5 style={{ margin: "15px 0", textAlign: "center" }}>
                        Informações do Leilão
                      </h5>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-5">
                            <h6
                              style={{
                                textAlign: "center",
                                marginBottom: "7px",
                              }}
                            >
                              Fazenda
                            </h6>
                            <div className="form-group">
                              <input
                                id=""
                                type="text"
                                className="form-control cc-exp"
                                value={InfoLeilao?.fazenda?.nome}
                                onChange={(e) => onInputChange(e)}
                                disabled
                              />
                            </div>
                          </div>

                          <div className="col-4">
                            <h6
                              style={{
                                textAlign: "center",
                                marginBottom: "7px",
                              }}
                            >
                              Data de Inicio
                            </h6>
                            <div className="form-group">
                              <input
                                id=""
                                type="text"
                                className="form-control cc-exp"
                                value={InfoLeilao?.dataLeilao}
                                onChange={(e) => onInputChange(e)}
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <h5 style={{ margin: "15px 0", textAlign: "center" }}>
                          Animais Deste Lote
                        </h5>
                        <div className="row">
                          {/* <table className="table">
                            <tr>
                              <tr>
                                <th>Descrição</th>
                                <th>Raça</th>
                                <th>Peso</th>
                                <th>Proveniencia</th>
                                <th>Idade</th>
                              </tr>
                              {VerLoteAnimal.map((loteItem) => (
                                <tr>
                                  <td>{loteItem.animal.nome}</td>
                                  <td>{loteItem.animal.raca}</td>
                                  <td>{loteItem.animal.peso}</td>
                                  <td>{loteItem.animal.pais.nome}</td>
                                  <td>{loteItem.animal.idade}</td>
                                </tr>
                              ))}
                            </tr>
                          </table> */}
                        </div>

                        <div className="row">
                          <table className="table">
                            <tr>
                              <tr>
                                <th>Peso Minimo</th>
                                <th>Peso Maximo</th>
                                <th>Idade Minima</th>
                                <th>Idade Maxima</th>
                                <th>N de Machos</th>
                                <th>N de Femeas</th>
                              </tr>
                                <tr>
                                  <td>{VerLoteAnimal.pesomin}Kg</td>
                                  <td>{VerLoteAnimal.pesomaxi}Kg</td>
                                  <td>{VerLoteAnimal.idademin} meses</td>
                                  <td>{VerLoteAnimal.idademax} meses</td>
                                  <td>{VerLoteAnimal.numerosexoM}</td>
                                  <td>{VerLoteAnimal.numerosexoF}</td>
                                </tr>
                            </tr>
                          </table>
                        </div>

                        <div class="row">
                          <div className="col-5">
                            <div className="form-group">
                              <input
                                id=""
                                name="preco"
                                type="number"
                                className="form-control cc-exp"
                                value={preco}
                                placeholder={i18n.t("messages.preco")}
                                onChange={(e) => onInputChange(e)}
                              />
                            </div>
                          </div>

                          <div className="col-7">
                            <div className="input-group">
                              <select
                                id=""
                                name="hora"
                                className="form-control cc-exp"
                                value={hora}
                                onChange={(e) => onInputChange(e)}
                              >
                                <option>Selecionar tempo de Leilão</option>
                                <option value="1">1 Dia - 24 horas</option>
                                <option value="2">2 Dias - 48 horas</option>
                                <option value="24">24 horas</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "#b46739",
                      color: "#fff",
                    }}
                  >
                    {i18n.t("buttons.cancelar")}
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      backgroundColor: "#9ba239",
                      color: "#fff",
                    }}
                  >
                    {i18n.t("buttons.save")}
                  </button>
                </div>
                {/*  */}
              </form>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListLeilaoItemExclusivoCod;
