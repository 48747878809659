import api from "./api";

export const getAnimais = async () => {
  try {
    return await api.get("animais");
  } catch (error) {
    console.log(error.message);
  }
};
export const getAnimais_Fazenda = async (codigo) => {
  try {
    return await api.get("FazendaAnimais/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const getAnimais_Fazenda_anime = async (codigo) => {
  try {
    return await api.get("FazendaAnime/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const putAnimais_Fazenda_usado = async (codigo) => {
  try {
    return await api.put("FazendaAnime/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const getAnimal_individual = async (codigo) => {
  try {
    return await api.get("animal/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const createAnimal = async (data) => {
  try {
    return await api.post("animal", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getImagem = async (imagem) => {
  try {
    return await api.get(`exibirImagemAnimal/${imagem}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getAnimalCodigo = async (codigo) => {
  try {
    return await api.get(`animal/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateAnimal = async (data) => {
  try {
    return await api.put("animal", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeAnimal = async (codigo) => {
  try {
    return await api.delete(`animais/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
