const messages = {
    fr: {
        translations: {
            titles: {
                app: 'Mon application'
            },
            messages: {
                itWorks: 'Il fonctionne correctement comme prévu, avec plusieurs langues !!',
                smallText: 'un petit texte',
                inicio: 'Commencer',
                menus: 'Menus',
                cooperativas: 'Coopératives',
                cooperador: 'Coopérateur',
                cooperadores: 'Coopérateurs',
                fazendas: 'Farmes',
                fazenda: 'Farme',
                animais: 'Animaux',
                leilao: 'Enchéres',
                exclusivo: 'Exclusif',
                atividades: 'Activités',
                vendas: 'Ventes',
                servicos: 'Prestations de Service',
                agendas: 'Agendas',
                adicionar: 'Ajouter',
                funcionario: 'Employé',
                clientes: 'Clients',
                novo: 'Nouveau',
                funcionario: 'Employé',
                Pesquisar_por_nome: 'Rechercher par nom...',
                Pesquisar_por_animal: 'Rechercher par animaux...',
                buscar_por_fazenda: 'Recherche par ferme...',
                nome: 'Nom',
                sobrenome: "Nom de famille",
                email: 'E-mail',
                telefone: 'Téléphone',
                endereco: 'Adresse',
                opcao: 'Option',
                cadastrar: ' S inscrire',
                atualizar: 'Mettre à jour',
                website: 'Website (Optionnel)',
                senha: 'Le mot de passe',
                confirmar_senha: 'Confirmez le mot de passe',
                carregar_foto: 'Envoyer la photo',
                peso: 'Peso',
                idade: 'Âge',
                raca: 'Élever',
                sexo: 'Sexe',
                proveniencia: 'De',
                tempo_de_duracao: 'Durée',
                especie: 'Espèces',
                selecionar_sexo: 'Sélectionnez le sexe de l animal',
                macho_I: 'Mâle entier',
                macho_C: 'Mâle castré',
                novilho: 'Diriger',
                novilha: 'Génisse',
                femea: 'Femelle',
                touro: 'Taureau',
                associar_fazenda: 'Associez votre ferme',
                localizacao: 'Localisation',
                area: 'Zone',
                preco: 'Le prix',
                quota: 'Quota',
                data_venda: 'Date de vente',
                terminar: 'Sortir',
                meu_perfil: 'Mon profil',
                notificacao: 'Avis',


                visitante: 'Visiteur',
                usuarios: 'Utilisateurs',
                //STOCKS
                stockProduto: 'Stock de produits',
                carrinhoProduto: 'Chariots de produits',


                //MENU
                distancia: 'Distance',
                provincia_inicial: 'Origine',
                provincia_final: 'Destin',
                trajectoria: 'Trajectoire',

                //PESQUISAR
                Pesquisar_por_quota: 'recherche par commissions',


                // GRÁFICOS & RELATÓRIOS

                estatisticas: 'Statistiques',
                graficos: 'Graphique',
                titulo_grafico: 'CONTRÔLE DES DONNÉES DE LA CWAM',
                titulo_grafico_vendas: 'CONTRÔLE DES VENTES CWAM',
                relatorios: 'Rapports',
                todos: 'Tout',

                texto_relatorio: 'Données statistiques liées aux rapports',

                relatorio_leilao: 'Rapport d enchères',
                relatorio_vendas: 'Rapport des ventes',
                relatorio_cooperador: 'Rapport des coopérateurs',
                relatorio_clientes: 'Rapport client',
                relatorio_servicos: 'Rapport sur les services',
                relatorio_animais: 'Rapport d animaux',
                relatorio_agendas: 'Rapport de planification',
                relatorio_fazendas: 'Rapport sur les fermes',

                nif: 'NIF',
                seguro_social: 'Numéro de sécurité sociale',
                fundacao_fazenda: 'Fondation agricole',
                cood_cooperador: 'Code Coopérateur',
                buscar_cooperador: 'Chercher un coopérateur...',

                //Novos dados da Fazenda

                latitude: 'Latitude',
                longitude: 'Longitude',
                selecionar_provincia: 'Sélectionnez la province',

                //PAIS
                pais: 'Pays',

                // PROVENCIAS

                Huila: 'Huila',
                Luanda: 'Luanda',
                Cunene: 'Cunene',
                Huambo: 'Huambo',
                Namibe: 'Namibe',
                Benguela: 'Benguela',

                bi: 'BI',
                carta_de_conducao: 'Permis de conduire',

                //LEILAO

                horario: 'Temps',
                localidade: 'Emplacement',
                lote: 'Lot',
                valor_inicial: 'Valeur initiale',
                status: 'Statut',
                tipo: 'Taper',
                selecionar_fazenda: 'Sélectionnez la ferme',
                selecionar_cooperador: 'Sélectionnez Coopérateur',
                data_realizar: 'Date à effectuer',

                // Leilão

                dia: 'Jour',
                valor: 'Évaluer',
                // Agenda

                descricao: 'La description',

                // Servicos

                titulo: 'Titre',
                data: 'Date',

                                // Novas Alteraões de Idiomas
 
                                itens_leilao: '',
                                mensagens: 'Mensagens',
                                assunto: 'Assunto',
                                menssagem: 'Mensagem',
                                carrinho: 'Carrinho',
                                quantidade: 'Quantidade',
                                fornecedor: 'Fornecedor',
                                fornecedores: 'Fornecedores',
                                morada: 'Morada',
                                categoria: 'Categoria',
                                produtos: 'Produtos',
                                produto: 'Produto',
                                codigo: 'Código',
                                voltar_leilao_exclusivo: 'Voltar ao leilão exclusivo',
                                comissao: 'Comissão',
                                imposto: 'Imposto',
                                preco_inicial: 'Preço Inicial',
                                preco_de_venda: 'Preço de venda',
                                empresa: 'Empresa',

            },


            // Transporte 
            transporte: 'Le transport',
            motorista: 'Chauffeur',
            modelo: 'Modèle',
            marca: 'Marque',
            tipo_de_carga: 'Type de charge',
            preco_transporte: 'Prix ​​des transports',
            entrega: 'Livraison',
            matricula: 'Inscription',

            proprietario: 'Propriétaire',
            origem: 'Origine',
            destino: 'Destin',
            precoTransporte: 'Prix ​​des transports',
            selecionar_pais: 'Choisissez le pays',
            selecionar_provincia: 'Sélectionner la province',
            selecionar_municipio: 'Sélectionnez la municipalité',
            trajetoria: 'Trajectoire',

            // GRÁFICOS & RELATÓRIOS

            estatisticas: 'Statistiques',
            graficos: 'Gráficos',
            titulo_grafico: 'CONTRÔLE DES DONNÉES CWAM',
            titulo_grafico_vendas: 'CONTRÊLE DES VENTES CWAM',
            relatorios: 'Rapports',
            todos: '',
        },


        buttons: {
            save: 'Sauver',
            cancelar: 'Annuler',
            pesquisar: 'Chercher',
        },


        h4: {
            texto_grafico_home: 'Population animale estimée dans le sud de l Angola'
        },


        span: {
            especie_1: 'Bétail',
            especie_2: 'Porc',
            especie_3: 'Chévres',
        },


        p: {
            descricao_grafico_1: 'Environ 95% du bétail en Angola est exploité par des paysans (environ / 4 000 000 de têtes)',
            descricao_grafico_2: 'La plus grande concentration de bétail du pays, avec une valeur d environ 90%. est au sud de',
        }
    }

}


export { messages }