import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import {
  createPais,
  getPaisCodigo,
  getPaises,
  removePais,
  updatePais,
} from "../../service/PaisService";

import * as messages from "../message/toastr";
import * as yup from "yup";

import TabProvincia from "../tab/TabProvincia";
import TabMunicipio from "../tab/TabMunicipio";
import Swal from "sweetalert2";
import { i18n } from "../../Traducao/translate/i18n";

const I18N_STORAGE_KEY = "i18nextLng";

const ListaPais = () => {
  const { codigo } = useParams();
  const [paises, setPaises] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllpais();
  }, []);

  //LISTAR
  const getAllpais = () => {
    getPaises()
      .then((response) => {
        setPaises(response.data);
        setLoading(false);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deletePais = (codigo) => {
    Swal.fire({
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removePais(codigo).then((response) => {
          getAllpais();
          Swal.fire("Eliminado!", "Eliminado.", "success");
        });
      }
    });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  //SALVAR
  const [pais, setPais] = useState({
    nome: "",
  });

  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  const { nome } = pais;
  //
  const onInputChange = (e) => {
    setPais({ ...pais, [e.target.name]: e.target.value });
  };

  //Limpar Campos
  const handleClear = () => {
    setPais({
      nome: "",
    });
  };
  //Botao Salvar
  const saveUpdatePais = async (e) => {
    e.preventDefault();

    if (!(await validate())) return;

    // const formData = new FormData();
    const dado = { nome };
    //
    if (codigo) {
      await updatePais(codigo, dado)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllpais();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      await createPais(dado)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          handleClear();
          getAllpais();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };

  //
  useEffect(() => {
    loadpais();
  }, []);
  //
  const loadpais = () => {
    if (codigo) {
      getPaisCodigo(codigo).then((response) => {
        setPais(response.data);
      });
    }
  };

  //VAlidacao
  async function validate() {
    let schema = yup.object().shape({
      nome: yup
        .string("Erro: Necessário preencher o nome!")
        .required("Erro: Necessário preencher o nome!"),
    });
    try {
      await schema.validate(pais);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //
  if (loading) {
    return <div className="loading"></div>;
  }
  //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-6 col-sm-6">
                <Link to="/addpais" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus"></span>
                  </button>
                </Link>
                <Link to="/relatoriopaises" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="fa fa-print"></span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.pais")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.nome")}</th>
                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paises.map((p) => (
                          <tr key={p.codigo}>
                            <td data-label="País:">{p.nome}</td>
                            {/* <td>{p.paisID}</td> */}
                            <td data-label="Adicionais:">
                              {/*  */}
                              <Link
                                to={`/editpais/${p.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>
                              {/*  */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deletePais(p.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                              {/*  */}
                            </td>
                          </tr>
                        ))}
                        {/* })} */}
                      </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
              <div className="default-tab">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                      className="nav-item nav-link active"
                      id="nav-home-tab"
                      data-toggle="tab"
                      href="#nav-home"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      {i18n.t("messages.pais")}
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-profile-tab"
                      data-toggle="tab"
                      href="#nav-profile"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      {i18n.t("messages.provincia")}
                    </a>
                    <a
                      className="nav-item nav-link"
                      id="nav-contact-tab"
                      data-toggle="tab"
                      href="#nav-contact"
                      role="tab"
                      aria-controls="nav-contact"
                      aria-selected="false"
                    >
                      {i18n.t("messages.municipio")}
                    </a>
                  </div>
                </nav>
                <div className="tab-content pl-3 pt-2" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    {/*  */}

                    <form
                      // enctype="multipart/form-data"
                      onSubmit={(e) => saveUpdatePais(e)}
                    >
                      <h6>
                        {" "}
                        {status.type === "error" ? (
                          <p style={{ color: "#ff0000" }}>{status.mensagem}</p>
                        ) : (
                          ""
                        )}
                      </h6>
                      {/* pais */}
                      <div className="row">
                        <div className="col-5">
                          <div className="form-group">
                            <input
                              name="nome"
                              type="text"
                              className="form-control cc-exp"
                              placeholder={i18n.t("messages.nome")}
                              value={pais.nome}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </div> 
                        {/* event to do a different mod */}
                      </div>
                      {/*  */}
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn"
                          data-dismiss="modal"
                          style={{
                            backgroundColor: "#b46739",
                            color: "#fff",
                          }}
                        >
                          {i18n.t("buttons.cancelar")}
                        </button>
                        <button
                          type="submit"
                          className="btn"
                          style={{
                            backgroundColor: "#9ba239",
                            color: "#fff",
                          }}
                        >
                          <span className="lar la-save">
                            {i18n.t("buttons.save")}
                          </span>
                        </button>
                      </div>
                      {/*  */}
                      {/* FIM pais */}
                    </form>

                    {/*  */}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <TabProvincia />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-contact"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    <TabMunicipio />
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListaPais;
