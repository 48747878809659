import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {} from "../../service/LeilaoExclusivoService";
import { useParams } from "react-router-dom";
import logo from "../../assets/img/logo/logo1.jpg";
import { getLeilaoCodigo } from "../../service/LeilaoService";
import { getLeilaoCodigoItem } from "../../service/LeilaoItemService";
import redirects from "../../components/redirect";

function App() {
  const componentRef = useRef();
  const { codigo } = useParams();
  const [dados, setDados] = useState({});
  const [itens, setItens] = useState({});
  const { leiloes } = redirects();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Relatorios",
  });

  useEffect(() => {
    busca();
  }, []);

  const busca = async () => {
    try {
      const { data } = await getLeilaoCodigo(codigo);
      setDados(data);

      const item = await getLeilaoCodigoItem(codigo);
      setItens(item.data[0]);
      console.log(item.data[0]);
    } catch (error) {
      console.log(error.message);
    }
  };

  const [data, setData] = useState("");
  const dataTime = () => {
    setData(new Date().toLocaleString());
  };
  const [totalTime, setTotalTime] = useState([60]);
  useEffect(() => {
    setTimeout(() => {
      setTotalTime(totalTime - 1);
    }, 1000);
    dataTime();
  }, [totalTime]);

  return (
    <>
      <div className="Formatacao-Relatorio" ref={componentRef}>
        <div className="App_header">
          <div className="data_esq">
            <img src={logo} />
            <h6>Vagne Zau Lelo</h6>
            <small>{data}</small>
          </div>
          <div className="data_dir">
            <h6>Cooperativa Wapoma Agro-pecuária e Mineira</h6>
            <small>Huíla Lubango</small>
            <small>cooperativacwamahuila@gmail.com</small>
            <small>www.cwam.com</small>
          </div>
        </div>
        <header className="App_h1">
          <h3>Relatório do Leilão</h3>
        </header>

        <div className="data_header">
          <div className="data_cab">
            <div className="data_esq">
              <p>Dados do Leilão:</p>
              <p>Codigo: {dados.codLeilao}</p>
              <p>Quantidade: {dados.quantidade}</p>
            </div>
            <div className="data_esq">
              <p>Estado: {dados.status}</p>
              <p>Data: {dados.dataLeilao}</p>
            </div>
          </div>

          <div className="App_body">
            <div className="table_data">
              <table className="table">
                <thead>
                  <th className="th">Codigo</th>
                  <th className="th">Lote</th>
                  <th className="th">Fazenda</th>
                  <th className="th">Quantidade</th>
                  <th className="th">Dia</th>
                  <th className="th">Hora</th>
                  <th className="th">Preco</th>
                  <th className="th">Data</th>
                </thead>
                {/*{itens.map((row) => (
                  <tbody>
                    <tr className="tr" key={row.codigo}>
                      <td className="td">{row.codigo}</td>
                       <td className="td">{row.leilao?.codigo}</td>
                      <td className="td">{row.lote?.nome}</td>
                      <td className="td">{row.quantidade}</td>
                      <td className="td">{row.dia}</td>
                      <td className="td">{row.hora}</td>
                      <td className="td">{row.preco}</td>
                      <td className="td">{row.data}</td>
                    </tr>
                  </tbody>
                ))} */}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="data_funny">
        <button onClick={leiloes} className="btn botao_return">
          <span className="fa fa-reply"></span>
        </button>
        <button onClick={handlePrint} className="btn botao_print">
          <span className="fa fa-print"></span>
        </button>
      </div>
    </>
  );
}

export default App;
