import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPaises } from "../../service/PaisService";
import * as messages from "../../components/message/toastr";
import {
  createProvincia,
  getProvinciaCodigo,
  updateProvincia,
} from "../../service/ProvinciaService";

import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const TabProvincia = () => {
  const { codigo } = useParams();
  const [paises, setPaises] = useState([]);

  useEffect(() => {
    getAllPais();
  }, []);

  //LISTAR
  const getAllPais = () => {
    getPaises()
      .then((response) => {
        setPaises(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  //SALVAR Fazenda
  const [provincia, setProvincia] = useState({
    nome: "",
    pais: {
      codigo:""
    }
  });

  const { nome, pais } = provincia;
  //
  const [Addpais , setAddPais] = useState()
  const onInputChange = (e) => {
    setProvincia({ ...provincia, [e.target.name]: e.target.value });
    if (e.target.name === 'pais') {
      setAddPais(e.target.value)
    }
  };

  //Botao Salvar
  const saveUpdateProvincia = async (e) => {
    e.preventDefault();

    const data ={
   nome,
    pais:{
      codigo: Addpais
    },
    }

    if (codigo) {
      await updateProvincia(data)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllPais();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createProvincia(data)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllPais();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadpais();
  }, []);
  //
  const loadpais = () => {
    if (codigo) {
      getProvinciaCodigo(codigo)
        .then((response) => {
          setPaises(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };

  return (
    <div>
      <form
        onSubmit={(e) => saveUpdateProvincia(e)}
      >
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                id=""
                name="nome"
                type="text"
                className="form-control cc-exp"
                value={nome}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.nome")}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-group">
              <select
                data-placeholder="Selecionar..."
                className="form-control cc-exp"
                tabindex="1"
                name="pais"
                value={pais}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.pais")}
              >
                <option>Selecionar pais</option>
                {paises.map((pais) => (
                  <option key={pais.codigo} value={pais.codigo}>
                    {pais.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span>{i18n.t("buttons.save")}</span>
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabProvincia;
