import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { createAgenda, getAgendaCodigo, updateAgenda } from "../../service/AgendaService";
import * as messages from "../../components/message/toastr";

import { i18n } from '../../Traducao/translate/i18n';
import * as yup from "yup";

const I18N_STORAGE_KEY = 'i18nextLng';


const TabAgenda = (props) => {

  // Buscar por ID
  const { codigo } = useParams();
  //SALVAR
  const [agenda, setAgenda] = useState({
    imgUrl: "",
    descricao: "",
    data: "",
    exclusivo: false,
  });

  //Limpar
  const handleClear = () => {
    setAgenda({
      imgUrl: "",
      descricao: "",
      data: "",
      exclusivo: false,
    })
  }
  const [imgUrl, setImgUrl] = useState("");

  const { descricao, data, exclusivo } = agenda;
  //

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //

  const onInputChange = (e) => {
    setAgenda({ ...agenda, [e.target.name]: e.target.value });
  };

  //Botao Salvar
  const saveUpdateAgenda = async (e) => {
    e.preventDefault();


    if (!(await validate())) return;


    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("descricao", descricao);
    formData.append("exclusivo", exclusivo);
    formData.append("data", data);

    //
    if (codigo) {
      await updateAgenda(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          // retrievecooperadores();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createAgenda(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          // retrievecooperadores();
          handleClear();
          props.VerAsAgendas()
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadAgenda();
  }, []);
  //
  const loadAgenda = () => {
    if (codigo) {
      getAgendaCodigo(codigo)
        .then((response) => {
          setAgenda(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };
  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setImgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };

  //
  const titulo = () => {
    if (codigo) {
      return <h3 style={{ color: "#fff" }}> {i18n.t('messages.atualizar')} </h3>;
    } else {
      return <h3 style={{ color: "#fff" }}> {i18n.t('messages.cadastrar')} </h3>;
    }
  };
  //
  //

  async function validate() {
    let schema = yup.object().shape({

      descricao: yup
        .string("Adicione uma descrição ao Agenda!")
        .required("Adicione uma descrição ao Agenda!"),

      data: yup
        .string("Adicione a data de Realização da Agenda!")
        .required("Adicione a data de Realização da Agenda!"),

    });
    try {
      await schema.validate(agenda);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //
  return (
    <div>
      {/* Agenda */}

      {/* <!-- .content --> */}

      <form
        enctype="multipart/form-data"
        onSubmit={(e) => saveUpdateAgenda(e)}
      >
        {status.type === "error" ? (
          <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
        ) : (
          ""
        )}
        {/* Agenda */}
        <div className="row">
          <div className="col-6">
            <div className="input-group">
              <div className="preview">
                <img id="file-ip-1-preview" />
              </div>
              {/* </div> */}
              <label className="custom-btn" for="file-ip-1">

                {i18n.t('messages.carregar_foto')}

              </label>
              <input
                type="file"
                id="file-ip-1"
                accept="image/*"
                name="img_url"
                className="form-control cc-cvc"
                onChange={(e) => showPreview(e)}
                hidden
              ></input>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                id=""
                name="data"
                type="date"
                className="form-control cc-exp"
                value={data}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t('messages.data')}
              />
            </div>
          </div>
          <div className="col-6">
            {/* <div className="input-group">
              <input
                id=""
                name="exclusivo"
                type="checkbox"
                className="form-control cc-exp"
                value={exclusivo}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t('messages.exclusivo')}
              />
            </div> */}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="row form-group">
              <div className="col-12 col-md-12">
                <textarea
                  name="descricao"
                  id="textarea-input"
                  rows="6"
                  value={descricao}
                  onChange={(e) => onInputChange(e)}
                  placeholder={i18n.t('messages.descricao')}
                  className="form-control">
                </textarea>
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        {/*  */}


        {/*  */}

        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t('buttons.cancelar')}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span>
              {i18n.t('buttons.save')}
            </span>
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabAgenda;
