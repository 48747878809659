import api from "./api";



export const getFuncionarios = async () => {
  try {
    return await api.get("https://webccgsa.fly.dev /FuncionarioCcgsa");
  } catch (error) {
    console.log(error.message);
  }
};

export const createSession = async (data) => {
  try {
    return await api.post("authFuncionario", data);
  } catch (error) {
    console.log(error.message);
  }
};

export const encontrarFuncionario = async (params) => {
  try {
    return await api.get(`funcionarioEmail/${params}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getPerfilFuncionario = async (codigo) => {
  try {
    return await api.get(`funcionario/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};