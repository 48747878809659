const messages = {
  en: {
    translations: {
      titles: {
        app: "My Aplication",
      },
      messages: {
        itWorks: "Its working properly as expected, with multiple languages!",
        smallText: "A small Text",
        inicio: "Home",
        menus: "Menus",
        cooperativas: "Cooperatives",
        cooperador: "Cooperator",
        cooperadores: "Cooperators",
        fazendas: "Farms",
        fazenda: "Farm",
        animais: "Animals",
        animal: "Animal",
        leilao: "Auction",
        exclusivo: "Exclusive",
        atividades: "Activities",
        vendas: "Sales",
        servicos: "Services",
        agendas: "Schedule",
        adicionar: "Add",
        funcionario: "Employee",
        clientes: "Customers",
        novo: "Register",
        funcionario: "Employee",
        Pesquisar_por_nome: "Search by name...",
        Pesquisar_por_animal: "Search by animal...",
        buscar_por_fazenda: "Search by farm...",
        nome: "Name",
        sobrenome: "Last name",
        email: "E-mail",
        telefone: "Phone",
        endereco: "Address",
        opcao: "Option",
        cadastrar: "Register",
        atualizar: "Update",
        website: "Website (Optional)",
        senha: "Password",
        confirmar_senha: "Confirm password",
        carregar_foto: "Upload photo",
        peso: "Lester",
        idade: "Age",
        raca: "Breed",
        sexo: "Sex",
        proveniencia: "From",
        tempo_de_duracao: "Duration time",
        especie: "Species",
        selecionar_sexo: "Select animal sex",
        macho_I: "Interim Male",
        macho_C: "castrated male",
        novilho: "Steer",
        novilha: "Heifer",
        femea: "Female",
        touro: "Bull",
        associar_fazenda: "Associate your farm",

        associar_cooperador: "Associate Cooperator",
        localizacao: "Localization",
        area: "Area",
        preco: "Price",
        quota: "Quota",
        data_venda: "Date of Sale",
        terminar: "Log out",
        meu_perfil: "My Profile",
        notificacao: "Notifications",

        visitante: "Visitor",
        usuarios: "Users",
        //STOKS PRODUTO
        stockProduto: "Product Stock",
        carrinhoProduto: "Product Carts",

        texto_relatorio: "Statistical Data Related to Reports",

        relatorio_leilao: "Auction Report",
        relatorio_vendas: "Sales Report",
        relatorio_cooperador: "Cooperators Report",
        relatorio_clientes: "Customer Report",
        relatorio_servicos: "Services Report",
        relatorio_animais: "Animal Report",
        relatorio_agendas: "Schedule Report",
        relatorio_fazendas: "Farms Report",

        nif: "NIF",
        seguro_social: "Nocial security number",
        fundacao_fazenda: "Farm Foundation",
        cood_cooperador: "Cooperator Code",
        buscar_cooperador: "Look for cooperator...",

        //Novos dados da Fazenda

        latitude: "Latitude",
        longitude: "Longitude",
        selecionar_provincia: "Select Province",

        //PAIS
        pais: "Country",

        // PROVENCIAS

        Huila: "Huila",
        Luanda: "Luanda",
        Cunene: "Cunene",
        Huambo: "Huambo",
        Namibe: "Namibe",
        Benguela: "Benguela",

        bi: "BI",
        carta_de_conducao: "Driving license",

        //MENU
        distancia: "Distance",
        provincia_inicial: 'Origin',
        provincia_final: 'Destiny',
        trajectoria: 'Trajectory',

        //PESQUISAR POR 
        Pesquisar_por_quota: 'search by commission',

        //LEILAO

        horario: "Time",
        localidade: "Location",
        lote: "Batch",
        valor_inicial: "Initial value",
        status: "Status",
        tipo: "Type",
        selecionar_fazenda: "Select Farm",
        selecionar_cooperador: "Select Cooperator",
        data_realizar: "Date to be performed",

        // Leilão

        dia: "Day",
        valor: "Value",
        // Agenda

        descricao: "Description",

        // Servicos

        titulo: "Title",
        data: "Date",

        // Novas Alteraões de Idiomas

        itens_leilao: "Auction Items",
        mensagens: "Messages",
        assunto: "Subject",
        menssagem: "Message",
        carrinho: "Cart",
        quantidade: "The amount",
        fornecedor: "Provider",
        fornecedores: "Providers",
        morada: "Household",
        categoria: "Category",
        produtos: "Products",
        produto: "Product",
        codigo: "Code",
        voltar_leilao_exclusivo: "Back to exclusive auction",
        comissao: "Commission",
        imposto: "Tax",
        preco_inicial: "Initial price",
        preco_de_venda: "Sale price",
        empresa: "Company",
      },

      // Transporte
      transporte: "Transport",
      motorista: "Driver",
      modelo: "Model",
      marca: "Brand",
      tipo_de_carga: "Load Type",
      preco_transporte: "Transport Price",
      entrega: "Delivery",
      matricula: "Registration",
      proprietario: "Owner",
      origem: "Origin",
      destino: "Destiny",
      precoTransporte: "Transport Price",
      selecionar_pais: "Select Country",
      selecionar_provincia: "Select province",
      selecionar_municipio: "Select Municipality",
      trajetoria: "Trajectory",

      // GRÁFICOS & RELATÓRIOS

      estatisticas: "Statistics",
      graficos: "Charts",
      titulo_grafico: "CWAM DATA CONTROL",
      titulo_grafico_vendas: "CWAM SALES CONTROL",
      relatorios: "Reports",
      todos: "All",
    },
    buttons: {
      save: "Save",
      cancelar: "Cancel",
      pesquisar: "Search",
    },

    h4: {
      texto_grafico_home: "Estimated animal population in southern Angola",
    },

    span: {
      especie_1: "Cattle",
      especie_2: "Swine",
      especie_3: "Goats",
    },

    p: {
      descricao_grafico_1:
        "About 95% of the cattle in Angola are exploited by peasant farmers (approximately / 4,000,000 heads)",
      descricao_grafico_2:
        "The largest concentration of livestock in the country, with a value of around 90%. is in the south of ",
    },
  },
};

export { messages };
