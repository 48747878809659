import api from "./api";

export const getProdutos = async () => {
  try {
    return await api.get("Produto");
  } catch (error) {
    console.log(error.message);
  }
};
export const getProdutos_Categoria = async (codigo) => {
  try {
    return await api.get("produtoCategoria/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const createProduto = async (data) => {
  try {
    return await api.post("criar_produto", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const createArtigoProduto = async (data) => {
  try {
    return await api.post("criar_artigoproduto", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getImagem = async (imagem) => {
  try {
    return await api.get(`exibirImagemProduto/${imagem}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getProdutoCodigo = async (codigo) => {
  try {
    return await api.get(`produto/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getProduto_individual = async (codigo) => {
  try {
    return await api.get("produto/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateProduto = async (data) => {
  try {
    return await api.put("produto", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeProduto = async (codigo) => {
  try {
    return await api.delete(`produto/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
