import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import {
  createLote,
  getLotes,
  removeLote,
  updateLote,
} from "../../service/LoteService";
import { getFazendas } from "../../service/FazendaService";
import { getEspecies } from "../../service/EspecieService";
import * as messages from "../message/toastr";
import Swal from "sweetalert2";

import { i18n } from "../../Traducao/translate/i18n";

import TabRapido from "../tabModal/TabRapido";
import TabExistente from "../tabModal/TabExistente";

import * as yup from "yup";

const I18N_STORAGE_KEY = "i18nextLng";

const List_Lote = () => {
  // Buscar por ID
  const { codigo } = useParams();
  const [lotes, setLotes] = useState([]);
  const [verEspecie, setverEspecie] = useState([]);

  //
  useEffect(() => {
    getAllLote();
    getAllEspecie();
  }, []);

  //LISTAR
  const getAllLote = () => {
    getLotes()
      .then((response) => {
        setLotes(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllEspecie = () => {
    getEspecies()
      .then((response) => {
        setverEspecie(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const [verFazenda, setverFazenda] = useState([]);
  useEffect(() => {
    getAllFazenda();
  }, []);

  const getAllFazenda = () => {
    getFazendas()
      .then((response) => {
        setverFazenda(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deleteLote = (codigo) => {
    Swal.fire({
      title: "Deseja eliminar?",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeLote(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllLote();
        });
      }
    });
  };
  //

  //SALVAR
  const [lote, setLote] = useState({
    imgUrl: "",
    especie: "",
    loteVendaLeilao: "",
    fazenda: "",
  });

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //

  const [imgUrl, setImgUrl] = useState("");
  const { especie, loteVendaLeilao, fazenda } = lote;
  //
  const onInputChange = (e) => {
    setLote({ ...lote, [e.target.name]: e.target.value });
  };

  const [loteId, setLoteId] = useState([]);
  const [loteEspecieId, setLoteEspecieId] = useState([]);
  //Botao Salvar
  const saveUpdateLote = async (e) => {
    e.preventDefault();


    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("especie", especie);
    formData.append("loteVendaLeilao", loteVendaLeilao);
    formData.append("fazenda", fazenda);

    //
    if (codigo) {
      await updateLote(lote)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllLote();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createLote(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllLote();
          const result = response.data.codigo;
          const resultEspecie = response.data.especie.codigo;
          setLoteId(result);
          setLoteEspecieId(resultEspecie);
          setTrocarModal(true);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };

  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };
  const titulo2 = () => {
    return <h3 style={{ color: "#fff" }}> Adicionar </h3>;
  };

  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setImgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };

  // Items do Leilao
  const [EscolhaCAtegoria, setEscolhaCAtegoria] = useState(0);

  const verCategoriaTrocar = (e) => {
    if (e.target.value === "rapido") {
      setEscolhaCAtegoria("rapido");
    }
    if (e.target.value === "especifico") {
      setEscolhaCAtegoria("especifico");
    }
    if (e.target.value === "buscar") {
      setEscolhaCAtegoria("buscar");
    }
  };

  const [TrocarModal, setTrocarModal] = useState(false);
  //

  //
  async function validate() {
    let schema = yup.object().shape({
      loteVendaLeilao: yup
        .string("Selecionar um estado para o Lote é obrigatório!")
        .required("Selecionar um estado para o Lote é obrigatório!"),

      especie: yup
        .string("Selecionar a especie é obrigatório!")
        .required("Selecionar a especie é obrigatório!"),

      fazenda: yup
        .string("Selecionar a Fazenda do Lote é obrigatório!")
        .required("Selecionar a Fazenda do Lote é obrigatório!"),

    });
    try {
      await schema.validate(lote);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-6 col-sm-6">
                <Link to="/addlotes" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus"></span>
                  </button>
                </Link>

                <Link to="/relatoriolotes" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                    title="Relatórios"
                  >
                    <span className="fa fa-print"></span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button>
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.lote")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th className="th">Imagem</th>
                          <th scope="col">{i18n.t("messages.lote")} </th>
                          <th scope="col">{i18n.t("messages.especie")} </th>
                          <th scope="col">{i18n.t("messages.fazenda")} </th>
                          <th scope="col">{i18n.t("messages.opcao")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        {lotes.map((lote) => (
                          <tr key={lote.codigo}>
                            <td data-label="Imagem:" className="td">
                              <img
                                src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirImagemAnimal/${lote.imgUrl}`}
                                className="IMGList"
                                alt="Imagem do lote"
                                title="Imagem do lote"
                              />
                            </td>
                            <td>{lote.codigo}</td>
                            <td data-label="Especie:">{lote.especie.especieAnimal}</td>
                            <td data-label="Fazenda:">{lote.fazenda.nome}</td>

                            <td data-label="Adicionais:">
                              <Link
                                to={`/viewlote/${lote.codigo}`}
                                className="btn btn-primary"
                              >
                                <span className="las la-eye link-add"></span>
                              </Link>
                              {/*  */}
                              <Link
                                to={`/editlote/${lote.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>
                              <Link
                                to={`/relatoriolote/${lote.codigo}`}
                                className="btn btn-primary"
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="fa fa-print link-add-print"></span>
                              </Link>
                              {/*  */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deleteLote(lote.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- .content --> */}
        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            {TrocarModal === false ? (
              <div className="card-body">
                {/* LOTE */}
                <form
                  enctype="multipart/form-data"
                  onSubmit={(e) => saveUpdateLote(e)}
                >

                  {status.type === "error" ? (
                    <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
                  ) : (
                    ""
                  )}
                  <div className="row">
                    <div className="col-6">
                      <div className="input-group">
                        <div className="preview">
                          <img id="file-ip-1-preview" />
                        </div>
                        {/* </div> */}
                        <label className="custom-btn" for="file-ip-1">
                          {i18n.t("messages.carregar_foto")}
                        </label>
                        <input
                          type="file"
                          id="file-ip-1"
                          accept="image/*"
                          name="imgUrl"
                          className="form-control cc-cvc"
                          onChange={(e) => showPreview(e)}
                          hidden
                        ></input>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="row">
                    <div className="col-5">
                      <div className="input-group">
                        <select
                          data-placeholder="Selecionar..."
                          className="form-control cc-exp"
                          tabindex="1"
                          name="fazenda"
                          value={fazenda}
                          onChange={(e) => onInputChange(e)}
                          placeholder={i18n.t("messages.fazenda")}
                        >
                          <option>Selecionar Fazenda</option>
                          {verFazenda.map((fazenda) => (
                            <option value={fazenda.codigo}>
                              {fazenda.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/*  */}
                    <div className="col-4">
                      <select
                        data-placeholder="Selecionar..."
                        className="form-control cc-exp"
                        tabindex="1"
                        name="especie"
                        value={especie}
                        onChange={(e) => onInputChange(e)}
                        placeholder={i18n.t("messages.especie")}
                      >
                        <option>Selecionar Especie</option>
                        {verEspecie.map((especie) => (
                          <option value={especie.codigo}>
                            {especie.especieAnimal}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-3">
                      <div className="input-group">
                        <select
                          data-placeholder="Selecionar..."
                          className="form-control cc-exp"
                          tabindex="1"
                          name="loteVendaLeilao"
                          value={loteVendaLeilao}
                          onChange={(e) => onInputChange(e)}
                        >
                          <option
                            value=""
                            label={i18n.t("messages.estado")}
                          ></option>
                          <option value="LEILÃO">
                            {i18n.t("messages.leilao")}
                          </option>
                          <option value="VENDA">
                            {i18n.t("messages.vendas")}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                      style={{
                        backgroundColor: "#ccc",
                        color: "#000",
                      }}
                    >
                      {i18n.t("buttons.cancelar")}
                    </button>
                    {/*  */}
                    <button
                      type="submit"
                      className="btn"
                      data-toggle="modal"
                      data-target="#largeModalLote"
                      // data-dismiss="modal"

                      style={{
                        backgroundColor: "#9ba239",
                        color: "#fff",
                      }}
                    >
                      {i18n.t("buttons.save")}
                    </button>
                  </div>
                </form>

                {/* FIM LOTE */}
              </div>
            ) : (
              <div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-3">
                      <input
                        type="radio"
                        name="categoriatroca"
                        value="rapido"
                        id="rapido"
                        style={{ display: "none" }}
                        onChange={(e) => verCategoriaTrocar(e)}
                      />
                      <label
                        for="rapido"
                        data-toggle="modal"
                        data-target="#modalrapido"
                        class="btn btn-secondary mb-1"
                        style={{ marginRight: "10px", width: "140px" }}
                      >
                        Lote Geral
                      </label>
                    </div>

                    <div className="col-3">
                      <input
                        type="radio"
                        name="categoriatroca"
                        value="buscar"
                        id="buscar"
                        style={{ display: "none" }}
                        onChange={(e) => verCategoriaTrocar(e)}
                      />
                      <label
                        for="buscar"
                        data-toggle="modal"
                        data-target="#modalrapido"
                        class="btn btn-secondary"
                        style={{ marginLeft: "90px", width: "273px" }}
                      >
                        Adicionar Animais existentes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="modal-footer" style={{ marginTop: "50px" }}>
                  <button
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "#ccc",
                      color: "#000",
                    }}
                  >
                    Voltar
                  </button>
                </div>
              </div>
            )}

            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Inicio Item Lote BOTAO------> */}

      {/* <!-------- Cadastro RAPIDO, EXTENSIVO e EXTENSIVO ------> */}
      <div
        className="modal fade"
        id="modalrapido"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalrapido"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo2()}
              </h5>
            </div>

            {/* Escolha de categorias */}
            <div className="card-body">
              {EscolhaCAtegoria === "rapido" ? (
                <TabRapido loteId={loteId} loteEspecieId={loteEspecieId} />
              ) : (
                ""
              )}

              {EscolhaCAtegoria === "buscar" ? (
                <TabExistente loteId={loteId} />
              ) : (
                ""
              )}
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default List_Lote;
