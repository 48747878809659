import Menu from "../../components/layout/Menu";
import ListCarrinho from "../../components/listTable/ListCarrinho";

const Animal = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListCarrinho />
      </div>
      {/*  */}
    </>
  );
};

export default Animal;