import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"; 

import { AuthProvider, AuthContext } from "./contexts/Auth";
import Home from "./pages/Home";
import Login from "./pages/admin/Login";
import NotFound from "./components/error/NotFound";
// cooperadores
import Cooperador from "./pages/cooperador/Cooperador";
import ViewCooperador from "./pages/cooperador/ViewCooperador";

// animal
import Animal from "./pages/animal/Animal";
import ViewAnimal from "./pages/animal/ViewAnimal";

// leilao
import LeilaoItem from "./pages/leilaoItem/LeilaoItem";
import ViewItemLeilao from "./pages/leilaoItem/ViewItemLeilao";
import LeilaoItemExclusivo from "./pages/leilaoItemExclusivo/LeilaoItemExclusivo";
import ViewItemLeilaoExclusivo from "./pages/leilaoItemExclusivo/ViewItemLeilaoExclusivo";
import ViewLeilao from "./pages/leilao/ViewLeilao";
import Exclusivo from "./pages/exclusivo/Exclusivo";
import ViewLeilaoExclusivo from "./pages/exclusivo/ViewLeilaoExclusivo";
import Leilao from "./pages/leilao/Leilao";

// lote
import Lote from "./pages/lote/Lote";
import ViewLote from "./pages/lote/ViewLote";
import LoteItem from "./pages/loteItem/LoteItem";
import ViewLoteItem from "./pages/loteItem/ViewLoteItem";

// lance
import Lance from "./pages/lance/Lance";
import ViewLance from "./pages/lance/ViewLance";

// servico
import Servico from "./pages/servico/Servico";
import ViewServico from "./pages/servico/ViewServico";

// comprovativo
import ViewComprovativo from "./pages/comprovativo/ViewComprovativo";

// mensagens
import ViewMensagens from "./pages/mensagem/ViewMensagens";
import Mensagem from "./pages/mensagem/Mensagem";

// transportes
import Transporte from "./pages/trasporte/Transporte";
import ViewTransporte from "./pages/trasporte/ViewTransporte";

// graficos
import CharAnimal from "./pages/grafico/CharAnimal";
import CharVendas from "./pages/grafico/CharVendas";

// fornecedor
import Fornecedor from "./pages/fornecedores/Fornecedor";
import ViewFornecedor from "./pages/fornecedores/ViewFornecedor";

// categorias
import Categoria from "./pages/categoria/Categoria";
import ViewCategoria from "./pages/categoria/ViewCategoria";

// produto
import Produto from "./pages/produto/Produto";
import ViewProduto from "./pages/produto/ViewProduto";

// comissao
import Comissao from "./pages/comissao/Comissao";

// imposto
import Imposto from "./pages/imposto/Imposto";

// proprietario
import Proprietario from "./pages/proprietario/Proprietario";

// fazemda
import Fazenda from "./pages/fazenda/Fazenda";
import Viewfazenda from "./pages/fazenda/Viewfazenda";

// agenda
import ViewAgenda from "./pages/agenda/ViewAgenda";
import Agenda from "./pages/agenda/Agenda";

// funcionario
import Funcionario from "./pages/funcionario/Funcionario";
import ViewFuncionario from "./pages/funcionario/ViewFuncionario";

// vendas
import Venda from "./pages/venda/Venda";
import ViewVenda from "./pages/venda/ViewVenda";

// pais
import Pais from "./pages/pais/Pais";
// import Pais from "./pages/pais/index";

// provincia Municipio
import Provincia from "./pages/provincia/Provincia";
import Municipio from "./pages/municipio/Municipio";

// usuarios
import Usuarios from "./pages/usuarios/Usuarios";

// comprovativo Entrega
import Comprovativo from "./pages/comprovativo/Comprovativo";
import Entrega from "./pages/entrega/Entrega";

// Participantes para o leilao
import Participantes from "./pages/participantes/Participantes";

// carrinho
import Carrinho from "./pages/carrinho/Carrinho";
import ViewCarrinho from "./pages/carrinho/ViewCarrinho";
import CarrinhoProduto from "./pages/carrinhoProduto/CarrinhoProduto";
import ViewCarrinhoProduto from "./pages/carrinhoProduto/ViewCarrinhoProduto";

// distancia
import Distancia from "./pages/distancia/Distancia";

// stock
import StockProduto from "./pages/stockProduto/StockProduto";
import ListStockProduto from "./components/listTable/ListStockProduto";
import ViewStockProduto from "./pages/stockProduto/ViewStockProduto";
import ListStockAnimal from "./components/listTable/ListStockAnimal";

// Relatórios
import RelatoriosAnimal from "./pages/relatorios/relatorioAnimal";
import RelatoriosCooperador from "./pages/relatorios/relatorioCooperador";
import RelatoriosFazenda from "./pages/relatorios/relatorioFazenda";
import RelatorioGeral from "./pages/relatorios/Relatorios";
import RelatorioStock from "./pages/relatorios/relatorioStock";
import RelatorioLote from "./pages/relatorios/relatorioLote";
import RelatorioAgenda from "./pages/relatorios/relatorioAgenda";
import RelatorioServico from "./pages/relatorios/relatorioServico";
import RelatorioLance from "./pages/relatorios/relatorioLance";
import RelatorioPais from "./pages/relatorios/relatorioPais";
import RelatorioLeilao from "./pages/relatorios/relatorioLeilao";
import RelatorioEspecie from "./pages/relatorios/relatorioEspecie";
import RelatorioRaca from "./pages/relatorios/relatorioRaca";
import RelatorioTransporte from "./pages/relatorios/relatorioTransporte";
import RelatorioProduto from "./pages/relatorios/relatorioProduto";
import RelatorioLeilaoExclusivo from "./pages/relatorios/relatorioLeilaoExclusivo";

// Relatórios Por Animal
import RelatorioIndividualAnimal from "./pages/relatorios/individualAnimal";
import RelatorioIndividualCooperador from "./pages/relatorios/individualCooperador";
import RelatorioIndividualFazenda from "./pages/relatorios/individualFazenda";
import RelatorioIndividualLote from "./pages/relatorios/individualLote";
import RelatorioIndividualAgenda from "./pages/relatorios/individualAgenda";
import RelatorioIndividualStock from "./pages/relatorios/individualStock";
import RelatorioIndividualServico from "./pages/relatorios/individualServico";
import RelatorioIndividualLance from "./pages/relatorios/individualLance";
import RelatorioIndividualProduto from "./pages/relatorios/individualProduto";
import RelatorioIndividualLeilao from "./pages/relatorios/individualLeilao";
import RelatorioIndividualLeilaoExclusivo from "./pages/relatorios/individualLeilaoExclusivo";

// especies Racas
import Especie from "./pages/especie/Especie";
import Raca from "./pages/raca/Raca";

// Tag
import Tag from "./pages/tag/Tag";

// Notificacoes
import ViewNotificacoes from "./pages/notificacoes/ViewNotificacoes";
import Notificacoes from "./pages/notificacoes/Notificacoes";

// Noticias
import Noticias from "./pages/noticias/Noticias";
import ViewNoticias from "./pages/noticias/ViewNoticias";

// perfis
import Perfils from "./pages/perfil/Perfils";

// fatura
import Factura from "./components/factura";

// permissao
import Permissao from "./pages/permissao/Permissao";
import PermissaoFuncionario from "./pages/permissaoFuncionario/PermissaoFuncionario";

const AppRoutes = () => {
  // Pagina Privada
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext);
    //Quando usuario nao tiver acesso e quizer acessar uma pagina volta no login

    if (loading) {
      // return <div className="loading">Carregando, Aguarde...</div>;
      // return <div className="loading"></div>;
      return (
        <div id="inicio-loader">
          <div className="loader"></div>
          <div className="loading">
            <div className="bola-0">C</div>
            <div className="bola-1">W</div>
            <div className="bola-2">A</div>
            <div className="bola-1">M</div>
          </div>
        </div>
      );
    }

    if (!authenticated) {
      return <Navigate to="/login" />;
    }
    //Se tiver pode acessar
    return children;
  };
  //
  return (
    <Router>
      {/*  */}
      <AuthProvider>
        <Routes>
          {/* Home Pege */}
          <Route
            exact
            path="/"
            element={
              <Private>
                <Home />
              </Private>
            }
          />
          {/* COOPERADOR PAGES */}
          <Route
            exact
            path="/cooperadores"
            element={
              <Private>
                <Cooperador />
              </Private>
            }
          />
          <Route
            exact
            path="/addcooperadores"
            element={
              <Private>
                <Cooperador />
              </Private>
            }
          />
          <Route
            exact
            path="/editcooperador/:codigo"
            element={
              <Private>
                <Cooperador />
              </Private>
            }
          />
          <Route
            exact
            path="/viewcooperador/:codigo"
            element={
              <Private>
                <ViewCooperador />
              </Private>
            }
          />
          {/* ANIMAL PAGES */}
          <Route
            exact
            path="/animais"
            element={
              <Private>
                <Animal />
              </Private>
            }
          />
          <Route
            exact
            path="/addanimais"
            element={
              <Private>
                <Animal />
              </Private>
            }
          />
          <Route
            exact
            path="/viewanimal/:codigo"
            element={
              <Private>
                <ViewAnimal />
              </Private>
            }
          />
          <Route
            exact
            path="/editanimal/:codigo"
            element={
              <Private>
                <Animal />
              </Private>
            }
          />
          {/* FAZENDA PAGES */}
          <Route
            exact
            path="/fazendas"
            element={
              <Private>
                <Fazenda />
              </Private>
            }
          />
          <Route
            exact
            path="/addfazendas"
            element={
              <Private>
                <Fazenda />
              </Private>
            }
          />
          <Route
            exact
            path="/editfazenda/:codigo"
            element={
              <Private>
                <Fazenda />
              </Private>
            }
          />
          <Route
            exact
            path="/viewfazenda/:codigo"
            element={
              <Private>
                <Viewfazenda />
              </Private>
            }
          />
          {/* AGENDA PAGES */}
          <Route
            exact
            path="/agendas"
            element={
              <Private>
                <Agenda />
              </Private>
            }
          />
          <Route
            exact
            path="/addagendas"
            element={
              <Private>
                <Agenda />
              </Private>
            }
          />
          <Route
            exact
            path="/viewagenda/:codigo"
            element={
              <Private>
                <ViewAgenda />
              </Private>
            }
          />
          <Route
            exact
            path="/editagenda/:codigo"
            element={
              <Private>
                <Agenda />
              </Private>
            }
          />
          {/* LOTE PAGES */}
          <Route
            exact
            path="/lotes"
            element={
              <Private>
                <Lote />
              </Private>
            }
          />
          <Route
            exact
            path="/addlotes"
            element={
              <Private>
                <Lote />
              </Private>
            }
          />
          <Route
            exact
            path="/editlote/:codigo"
            element={
              <Private>
                <Lote />
              </Private>
            }
          />
          <Route
            exact
            path="/viewlote/:codigo"
            element={
              <Private>
                <ViewLote />
              </Private>
            }
          />
          {/* LEILAO PAGES */}
          <Route
            exact
            path="/leiloes"
            element={
              <Private>
                <Leilao />
              </Private>
            }
          />
          <Route
            exact
            path="/addleiloes"
            element={
              <Private>
                <Leilao />
              </Private>
            }
          />
          <Route
            exact
            path="/viewleilao/:codigo"
            element={
              <Private>
                <ViewLeilao />
              </Private>
            }
          />
          <Route
            exact
            path="/editleilao/:codigo"
            element={
              <Private>
                <Leilao />
              </Private>
            }
          />
          {/* LANCE PAGES */}
          <Route
            exact
            path="/lances"
            element={
              <Private>
                <Lance />
              </Private>
            }
          />
          <Route
            exact
            path="/viewlance/:codigo"
            element={
              <Private>
                <ViewLance />
              </Private>
            }
          />
          {/* LEILAO EXCLUSIVO PAGES */}
          <Route
            exact
            path="/exclusivos"
            element={
              <Private>
                <Exclusivo />
              </Private>
            }
          />
          <Route
            exact
            path="/addleilaoexclusivos"
            element={
              <Private>
                <Exclusivo />
              </Private>
            }
          />
          <Route
            exact
            path="/viewleilaoexclusivo/:codigo"
            element={
              <Private>
                <ViewLeilaoExclusivo />
              </Private>
            }
          />
          <Route
            exact
            path="/editleilaoexclusivo/:codigo"
            element={
              <Private>
                <Exclusivo />
              </Private>
            }
          />
          {/* SERVICO PAGES */}
          <Route
            exact
            path="/servicos"
            element={
              <Private>
                <Servico />
              </Private>
            }
          />
          <Route
            exact
            path="/addservicos"
            element={
              <Private>
                <Servico />
              </Private>
            }
          />
          <Route
            exact
            path="/viewservico/:codigo"
            element={
              <Private>
                <ViewServico />
              </Private>
            }
          />
          <Route
            exact
            path="/editservico/:codigo"
            element={
              <Private>
                <Servico />
              </Private>
            }
          />
          {/* VENDA PAGES */}
          <Route
            exact
            path="/vendas"
            element={
              <Private>
                <Venda />
              </Private>
            }
          />
          <Route
            exact
            path="/addvendas"
            element={
              <Private>
                <Venda />
              </Private>
            }
          />
          <Route
            exact
            path="/editvenda/:codigo"
            element={
              <Private>
                <Venda />
              </Private>
            }
          />
          <Route
            exact
            path="/viewvenda/:codigo"
            element={
              <Private>
                <ViewVenda />
              </Private>
            }
          />
          {/* ESPECIE PAGES */}
          <Route
            exact
            path="/especies"
            element={
              <Private>
                <Especie />
              </Private>
            }
          />
          <Route
            exact
            path="/addespecies"
            element={
              <Private>
                <Especie />
              </Private>
            }
          />
          <Route
            exact
            path="/editespecie/:codigo"
            element={
              <Private>
                <Especie />
              </Private>
            }
          />

           {/* TAGS PAGES */}
           <Route
            exact
            path="/tags"
            element={
              <Private>
                <Tag />
              </Private>
            }
          />
          <Route
            exact
            path="/addTag"
            element={
              <Private>
                <Tag />
              </Private>
            }
          />
          <Route
            exact
            path="/editTag/:codigo"
            element={
              <Private>
                <Tag />
              </Private>
            }
          />

          {/* FUNCIONARIO PAGES */}
          <Route
            exact
            path="/funcionarios"
            element={
              <Private>
                <Funcionario />
              </Private>
            }
          />{" "}
          <Route
            exact
            path="/addfuncionaio"
            element={
              <Private>
                <Funcionario />
              </Private>
            }
          />{" "}
          <Route
            exact
            path="/editfuncionaio/:codigo"
            element={
              <Private>
                <Funcionario />
              </Private>
            }
          />{" "}
          <Route
            exact
            path="/viewfornecedor/:codigo"
            element={
              <Private>
                <ViewFuncionario />
              </Private>
            }
          />
          {/* PERMISSAO PAGES */}
          <Route
            exact
            path="/permissoes"
            element={
              <Private>
                <Permissao />
              </Private>
            }
          />
          <Route
            exact
            path="/addpermissoes"
            element={
              <Private>
                <Permissao />
              </Private>
            }
          />
          <Route
            exact
            path="/addpermissoes"
            element={
              <Private>
                <Permissao />
              </Private>
            }
          />
          <Route
            exact
            path="/editPermissoes/:codigo"
            element={
              <Private>
                <Permissao />
              </Private>
            }
          />
          {/* FUNCIONARIOPERMISSAO PAGES */}
          <Route
            exact
            path="/permissaofuncionarios"
            element={
              <Private>
                <PermissaoFuncionario />
              </Private>
            }
          />
          {/*   ENTREGA    */}
          <Route
            exact
            path="/entregas"
            element={
              <Private>
                <Entrega />
              </Private>
            }
          />
          {/* FORNECEDOR */}
          <Route
            exact
            path="/fornecedores"
            element={
              <Private>
                <Fornecedor />
              </Private>
            }
          />
          <Route
            exact
            path="/addfornecedores"
            element={
              <Private>
                <Fornecedor />
              </Private>
            }
          />
          <Route
            exact
            path="/viewfornecedor/:codigo"
            element={
              <Private>
                <ViewFornecedor />
              </Private>
            }
          />
          <Route
            exact
            path="/editfornecedor/:codigo"
            element={
              <Private>
                <Fornecedor />
              </Private>
            }
          />
          {/* CATEGORIA */}
          <Route
            exact
            path="/categorias"
            element={
              <Private>
                <Categoria />
              </Private>
            }
          />
          <Route
            exact
            path="/addcategorias"
            element={
              <Private>
                <Categoria />
              </Private>
            }
          />
          <Route
            exact
            path="/viewcategoria/:codigo"
            element={
              <Private>
                <ViewCategoria />
              </Private>
            }
          />
          <Route
            exact
            path="/editcategoria/:codigo"
            element={
              <Private>
                <Categoria />
              </Private>
            }
          />


          {/* NOTICIAS PAGES */}
          <Route
            exact
            path="/noticias"
            element={
              <Private>
                <Noticias />
              </Private>
            }
          />
          <Route
            exact
            path="/addNoticias"
            element={
              <Private>
                <Noticias />
              </Private>
            }
          />
          <Route
            exact
            path="/editNoticias/:codigo"
            element={
              <Private>
                <Noticias />
              </Private>
            }
          />
          <Route
            exact
            path="/ViewNoticias/:codigo"
            element={
              <Private>
                <ViewNoticias />
              </Private>
            }
          />


          {/* PRODUTOS */}
          <Route
            exact
            path="/produtos"
            element={
              <Private>
                <Produto />
              </Private>
            }
          />
          <Route
            exact
            path="/addprodutos"
            element={
              <Private>
                <Produto />
              </Private>
            }
          />
          <Route
            exact
            path="/viewproduto/:codigo"
            element={
              <Private>
                <ViewProduto />
              </Private>
            }
          />
          <Route
            exact
            path="/editproduto/:codigo"
            element={
              <Private>
                <Produto />
              </Private>
            }
          />
          {/* LEILAO ITEM*/}
          <Route
            exact
            path="/leilaoitens/:codigo"
            element={
              <Private>
                <LeilaoItem />
              </Private>
            }
          />
          <Route
            exact
            path="/addleilaoitens"
            element={
              <Private>
                <LeilaoItem />
              </Private>
            }
          />
          <Route
            exact
            path="/viewleilaoitem/:codigo"
            element={
              <Private>
                <ViewItemLeilao />
              </Private>
            }
          />
          {/* LOTE ITEM*/}
          <Route
            exact
            path="/loteitens"
            element={
              <Private>
                <LoteItem />
              </Private>
            }
          />
          <Route
            exact
            path="/addloteitens"
            element={
              <Private>
                <LoteItem />
              </Private>
            }
          />
          <Route
            exact
            path="/viewloteitem/:codigo"
            element={
              <Private>
                <ViewLoteItem />
              </Private>
            }
          />
          {/* LEILAO ITEM EXCLUSIVO*/}
          <Route
            exact
            path="/itemexclusivos/:codigo"
            element={
              <Private>
                <LeilaoItemExclusivo />
              </Private>
            }
          />
          <Route
            exact
            path="/additemexclusivos"
            element={
              <Private>
                <LeilaoItemExclusivo />
              </Private>
            }
          />
          <Route
            exact
            path="/viewitemexclusivo/:codigo"
            element={
              <Private>
                <ViewItemLeilaoExclusivo />
              </Private>
            }
          />
          {/* COMISSAO */}
          <Route
            exact
            path="/comissoes"
            element={
              <Private>
                <Comissao />
              </Private>
            }
          />
          <Route
            exact
            path="/addcomissoes"
            element={
              <Private>
                <Comissao />
              </Private>
            }
          />
          {/* IMPOSTO */}
          <Route
            exact
            path="/impostos"
            element={
              <Private>
                <Imposto />
              </Private>
            }
          />
          <Route
            exact
            path="/addimpostos"
            element={
              <Private>
                <Imposto />
              </Private>
            }
          />
          {/* PROPRIETARIO */}
          <Route
            exact
            path="/proprietarios"
            element={
              <Private>
                <Proprietario />
              </Private>
            }
          />
          <Route
            exact
            path="/addproprietarios"
            element={
              <Private>
                <Proprietario />
              </Private>
            }
          />
          {/* MENSAGENS*/}
          <Route
            exact
            path="/mensagens"
            element={
              <Private>
                <Mensagem />
              </Private>
            }
          />
          <Route
            exact
            path="/viewmensagens/:codigo"
            element={
              <Private>
                <ViewMensagens />
              </Private>
            }
          />
          {/* CARRINHO */}
          <Route
            exact
            path="/carrinho"
            element={
              <Private>
                <Carrinho />
              </Private>
            }
          />
          <Route
            exact
            path="/viewcarrinho/:codigo"
            element={
              <Private>
                <ViewCarrinho />
              </Private>
            }
          />
          {/* CARRINHO PRODUTO*/}
          <Route
            exact
            path="/carrinhoprodutos"
            element={
              <Private>
                <CarrinhoProduto />
              </Private>
            }
          />
          <Route
            exact
            path="/viewcarrinhoproduto/:codigo"
            element={
              <Private>
                <ViewCarrinhoProduto />
              </Private>
            }
          />
          {/* TRANSPORTE */}
          <Route
            exact
            path="/transportes"
            element={
              <Private>
                <Transporte />
              </Private>
            }
          />
          <Route
            exact
            path="/addtransportes"
            element={
              <Private>
                <Transporte />
              </Private>
            }
          />
          <Route
            exact
            path="/viewtransporte/:codigo"
            element={
              <Private>
                <ViewTransporte />
              </Private>
            }
          />
          <Route
            exact
            path="/edittransporte/:codigo"
            element={
              <Private>
                <Transporte />
              </Private>
            }
          />
          {/* GRAFICO DE ANIMAIS*/}
          <Route
            exact
            path="/graficos"
            element={
              <Private>
                <CharAnimal />
              </Private>
            }
          />
          {/* GRAFICO DE VENDAS*/}
          <Route
            exact
            path="/grafico_vendas"
            element={
              <Private>
                <CharVendas />
              </Private>
            }
          />
          {/* PAIS */}
          <Route
            exact
            path="/paises"
            element={
              <Private>
                <Pais />
              </Private>
            }
          />
          <Route
            exact
            path="/addpais"
            element={
              <Private>
                <Pais />
              </Private>
            }
          />
          <Route
            exact
            path="/editpais/:codigo"
            element={
              <Private>
                <Pais />
              </Private>
            }
          />
          {/* PROVINCIA */}
          <Route
            exact
            path="/provincias"
            element={
              <Private>
                <Provincia />
              </Private>
            }
          />
          <Route
            exact
            path="/addprovincias"
            element={
              <Private>
                <Provincia />
              </Private>
            }
          />
          {/* MUNICIPIO */}
          <Route
            exact
            path="/municipios"
            element={
              <Private>
                <Municipio />
              </Private>
            }
          />
          <Route
            exact
            path="/addmunicipios"
            element={
              <Private>
                <Municipio />
              </Private>
            }
          />
          {/* MENSAGEM */}
          <Route
            exact
            path="/mensagens"
            element={
              <Private>
                <Mensagem />
              </Private>
            }
          />
          {/* USUARIOS/ VISITANTE */}
          <Route
            exact
            path="/usuarios"
            element={
              <Private>
                <Usuarios />
              </Private>
            }
          />
          <Route
            exact
            path="/addusers"
            element={
              <Private>
                <Usuarios />
              </Private>
            }
          />
          <Route
            exact
            path="/viewuser/:codigo"
            element={
              <Private>
                <viewUsuarios />
              </Private>
            }
          />
          <Route
            exact
            path="/edituser/:codigo"
            element={
              <Private>
                <Usuarios />
              </Private>
            }
          />



          {/* USUARIOS/ PARTICIPANTES */}
          {/* <Route
            exact
            path="/participantes"
            element={
              <Private>
                <Participantes />
              </Private>
            }
          /> */}

          <Route
            exact
            path="/participantes/:codigo"
            element={
              <Private>
                <Participantes />
              </Private>
            }
          />

          {/* LEILAO ITEM */}
          <Route
            exact
            path="/leilaoitens"
            element={
              <Private>
                <LeilaoItem />
              </Private>
            }
          />
          <Route
            exact
            path="/addleilaoitens"
            element={
              <Private>
                <LeilaoItem />
              </Private>
            }
          />
          {/* DISTANCIA */}
          <Route
            exact
            path="/distancias"
            element={
              <Private>
                <Distancia />
              </Private>
            }
          />
          <Route
            exact
            path="/adddistancias"
            element={
              <Private>
                <Distancia />
              </Private>
            }
          />
          {/* STOCKPRODUTO */}
          <Route
            exact
            path="/stockprodutos"
            element={
              <Private>
                <StockProduto />
              </Private>
            }
          />
          <Route
            exact
            path="/addstockprodutos"
            element={
              <Private>
                <StockProduto />
              </Private>
            }
          />
          {/* COMPROVATIVO */}
          <Route
            exact
            path="/comprovativos"
            element={
              <Private>
                <Comprovativo />
              </Private>
            }
          />
          {/* RACA */}
          <Route
            exact
            path="/racas"
            element={
              <Private>
                <Raca />
              </Private>
            }
          />
          <Route
            exact
            path="/addracas"
            element={
              <Private>
                <Raca />
              </Private>
            }
          />
          {/* NOTIFICACOES*/}
          <Route
            exact
            path="/notificacoes"
            element={
              <Private>
                <Notificacoes />
              </Private>
            }
          />
          <Route
            exact
            path="/viewnotificacoes/:codigo"
            element={
              <Private>
                <ViewNotificacoes />
              </Private>
            }
          />
          <Route
            exact
            path="/viewcomprovativo/:codigo"
            element={
              <Private>
                <ViewComprovativo />
              </Private>
            }
          />
          {/* RELATORIOS */}
          {/* RELATORIO GERAL PAGE */}
          <Route
            exact
            path="/relatorios"
            element={
              <Private>
                <RelatorioGeral />
              </Private>
            }
          />
          {/* RELATORIO ANIMAL */}
          <Route
            exact
            path="/relatorioanimais"
            element={
              <Private>
                <RelatoriosAnimal />
              </Private>
            }
          />
          <Route
            exact
            path="/relatorioanimal/:codigo"
            element={
              <Private>
                <RelatorioIndividualAnimal />
              </Private>
            }
          />
          {/* RELATORIO COOPERADOR */}
          <Route
            exact
            path="/relatoriocooperadores"
            element={
              <Private>
                <RelatoriosCooperador />
              </Private>
            }
          />
          <Route
            exact
            path="/relatoriocooperador/:codigo"
            element={
              <Private>
                <RelatorioIndividualCooperador />
              </Private>
            }
          />
          {/* RELATORIO FAZENDA */}
          <Route
            exact
            path="/relatoriofazendas"
            element={
              <Private>
                <RelatoriosFazenda />
              </Private>
            }
          />
          <Route
            exact
            path="/relatoriofazenda/:codigo"
            element={
              <Private>
                <RelatorioIndividualFazenda />
              </Private>
            }
          />
          {/* RELATORIO LOTES */}
          <Route
            exact
            path="/relatoriolotes"
            element={
              <Private>
                <RelatorioLote />
              </Private>
            }
          />
          <Route
            exact
            path="/relatoriolote/:codigo"
            element={
              <Private>
                <RelatorioIndividualLote />
              </Private>
            }
          />
          {/* RELATORIO AGENDA */}
          <Route
            exact
            path="/relatorioagendas"
            element={
              <Private>
                <RelatorioAgenda />
              </Private>
            }
          />
          <Route
            exact
            path="/relatorioagenda/:codigo"
            element={
              <Private>
                <RelatorioIndividualAgenda />
              </Private>
            }
          />
          {/* RELATORIO SERVICO */}
          <Route
            exact
            path="/relatorioservicos"
            element={
              <Private>
                <RelatorioServico />
              </Private>
            }
          />
          <Route
            exact
            path="/relatorioservico/:codigo"
            element={
              <Private>
                <RelatorioIndividualServico />
              </Private>
            }
          />
          {/* RELATORIO LANCE */}
          <Route
            exact
            path="/relatoriolances"
            element={
              <Private>
                <RelatorioLance />
              </Private>
            }
          />
          <Route
            exact
            path="/relatoriolance/:codigo"
            element={
              <Private>
                <RelatorioIndividualLance />
              </Private>
            }
          />
          {/* RELATORIO STOCK */}
          <Route
            exact
            path="/relatoriostocks"
            element={
              <Private>
                <RelatorioStock />
              </Private>
            }
          />
          <Route
            exact
            path="/relatoriostock/:codigo"
            element={
              <Private>
                <RelatorioIndividualStock />
              </Private>
            }
          />
          {/* RELATORIO PAIS */}
          <Route
            exact
            path="/relatoriopaises"
            element={
              <Private>
                <RelatorioPais />
              </Private>
            }
          />
          {/* Factura */}
          <Route
            exact
            path="/factura"
            element={
              <Private>
                <Factura />
              </Private>
            }
          />
          {/* RELATORIO LEILAO */}
          <Route
            exact
            path="/relatorioleiloes"
            element={
              <Private>
                <RelatorioLeilao />
              </Private>
            }
          />
          <Route
            exact
            path="/relatorioleilao/:codigo"
            element={
              <Private>
                <RelatorioIndividualLeilao />
              </Private>
            }
          />
          {/* RELATORIO LEILAO EXCLUSIVO */}
          <Route
            exact
            path="/relatorioleilaoexclusivos"
            element={
              <Private>
                <RelatorioLeilaoExclusivo />
              </Private>
            }
          />
          <Route
            exact
            path="/relatorioleilaoexclusivo/:codigo"
            element={
              <Private>
                <RelatorioIndividualLeilaoExclusivo />
              </Private>
            }
          />
          {/* RELATORIO PRODUTO */}
          <Route
            exact
            path="/relatorioprodutos"
            element={
              <Private>
                <RelatorioProduto />
              </Private>
            }
          />
          <Route
            exact
            path="/relatorioproduto/:codigo"
            element={
              <Private>
                <RelatorioIndividualProduto />
              </Private>
            }
          />
          {/* RELATORIO ESPECIE */}
          <Route
            exact
            path="/relatorioespecies"
            element={
              <Private>
                <RelatorioEspecie />
              </Private>
            }
          />
          {/* RELATORIO RACA */}
          <Route
            exact
            path="/relatorioracas"
            element={
              <Private>
                <RelatorioRaca />
              </Private>
            }
          />
          {/* RELATORIO TRANSPORTE */}
          <Route
            exact
            path="/relatoriotransportes"
            element={
              <Private>
                <RelatorioTransporte />
              </Private>
            }
          />
          {/* Lista Stock Produto, Animal */}
          <Route
            exact
            path="/stockProduto"
            element={
              <Private>
                <ListStockProduto />
              </Private>
            }
          />
          <Route
            exact
            path="/veiwStockProduto/:codigo"
            element={
              <Private>
                <ViewStockProduto />
              </Private>
            }
          />

          <Route
            exact
            path="/stockAnimal"
            element={
              <Private>
                <ListStockAnimal />
              </Private>
            }
          />
          {/* Perfils */}
          <Route
            exact
            path="/perfil/:email"
            element={
              <Private>
                <Perfils />
              </Private>
            }
          />
          {/* Login */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default AppRoutes;
