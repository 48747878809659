import Menu from "../../components/layout/Menu";
import ListLance from "../../components/listTable/ListLance";

const Lance = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListLance />
      </div>
      {/*  */}
    </>
  );
};

export default Lance;
