import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: true,
      text: "TOTAL DE ANIMAIS LEILUADOS POR ESPÉCIE 2009 A 2013",
    },
  },
};

const ChartAnimais = () => {
  const [data, setData] = useState({
    // labels,
    labels: ["Segunda", "Terca", "Quarta", "Quinta", "Sexta"],
    datasets: [
      {
        label: "Dataset 1",
        data: [2203, 285, 245, 32, 6, 117, 24],
        borderColor: '#424616',
        backgroundColor: ['#73d82fc9', '#acb43994', '#444911', '#7e852494', '#252706' , '#424616' , '#181a02'],
      },
      {
        label: "Dataset 2",
        data: [2203, 285, 245, 32, 6, 117, 24],
        borderColor: '#424616',
        backgroundColor: ['#73d82fc9', '#acb43994', '#444911', '#7e852494', '#252706' , '#424616' , '#181a02'],
      },
    ],
  });
  
  useEffect(() => {
    const fetchData = async () => {
      const url = "https://jsonplaceholder.typicode.com/posts/1/comments";
      const labelSet = [];
      const dataSet1 = [];
      const dataSet2 = [];
      await fetch(url)
        .then((data) => {
          console.log("Api data", data);
          const res = data.json();
          return res;
        })
        .then((res) => {
          console.log("ressss", res);
          for (const val of res) {
            dataSet1.push(val.id);
            dataSet2.push(val.postId);
            labelSet.push(val.name);
          }
          setData({
            // labels,
            labels: labelSet,
            datasets: [
              {
                label: "Dataset ID1",
                data: dataSet1,
                borderColor: '#424616',
                backgroundColor: ['#73d82fc9', '#acb43994', '#444911', '#7e852494', '#252706' , '#424616' , '#181a02'],
              },
              {
                label: "Dataset ID2",
                data: dataSet2,
                borderColor: '#424616',
                backgroundColor: ['#73d82fc9', '#acb43994', '#444911', '#7e852494', '#252706' , '#424616' , '#181a02'],
              },
            ],
          });
          console.log("arrData", dataSet1, dataSet2);
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    fetchData();
  }, []);
  //
  return (
    <div id="right-panel" className="right-panel">
      {/*  */}
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{ background: "#b46739", fontFamily:"arial", color: "#fff" }}
                >
                  <strong className="card-title">Graficos de Controle de Dados</strong>
                </div>

                <div className="col-xs-12 col-sm-12">
                  <div className="row">
                    {/* <div style={{ width: "800", height: "500" }}> */}
                    <Bar data={data} options={options} />
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- .animated --> */}
      </div>
      {/* <!-- .content --> */}

      <div className="clearfix"></div>
      {/*  */}
    </div>
  );
};

export default ChartAnimais;
