import Menu from "../../components/layout/Menu";
import ListTag from "../../components/listTable/ListTag";

const Tag = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListTag />
      </div>
      {/*  */}
    </>
  );
};

export default Tag;