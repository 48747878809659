import Menu from "../../components/layout/Menu";
import ListaPais from "../../components/listTable/ListaPais";

const Pais = () => {
    //
    return (
        <>
            <Menu />
            {/*  */}
            <div>
                <ListaPais />
            </div>
            {/*  */}
        </>
    );
};

export default Pais;
