import api from "./api";

export const getStockAnimal = async () => {
  try {
    return await api.get("ArtigoAnimal");
  } catch (error) {
    console.log(error.message);
  }
};

export const createStockAnimal = async (data) => {
  try {
    return await api.post("criar_artigoAnimal", data);
  } catch (error) {
    console.log(error.message);
  }
};

export const updateStockAnimal = async (data) => {
    try {
      return await api.put("criar_artigoAnimal", data);
    } catch (error) {
      console.log(error.message);
    }
  };
  

export const getStockAnimalCodigo = async (codigo) => {
  try {
    return await api.get(`ItemPLeilao/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getLoteAnimal = async (codigo) => {
  try {
    return await api.get(`loteFazenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};


export const getFazendaAnimal = async (codigo) => {
  try {
    return await api.get(`FazendaAnimais/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getAnimalLote = async (codigo) => {
  try {
    return await api.get(`loteAnimal/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const removeAnimalLote = async (codigo) => {
  try {
    return await api.delete(`animalLote/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
