import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import boi from "../assets/img/icon/boi.png";
import cooperativa from "../assets/img/icon/cooperativo.png";
import fazenda from "../assets/img/icon/fazenda.png";
import leilao from "../assets/img/icon/leilao.png";
import livrodevisitas from "../assets/img/icon/livro-de-visitas.png";
import valor from "../assets/img/icon/valor.png";
import vendarapida from "../assets/img/icon/venda-rapida.png";
import servicosdigitais from "../assets/img/icon/servicos-digitais.png";
import Menu from "../components/layout/Menu";
import BarHome from "../components/chart/barHome";
import CadasroProgressivo from "../components/cooperador/CadastroProgressivo";
import $ from "jquery";

// Total de Entidades
import { getCooperadores } from "../service/CooperadoresService";
import { getVisitantes } from "../service/VisitanteService";
import { getLeiloes } from "../service/LeilaoService";
import { getFazendas } from "../service/FazendaService";
import { getAgendas } from "../service/AgendaService";
import { getServicos } from "../service/ServicoService";
import { getAnimais } from "../service/AnimalService";
import { getArtigos } from "../service/VendaService";

// Multi Linguagens 

import { useState } from "react";
import { i18n } from "../Traducao/translate/i18n";

const I18N_STORAGE_KEY = "i18nextLng";

//
const Home = () => {
  const [language] = useState(localStorage.getItem(I18N_STORAGE_KEY));
  const handleSelectChange = (event) => {
    localStorage.setItem(I18N_STORAGE_KEY, event.target.value);
    window.location = window.location;
  };

  //
  useEffect(() => {
    count();
  }, []);
  const count = async () => {
    // Counter Number
    await $(".count").each(function () {
      $(this)
        .prop("Counter", 0)
        .animate(
          {
            Counter: $(this).text(),
          },
          {
            duration: 3000,
            easing: "swing",
            step: function (now) {
              $(this).text(Math.ceil(now));
            },
          }
        );
    });
  };
  //
  // cooperadores // visitantes
  const [cooperadores, setCooperadores] = useState([]);
  const [visitantes, setVisitantes] = useState([]);
  const [fazendas, setFazendas] = useState([]);
  const [leiloes, setLeiloes] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [animais, setAnimais] = useState([]);
  const [vendas, setVendas] = useState([]);

  //
  useEffect(() => {
    busca();
  }, []);

  //LISTAR
  const busca = async () => {
    try {
      const cooperador = await getCooperadores();
      setCooperadores(cooperador.data);
      const visitante = await getVisitantes();
      setVisitantes(visitante.data);
      const animal = await getAnimais();
      setAnimais(animal.data);
      const agenda = await getAgendas();
      setAgendas(agenda.data);
      const fazenda = await getFazendas();
      setFazendas(fazenda.data);
      const servico = await getServicos();
      setServicos(servico.data);
      const leilao = await getLeiloes();
      setLeiloes(leilao.data);
      const venda = await getArtigos();
      setVendas(venda.data);
    } catch (error) {
      console.log(error.message);
    }
  };
  const [verTitulo, setverTitulo] = useState("cooperador");

  function TrocarTitulo(m) {
    setverTitulo(m);
  }
  return (
    <>
      <Menu />

      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="container">
          <div className="alert alert-dark progressivo" role="alert">
            {/* Para maior esperiencia encontre o cadatstro passo passo */}
            <span
              style={{
                color: "#fff",
                fontWeight: "bolder"
              }}
            >
              {" "}
              Cadastros de Cooperador, Fazenda e Animais progressivo
            </span>
            <Link to="" >
              {" "}
              <button className="bbb"
                data-toggle="modal"
                data-target="#largeModal"
                style={{
                  // marginLeft: "430px",
                  background: "#b46739",
                  color: "#fff",
                  border: "none",
                  outline: "none",
                  // padding: "8px",
                  borderRadius: "8px",
                  cursor: "progress",
                }}
              >
                <span className="las la-plus">Cadastrar</span>
              </button>
            </Link>
          </div>
        </div>
        {/*  */}
  
      </div>

      {/* Cadastro Rapido */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              {verTitulo === "cooperador" ? (
                <h4 className="modal-title" id="largeModalLabel">
                  Cadastrar Cooperador
                </h4>
              ) : (
                ""
              )}

              {verTitulo === "fazenda" ? (
                <h4 className="modal-title" id="largeModalLabel">
                  Cadastrar Fazenda
                </h4>
              ) : (
                ""
              )}

              {verTitulo === "animal" ? (
                <h4 className="modal-title" id="largeModalLabel">
                  Cadastrar Animal
                </h4>
              ) : (
                ""
              )}
            </div>

            {/* Escolha de categorias */}
            <div className="card-body">
              <CadasroProgressivo tituloHome={TrocarTitulo} />
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* Cadastro Rapido */}

      {/* <!-- Right Panel --> */}
      <div id="right-panel" className="right-panel">
        {/* <!-- Content --> */}
        <div className="content">
          {/* <!-- Animated --> */}
          <div className="animated fadeIn">
            {/* <!-- Widgets  --> */}
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <Link to="/leiloes">
                    <div className="card-body">
                      <div className="stat-widget-five">
                        <div className="stat-icon dib flat-color-1">
                          <i className="">
                            <img src={leilao} className="icon" />
                          </i>
                        </div>

                        <div className="stat-content">
                          <div className="text-left dib">
                            <div className="stat-text">
                              {/* <span className="count" onClick={count}>
                                23
                              </span> */}
                              {leiloes.length}
                            </div>
                            <div className="stat-heading">
                              {i18n.t("messages.leilao")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <Link to="/vendas">
                    <div className="card-body">
                      <div className="stat-widget-five">
                        <div className="stat-icon dib flat-color-2">
                          <i className="">
                            <img src={vendarapida} className="icon" />
                          </i>
                        </div>
                        <div className="stat-content">
                          <div className="text-left dib">
                            {/* <div className="stat-text">
                              <span className="count">345</span>
                            </div> */}
                            {vendas.length}
                            <div className="stat-heading">
                              {i18n.t("messages.vendas")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <Link to="/cooperadores">
                    <div className="card-body">
                      <div className="stat-widget-five">
                        <div className="stat-icon dib flat-color-3">
                          <i className="">
                            <img src={cooperativa} className="icon" />
                          </i>
                        </div> 
                        <div className="stat-content">
                          <div className="text-left dib">
                            <div className="stat-text">
                              {/* <span className="count">
                            </span> */}
                              {cooperadores.length}
                            </div>
                            <div className="stat-heading">
                              {i18n.t("messages.cooperador")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <Link to="/usuarios">
                    <div className="card-body">
                      <div className="stat-widget-five">
                        <div className="stat-icon dib flat-color-4">
                          <i className="">
                            <img src={valor} className="icon" />
                          </i>
                        </div>
                        <div className="stat-content">
                          <div className="text-left dib">
                            <div className="stat-text">
                              {/* <span className="count">2986</span> */}
                              {visitantes.length}
                            </div>
                            <div className="stat-heading">
                              {i18n.t("messages.clientes")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- /Widgets --> */}
          </div>
          {/* <!-- .row --> */}
        </div>
        {/*  */}
        <div className="content">
          {/* <!-- Animated --> */}
          <div className="animated fadeIn">
            {/* <!-- Widgets  --> */}
            <div className="row">
              <div className="col-lg-3 col-md-6">
                {/* <div className="card" style={{backgroundColor: "red"}}> */}
                <div className="card">
                  <Link to="/servicos">
                    <div className="card-body">
                      <div className="stat-widget-five">
                        <div className="stat-icon dib flat-color-1">
                          <i className="">
                            <img src={servicosdigitais} className="icon" />
                          </i>
                        </div>
                        <div className="stat-content">
                          <div className="text-left dib">
                            {/* <div className="stat-text">
                            $<span className="count">9</span>
                          </div> */}
                            {servicos.length}
                            <div className="stat-heading">
                              {i18n.t("messages.servicos")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <Link to="/animais">
                    {/* <div className="card-body" style={{backgroundColor: "#686d25"}}> */}
                    <div className="card-body">
                      <div className="stat-widget-five">
                        <div className="stat-icon dib flat-color-2">
                          <i className="">
                            <img src={boi} className="icon" />
                          </i>
                        </div>
                        <div className="stat-content">
                          <div className="text-left dib">
                            {/* <div className="stat-text">
                            <span className="count">3435</span>
                          </div> */}
                            {animais.length}
                            <div className="stat-heading">
                              {i18n.t("messages.animais")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <Link to="/agendas">
                    <div className="card-body">
                      <div className="stat-widget-five">
                        <div className="stat-icon dib flat-color-3">
                          <i className="">
                            <img src={livrodevisitas} className="icon" />
                          </i>
                        </div>
                        <div className="stat-content">
                          <div className="text-left dib">
                            {/* <div className="stat-text">
                            <span className="count">19</span>
                          </div> */}
                            {agendas.length}
                            <div className="stat-heading">
                              {i18n.t("messages.agendas")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <Link to="/fazendas">
                    <div className="card-body">
                      <div className="stat-widget-five">
                        <div className="stat-icon dib flat-color-4">
                          <i className="">
                            <img src={fazenda} className="icon" />
                          </i>
                        </div>
                        <div className="stat-content">
                          <div className="text-left dib">
                            {/* <div className="stat-text">
                            <span className="count">286</span>
                          </div> */}
                            {fazendas.length}
                            <div className="stat-heading">
                              {i18n.t("messages.fazendas")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              {/* Gráficos */}
              <div className="col-lg-7 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4
                      className="descricao"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("h4.texto_grafico_home")}
                    </h4>
                    <BarHome />
                  </div>
                </div>
              </div>
              {/*  */}
              <div className="col-lg-5 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="distancia">
                      <span
                        className="cor-bovino"
                        style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                      ></span>
                      {i18n.t("span.especie_1")}
                    </div>
                    <div className="distancia">
                      <span
                        className="cor-suino"
                        style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                      ></span>
                      {i18n.t("span.especie_2")}
                    </div>
                    <div className="distancia">
                      <span
                        className="cor-caprino"
                        style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                      ></span>
                      {i18n.t("span.especie_3")}
                    </div>

                    <p className="texto-grafico">
                      {i18n.t("p.descricao_grafico_1")}
                    </p>

                    <p className="texto-grafico">
                      {i18n.t("p.descricao_grafico_2")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /Widgets --> */}

            {/* <!-- .animated --> */}
          </div>
          {/* <!-- /.content --> */}
          <div className="clearfix"></div>
        </div>
        {/* <!-- /#right-panel --> */}
      </div>
    </>
  );
};

export default Home;
