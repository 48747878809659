import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { createNoticia, getNoticiaCodigo, getNoticias, removeNoticia, updateNoticia } from "../../service/NoticiaService";
import * as messages from "../message/toastr";
import Swal from "sweetalert2";
import { i18n } from "../../Traducao/translate/i18n";
import usePagination from "../pagination/Pagination";
import { Pagination } from '@mui/material';
import { getTag } from "../../service/TagService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const I18N_STORAGE_KEY = "i18nextLng";

const ListNoticia = () => {
  // Buscar por ID
  const { codigo } = useParams();
  const [noticias, setNoticias] = useState([]);
  const [tags, setTag] = useState([]);

  //PAGINACAO
  let [page, setPage] = useState(1);
  const PER_PAGE = 5;

  const count = Math.ceil(noticias.length / PER_PAGE);
  const _DATA = usePagination(noticias, PER_PAGE);
  //PESQUISAR
  const [search, setSearch] = useState("");



  //
  useEffect(() => {
    getAllnoticias();
    getAllTag();
  }, []);

  //LISTAR
  const getAllnoticias = () => {
    getNoticias()
      .then((response) => {
        setNoticias(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllTag = () => {
    getTag()
      .then((response) => {
        setTag(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deleteNoticia = (codigo) => {
    Swal.fire({
      title: "Deseja eliminar?",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeNoticia(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllnoticias();
        });
      }
    });
  };

  //SALVAR
  const [noticia, setNoticia] = useState({
    imgUrl: "",
    // mostrar: "",
    titulo: "",
    tag: "",
    conteudo: "",
    // date: "",
  });

  const [imgUrl, setImgUrl] = useState("");
  const [conteudo, setConteudo] = useState("");

  const { titulo, tag } = noticia;
  //
  const onInputChange = (e) => {
    setNoticia({ ...noticia, [e.target.name]: e.target.value });
  };

  //Botao Salvar
  const saveUpdateNoticia = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("conteudo", conteudo);
    formData.append("titulo", titulo);
    // formData.append("mostrar", mostrar);
    formData.append("tag", tag);

    //
    if (codigo) {
      await updateNoticia(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllnoticias();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createNoticia(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllnoticias();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadNoticia();
  }, []);
  //
  const loadNoticia = () => {
    if (codigo) {
      getNoticiaCodigo(codigo)
        .then((response) => {
          setNoticia(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };
  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setImgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };

  //
  const titulo_1 = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };
  //
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
      //
      const modules = { toolbar: { container } };
  //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/addNoticias" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus">

                    </span>
                  </button>
                </Link>
                <Link to="" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="fa fa-print">Relatórios</span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {/* {i18n.t("messages.noticias")} */}
                      Notícias
                    </strong>
                  </div>
                  <div className="card-body">

                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.data")} </th>
                          <th scope="col">{i18n.t("messages.titulo")} </th>
                          <th scope="col">{i18n.t("messages.opcao")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        {_DATA
                          .currentData()
                          .filter((row) => {
                            const titulo = row.titulo
                              .toLowerCase()
                              .includes(search.toLocaleLowerCase());
                            if (search === 0) {
                              return row;
                            } else if (titulo) {
                              return row;
                            }
                          }).map((noticia) => (
                            <tr key={noticia.codigo}>
                              <td>{noticia.date}</td>
                              <td>{noticia.titulo}</td>
                              <td>
                                <Link
                                  to={`/ViewNoticias/${noticia.codigo}`}
                                  className="btn btn-primary"
                                >
                                  <span className="las la-eye"></span>
                                </Link>

                                <Link
                                  to={`/editNoticias/${noticia.codigo}`}
                                  id="event-modal"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <button
                                    type="submit"
                                    className="btn btn-warning"
                                    data-toggle="modal"
                                    data-target="#largeModal"
                                  >
                                    <span className="las la-edit"></span>
                                  </button>
                                </Link>
                                <Link
                                  to={`//${noticia.codigo}`}
                                  className="btn btn-primary"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <span className="fa fa-print"></span>
                                </Link>

                                <button
                                  type="submit"
                                  className="btn btn-danger"
                                  onClick={() => deleteNoticia(noticia.codigo)}
                                  style={{ marginLeft: "10px" }}
                                >
                                  <span className="las la-trash"></span>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <Pagination
                            count={count}
                            size="small"
                            // className="page-link"
                            page={page}
                            variant="text"
                            color="success"
                            shape="circular"
                            onChange={handleChange}
                          />
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo_1()}
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
              {/*   */}

              {/* <!-- .content --> */}

              <form
                enctype="multipart/form-data"
                onSubmit={(e) => saveUpdateNoticia(e)}
              >
                {/*  */}
                <div className="row">
                  <div className="col-6">
                    <div className="input-group">
                      <div className="preview">
                        <img id="file-ip-1-preview" />
                      </div>
                      {/* </div> */}
                      <label className="custom-btn" for="file-ip-1">
                        {i18n.t("messages.carregar_foto")}
                      </label>
                      <input
                        type="file"
                        id="file-ip-1"
                        accept="image/*"
                        name="imgUrl"
                        className="form-control cc-cvc"
                        onChange={(e) => showPreview(e)}
                        hidden
                      ></input>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <input
                        id=""
                        name="titulo"
                        type="text"
                        className="form-control cc-exp"
                        value={titulo}
                        placeholder="Titulo"
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <select
                        name="tag"
                        value={tag}
                        onChange={(e) => onInputChange(e)}
                        className="form-control cc-exp"
                      >
                        <option>Selecione o tag</option>
                        {tags.map((tag) => (
                          <option key={tag.codigo}
                            value={tag.codigo}>{tag.titulo}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-12">
                    <div className="row form-group">
                      <div className="col-12 col-md-12">
                        {/* <textarea
                          name="conteudo"
                          id="textarea-input"
                          rows="4"
                          value={conteudo}
                          onChange={(e) => onInputChange(e)}
                          placeholder="Conteudo"
                          className="form-control"
                        ></textarea> */}


                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          className='custom-quill'
                          name="conteudo"
                          value={conteudo}
                          onChange={setConteudo}
                          style={{ maxHeight: '300px' }} // Defina a altura máxima conforme necessário
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/*  */}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn"
                    data-dismiss="modal"
                    style={{
                      backgroundColor: "#b46739",
                      color: "#fff",
                    }}
                  >
                    {i18n.t("buttons.cancelar")}
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    style={{
                      backgroundColor: "#9ba239",
                      color: "#fff",
                    }}
                  >

                    {i18n.t("buttons.save")}
                  </button>
                </div>
                {/*  */}
              </form>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};
//
let container = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  // ["blockquote", "code-block"],

  // [{ header: 1 }, { header: 2 }], // custom button values
  // [{ list: "ordered" }, { list: "bullet" }],
  // [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction

  // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  // [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ["clean"], // remove formatting button
];
export default ListNoticia;
