import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  createMunicipio,
  getMunicipioCodigo,
  updateMunicipio,
} from "../../service/MunicipioService";
import * as messages from "../../components/message/toastr";
import { getProvincias } from "../../service/ProvinciaService";
import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const TabMunicipio = () => {
  const { codigo } = useParams();
  const [municipios, setMunicipios] = useState([]);

  //
  useEffect(() => {
    getAllProvincia();
  }, []);

  //LISTAR
  const getAllProvincia = () => {
    getProvincias()
      .then((response) => {
        setMunicipios(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  //SALVAR Fazenda
  const [municipio, setMunicipio] = useState({
    nome: "",
    provincia: {
      codigo: ""
    }
  });

  const { nome, provincia } = municipio;
  //'

  const [AddProvincia , setAddProvincia] = useState()
  const onInputChange = (e) => {
    setMunicipio({ ...municipio, [e.target.name]: e.target.value });
    if (e.target.name === 'provincia') {
      setAddProvincia(e.target.value)
    }
  };

  //Botao Salvar
  const saveUpdatemunicipio = async (e) => {
    e.preventDefault();

    const data = {
      nome,
      provincia:{
          codigo: AddProvincia
      }
    }

    //
    if (codigo) {
      await updateMunicipio(data)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllProvincia();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createMunicipio(data)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllProvincia();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadprovincia();
  }, []);
  //
  const loadprovincia = () => {
    if (codigo) {
      getMunicipioCodigo(codigo)
        .then((response) => {
          setMunicipios(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };

  return (
    <div>
      <form
        enctype="multipart/form-data"
        onSubmit={(e) => saveUpdatemunicipio(e)}
      >
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                id=""
                name="nome"
                type="text"
                className="form-control cc-exp"
                value={nome}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.nome")}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-group">
              <select
                data-placeholder="Selecionar..."
                className="form-control cc-exp"
                tabindex="1"
                name="provincia"
                value={provincia}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.provincia")}
              >
                <option>Selecionar provincia</option>
                {municipios.map((provincia) => (
                  <option key={provincia.codigo} value={provincia.codigo}>
                    {provincia.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span>{i18n.t("buttons.save")}</span>
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabMunicipio;
