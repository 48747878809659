import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  getCooperadorCodigo,
  getCooperadorCodigoFazenda,
} from "../../service/CooperadoresService";
import { useParams } from "react-router-dom";
import logo from "../../assets/img/logo/logo1.jpg";
import redirects from "../../components/redirect";

function App() {
  const componentRef = useRef();
  const { cooperadores } = redirects();

  const [data, setData] = useState("");
  const dataTime = () => {
    setData(new Date().toLocaleString());
  };
  const [totalTime, setTotalTime] = useState([60]);
  useEffect(() => {
    setTimeout(() => {
      setTotalTime(totalTime - 1);
    }, 1000);
    dataTime();
  }, [totalTime]);

  const { codigo } = useParams();
  const [dados, setDados] = useState([]);
  const [coopFazenda, setCoopFazendas] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Relatorios",
  });

  const busca = async () => {
    const { data } = await getCooperadorCodigo(codigo);
    setDados(data);

    const cooperadorFazenda = await getCooperadorCodigoFazenda(codigo);
    setCoopFazendas(cooperadorFazenda.data);
  };

  useEffect(() => {
    busca();
  }, []);

  return (
    <>
      <div className="Formatacao-Relatorio" ref={componentRef}>
        <div className="App_header">
          <div className="data_esq">
            <img src={logo} />
            <h6>Vagne Zau Lelo</h6>
            <small>{data}</small>
          </div>
          <div className="data_dir">
            <h6>Cooperativa Wapoma Agro-pecuária e Mineira</h6>
            <small>Huíla Lubango</small>
            <small>cooperativacwamahuila@gmail.com</small>
            <small>www.cwam.com</small>
          </div>
        </div>
        <header className="App_h1">
          <h3>Relatório do Cooperador</h3>
        </header>

        <div className="data_header">
          <div className="data_cab">
            <div className="data_esq">
              <p>Dados do Cooperador:</p>
              <p>Cod: {dados.userid}</p>
              <p>Nome: {dados.nome}</p>
              <p>Email: {dados.email}</p>
              <p>B.I: {dados.bi}</p>
            </div>
            <div className="data_esq">
              <p>Data de Nascimento: {dados.nascimento}</p>
              <p>Telefone: {dados.telefone}</p>
              <p>Endereço: {dados.endereco}</p>
              <p>Gênero: {dados.gender}</p>
              <p>Website: {dados.website}</p>
            </div>
          </div>

          <div className="App_body">
            <div className="table_data">
              <table className="table">
                <thead>
                  <th className="th">Codigo</th>
                  <th className="th">Fazenda</th>
                  <th className="th">NIF</th>
                  <th className="th">Área</th>
                  <th className="th">Nº de Seguro Social</th>
                  <th className="th">Localização</th>
                </thead>
                {coopFazenda.map((row) => (
                  <tbody key={row.codigo}>
                    <tr className="tr">
                      <td className="td">{row.codigo}</td>
                      <td className="td">{row.nome}</td>
                      <td className="td">{row.nif}</td>
                      <td className="td">{row.area} Ha</td>
                      <td className="td">{row.ses}</td>
                      <td className="td">{row.municipio?.nome}</td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="data_funny">
        <button onClick={cooperadores} className="btn botao_return">
          <span className="fa fa-reply"></span>
        </button>
        <button onClick={handlePrint} className="btn botao_print">
          <span className="fa fa-print"></span>
        </button>
      </div>
    </>
  );
}

export default App;
