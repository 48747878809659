import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { getParticipantesAceitesCodigo } from "../../service/ParticipanteService";
import * as messages from "../message/toastr";
import Swal from "sweetalert2";
import { i18n } from '../../Traducao/translate/i18n';

const I18N_STORAGE_KEY = 'i18nextLng';

const ListParticipantes = () => {
    const { codigo } = useParams();
    const [participantes, setParticipantes] = useState([]);
    //
    useEffect(() => {
        participantesAceites();
    }, []);

    //LISTAR PARTICIPANTES ACEITES PARA O LEILAO
    const participantesAceites = () => {
        getParticipantesAceitesCodigo(codigo)
            .then((response) => {
                setParticipantes(response.data);
            })
            .catch((error) => {
                messages.mensagemErro(error);
            });
    };

    //APAGAR
    // const deleteVisitantes = (codigo) => {
    //     Swal.fire({
    //         title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
    //         text: "Realmente deseja eliminar!",
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#d33",
    //         confirmButtonText: "Sim, eliminar!",
    //         cancelButtonText: "Cancelar",
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             removeVisitante(codigo).then((response) => {
    //                 Swal.fire("Eliminado!", "Eliminado.", "success");
    //                 getAllVisitantes();
    //             });
    //         }
    //     });
    // };
    //

    const titulo = () => {
        if (codigo) {
            return <h3 style={{ color: "#fff" }}> {i18n.t('messages.atualizar')} </h3>;
        } else {
            return <h3 style={{ color: "#fff" }}> {i18n.t('messages.cadastrar')} </h3>;
        }
    };

    //
    return (
        <>
            <Menu />
            <div id="right-panel" className="right-panel">
                {/*  */}
                <div className="breadcrumbs">
                    <div className="breadcrumbs-inner">
                        <div className="row m-0">
                            <div className="col-xs-6 col-sm-6">
                                <form className="d-flex">
                                    <input
                                        className="form-control me-2"
                                        type="search"
                                        placeholder={i18n.t('messages.Pesquisar_por_nome')}
                                        aria-label="Search"
                                    />
                                    <button className="btn btn-outline-success" type="submit">
                                        {i18n.t('buttons.pesquisar')}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}

                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div
                                        className="card-header dd-eri"
                                        style={{ background: "#b46739", color: "#fff" }}
                                    >
                                        <strong className="card-title" id="s-1">
                                            Participantes Aceites para o Leilão
                                        </strong>

                                        <Link to={`/viewleilao/${codigo}`} id="event-modal">
                                            <strong class1Name="card-title" id="s-2"
                                                style={{
                                                    color: "#fff",
                                                }}
                                            >
                                                Todos os Pedidos
                                            </strong>
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-striped responsiveTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        {i18n.t('messages.data')}
                                                    </th>

                                                    <th scope="col">
                                                        {i18n.t('messages.nome')}
                                                    </th>
 
                                                    <th scope="col">
                                                        Licitador
                                                    </th>
 
                                                    <th scope="col">
                                                        {i18n.t('messages.status')}
                                                    </th>
                                                    {/* <th scope="col">
                                                        {i18n.t('messages.opcao')}
                                                    </th> */}
                                                </tr> 
                                            </thead>
                                            <tbody> 
                                                {participantes.map((aceite) => (
                                                    <tr key={aceite.codigo}>
                                                        <td data-label="Data:">{aceite.data}</td>
                                                        <td data-label="Nome:">{aceite.participantePendente.visitante.nome}</td>
                                                        <td data-label="Licitador:">{aceite.participantePendente.visitante.visitanteid}</td>  
                                                        <td data-label="Estado:"> <button id="estado-aprovado"> {aceite.participantePendente.status} </button> </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/*  */}
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-end">
                                                <li className="page-item disabled">
                                                    <a
                                                        className="page-link"
                                                        href="#"
                                                        tabindex="-1"
                                                        aria-disabled="true"
                                                    >
                                                        Previous
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#">
                                                        1
                                                    </a> 
                                                </li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#">
                                                        2
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#">
                                                        3
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#">
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                        {/*  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- .animated --> */}
                </div>
                {/* <!-- .content --> */}

                <div className="clearfix"></div>
                {/*  */}
            </div>
        </>
    );
};

export default ListParticipantes;
