import api from "./api";

export const getPermissoes = async () => {
  try {
    return await api.get("Permissaos");
  } catch (error) {
    console.log(error.message);
  }
};
export const createPermissao = async (data) => {
  try {
    return await api.post("Permissao", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getPermissaoCodigo = async (codigo) => {
  try {
    return await api.get(`Permissao/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updatePermissao = async (codigo, data) => {
  try {
    return await api.put(`Permissao/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removePermissao = async (codigo) => {
  try {
    return await api.delete(`Permissao/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
