import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { getMensanges } from "../../service/MensagensService";
import * as messages from "../message/toastr";

import Swal from "sweetalert2";

import { i18n } from "../../Traducao/translate/i18n";

const I18N_STORAGE_KEY = "i18nextLng";

const ListMensagens = () => {
  const { codigo } = useParams();
  const [mensagens, setMensagens] = useState([]);

  //
  useEffect(() => {
    getAllMensagens();
  }, []);

  //LISTAR
  const getAllMensagens = () => {
    getMensanges()
      .then((response) => {
        setMensagens(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                  {/* <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button> */}
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.mensagens")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.nome")}</th>

                          <th scope="col">{i18n.t("messages.email")}</th>

                          <th scope="col">{i18n.t("messages.assunto")}</th>

                          <th scope="col">{i18n.t("messages.menssagem")}</th>

                          {/* 
                                                    <th scope="col">
                                                        {i18n.t('messages.opcao')}
                                                    </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {mensagens.map((mensagem) => (
                          <tr key={mensagem.codigo}>
                            <td>{mensagem.nome}</td>
                            <td>{mensagem.email}</td>
                            <td>{mensagem.assunto}</td>
                            <td>{mensagem.messagem}</td>
                            <td>
                              <Link
                                to={`/viewmensagens/${mensagem.codigo}`}
                                className="btn btn-primary"
                              >
                                <span className="las la-eye"></span>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}

      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListMensagens;
