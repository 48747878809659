import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { createFazenda, updateFazenda } from "../../service/FazendaService";
import {
  getCooperadorCodigo,
  getCooperadores,
} from "../../service/CooperadoresService";
import { getPaises } from "../../service/PaisService";
import { getProvinciaPais } from "../../service/ProvinciaService";
import { getMunicipioProvinca } from "../../service/MunicipioService";
import * as messages from "../../components/message/toastr";
import { i18n } from "../../Traducao/translate/i18n";

import * as yup from "yup";

const I18N_STORAGE_KEY = "i18nextLng";

const TabFazenda = () => {
  const { codigo } = useParams();
  const [cooperadores, setCooperadores] = useState([]);

  //
  useEffect(() => {
    getAllCooperador();
  }, []);

  //LISTAR
  const getAllCooperador = () => {
    getCooperadores()
      .then((response) => {
        setCooperadores(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  //SALVAR Fazenda
  const [Fazendas, setFazendas] = useState({
    logo: "",
    nome: "",
    municipio: "",
    area: "",
    data: "",
    nif: "",
    ses: "",
    cooperador: "",
  });



  const [logo, setlogo] = useState("");

  const { nome, municipio, area, nif, data, ses, cooperador } = Fazendas;
  //

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //

  const onInputChange = (e) => {
    setFazendas({ ...Fazendas, [e.target.name]: e.target.value });
  };

  const [paises, setPaises] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  useEffect(() => {
    getAllPais();
  }, []);

  //LISTAR PAIS, PROVINCIA MUNICIPIO
  const getAllPais = () => {
    getPaises()
      .then((response) => {
        setPaises(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllProvincia = (e) => {
    getProvinciaPais(e.target.value)
      .then((response) => {
        setProvincias(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllMunicipio = (e) => {
    getMunicipioProvinca(e.target.value)
      .then((response) => {
        setMunicipios(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //Botao Salvar
  const saveUpdateFazenda = async (e) => {
    e.preventDefault();


    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("logo", logo);
    formData.append("nome", nome);
    formData.append("municipio", municipio);
    formData.append("area", area);
    formData.append("nif", nif);
    formData.append("data", data);
    formData.append("ses", ses);
    formData.append("cooperador", cooperador);

    //
    if (codigo) {
      await updateFazenda(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllCooperador();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createFazenda(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllCooperador();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadCooperador();
  }, []);
  //
  const loadCooperador = () => {
    if (codigo) {
      getCooperadorCodigo(codigo)
        .then((response) => {
          setCooperadores(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };
  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setlogo(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };
  //
  //

  async function validate() {
    let schema = yup.object().shape({

      municipio: yup
        .string("Selecione o País, Província e Município da Fazenda!")
        .required("Selecione o País, Província e Município da Fazenda!"),

      ses: yup
        .string("Adicione o Nº de seguro Social da Fazenda!")
        .required("Adicione o Nº de seguro Social da Fazenda!"),

      nif: yup
        .string("Adicione o NIF da Fazenda!")
        .required("Adicione o NIF da Fazenda!"),

      data: yup
        .string("Adicione a data de Fundação da Fazenda!")
        .required("Adicione a data de Fundação da Fazenda!"),

      area: yup
        .string("Adicione uma Área a Fazenda!")
        .required("Adicione uma Área a Fazenda!"),

      cooperador: yup
        .string("Selecionar um Cooperador é obrigatório!")
        .required("Selecionar um Cooperador é obrigatório!"),

      nome: yup
        .string("O campo nome na Fazenda é obrigatório!")
        .required("O campo nome na Fazenda é obrigatório!"),

    });
    try {
      await schema.validate(Fazendas);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //

  return (
    <div>
      <form
        enctype="multipart/form-data"
        onSubmit={(e) => saveUpdateFazenda(e)}
      >

        {status.type === "error" ? (
          <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
        ) : (
          ""
        )}

        {/* ANIMAL */}

        <div className="row">
          <div className="col-6">
            <div className="input-group">
              <div className="preview">
                <img id="file-ip-1-preview" />
              </div>
              {/* </div> */}
              <label className="custom-btn" for="file-ip-1">
                {i18n.t("messages.carregar_foto")}
              </label>
              <input
                type="file"
                id="file-ip-1"
                accept="image/*"
                name="logo"
                className="form-control cc-cvc"
                hidden
                onChange={(e) => showPreview(e)}
              ></input>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <input
                id=""
                name="nome"
                type="text"
                className="form-control cc-exp"
                value={nome}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.fazenda")}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="input-group">
              <select
                data-placeholder="Selecionar..."
                className="form-control cc-exp"
                tabindex="1"
                name="cooperador"
                value={cooperador}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.cooperador")}
              >
                <option>Selecionar Cooperador</option>
                {cooperadores.map((cooperador) => (
                  <option key={cooperador.codigo} value={cooperador.codigo}>
                    {cooperador.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <input
                id=""
                name="area"
                type="text"
                className="form-control cc-exp"
                value={area}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.area")}
              />
            </div>
          </div>
        </div>

        {/*  */}
        <div className="row">
          <div className="col-4">
            <div className="input-group">
              <input
                id=""
                name="data"
                type="date"
                className="form-control cc-exp"
                value={data}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.fundacao_fazenda")}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="input-group">
              <input
                id=""
                name="nif"
                type="text"
                className="form-control cc-exp"
                value={nif}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.nif")}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <input
                id=""
                name="ses"
                type="text"
                className="form-control cc-exp"
                value={ses}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.seguro_social")}
              />
            </div>
          </div>
        </div>

        <div className="card-body">
          <div className="row">
            <div classNmae="col-4">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar pais..."
                  className="form-control cc-exp"
                  tabindex="1"
                  name="pais"
                  // value={pais}
                  onChange={(e) => getAllProvincia(e)}
                >
                  <option
                    value=""
                    label={i18n.t("messages.selecionar_pais")}
                  ></option>
                  {paises.map((pais) => (
                    <option key={pais.codigo} value={pais.codigo}>
                      {pais.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-4">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar pais..."
                  className="form-control cc-exp"
                  tabindex="1"
                  name="provincia"
                  onChange={(e) => getAllMunicipio(e)}
                >
                  <option
                    value=""
                    label={i18n.t("messages.selecionar_provincia")}
                  ></option>
                  {provincias.map((provincia) => (
                    <option key={provincia.codigo} value={provincia.codigo}>
                      {provincia.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-4">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar municipio..."
                  className="form-control cc-exp"
                  tabindex="1"
                  name="municipio"
                  value={municipio}
                  onChange={(e) => onInputChange(e)}
                >
                  <option
                    value=""
                    label={i18n.t("messages.selecionar_municipio")}
                  ></option>
                  {municipios.map((municipio) => (
                    <option key={municipio.codigo} value={municipio.codigo}>
                      {municipio.nome}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span>{i18n.t("buttons.save")}</span>
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabFazenda;
