import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

import logo from "../../../assets/img/logo/logo.jpg";
import logo7 from "../../../assets/img/avatar/7.png";

import { AuthContext } from "../../../contexts/Auth";
import $ from "jquery";

import { getMensanges } from "../../../service/MensagensService";

import { getNotificacoes } from "../../../service/NotificacoesService";

import { } from "../../../service/LanceService";

import * as messages from "../../message/toastr";

import { i18n } from "../../../Traducao/translate/i18n";
import {
  encontrarFuncionario,
  getPerfilFuncionario,
} from "../../../service/LoginService";

const I18N_STORAGE_KEY = "i18nextLng";

const HeaderMenu = () => {
  const [language] = useState(localStorage.getItem(I18N_STORAGE_KEY));
  const handleSelectChange = (event) => {
    localStorage.setItem(I18N_STORAGE_KEY, event.target.value);
    window.location = window.location;
  };

  const [hamburgerOpen, sethamburgerOpen] = useState(false);
  const toggleHamburger = () => {
    $("#menuToggle").on("click", function (event) {
      var windowWidth = $(window).width();
      if (windowWidth < 1010) {
        $("body").removeClass("open");
        if (windowWidth < 760) {
          $("#left-panel").slideToggle();
        } else {
          $("#left-panel").toggleClass("open-menu");
        }
      } else {
        $("body").toggleClass("open");
        $("#left-panel").removeClass("open-menu");
      }
    });
    $(".menu-item-has-children.dropdown").each(function () {
      $(this).on("click", function () {
        var $temp_text = $(this).children(".dropdown-toggle").html();
        $(this)
          .children(".sub-menu")
          .prepend('<li className="subtitle">' + $temp_text + "</li>");
      });
    });
  };

  const [mensagens, setMensagens] = useState([]);

  // Mensagens
  useEffect(() => {
    getAllMensagens();
  }, []);

  //LISTAR
  const getAllMensagens = async () => {
    await getMensanges()
      .then((response) => {
        setMensagens(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  // Lances // Notificações
  const [notificacoes, setNotificacoes] = useState([]);

  //
  useEffect(() => {
    getAllNotificacoes();
  }, []);

  //LISTAR
  const getAllNotificacoes = () => {
    getNotificacoes()
      .then((response) => {
        setNotificacoes(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //PERFIL LOGOUT
  //
  //~
  const { user, logout } = useContext(AuthContext);
  const { email } = useParams();

  const logado = user?.codigo;

  const [VerFuncionario, setVerFuncionario] = useState([]);
  const [VerFuncionarioCodigo, setVerFuncionarioCodigo] = useState([]);

  //
  useEffect(() => {
    PerfilFuncionario();
    dadosFuncionario();
  });

  const PerfilFuncionario = async () => {
    await getPerfilFuncionario(logado).then((response) => {
      setVerFuncionarioCodigo(response.data);
    });
  };

  const dadosFuncionario = () => {
    if (email) {
      encontrarFuncionario(email).then((response) => {
        setVerFuncionario(response.data);
        console.log(response.data);
      });
    }
  };

  //Logout
  const handleLogout = () => {
    logout();
  };

  return (
    // <!-- Right Panel -->

    <div id="right-panel" className="right-panel">
      {/* <!-- Header--> */}
      <header id="header" className="header">
        <div className="top-left">
          <div className="navbar-header">
            {/* <a className="navbar-brand" href="./"><img src={logo} alt="Logo" /></a> */}
            {/* <a className="navbar-brand hidden" href="./"><img src={logo} alt="Logo" width={50} height={50}/></a> */}
            <a id="menuToggle" className="menutoggle" onClick={toggleHamburger}><i className="fa fa-bars"></i></a>
          </div>
        </div>
        <div className="top-right">
          <div className="header-menu">
            <div className="header-left">
              {/*  */}
              <div className="dropdown for-notification">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="notification" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-bell"></i>
                  <span className="bg-danger">{notificacoes.length}</span>
                </button>

                <div className="dropdown-menu" aria-labelledby="notification">
                  {notificacoes.length === 0 ? <p className="red">Sem Notificações</p>
                    :
                    ""}
                  {notificacoes.map((notificacao) => (
                    <Link
                      to={`/viewnotificacoes/${notificacao.codigo}`} className="dropdown-item media" key={notificacao.codigo}>
                      <i className="fa fa-check"></i>
                      <p>{notificacao.title}</p>
                    </Link>

                  ))}

                </div>
              </div>

              <div className="dropdown for-message">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="message" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-envelope"></i>
                  <span className="bg-primary">{mensagens.length}</span>
                </button>

                <div className="dropdown-menu" aria-labelledby="message">
                  {mensagens.length === 0 ? (
                    <p className="red">Sem Mensagens</p>

                  ) : (
                    ""
                  )}
                  {mensagens.map((mensagem) => (
                    <span>
                      <Link
                        to={`/mensagem/ViewMensagens/${mensagem.codigo}`} className="dropdown-item media">
                        <div className="message media-body">
                          <span className="name float-left">{mensagem.nome}</span>
                          <span className="time float-right">{mensagem.email}</span>
                        </div>
                      </Link>
                    </span>
                  ))}

                </div>
              </div>
            </div>

            <div className="user-area dropdown float-right">
              <a href="#" className="dropdown-toggle active" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img
                  className="user-avatar rounded-circle"
                  src={logo7}
                  // src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirImagemFuncionario/${VerFuncionarioCodigo.imgUrl}`}
                  alt="User Avatar"
                />
              </a>

              <div className="user-menu dropdown-menu">
                {/* <Link className="nav-link" to={`/perfil/${user?.email}`}>
                  <i className="fa fa- user"></i>{" "}
                  {i18n.t("messages.meu_perfil")}
                </Link> */}

                <Link className="nav-link" to="" onClick={handleLogout}>
                  <i className="fa fa-power -off"></i> {i18n.t("messages.terminar")}
                </Link>
                <div className="form-group">
                  <select
                    className="form-control"
                    onChange={handleSelectChange}
                    value={language}
                  >
                    <option value="pt-BR">PT</option>
                    <option value="en-US">EN</option>
                    <option value="fr-FR">FR</option>
                  </select>
                </div>.
              </div>
            </div>

          </div>
        </div>
      </header>
      {/* <!-- /#header -->  */}
    </div>
  );
};

export default HeaderMenu;
