import Menu from "../../components/layout/Menu";
import FormPerfil from "../../components/perfil/FormPerfil";

const Perfils = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <FormPerfil />
      </div>
      {/*  */}
    </>
  );
};

export default Perfils;
