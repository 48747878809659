import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  createServico,
  getServicoCodigo,
  updateServico,
} from "../../service/ServicoService";
import * as messages from "../../components/message/toastr";

import { i18n } from "../../Traducao/translate/i18n";
import * as yup from "yup";

const I18N_STORAGE_KEY = "i18nextLng";

const TabServicos = (props) => {
  // Buscar por ID
  const { codigo } = useParams();
  //SALVAR
  const [servico, setServico] = useState({
    imgUrl: "",
    titulo: "",
    descricao: "",
    data: "",
  });
  const [imgUrl, setImgUrl] = useState("");

  const { descricao, titulo, data } = servico;
  //
  const handleClear = () => {
    setServico({
      imgUrl: "",
      titulo: "",
      descricao: "",
      data: "",
    })
  }

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //

  //
  const onInputChange = (e) => {
    setServico({ ...servico, [e.target.name]: e.target.value });
  };
  //Botao Salvar
  const saveUpdateServico = async (e) => {
    e.preventDefault();

    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("titulo", titulo);
    formData.append("descricao", descricao);
    formData.append("data", data);
    //
    if (codigo) {
      await updateServico(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          // retrievecooperadores();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createServico(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          // retrievecooperadores();
          handleClear();
          props.VerOsServicos()
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadServico();
  }, []);
  //
  const loadServico = () => {
    if (codigo) {
      getServicoCodigo(codigo)
        .then((response) => {
          setServico(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };
  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setImgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };
  //

  async function validate() {
    let schema = yup.object().shape({


      // imgUrl: yup
      //   .string("Carrege uma imagem!")
      //   .required("Carrege uma imagem!"),
      descricao: yup
        .string("Adicione uma descrição ao Serviço!")
        .required("Adicione uma descrição ao Serviço!"),

      titulo: yup
        .string("Preencher o Título é obrigatório!")
        .required("Preencher o Título é obrigatório!"),

      data: yup
        .string("Adicione a data de Realização do Serviço!")
        .required("Adicione a data de Realização do Serviço!"),


    });
    try {
      await schema.validate(servico);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //

  return (
    <div>
      <form
        enctype="multipart/form-data"
        onSubmit={(e) => saveUpdateServico(e)}
      >
        {status.type === "error" ? (
          <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-6">
            <div className="input-group">
              <div className="preview">
                <img id="file-ip-1-preview" />
              </div>
              <label className="custom-btn" for="file-ip-1">
                {i18n.t("messages.carregar_foto")}
              </label>
              <input
                type="file"
                id="file-ip-1"
                accept="image/*"
                name="imgUrl"
                className="form-control cc-cvc"
                onChange={(e) => showPreview(e)}
                hidden
              ></input>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-6">
            <div className="input-group">
              <input
                id=""
                name="data"
                type="date"
                className="form-control cc-exp"
                value={data}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.data")}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="input-group">
              <input
                id=""
                name="titulo"
                type="text"
                className="form-control cc-exp"
                placeholder={i18n.t("messages.titulo")}
                value={titulo}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
        </div>

        <br></br>

        <div className="row">
          <div className="col-12">
            <div className="row form-group">
              <div className="col-12 col-md-12">
                <textarea
                  name="descricao"
                  id="textarea-input"
                  rows="6"
                  value={descricao}
                  onChange={(e) => onInputChange(e)}
                  placeholder={i18n.t("messages.descricao")}
                  className="form-control"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span>{i18n.t("buttons.save")}</span>
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabServicos;
