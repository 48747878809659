import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getArtigoCodigo } from "../../service/VendaService";
// import CooperadoresService from "../../service/CooperadoresService";

import Menu from "../../components/layout/Menu";

const ViewVenda = (props) => {
  //
  const [venda, setVenda] = useState({
    lote: "",
    preco: "",
    imposto: "",
    comissao: "",
    totalDescontoIva: "",
    totalCooperativa: "",
    totalValorCrescinto: "",
    totalCooperador: "",
    totalValorIva: "",
    totalSaldo: "",
    dataArtigo: "",
    artigoId: "",
    vendido: "",
  });

  const { codigo } = useParams();

  //
  useEffect(() => {
    ViewVendas();
  }, []);
  //
  const ViewVendas = () => {
    if (codigo) {
      getArtigoCodigo(codigo).then((response) => {
        setVenda(response.data);
      });
    }
  }; 

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">
                <Link
                  to="/vendas"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="las la-plus">Voltar</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">Dados do Stock</strong>
                  </div>
                  <div className="card-body">
                    {/*  */}

                    <div className="card" style={{ width: "100%" }}>
                      <ul className="list-group list-group-flush">

                        <li className="list-group-item">
                          <strong>Data de Cadastro:</strong>
                          {venda.dataArtigo}
                        </li>

                        <li className="list-group-item">
                          <strong>Lote:</strong> {venda.lote.nome}
                        </li>

                        {/* <li className="list-group-item">
                          <strong>Fazenda: </strong>
                          {venda.lote.fazenda.nome}
                        </li> */}

                        <li className="list-group-item">
                          <strong>Estado: </strong>
                          {venda.lote.loteVendaLeilao}
                        </li>

                        {/* <li className="list-group-item">
                          <strong>Espécie: </strong>
                          {venda.lote.especie.especieAnimal}
                        </li> */}


                        <li className="list-group-item">
                          <strong>Preço:</strong> {venda.preco} Kz
                        </li>

                        <li className="list-group-item">
                          <strong>Tipo de Imposto:</strong>{" "}
                          {venda.imposto.descricao}
                        </li>
                        <li className="list-group-item">
                          <strong>IVA:</strong> {venda.imposto.iva}%
                        </li>
                        <li className="list-group-item">
                          <strong>Comissão:</strong> {venda.comissao.quota}%
                        </li>

                        <li className="list-group-item">
                          <strong>Total de Desconto com IVA:</strong> {venda.totalDescontoIva}Kz
                        </li>

                        <li className="list-group-item">
                          <strong>Total de IVA:</strong> {venda.totalValorIva}Kz
                        </li>

                        <li className="list-group-item">
                          <strong>Total da Cooperativa:</strong>{" "}
                          {venda.totalCooperativa}Kz
                        </li>

                        <li className="list-group-item">
                          <strong>Preço do Venda:</strong>{" "}
                          {venda.totalValorCrescinto}Kz
                        </li>
                      </ul>
                    </div>

                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}
    </>
  );
};

export default ViewVenda;
