import api from "./api";

export const getCarrinhoProdutos = async () => {
  try {
    return await api.get("CarrinhoProduto");
  } catch (error) {
    console.log(error.message);
  }
};
export const createCarrinhoProduto = async (data) => {
  try {
    return await api.post("criar_carrinhoProduto", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getCarrinhoProdutoCodigo = async (codigo) => {
  try {
    return await api.get(`carrinhoProduto/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getCarrinhoProdutoVisitante = async (codigo) => {
  try {
    return await api.get(`carrinhoProduto_visitante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getTotalProdutosCart = async (codigo) => {
  try {
    return await api.get(`carrinhoProduto/visitante=${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getTotalAnimaisCart = async (codigo) => {
  try {
    return await api.get(`carrinhoProduto/visitante=${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateCarrinhoProduto = async (data) => {
  try {
    return await api.put("carrinhoProduto", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const findByNome = async (nome) => {
  try {
    return await api.get(`?nome=${nome}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeCarrinhoProduto = async (codigo) => {
  try {
    return await api.delete(`${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
