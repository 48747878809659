import api from "./api";

export const getAgendas = async () => {
  try {
    return await api.get("Agenda");
  } catch (error) {
    console.log(error.message);
  }
};
export const createAgenda = async (data) => {
  try {
    return await api.post("criar_agenda", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getAgendaCodigo = async (codigo) => {
  try {
    return await api.get(`agenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateAgenda = async (data) => {
  try {
    return await api.put("agenda", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeAgenda = async (codigo) => {
  try {
    return await api.delete(`agenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getImagem = async (imagem) => {
  try {
    return await api.get(`exibirImagemAgenda/${imagem}`);
  } catch (error) {
    console.log(error.message);
  }
};
