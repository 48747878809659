import api from "./api";

export const getPaises = async () => {
  try {
    return await api.get("Pais");
  } catch (error) {
    console.log(error.message);
  }
};
export const createPais = async (data) => {
  try {
    return await api.post("criar_pais", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getPaisCodigo = async (codigo) => {
  try {
    return await api.get(`pais/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updatePais = async (codigo, data) => {
  try {
    return await api.put(`pais/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removePais = async (codigo) => {
  try {
    return await api.delete(`pais/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
