import Menu from "../../components/layout/Menu";
import ListComissao from "../../components/listTable/ListComissao";

const Comissao = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListComissao />
      </div>
      {/*  */}
    </>
  );
};

export default Comissao;