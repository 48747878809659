import api from "./api";

export const getNotificacoes = async () => {
  try {
    return await api.get("Notifications");
  } catch (error) {
    console.log(error.message);
  }
};
export const getNotificacaoCodigo = async (codigo) => {
  try {
    return api.get(`Notifications/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeNotificacao = async (codigo) => {
  try {
    return api.delete(`notificacaos/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
