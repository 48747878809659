import Menu from "../../components/layout/Menu";
import ListCooperador from "../../components/listTable/ListCooperador";

const Cooperador = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListCooperador />
      </div>
      {/*  */}
    </>
  );
};

export default Cooperador;
