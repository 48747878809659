import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getAnimalCodigo } from "../../service/AnimalService";
import Menu from "../../components/layout/Menu";

const ViewAnimal = (props) => {
  const [animal, setAnimal] = useState({
    imgUrl: "",
    peso: "",
    especie: "",
    raca: "",
    pais: "",
    sexoAnimal: "",
    tempoDeDuracao: "",
    animalId: "",
    fazenda: "",
  });

  const { codigo } = useParams();
  useEffect(() => {
    viewAnimal();
  }, []);

  const viewAnimal = () => {
    if (codigo) {
      getAnimalCodigo(codigo).then((response) => {
        setAnimal(response.data);
      });
    }
  };

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">
                <Link
                  to="/animais"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="fa fa-reply"></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">Fixa de Informações do Animal</strong>
                  </div>

                  <div className="col-xs-12 col-sm-12">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="card">
                          <div className="card-body">
                            <img

                              src={`${animal.imgUrl}`}
                              className="foto-animal"
                            />

                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="card">
                          {/* <div className="card" style="width: 100%"> */}
                          <div className="card" style={{ width: "100%" }}>
                            <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <strong>Fazenda:</strong> {animal.fazenda.nome}
                              </li>
 
                              <li className="list-group-item">
                                <strong>Peso:</strong> {animal.peso} Kg
                              </li>
                              {/* <li className="list-group-item">
                                <strong>Especie:</strong> {animal.raca.especie.especieAnimal}
                              </li> */}
                              <li className="list-group-item">
                                <strong>Raça:</strong> {animal.raca.racaAnimal}
                              </li>
                              { <li className="list-group-item">
                                <strong>País de Origem:</strong> {animal.pais.nome}
                              </li> }
                              <li className="list-group-item">
                                <strong>Sexo:</strong> {animal.sexoAnimal}
                              </li>
                              <li className="list-group-item">
                                <strong>Código:</strong> {animal.animalId}
                              </li> 
                            
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}
    </>
  );
};

export default ViewAnimal;
