import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../../components/layout/Menu";
import { getFazendas, removeFazenda } from "../../service/FazendaService";
import * as messages from "../../components/message/toastr";
import TabFazenda from "../tab/TabFazenda";
import Swal from "sweetalert2";
import { i18n } from "../../Traducao/translate/i18n";
import usePagination from "../pagination/Pagination";
import { Pagination } from '@mui/material';
const I18N_STORAGE_KEY = "i18nextLng";

const ListFazenda = () => {
  const { codigo } = useParams();
  const [fazendas, setFazendas] = useState([]);

   //PAGINACAO
   let [page, setPage] = useState(1);
   const PER_PAGE = 5;
 
   const count = Math.ceil(fazendas.length / PER_PAGE);
   const _DATA = usePagination(fazendas, PER_PAGE);
   //PESQUISAR
   const [search, setSearch] = useState("");
 

  useEffect(() => {
    getAllFazenda();
  }, []);

  //LISTAR

  const getAllFazenda = async () => {
    try {
      const { data } = await getFazendas();
      setFazendas(data);
    } catch (error) {
      messages.mensagemErro(error);
    }
  };

  //APAGAR
  const deleteFazendas = (codigo) => {
    Swal.fire({
      // title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeFazenda(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllFazenda();
        });
      }
    });
  };

  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };
    //
    const handleChange = (e, p) => {
      setPage(p);
      _DATA.jump(p);
    };
    //

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/addfazendas" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus"></span>
                  </button>
                </Link>
                {/*  */}
                <Link to="/relatoriofazendas" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="fa fa-print"></span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
            
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.fazendas")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th className="th">Imagem</th>
                          <th scope="col">{i18n.t("messages.fazendas")}</th>
                          <th scope="col">{i18n.t("messages.localizacao")}</th>
                          <th scope="col">{i18n.t("messages.area")}</th>
                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {_DATA
                          .currentData()
                          .filter((row) => {
                            const nome = row.nome
                              .toLowerCase()
                              .includes(search.toLocaleLowerCase());
                            if (search === 0) {
                              return row;
                            } else if (nome) {
                              return row;
                            }
                          }).map((fazenda) => (
                          <tr key={fazenda.codigo}>
                            <td data-label="Imagem:" className="td">
                              <img
                                src={`${fazenda.logo}`}
                                className="IMGList"
                                alt="Imagem da Fazenda"
                                title="Imagem  da Fazenda"
                              />
                            </td>
                            <td data-label="Nome:">{fazenda.nome}</td>
                            <td data-label="Localização:">
                              {fazenda.municipio?.provincia?.nome} |{" "}
                              {fazenda.municipio?.nome}
                            </td>
                            <td data-label="Área:">{fazenda.area} Hectares</td>
                            <td data-label="Adicionais:">
                              <Link
                                to={`/viewfazenda/${fazenda.codigo}`}
                                className="btn btn-primary"
                              >
                                <span className="las la-eye link-add"></span>
                              </Link>
                              {/*  */}
                              <Link
                                to={`/editfazenda/${fazenda.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>
                              <Link
                                to={`/relatoriofazenda/${fazenda.codigo}`}
                                className="btn btn-primary"
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="fa fa-print link-add-print"></span>
                              </Link>
                              {/*  */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deleteFazendas(fazenda.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                        <Pagination
                            count={count}
                            size="small"
                            // className="page-link"
                            page={page}
                            variant="text"
                            color="success"
                            shape="circular"
                            onChange={handleChange}
                          />
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
              <div className="default-tab">
                <div className="tab-content pl-3 pt-2" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-profile"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <TabFazenda />
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListFazenda;
