import React from "react";

import { useState } from 'react';
import { i18n } from '../../Traducao/translate/i18n';



import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


//total de animais leiluados por especie

const options = {
  indexAxis: "x",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "left",
    },
    title: {
      display: true,
      text: "TOTAL DE ANIMAIS LEILUADOS POR ESPÉCIE 2009 A 2013",
    },
  },
};

const labels = [
  'BOVINO',
  'OVINOS',
  'CAPRINOS',
  'ESQUIDEOS',
  'ASININOS',
  'SUINOS',
  'BUFFALOS', 
];
const data = {
  labels,
  datasets: [
    {
      label: "",
      backgroundColor: ['#73d82fc9', '#acb43994', '#444911', '#7e852494', '#252706' , '#424616' , '#181a02'],
      borderColor: '#424616',
      data: [2203, 285, 245, 32, 6, 117, 24],
    },
  ],
};
const Graficoanimais = () => {
  return (
    <div id="right-panel" className="right-panel">
      {/*  */}
      <div className="content">
        <div className="animated fadeIn">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                {/* <div
                  className="card-header"
                  style={{ background: "#b46739", fontFamily:"arial", color: "#fff" }}
                >
                  <strong className="card-title">
                    {i18n.t('messages.titulo_grafico')}
                  </strong>
                </div> */}

                <div className="col-xs-9 col-sm-9">
                  <div className="row">
                    {/* <div style={{ width: "800", height: "500" }}> */}
                    <Bar data={data} options={options} /> 
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- .animated --> */}
      </div>
      {/* <!-- .content --> */}

      <div className="clearfix"></div>
      {/*  */}
    </div>


  );
};


                        

export default Graficoanimais;