import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getLeilaoItemCodigo } from "../../service/LeilaoItemService";
import Menu from "../../components/layout/Menu";

const ViewLeilao = (props) => {
  //
  const [itemleilao, setItemLeilao] = useState({
    animal: "",
    preco: "",
    data: "",
    quantidade_fixa: "",
    leilao: "",
    artigo: "",
  });

  const { codigo } = useParams();

  //
  useEffect(() => {
    viewItemLeilao();
  }, []);
  //
  const viewItemLeilao = () => {
    if (codigo) {
      getLeilaoItemCodigo(codigo).then((response) => {
        setItemLeilao(response.data);
      });
    }
  };

  //
  //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">
                <Link
                  to="/leilaoItem"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="las la-plus">Voltar</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">Informações do Item do Leilão</strong>
                  </div>

                  <div className="col-xs-12 col-sm-12">
                    <div className="row">

                      <div className="col-sm-8">
                        <div className="card">
                          <div className="card" style={{ width: "100%" }}>
                            <ul className="list-group list-group-flush">

                            {/* <li className="list-group-item">
                                <strong>Animal:</strong> {itemleilao.animal.nome}
                              </li>

                              <li className="list-group-item">
                                <strong>Raça:</strong> {itemleilao.animal.raca}
                              </li>

                              <li className="list-group-item">
                                <strong>Espécie:</strong> {itemleilao.animal.especie}
                              </li>

                              <li className="list-group-item">
                                <strong>Sexo:</strong> {itemleilao.animal.sexoAnimal}
                              </li>

                              <li className="list-group-item">
                                <strong>Idade:</strong> {itemleilao.animal.idade}
                              </li>

                              <li className="list-group-item">
                                <strong>Fazenda:</strong> {itemleilao.animal.fazenda.nome}
                              </li> */}

                              <li className="list-group-item">
                                <strong>Preço:</strong> {itemleilao.preco}
                              </li>

                              <li className="list-group-item">
                                <strong>Data:</strong> {itemleilao.data}
                              </li>




                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}
    </>
  );
};

export default ViewLeilao;
