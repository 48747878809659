import Menu from "../../components/layout/Menu";
import ChartAnimais from "../../components/chart/chartAnimais";
import Graficoanimais from "../../components/chart/grafico_animais";
import Graficoanimais_2 from "../../components/chart/grafico_animais_2";
import Graficoanimais_3 from "../../components/chart/grafico_animais_3"; 
import GraficoAnimalEspecie from "../../components/chart/GraficoAnimalEspecie";

import { useState } from 'react';
import { i18n } from '../../Traducao/translate/i18n';



const I18N_STORAGE_KEY = 'i18nextLng';


const CharAnimal = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div> 
        < GraficoAnimalEspecie />
        < Graficoanimais/>
        < Graficoanimais_2/>
        < Graficoanimais_3/>
      </div>
      {/*  */}
    </>
  );
};

export default CharAnimal;

