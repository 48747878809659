import React from "react";
import { Link } from "react-router-dom";

import { useState } from "react";
import { i18n } from "../../../Traducao/translate/i18n";

const I18N_STORAGE_KEY = "i18nextLng";

const Sidebar = () => { 
  return (
    <div cl>
      {/* <!-- Left Panel --> */}
      <aside id="left-panel" className="left-panel">
        <nav className="navbar navbar-expand-sm navbar-default">
          <div id="main-menu" className="main-menu collapse navbar-collapse">
            <ul className="nav navbar-nav">
              <li>
                <Link
                  to="/"
                  style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                >
                  <i className="menu-icon fa fa-home"></i>
                  {i18n.t("messages.inicio")}
                </Link>
              </li>
              {/* <li className="menu-title">{i18n.t("messages.menus")}</li> */}
              {/*  */}
              <li>
                <Link
                  to="/paises"
                  style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                >
                  <i className="menu-icon fa fa-globe"></i>
                  {i18n.t("messages.pais")}
                </Link>
              </li>
              {/*  */}
              {/* <!-- /.menu-title --> */}
              <li className="menu-item-has-children dropdown">
                <Link
                  to="#"
                  style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="menu-icon fa fa-users"></i>
                  {i18n.t("messages.cooperativas")}
                </Link>
                <ul className="sub-menu children dropdown-menu">
                  <li>
                    <Link
                      to="/cooperadores"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.cooperador")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/fazendas"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.fazendas")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/animais"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.animais")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/lotes"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.lote")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="menu-item-has-children dropdown">
                {/* <Link
                  to="#"
                  style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="menu-icon fa fa-line-chart"></i>
                  {i18n.t("messages.leilao")}
                </Link> */}
                <ul className="sub-menu children dropdown-menu">
                  <li>
                    <Link
                      to="/leiloes"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.fazendas")}
                    </Link>
                  </li>


                  <li>
                    <Link
                      to="/exclusivos"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.exclusivo")}
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="menu-item-has-children dropdown">
                <Link
                  to="#"
                  style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  <i className="menu-icon fa fa-table"></i>
                  {i18n.t("messages.atividades")}
                </Link>
                <ul className="sub-menu children dropdown-menu">
                  <li>
                    <Link
                      to="/vendas"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.stock")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/carrinho"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.carrinho")}
                    </Link>
                  </li> */}

                  <li>
                    {/* <i className="fa fa-table"></i> */}
                    <Link
                      to="/servicos"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.servicos")}
                    </Link>
                  </li>
                  <li>
                    {/* <i className="fa fa-calendar"></i> */}
                    <Link
                      to="/agendas"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.agendas")}
                    </Link>
                  </li>
                </ul>
              </li> 
              {/*  */}

              <li className="menu-item-has-children dropdown">
                <Link
                  to="#"
                  style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  <i className="menu-icon fa fa-table"></i>
                  {i18n.t("messages.paginas")}
                </Link>
                <ul className="sub-menu children dropdown-menu">
                  <li>
                    <Link
                      to="/usuarios"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-user"></i>
                      {i18n.t("messages.usuarios")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/noticias"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-user"></i>
                      {i18n.t("Noticias")}
                    </Link>
                  </li>
                  {/* VER HABILITAR */}

                  <li>
                    <Link
                      to="/tags"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-user"></i>
                
                      Tag
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/entregas"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-envelope"></i>
                      {i18n.t("messages.entrega")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/comprovativos"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-check-square"></i>
                      {i18n.t("messages.comprovativos")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/mensagens"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-envelope"></i>
                      {i18n.t("messages.mensagens")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/notificacoes"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-bell"></i>
                      {i18n.t("messages.notificacoes")}
                    </Link>
                  </li>
                </ul>
              </li>

              {/* SERVICOS 
              <li className="menu-title">{i18n.t("messages.servicos")}</li> */}
              <li className="menu-item-has-children dropdown">
                <Link
                  to="#"
                  style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  <i className="menu-icon fa fa-laptop"></i>
                  {i18n.t("messages.adicionar")}
                </Link>
                <ul className="sub-menu children dropdown-menu">
                  <li>
                    <Link
                      to="/especies"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-cubes"></i>
                      {i18n.t("messages.especie")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/racas"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-cubes"></i>
                      {i18n.t("messages.raca")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/distancias"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-user"></i>
                      {i18n.t("messages.distancia")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/categorias"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-cogs"></i>
                      {i18n.t("messages.categoria")}
                    </Link>
                  </li>

                  <li>
                    <li>
                      <Link
                        to="/produtos"
                        style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                      >
                        <i className="menu-icon fa fa-cubes"></i>
                        {i18n.t("messages.produtos")}
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/proprietarios"
                        style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                      >
                        <i className="menu-icon fa fa-user"></i>
                        {i18n.t("messages.proprietario")}
                      </Link>
                    </li>

                    <Link
                      to="/transportes"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-truck"></i>
                      {i18n.t("messages.transporte")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/fornecedores"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-handshake"></i>
                      {i18n.t("messages.fornecedores")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/impostos"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-credit-card-alt"></i>
                      {i18n.t("messages.imposto")}
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/comissoes"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-suitcase"></i>
                      {i18n.t("messages.comissao")}
                    </Link>
                  </li>
                </ul>
              </li>

              {/* ESTATISTICAS
              <li className="menu-title">{i18n.t("messages.estatisticas")}</li> */}

              <li className="menu-item-has-children dropdown">
                <Link
                  to="#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="menu-icon fa fa-industry"></i>
                  {i18n.t("messages.graficos")}
                </Link>
                <ul className="sub-menu children dropdown-menu">
                  <li>
                    <Link
                      to="/graficos"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.animais")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/graficovendas"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      {i18n.t("messages.vendas")}
                    </Link>
                  </li>
                </ul>
              </li>

              {/* <li className="menu-item-has-children dropdown">
                <Link
                  to="#"
                  style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                  lassName="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="menu-icon fa fa-flag"></i>
                  {i18n.t("messages.relatorios")}
                </Link>
                <ul className="sub-menu children dropdown-menu">
                  <li>
                    <Link
                      to="/relatorios"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      <i className="menu-icon fa fa-table"></i>
                      {i18n.t("messages.todos")}
                    </Link>
                  </li>
                </ul>
              </li> */}

              {/* <li className="menu-title">ADMINISTRÇÃO</li> */}
              {/* <li className="menu-item-has-children dropdown">
                <Link
                  to="#"
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="menu-icon fa fa-industry"></i>
                  Administrativa
                </Link>
                <ul className="sub-menu children dropdown-menu"> */}
                  {/* <li>
                    <Link
                      to="/funcionarios"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      Funcionario
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link
                      to="/permissoes"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      Permissão
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link
                      to="/loginRole"
                      style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
                    >
                      Grupo de Permissão
                    </Link>
                  </li> */}
                {/* </ul>
              </li> */}
            </ul>
          </div>

          {/* <!-- /.navbar-collapse --> */}
        </nav>
      </aside>
      {/* <!-- /#left-panel --> */}
    </div>
  );
};

export default Sidebar;
