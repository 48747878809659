import api from "./api";

export const getRacas = async () => {
  try {
    return await api.get("racas");
  } catch (error) {
    console.log(error.message);
  }
};
export const getRacasByEspecie = async (codigo) => {
  try {
    return await api.get(`racaByEspecie/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const createRaca = async (data) => {
  try {
    return await api.post("raca", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateRaca = async (codigo, data) => {
  try {
    return await api.put(`raca/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeRaca = async (codigo) => {
  try {
    return await api.delete(`raca/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getRacaCodigo = async (codigo) => {
  try {
    return await api.get(`raca/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getAllList = async (params) => {
  try {
    return await api.get("raca", { params });
  } catch (error) {
    console.log(error.message);
  }
};
