import api from "./api";

export const getRacaRapidoLote = async (codigo) => {
  try {
    return await api.get("RacasByLote/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
// export const getAnimais_Fazenda = async (codigo) => {
//   try {
//     return await api.get("FazendaAnimais/" + codigo);
//   } catch (error) {
//     console.log(error.message);
//   }
// };

export const getRacaRapido_individual = async (codigo) => {
  try {
    return api.get("racaByEspecie/" + codigo);
    // return api.get("RacasByLote/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const racaRapidosLote = async (data) => {
  try {
    return await api.post("criar_rashtag", data);
  } catch (error) {
    console.log(error.message);
  }
};
// export const getLoteCodigo = async (codigo) => {
//   try {
//     return await api.get(`lote/${codigo}`);
//   } catch (error) {
//     console.log(error.message);
//   }
// };
// export const updateLote = async (data) => {
//   try {
//     return await api.put("lote", data);
//   } catch (error) {
//     console.log(error.message);
//   }
// };
// export const removeLote = async (codigo) => {
//   try {
//     return await api.delete(`lote/${codigo}`);
//   } catch (error) {
//     console.log(error.message);
//   }
// };
