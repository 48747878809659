import api from "./api";

export const getFuncionarios = async () => {
  try {
    return await api.get("FuncionarioCcgsa");
  } catch (error) {
    console.log(error.message);
  }
};
export const createFuncionario = async (data) => {
  try {
    return await api.post("criar_funcionario_img", data);
  } catch (error) {
    console.log(error.message);
  }  
};
export const getCodigoFuncionario = async (codigo) => {
  try {
    return await api.get(`funcionario/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateFuncionario = async (data) => {
  try {
    return await api.put("criar_funcionario_img", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeFuncionario = async (codigo) => {
  try {
    return await api.delete(`funcionario/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
