import api from "./api";

export const getAnimalLotes = async () => {
  try {
    return await api.get("animalLotes");
  } catch (error) {
    console.log(error.message);
  }
};

export const createAnimalLote = async (data) => {
  try {
    return await api.post("animalLote", data);
  } catch (error) {
    console.log(error.message);
  }
};

export const getAnimalLoteCodigo = async (codigo) => {
  try {
    return await api.get(`ItemPLeilao/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getLoteFazenda = async (codigo) => {
  try {
    return await api.get(`loteFazenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getLoteCodigo = async (codigo) => {
  try {
    return await api.get(`lote/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};


export const getFazendaAnimal = async (codigo) => {
  try {
    return await api.get(`FazendaAnimais/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getAnimalLote = async (codigo) => {
  try {
    return await api.get(`loteAnimal/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const removeAnimalLote = async (codigo) => {
  try {
    return await api.delete(`animalLote/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getMudarExistente = async (codigo) => {
  try {
    return await api.delete(`loteExistente/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getloteRapidoByLote = async (codigo) => {
  try {
    return await api.get(`loteRapidoByLote/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

