import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { createCategoria, getCategoriaCodigo, getCategorias, updateCategoriaCodigo } from "../../service/CategoriaService";
import * as messages from "../message/toastr";
import * as yup from "yup";
import { i18n } from '../../Traducao/translate/i18n';
const I18N_STORAGE_KEY = 'i18nextLng';


const TabCategoria = ({ vetor }) => {

  const { codigo } = useParams();
  const [categoria, setCategoria] = useState([]);

  //
  useEffect(() => {
    getAllCategoria();
  }, []);

  //LISTAR
  const getAllCategoria = () => {
    getCategorias()
      .then((response) => {
        setCategoria(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };


  //SALVAR  
  const [Categoria, setCategorias] = useState({
    nome: "",
  });

  //Limpar
  const handleClear = () => {
    setCategorias({
      nome: "",
    })
  }
  const { nome } = Categoria;
  //

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //

  const onInputChange = (e) => {
    setCategorias({ ...Categoria, [e.target.name]: e.target.value });
  };


  //Botao Salvar
  const saveUpdateCategoria = async (e) => {
    e.preventDefault();


    if (!(await validate())) return;


    const data ={
      nome
    }

    //
    if (codigo) {
      await updateCategoriaCodigo(data)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllCategoria();
          handleClear();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createCategoria(data)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllCategoria();
          handleClear();

        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadCategoria();
  }, []);
  //
  const loadCategoria = () => {
    if (codigo) {
      getCategoriaCodigo(codigo)
        .then((response) => {
          setCategorias(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };

  //
  async function validate() {
    let schema = yup.object().shape({
      nome: yup
        .string("Por favor, informe a categoria!")
        .required("Por favor, informe a categoria!"),

    });
    try {
      await schema.validate(Categoria);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //

  return (
    <div>
      <form
        onSubmit={(e) => saveUpdateCategoria(e)}
      >

        {status.type === "error" ? (
          <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-12">
            {/* <div className="form-group"> */}
            <input

              id=""
              name="nome"
              type="text"
              className="form-control cc-exp"
              value={nome}
              onChange={(e) => onInputChange(e)}
              // placeholder={i18n.t('messages.nome')}
              placeholder="Descriçao"
            />
            {/* </div> */}
          </div>
        </div>


        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t('buttons.cancelar')}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >

            {i18n.t('buttons.save')}
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabCategoria;
