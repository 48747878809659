import api from "./api";

export const getExclusivos = async () => {
  try {
    return await api.get("LeilaoExclusivos");
  } catch (error) {
    console.log(error.message);
  }
};
export const createExclusivo = async (data) => {
  try {
    return await api.post("LeilaoExclusivo", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getFazendaLeilao = async (codigo) => {
  try {
    return await api.get("pegaLeilaoFazenda/" + codigo);
  } catch (error) {
    console.log(error.message);
  }
};
export const getExclusivoCodigo = async (codigo) => {
  try {
    return await api.get(`/leilaoExclusivo/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateExclusivo = async (data) => {
  try {
    return await api.put("", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeExclusivo = async (codigo) => {
  try {
    return await api.delete(`LeilaoExclusivo/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
