import api from "./api";

export const getExclusivoItens = async () => {
  try {
    return await api.get("LeilaoItemExclusivo");
  } catch (error) {
    console.log(error.message);
  }
};
export const createExclusivoItem = async (data) => {
  try {
    return await api.post("criar_leilaoItemExclusivo", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getExclusivoItemCodigo = async (codigo) => {
  try {
    return await api.get(`ItemExclusivoPLeilao/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateExclusivoItem = async (data) => {
  try {
    return await api.put("", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeExclusivoItem = async (codigo) => {
  try {
    return await api.delete(`${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
