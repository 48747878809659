import api from "./api";

export const getFazendas = async () => {
  try {
    return await api.get("fazendas");
  } catch (error) {
    console.log(error.message);
  }
};
export const createFazenda = async (data) => {
  try {
    return await api.post("fazenda", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const createFazendaRetorna = async (data) => {
  try {
    return await api.post("fazendaRapido", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getFazendaCodigo = async (codigo) => {
  try {
    return await api.get(`fazenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateFazenda = async (data) => {
  try {
    return await api.put("fazeasyncnda", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const findByNome = async (nome) => {
  try {
    return await api.get(`fazendas?nome=${nome}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeFazenda = async (codigo) => {
  try {
    return await api.delete(`fazenda/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getImagem = async (imagem) => {
  try {
    return await api.get(`exibirImagemFazenda/${imagem}`);
  } catch (error) {
    console.log(error.message);
  }
};
