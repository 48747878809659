import Menu from "../../components/layout/Menu";
import ListRaca from "../../components/listTable/ListRaca";

const Raca = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListRaca />
      </div>
      {/*  */}
    </>
  );
};

export default Raca;