import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { getAll, remove } from "../../service/VendaProdutoService";
import * as messages from "../message/toastr";
import Swal from "sweetalert2";

import { i18n } from "../../Traducao/translate/i18n";

const I18N_STORAGE_KEY = "i18nextLng";

const ListStockProduto = () => {
  const [produtos, setProdutos] = useState([]);

  //
  useEffect(() => {
    getAllProdutos();
  }, []);

  //LISTAR
  const getAllProdutos = () => {
    getAll()
      .then((response) => {
        setProdutos(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  //

  //APAGAR
  const deleteProdutos = (codigo) => {
    Swal.fire({
      title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        remove(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAll();
        });
      }
    });
  };
  //

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/relatorioSPro" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="fa fa-print">Relatórios</span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button>
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.stock")} dos Produtos
                    </strong>
                    {/* <Link to="/stockAnimal" id="event-modal">
                      <span
                        className="btn btn-flat m-b-30 m-t-30"
                        style={{
                          background: "#b46739",
                          color: "#fff",
                          marginLeft: "10px",
                        }}
                      >
                        Stock de Animais
                      </span>
                    </Link> */}
                    {/*  */}
                    <Link to="/vendas" id="event-modal">
                      <span
                        className="btn btn-flat m-b-30 m-t-30"
                        style={{
                          background: "#b46739",
                          color: "#fff",
                          marginLeft: "10px",
                        }}
                      >
                        Stock Lotes
                      </span>
                    </Link>
                  </div>
                  <div className="card-body">

                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.produto")}</th>
                          <th scope="col">{i18n.t("messages.categoria")}</th>
                          <th scope="col">{i18n.t("messages.preco")}</th>
                          <th scope="col">{i18n.t("messages.iva")}</th>
                          <th scope="col">{i18n.t("messages.comissao")}</th>
                          {/* <th scope="col">{i18n.t("messages.cooperador")}</th> */}
                          <th scope="col">
                            {i18n.t("messages.preco_de_venda")}
                          </th>
                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>

                        {produtos.map((produto) => (
                          <tr key={produto.codigo}>

                            <td data-label="Preço:">{produto.produto.nome}</td>
                            <td data-label="Preço:">{produto.produto.categoria.nome}</td>
                            <td data-label="Preço:">{produto.preco}kz</td>
                            <td data-label="Iva:">
                              {produto.totalValorIva == 0
                                ? "Sem Iva"
                                : `${produto.totalValorIva}kz (${produto.imposto.iva}%)`}
                            </td>
                            <td data-label="Comissão:">
                              {produto.totalCooperativa}kz ({produto.comissao.quota}
                              %)
                            </td>
                            <td data-label="Valor Coop:">{produto.totalValorCrescinto}kz</td>
                            <td data-label="Adicionais:">
                              <Link
                                to={`/veiwStockProduto/${produto.codigo}`}
                                className="btn btn-primary"
                              >
                                <span className="las la-eye link-add"></span>
                              </Link>
                              {/*  */}
                              {/* <Link
                                to={`/editproduto/${produto.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link> */}

                              {/* <Link
                                to={`/relatoriostock/${produto.codigo}`}
                                className="btn btn-primary"
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="fa fa-print link-add-print"></span>
                              </Link> */}

                              {/*  */}
                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => deleteProdutos(produto.codigo)}
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                            </td>
                          </tr>
                        ))}

                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>


    </>
  );
};

export default ListStockProduto;
