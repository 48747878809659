import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { getVisitantes, removeVisitante } from "../../service/VisitanteService";
import * as messages from "../message/toastr";
import usePagination from "../../components/pagination/Pagination";
import { Pagination } from '@mui/material';
import Swal from "sweetalert2";


import { i18n } from '../../Traducao/translate/i18n';


const I18N_STORAGE_KEY = 'i18nextLng';



const ListUsuarios = () => {
    const { codigo } = useParams();
    const [visitantes, setVisitantes] = useState([]);

    //PAGINACAO
    let [page, setPage] = useState(1);
    const PER_PAGE = 5;

    const count = Math.ceil(visitantes.length / PER_PAGE);
    const _DATA = usePagination(visitantes, PER_PAGE);
    //PESQUISAR
    const [search, setSearch] = useState("");



    //
    useEffect(() => {
        getAllVisitantes();
    }, []);

    //LISTAR
    const getAllVisitantes = () => {
        getVisitantes()
            .then((response) => {
                setVisitantes(response.data);
            })
            .catch((error) => {
                messages.mensagemErro(error);
            });
    };

    //APAGAR
    const deleteVisitantes = async (codigo) => {
        await Swal.fire({
            // title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
            text: "Realmente deseja eliminar!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sim, eliminar!",
            cancelButtonText: "Cancelar",
        }).then((result) => {
            if (result.isConfirmed) {
                removeVisitante(codigo).then((response) => {
                    Swal.fire("Eliminado!", "Eliminado.", "success");
                    getAllVisitantes();
                });
            }
        });
    };

    //
    const titulo = () => {
        if (codigo) {
            return <h3 style={{ color: "#fff" }}> {i18n.t('messages.atualizar')} </h3>;
        } else {
            return <h3 style={{ color: "#fff" }}> {i18n.t('messages.cadastrar')} </h3>;
        }
    };

    //
    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    //
    return (
        <>
            <Menu />
            <div id="right-panel" className="right-panel">
                {/*  */}
                <div className="breadcrumbs">
                    <div className="breadcrumbs-inner">
                        <div className="row m-0">
                            <div className="col-xs-6 col-sm-6">
                                {/* <Link to="/usuarios/addVisitante" id="event-modal">
                                </Link> */}
                            </div>
                            {/*  */}
                            <div className="col-xs-6 col-sm-6">
                                <form className="d-flex">
                                    <input
                                        className="form-control me-2"
                                        type="search"
                                        placeholder={i18n.t('messages.Pesquisar_por_nome')}
                                        aria-label="Search"
                                        onChange={(e) => {
                                            setSearch(e.target.value);
                                        }}
                                    />
                                </form>
                            </div>
                            {/*  */}
                        </div>
                    </div>
                </div>

                {/*  */}
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div
                                        className="card-header"
                                        style={{ background: "#b46739", color: "#fff" }}
                                    >
                                        <strong className="card-title">
                                            {i18n.t('messages.visitante')}
                                        </strong>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-striped responsiveTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        {i18n.t('messages.nome')}
                                                    </th>

                                                    <th scope="col">
                                                        {i18n.t('messages.email')}
                                                    </th>

                                                    <th scope="col">
                                                        {i18n.t('messages.telefone')}
                                                    </th>


                                                    <th scope="col">
                                                        {i18n.t('messages.bi')}
                                                    </th>

                                                    <th scope="col">
                                                        {i18n.t('messages.opcao')}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {visitantes.map((visitante) => (
                                                    <tr key={visitante.codigo}>
                                                        <td data-label="Nome:">{visitante.nome}</td>
                                                        <td data-label="Email:">{visitante.email}</td>
                                                        <td data-label="Telefone:">{visitante.telefone}</td>
                                                        <td data-label="BI:">{visitante.bi}</td>
                                                        <td data-label="Adicionais:">

                                                            {/*  */}
                                                            <Link
                                                                to={`/edituser/${visitante.codigo}`}
                                                                id="event-modal"
                                                                style={{ marginLeft: "5px" }}
                                                            >
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-warning"
                                                                    data-toggle="modal"
                                                                    data-target="#largeModal"
                                                                >
                                                                    <span className="las la-edit link-add"></span>
                                                                </button>
                                                            </Link>
                                                            {/*  */}
                                                            <button
                                                                type="submit"
                                                                className="btn btn-danger"
                                                                onClick={() =>
                                                                    deleteVisitantes(visitante.codigo)
                                                                }
                                                                style={{ marginLeft: "5px" }}
                                                            >
                                                                <span className="las la-trash link-add"></span>
                                                            </button>
                                                            {/*  */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/*  */}
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-end">
                                                <li className="page-item disabled">
                                                    <Pagination
                                                        count={count}
                                                        size="small"
                                                        // className="page-link"
                                                        page={page}
                                                        variant="text"
                                                        color="success"
                                                        shape="circular"
                                                        onChange={handleChange}
                                                    />
                                                </li>
                                            </ul>
                                        </nav>
                                        {/*  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- .animated --> */}
                </div>
                {/* <!-- .content --> */}
                <div className="clearfix"></div>
                {/*  */}
            </div>
            {/* <!--------Fim Cadastrar------> */}
        </>
    );
};

export default ListUsuarios;
