import React, { useState, useEffect } from "react";
import { createAnimalLote, getAnimalLote, getMudarExistente } from "../../service/LoteItemService";
import { getLoteCodigo } from "../../service/LoteService";
import * as messages from "../message/toastr";
import { getAnimais_Fazenda_anime } from "../../service/AnimalService";

import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const TabExistente = ({ loteId }) => {
  const [verAnimal, setVerAnimal] = useState([]);

  //
  useEffect(() => {
    getFazenda();
    getAllExistentes();
  }, []);

  const [verFazendaLote, setverFazendaLote] = useState([]);
  const [VerExistente, setVerExistente] = useState([]);
  const [VerCodFazenda, setVerCodFazenda] = useState(0);

  const getFazenda = () => {
    getLoteCodigo(loteId)
      .then((response) => {
        getAnimal(response.data.fazenda.codigo);
        setVerCodFazenda(response.data.fazenda.codigo);

      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
          
  //LISTAR animal
  const getAnimal = (codigo) => {
    getAnimais_Fazenda_anime(codigo)
      .then((response) => {
        setVerAnimal(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllExistentes = () => {
    getAnimalLote(loteId)
      .then((response) => {
        setVerExistente(response.data);
      })
      .catch((error) => {
        // messages.mensagemErro("Erro LOTES", error);
      });
  };

  // LOTES existente SALVAR
  const [existente, setExistente] = useState({
    animal: "",
    lote: { loteId },
  });

  const { animal } = existente;

  const onInputChange = (e) => {
    setExistente({ ...existente, [e.target.name]: e.target.value });
  };

  const AddOAnimalNoLote = async (cod) => {
    const datas = {
      lote: {
        codigo: loteId,
      },
      animal: {
        codigo: cod,
      },
    };
    createAnimalLote(datas)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        getAllExistentes();
        getMudarExistente(loteId);
        getAnimal(VerCodFazenda)
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
        console.log(error);
      });
  };
  //
  return (
    <div>
      <div className="card-body">
        <h3>Nº. do Lote Criador {loteId}</h3>
        <br />
        <table className="table">
          <thead>
            <tr>
              <th>Raça</th>
              <th>Peso kg</th>
              <th>Idade Meses</th>
              <th>Proveniencia</th>
              <th>Adicionar</th>
            </tr>
          </thead>
          <tbody>
            {verAnimal.map((animal) => (
              <tr key={animal.codigo}>
                <td>{animal.raca.racaAnimal}</td>
                <td>{animal.peso}Kg</td>
                <td>{animal.idade}</td>
                <td>{animal.pais.nome}</td>
                <td>
                  <a
                    onClick={() => AddOAnimalNoLote(animal.codigo)}
                    className="btn btn-success"
                    style={{
                      border: "none",
                      outline: "none",
                      fontWeight: "bold",
                      color: "#fff",
                    }}
                  >
                    Adicionar
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <h6>Medias dos pesos: 500Kg</h6> */}
        {VerExistente == 0 ? (
          <h1 style={{ textAlign: "center", color: "#9ba239" }}>
            Sem lotes na tabela
          </h1>
        ) : (
          <table className="table table-striped">
            <thead>
              <tr>
                {/* <th>Lote</th> */}
                {/* <th>Nº. de Animais</th> */}
                <th>Sexo</th>
                <th>Peso Kg</th>
                <th>Idade Meses</th>
                <th>Raça</th>
                <th>Fazenda</th>              
              </tr>
            </thead>
            <tbody>
              {VerExistente.map((rapido) => (
                <tr key={rapido.codigo}>
                  <td>{rapido.animal.sexoAnimal}</td>
                  <td>{rapido.animal.peso}Kg</td>
                  <td>{rapido.animal.idade}</td>
                  <td>{rapido.animal.raca.racaAnimal}</td>
                  <td>{rapido.lote.fazenda.nome}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="btn"
          data-dismiss="modal"
          style={{
            backgroundColor: "#ccc",
            color: "#000",
          }}
        >
          {i18n.t("buttons.cancelar")}
        </button>

        {/* <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span className="lar la-save">
              {i18n.t("buttons.save")}
              VAI Existente
            </span>
          </button> */}
      </div>
      {/* </form> */}
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default TabExistente;
