import Menu from "../../components/layout/Menu";
import ListLeilaoItemExclusivo from "../../components/listTable/ListLeilaoItemExclusivoCod";

const LeilaoItemExclusivo = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListLeilaoItemExclusivo />
      </div>
      {/*  */}
    </>
  );
};

export default LeilaoItemExclusivo;