import { data } from "jquery";
import api from "./api";

export const getCooperadores = async () => {
  try {
    return await api.get("cooperadores");
  } catch (error) {
    console.log(error.message);
  }
};
export const getCooperadorTotal = async () => {
  try {
    return await api.get("totalCooperadores");
  } catch (error) {
    console.log(error.message);
  }
};  
export const getAllList = async (params) => {
  try {
    return await api.get("cooperadores", { params });
  } catch (error) {
    console.log(error.message);
  }
};
export const createCooperador = async (data) => {
  try {
    return await api.post("cadastrar_cooperador_img", data);
  } catch (error) {
    console.log(error.message);
  }
};

//CadastroRapido
export const createCooperadorRapido = async (data) => {
  try {
    return await api.post("cooperadorRapido", data);
  } catch (error) {
    console.log(error.message);
  }
};
 
export const updateCooperador = async (codigo, data) => {
  try {
    return await api.put(`cooperador/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getCooperadorCodigo = async (codigo) => {
  try {
    return await api.get(`cooperador/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getCooperadorCodigoFazenda = async (codigo) => {
  try {
    return await api.get(`FazendaCooperador/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeCooperador = async (codigo) => {
  try {
    return await api.delete(`cooperador/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getImagem = async (imagem) => {
  try {
    return await api.get(`exibirImagemCooperador/${imagem}`);
  } catch (error) {
    console.log(error.message);
  } 
};
