import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { getServicoCodigo } from "../../service/ServicoService";
import { useParams } from "react-router-dom";
import logo from "../../assets/img/logo/logo1.jpg";
import redirects from "../../components/redirect";

function App() {
  const componentRef = useRef();
  const { codigo } = useParams();
  const [dados, setDados] = useState({});
  const { servicos } = redirects();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Relatorios",
  });

  useEffect(() => {
    busca();
  }, []);

  const busca = async () => {
    const { data } = await getServicoCodigo(codigo);
    setDados(data);
    console.log(data);
  };

  const [data, setData] = useState("");
  const dataTime = () => {
    setData(new Date().toLocaleString());
  };
  const [totalTime, setTotalTime] = useState([60]);
  useEffect(() => {
    setTimeout(() => {
      setTotalTime(totalTime - 1);
    }, 1000);
    dataTime();
  }, [totalTime]);

  return (
    <>
      <div className="Formatacao-Relatorio" ref={componentRef}>
        <div className="App_header">
          <div className="data_esq">
            <img src={logo} />
            <h6>Vagne Zau Lelo</h6>
            <small>{data}</small>
          </div>
          <div className="data_dir">
            <h6>Cooperativa Wapoma Agro-pecuária e Mineira</h6>
            <small>Huíla Lubango</small>
            <small>cooperativacwamahuila@gmail.com</small>
            <small>www.cwam.com</small>
          </div>
        </div>
        <header className="App_h1">
          <h3>Relatório do Serviço</h3>
        </header>

        <div className="data_header">
          <div className="App_body">
            <div className="table_data">
              <table className="table">
                <thead>
                  <th className="th">Codigo</th>
                  <th className="th">Título</th>
                  <th className="th">Data</th>
                  <th className="th">Descrição</th>
                </thead>
                <tbody>
                  <tr className="tr">
                    <td className="td">{dados.codigo}</td>
                    <td className="td">{dados.titulo}</td>
                    <td className="td">
                      {new Date(dados.data).toLocaleDateString()}
                    </td>
                    <td className="td">{dados.descricao}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="data_funny">
        <button onClick={servicos} className="btn botao_return">
          <span className="fa fa-reply"></span>
        </button>
        <button onClick={handlePrint} className="btn botao_print">
          <span className="fa fa-print"></span>
        </button>
      </div>
    </>
  );
}

export default App;
