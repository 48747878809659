import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { getCarrinhoServices, remove } from "../../service/CarrinhoService";
import * as messages from "../message/toastr";
import Swal from "sweetalert2";
import api from "../../Api";

import ImgCarrinho from "../../assets/img/ImgCarrinho/venda-rapida.png";
import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";
  
const List_Carrinho = () => {
  const { codigo } = useParams();
  const [carrinhos, setCarrinhos] = useState([]);

  //
  useEffect(() => {
    getAllcarrinho();
  }, []);

  //LISTAR
  const getAllcarrinho = () => {
    getCarrinhoServices()
      .then((response) => {
        setCarrinhos(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  //APAGAR
  const deleteCarrinho = (codigo) => {
    Swal.fire({
      title: "Deseja eliminar?",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        remove(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllcarrinho();
        });
      }
    });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };
  //

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div class="content">
                      <div class="animated fadeIn">
                        <div class="row">
                          {/*  */}
                          {carrinhos.map((carrinho) => (
                            <div className="card" style={{ width: "18rem", margin: "10px" }}>
                              <div
                                className="card-header alt"
                                style={{ background: "#9ba236" }}
                              >
                                <div className="media" key={carrinho.codigo}>
                                  <img
                                    className=""
                                    style={{
                                      width: "55px",
                                      height: "55px",
                                    }}
                                    src={ImgCarrinho}
                                  />
                                </div>
                              </div>
                              <div className="card-body">
                                <li className="list-group-item">
                                  {" "}
                                  <strong style={{ marginLeft: "50px" }}>
                                    Nome:
                                  </strong>
                                  {carrinho.visitante.nome}
                                </li>

                                <li className="list-group-item">
                                  <strong style={{ marginLeft: "50px" }}>
                                    Data:
                                  </strong>

                                  <a href="#"> {carrinho.data} </a>
                                </li>

                                <li className="list-group-item">
                                  <strong style={{ marginLeft: "50px" }}>
                                    Quantidade:
                                  </strong>

                                  <a href="#"> {carrinho.quantidade} </a>
                                </li>
                                <li className="list-group-item">
                                  <strong
                                    style={{ marginLeft: "50px" }}
                                  ></strong>

                                  <a href="#" style={{ marginLeft: "10px" }}>
                                    <Link
                                      to={`/carrinho/ViewCarrinho/${carrinho.codigo}`}
                                      className="btn btn-primary"
                                    >
                                      <span className="">Mais detalhes</span>
                                    </Link>
                                  </a>
                                </li>
                              </div>
                            </div>
                          ))}
                          {/*  */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}

      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default List_Carrinho;
