import Menu from "../../components/layout/Menu";
import ListEspecie from "../../components/listTable/ListEspecie";

const Lote = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListEspecie />
      </div>
      {/*  */}
    </>
  );
};

export default Lote;