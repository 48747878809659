import React from "react";

function App() {
  return (
    <>
      <div className="App1">
        <div className="data_header1">
          <div className="data_esq1">
            <h6>VAGNELELO, LDA</h6>
            <small>A1 - Sede Empresarial</small>
            <small>Bairro Comercial, S/N</small>
            <small>Municipio do Lubango</small>
            <small>928145620</small>
            <small>IBAN BANCO BAI/0040 0000 9922 3048 1013 5</small>
            <div className="data_furt1">
              <small>
                <strong>
                  <u>HUILA</u>
                </strong>
              </small>
              <small>
                NIF:{" "}
                <strong>
                  <u>006939490CA047</u>
                </strong>
              </small>
            </div>
            <table>
              <thead>
                <th colSpan={2}>FACTURA-RECIBO</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Nr.: <strong>FR A1VR22/7559</strong>
                  </td>

                  <td>
                    <p>
                      <small>20-10-22</small>
                    </p>
                    <small>14:34:02</small>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="data_dir_fact1">
            <small>Cliente:</small>
            <h6>TECNO BASE</h6>
            <br />
            <small>(indique: Rua/Nr.)</small>
            <small>(Cod.Postal/Bairro/Municipio)</small>
            <small style={{ marginLeft: "100px" }}>
              <strong>
                <u>(Cidade/Provincia)</u>
              </strong>
            </small>
          </div>
        </div>
        <header className="App_via1">
          <h6>2ª Via à data: 20-10-22 conforme original. (2)</h6>
        </header>

        <div className="App_body1">
          <div className="table_data1">
            <table>
              <thead>
                <th>Nº Cliente</th>
                <th>Contribuinte</th>
                <th colSpan={2}>Armazem</th>
                <th>Forma de Pagamento</th>
                <th>Pagina</th>
              </thead>
              <tbody>
                <tr>
                  <td>166</td>
                  <td>5484024277</td>
                  <td>ARMAZEM GERAL</td>
                  <td>41</td>
                  <td>NU Numerario</td>
                  <td>1 of 1</td>
                </tr>
              </tbody>
            </table>
            <br />
            <table>
              <thead>
                <th>Codigo</th>
                <th>Descricao</th>
                <th>Quant.</th>
                <th>Preco Unitario</th>
                <th>Desconto(%)</th>
                <th>Imposto (%)</th>
                <th>Total</th>
              </thead>
              <tbody>
                <tr>
                  <td>HP290</td>
                  <td>HP290G2 COMPUTADOR DA MESA</td>
                  <td>2,00</td>
                  <td>315.790,00</td>
                  <td>2,77</td>
                  <td>14,00</td>
                  <td>700.057,17</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
