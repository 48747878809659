import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import {
  createCooperador,
  getCooperadorCodigo,
  getCooperadores,
  removeCooperador,
  updateCooperador,
} from "../../service/CooperadoresService";
import * as messages from "../message/toastr";
import * as yup from "yup";
import { Pagination } from '@mui/material';
import Swal from "sweetalert2";
import { i18n } from "../../Traducao/translate/i18n";
import usePagination from "../../components/pagination/Pagination";
const I18N_STORAGE_KEY = "i18nextLng";

const ListCooperador_1 = () => {
  const { codigo } = useParams();
  const [cooperadores, setCooperadores] = useState([]);

  //PAGINACAO
  let [page, setPage] = useState(1);
  const PER_PAGE = 5;

  const count = Math.ceil(cooperadores.length / PER_PAGE);
  const _DATA = usePagination(cooperadores, PER_PAGE);
  //PESQUISAR
  const [search, setSearch] = useState("");


  useEffect(() => {
    getAllCooperador();
  }, []);

  //LISTAR
  const getAllCooperador = async () => {
    try {
      const { data } = await getCooperadores();
      setCooperadores(data);
    } catch (error) {
      messages.mensagemErro(error);
    }
  };

  //APAGAR
  const deleteCooperador = (codigo) => {
    Swal.fire({
      // title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeCooperador(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllCooperador();
        });
      }
    });
  };

  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  //Filtrar cooperadores

  // const [busca, setBusca] = useState('');
  // const cooperadoresFiltrados = cooperador
  //       .filter((pegarcooperador) => pegarcooperador.startsWith(busca));


  //SALVAR
  const [cooperador, setCooperador] = useState({
    imgUrl: "",
    nome: "",
    sobrenome: "",
    email: "",
    telefone: "",
    bi: "",
    senha: "cwam",
    endereco: "",
    website: "",
    nascimento: "",
  });

  const [imgUrl, setImgUrl] = useState("");

  const {
    nome,
    sobrenome,
    email,
    telefone,
    bi,
    senha,
    endereco,
    website,
    nascimento,
  } = cooperador;

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //
  const onInputChange = (e) => {
    setCooperador({ ...cooperador, [e.target.name]: e.target.value });
  };

  //Botao Salvar
  const saveUpdateCooperador = async (e) => {
    e.preventDefault();

    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("nome", nome);
    formData.append("sobrenome", sobrenome);
    formData.append("email", email);
    formData.append("telefone", telefone);
    formData.append("bi", bi);
    formData.append("senha", senha);
    formData.append("endereco", endereco);
    formData.append("website", website);
    formData.append("nascimento", nascimento);

    //
    if (codigo) {
      await updateCooperador(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllCooperador();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createCooperador(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllCooperador();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadCooperador();
  }, []);
  //
  const loadCooperador = () => {
    if (codigo) {
      getCooperadorCodigo(codigo)
        .then((response) => {
          setCooperador(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };
  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setImgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };

  async function validate() {
    let schema = yup.object().shape({

      // senha: yup
      //   .string("Necessário criar uma senha de usuário!")
      //   .required("Necessário criar uma senha de usuário!")
      //   .min(6, "A senha deve ter no mínimo 6 caracteres!"),

      website: yup
        .string("Informe o webSite!")
        .required("Informe o webSite!"),

      endereco: yup
        .string("Informar o endereço é obrigatório!")
        .required("Informar o endereço é obrigatório!"),

      telefone: yup
        .string("O campo Telefone é obrigatório!")
        .required("O campo Telefone é obrigatório!"),

      nascimento: yup
        .string("O campo Data de Nascimento é obrigatório!")
        .required("O campo Data de Nascimento é obrigatório!"),

      bi: yup
        .string("O campo Bilhete de Identidade é obrigatório!")
        .required("O campo Bilhete de Identidade é obrigatório!"),

      email: yup
        .string("O campo email é obrigatório!")
        .required("O campo email é obrigatório!")
        .email("e-mail inválido"),

      sobrenome: yup
        .string("O campo Sobrenome é obrigatório!")
        .required("O campo Sobrenome é obrigatório!"),

      nome: yup
        .string("O campo nome é obrigatório!")
        .required("O campo nome é obrigatório!"),
    });
    try {
      await schema.validate(cooperador);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-6 col-sm-6">
                <Link to="/addcooperadores" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus"></span>
                  </button>
                </Link>
                <Link to="/relatoriocooperadores" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="fa fa-print">Relatórios</span>
                  </button>
                </Link>
              </div>
              {/*  */}

              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="text"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />

                </form>
              </div>

              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.cooperador")}
                    </strong>
                  </div>
                  <div className="card-body">


                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col" className="th">Imagem</th>
                          <th scope="col">{i18n.t("messages.nome")}</th>
                          <th scope="col">{i18n.t("messages.email")}</th>
                          <th scope="col">{i18n.t("messages.telefone")}</th>
                          {/* <th scope="col">{i18n.t("messages.endereco")}</th> */}
                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_DATA
                          .currentData()
                          .filter((row) => {
                            const nome = row.nome
                              .toLowerCase()
                              .includes(search.toLocaleLowerCase());
                            if (search === 0) {
                              return row;
                            } else if (nome) {
                              return row;
                            }
                          }).map((cooperador) => (
                            <tr key={cooperador.codigo}>
                              <td data-label="Foto:" className="td">
                                <img
                                  src={`${cooperador.imgUrl}`}
                                  className="userIMGListBig"
                                  alt="Imagem do lote"
                                  title="Imagem do lote"
                                />
                              </td>
                              <td data-label="Nome:">{cooperador.nome} {cooperador.sobrenome}</td>
                              <td data-label="E-mail:">{cooperador.email}</td>
                              <td data-label="Telefone:">{cooperador.telefone}</td>
                              {/* <td data-label="Endereço:">{cooperador.endereco}</td> */}
                              <td data-label="Adicionais:" className="adicionais">
                                <Link
                                  to={`/viewcooperador/${cooperador.codigo}`}
                                  className="btn btn-primary"
                                >
                                  <span className="las la-eye link-add"></span>
                                </Link>
                                {/*  */}
                                <Link
                                  to={`/editcooperador/${cooperador.codigo}`}
                                  id="event-modal"
                                  style={{ marginLeft: "5px" }}
                                >
                                  <button
                                    type="submit"
                                    className="btn btn-warning"
                                    data-toggle="modal"
                                    data-target="#largeModal"
                                  >
                                    <span className="las la-edit link-add"></span>
                                  </button>
                                </Link>
                                <Link
                                  to={`/relatoriocooperador/${cooperador.codigo}`}
                                  className="btn btn-primary"
                                  style={{ marginLeft: "5px" }}
                                >
                                  <span className="fa fa-print link-add-print"></span>{" "}
                                </Link>
                                {/*  */}
                                <button
                                  type="submit"
                                  className="btn btn-danger"
                                  onClick={() =>
                                    deleteCooperador(cooperador.codigo)
                                  }
                                  style={{ marginLeft: "5px" }}
                                >
                                  <span className="las la-trash link-add"></span>
                                </button>
                                {/*  */}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>



                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <Pagination
                            count={count}
                            size="small"
                            // className="page-link"
                            page={page}
                            variant="text"
                            color="success"
                            shape="circular"
                            onChange={handleChange}
                          />
                        </li>



                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}

      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
              <div className="default-tab">
                <div className="tab-content pl-3 pt-2" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    {/*  */}

                    <form
                      enctype="multipart/form-data"
                      onSubmit={(e) => saveUpdateCooperador(e)}
                    >
                      {status.type === "error" ? (
                        <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
                      ) : (
                        ""
                      )}
                      {/* COOPERADOR */}
                      <div className="row">
                        <div className="col-6">
                          <div className="input-group">
                            <div className="preview">
                              <img id="file-ip-1-preview" />
                            </div>
                            {/* </div> */}
                            <label className="custom-btn" for="file-ip-1">
                              {i18n.t("messages.carregar_foto")}
                            </label>
                            <input
                              type="file"
                              id="file-ip-1"
                              accept="image/*"
                              name="imgUrl"
                              className="form-control cc-cvc"
                              onChange={(e) => showPreview(e)}
                              hidden
                            ></input>
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <input
                              name="nome"
                              type="text"
                              className="form-control cc-exp"
                              placeholder={i18n.t("messages.nome")}
                              value={nome}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="input-group">
                            <input
                              name="sobrenome"
                              type="sobrenome"
                              className="form-control cc-exp"
                              placeholder={i18n.t("messages.sobrenome")}
                              value={sobrenome}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="input-group">
                            <input
                              name="email"
                              type="email"
                              className="form-control cc-exp"
                              placeholder={i18n.t("messages.email")}
                              value={email}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="row">
                        <div className="col-4">
                          <div className="form-group">
                            <input
                              type="text"
                              name="bi"
                              className="form-control cc-exp"
                              placeholder="BI"
                              value={bi}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="form-group">
                            <input
                              type="date"
                              name="nascimento"
                              value={nascimento}
                              className="form-control cc-exp"
                              placeholder=""
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="input-group">
                            <input
                              id=""
                              name="telefone"
                              type="text"
                              className="form-control cc-cvc"
                              placeholder={i18n.t("messages.telefone")}
                              value={telefone}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <input
                              name="endereco"
                              type="text"
                              className="form-control"
                              placeholder={i18n.t("messages.endereco")}
                              value={endereco}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="input-group">
                            <input
                              name="website"
                              type="text"
                              className="form-control"
                              placeholder={i18n.t("messages.website")}
                              value={website}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                      {/*  */}
                      <div className="row">
                        {/* <div className="col-6">
                          <div className="form-group">
                            <div className="input-group">
                              <div className="input-group-addon">
                                <i className="fa fa-asterisk"></i>
                              </div>
                              <input
                                name="senha"
                                type="password"
                                className="form-control"
                                placeholder={i18n.t("messages.senha")}
                                value={senha}
                                onChange={(e) => onInputChange(e)}
                              />
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="col-6">
                          <div className="input-group">
                            <div className="input-group-addon">
                              <i className="fa fa-asterisk"></i>
                            </div>
                            <input
                              name=""
                              type="password"
                              className="form-control"
                              placeholder={i18n.t("messages.confirmar_senha")}
                            />{" "}
                          </div>
                        </div> */}
                      </div>
                      {/*  */}
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn"
                          data-dismiss="modal"
                          style={{
                            backgroundColor: "#b46739",
                            color: "#fff",
                          }}
                        >
                          {i18n.t("buttons.cancelar")}
                        </button>
                        <button
                          type="submit"
                          className="btn"
                          style={{
                            backgroundColor: "#9ba239",
                            color: "#fff",
                          }}
                        >
                          <span className="lar la-save">
                            {i18n.t("buttons.save")}
                          </span>
                        </button>
                      </div>
                      {/*  */}
                      {/* FIM COOPERADOR */}
                    </form>

                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListCooperador_1;
