import React, { useState, useEffect } from "react";
import { i18n } from "../../Traducao/translate/i18n";
import {
  getProdutos_Categoria,
  getProduto_individual,
  updateProduto,
  createArtigoProduto,
} from "../../service/ProdutoService";
import { getCategorias } from "../../service/CategoriaService";
import { getComissaoCodigo, getComissoes } from "../../service/ComissaoService";
import { getImpostoCodigo, getImpostos } from "../../service/ImpostoService";
import * as messages from "../message/toastr";
import { Link, useParams, useNavigate } from "react-router-dom";
const I18N_STORAGE_KEY = "i18nextLng";

const StokProduto = () => {
  // Buscar por ID
  const { codigo } = useParams();
  //SALVAR
  const [venda, setVenda] = useState({
    produto: {
      codigo:""
    },
    preco: "",
    quantidade_fixa: "",
    comissao: {
      codigo:""
    },
    imposto:  {
      codigo:""
    }
  });

  const handleClean = () => {
    setVenda({
      produto: "",
      preco: "",
      quantidade_fixa: "",
      comissao: "",
      imposto: "",
    });
  };

  const [verCategoria, setVerCategoria] = useState([]);
  const [verComissao, setverComissao] = useState([]);
  const [verImposto, setverImposto] = useState([]);
  useEffect(() => {
    getAllVerCategoria();
    getAllComissao();
    getAllImposto();
  }, []);

  const getAllVerCategoria = () => {
    getCategorias()
      .then((response) => {
        setVerCategoria(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const getAllComissao = () => {
    getComissoes()
      .then((response) => {
        setverComissao(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const getAllImposto = () => {
    getImpostos()
      .then((response) => {
        setverImposto(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const [verproduto, setVerproduto] = useState([]);
  const verOsAniamis = (e) => {
    getProdutos_Categoria(e.target.value).then((response) => {
      setVerproduto(response.data);
    });
  };

  const [verFichaproduto, setverFichaproduto] = useState({
    nome: "",
    cod: "",
  });

  const { produto, preco, quantidade_fixa, comissao, imposto } = venda;
  const [verPrecoS, setverPrecoS] = useState();
  const [verImpostoS, setverImpostoS] = useState(0);
  const [verImpostoSelect, setverImpostoSelect] = useState(0);
  const [verComissaoS, setverComissaoS] = useState(0);
  const [verComissaoSelect, setverComissaoSelect] = useState(0);
  const [vertotalS, setvertotalS] = useState(0);

  // 
  const [addProduto, setAddProduto] = useState();
  const [addComissao, setAddComissao] = useState();
  const [addImposto, setAddImposto] = useState();
  //
  const onInputChange = (e) => {
    setVenda({ ...venda, [e.target.name]: e.target.value });
    // verTotalVendaCalculo();

    if (e.target.name === "produto") {
      getProduto_individual(e.target.value).then((response) => {
        setverFichaproduto(response.data);
      });
    }
    // {
      if (e.target.name === 'produto') {
        setAddProduto(e.target.value)
      }
      // 
    if (e.target.name === "preco") {
      setverPrecoS(parseInt(e.target.value));
      if (verImpostoS === 0 && verComissaoS === 0) {
        setvertotalS(parseInt(e.target.value));
      } else {
        const impostoN = parseInt(
          (parseInt(e.target.value) * verImpostoSelect) / 100
        );
        setverImpostoS(impostoN);

        const comissaoN = parseInt(
          ((parseInt(e.target.value) + impostoN) * verComissaoSelect) / 100
        );
        setverComissaoS(comissaoN);

        setvertotalS(parseInt(parseInt(e.target.value + parseInt(impostoN))));
      }
    }

    if (e.target.name === "imposto") {
      getImpostoCodigo(e.target.value)
        .then((response) => {
          const imposto = parseInt((verPrecoS * response.data.iva) / 100);
          setverImpostoS(imposto);
          setverImpostoSelect(response.data.iva);
          setvertotalS(parseInt(verPrecoS + imposto));
        })
        .catch((error) => {
          messages.mensagemErro(error);
        });
    }

       // {
        if (e.target.name === 'imposto') {
          setAddImposto(e.target.value)
        }
        // 

    if (e.target.name === "comissao") {
      getComissaoCodigo(e.target.value)
        .then((response) => {
          if (verImpostoS === 0) {
            const comissao = parseInt((verPrecoS * response.data.quota) / 100);
            setverComissaoS(comissao);
          } else {
            const comissao = parseInt(
              ((verPrecoS + verImpostoS) * response.data.quota) / 100
            );
            setverComissaoS(comissao);
            setverComissaoSelect(response.data.quota);
            setvertotalS(parseInt(verPrecoS + verImposto));
          }
        })
        .catch((error) => {
          messages.mensagemErro(error);
        });
    }
           // {
            if (e.target.name === 'comissao') {
              setAddComissao(e.target.value)
            }
            // 
  };

  //Botao Salvar
  const saveUpdateVenda = async (e) => {
    e.preventDefault();

const data ={
 produto:{
   codigo: addProduto
 },
 preco,
quantidade_fixa,
 comissao:{
  codigo: addComissao
 },
   imposto:{
     codigo: addImposto
   }
}    

    //
    if (codigo) {
      await updateProduto(data)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          // retrievecooperadores();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //

      await createArtigoProduto(data)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          handleClean()
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //   //
  //   useEffect(() => {
  //     loadVenda();
  //   }, []);
  //   //
  //   const loadVenda = () => {
  //     if (codigo) {
  //       VendaProdutoService.get(codigo)
  //         .then((response) => {
  //           setVenda(response.data);
  //         })
  //         .catch((error) => {
  //           messages.mensagemErro("Erro ao buscar dados");
  //         });
  //     }
  //   };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  //

  return (
    <div>
      {/* ANIMAIS */}

      {/* <!-- .content --> */}

      <form
        //enctype="multipart/form-data"
        onSubmit={(e) => saveUpdateVenda(e)}
      >
        {/* Venda */}

        <div className="row">
          <div className="col-6">
            <div className="input-group">
              <select
                data-placeholder="Selecionar..."
                className="form-control cc-exp"
                tabindex="1"
                name="categoria"
                // value={categoria}
                onChange={(e) => verOsAniamis(e)}
                placeholder={i18n.t("messages.categoria")}
              >
                <option>Selecionar categoria</option>
                {verCategoria.map((categoria) => (
                  <option value={categoria.codigo}>{categoria.nome}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-6">
            <div className="input-group">
              <select
                data-placeholder="Selecionar..."
                className="form-control cc-exp"
                // tabindex="1"
                name="produto"
                value={produto}
                onChange={(e) => onInputChange(e)}
                placeholder={i18n.t("messages.produto")}
              >
                <option>Selecionar produto</option>
                {verproduto.map((produto) => (
                  <option key={produto.codigo} value={produto.codigo}>
                    {produto.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {verFichaproduto.nome === "" ? (
          ""
        ) : (
          <div>
            <h5 style={{ padding: "10px" }}>FICHA DO PRODUTO</h5>
            <div className="row fundo-fixa-produto">
              <div className="col-3">
                <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                  {" "}
                  produto
                </h6>
                <div className="form-group">
                  <input
                    id=""
                    type="text"
                    className="form-control cc-exp"
                    value={verFichaproduto.nome}
                    onChange={(e) => onInputChange(e)}
                    disabled
                  />
                </div>
              </div>

              <div className="col-3">
                <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                  {" "}
                  Codigo
                </h6>
                <div className="form-group">
                  <input
                    id=""
                    type="text"
                    className="form-control cc-exp"
                    value={verFichaproduto.cod}
                    onChange={(e) => onInputChange(e)}
                    disabled
                  />
                </div>
              </div>
            </div>

            <h5 style={{ padding: "10px" }}>CADASTRAR STOCK DO PRODUTO</h5>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <input
                    id=""
                    name="preco"
                    type="number"
                    className="form-control cc-exp"
                    value={preco}
                    placeholder={i18n.t("messages.preco")}
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <input
                    id=""
                    name="quantidade_fixa"
                    type="number"
                    className="form-control cc-exp"
                    value={quantidade_fixa}
                    // placeholder={i18n.t("messages.quantidade_fixa")}
                    placeholder="Quantidade"
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>
            </div>

            {/*  */}

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <select
                    data-placeholder="Selecionar..."
                    className="form-control cc-exp"
                    tabindex="1"
                    name="imposto"
                    onChange={(e) => onInputChange(e)}
                  >
                    <option>Selecionar Imposto</option>
                    {verImposto.map((imposto) => (
                      <option value={imposto.codigo}>
                        {imposto.iva} % <span></span>
                        {imposto.descricao}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-6">
                <div className="form-group">
                  <div className="input-group">
                    <select
                      data-placeholder="Selecionar..."
                      className="form-control cc-exp"
                      tabindex="1"
                      name="comissao"
                      onChange={(e) => onInputChange(e)}
                    >
                      <option>Selecionar Comissão</option>
                      {verComissao.map((comissao) => (
                        <option value={comissao.codigo}>
                          {comissao.quota} %
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <h5 style={{ padding: "10px" }}>Calculos</h5>
            <div className="row">
              <div className="col-3">
                <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                  {" "}
                  Preco inicial
                </h6>
                <div className="form-group">
                  <input
                    id=""
                    type="number"
                    className="form-control cc-exp"
                    value={verPrecoS}
                    // onChange={(e) => onInputChange(e)}
                    disabled
                  />
                </div>
              </div>

              <div className="col-3">
                <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                  {" "}
                  Imposto
                </h6>
                <div className="form-group">
                  <input
                    id=""
                    type="text"
                    className="form-control cc-exp"
                    value={verImpostoS}
                    disabled
                  />
                </div>
              </div>

              <div className="col-3">
                <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                  {" "}
                  Comissao
                </h6>
                <div className="form-group">
                  <input
                    id=""
                    type="text"
                    className="form-control cc-exp"
                    value={verComissaoS}
                    disabled
                  />
                </div>
              </div>

              <div className="col-3">
                <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                  {" "}
                  Valor de Venda
                </h6>
                <div className="form-group">
                  <input
                    id=""
                    type="text"
                    className="form-control cc-exp"
                    value={vertotalS}
                    disabled
                  />
                </div>
              </div>
            </div>

            {/*  */}
          </div>
        )}
        {/*  */}

        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span>{i18n.t("buttons.save")}</span>
          </button>
        </div>
        {/*  */}
      </form>
      {/* FIM ANIMAIS */}
    </div>
  );
};

export default StokProduto;
