import Menu from "../../components/layout/Menu";
import ListFazenda from "../../components/listTable/ListFazenda";

const Fazenda = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListFazenda />
      </div>
      {/*  */}
    </>
  );
};

export default Fazenda;
