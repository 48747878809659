import api from "./api";

export const getCategorias = async () => {
  try {
    return await api.get("CategoriaProduto");
  } catch (error) {
    console.log(error.message);
  }
};
export const createCategoria = async (data) => {
  try {
    return await api.post("criar_categoriaProduto", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getCategoriaCodigo = async (codigo) => {
  try {
    return await api.get(`categoria/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateCategoria = async (data) => {
  try {
    return await api.put("categoria", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateCategoriaCodigo = async (codigo, data) => {
  try {
    return await api.put(`categoriaProduto/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};


export const removeCategoria = async (codigo) => {
  try {
    return await api.delete(`categoria/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
