import React, { useState, useEffect } from "react";
import { createCooperadorRapido } from "../../service/CooperadoresService";
import * as messages from "../message/toastr";
import * as yup from "yup";
import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const CadastrarCooperadorModal = (props, cooperadorId) => {
  //SALVAR
  const [cooperador, setCooperador] = useState({
    imgUrl: "",
    nome: "",
    sobrenome: "",
    email: "",
    telefone: "",
    bi: "",
    senha: "",
    endereco: "",
    website: "",
    data: "",
  });

  const [imgUrl, setImgUrl] = useState("");

  const {
    nome,
    sobrenome,
    email,
    telefone,
    bi,
    senha,
    endereco,
    website,
    data,
  } = cooperador;

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //
  const onInputChange = (e) => {
    setCooperador({ ...cooperador, [e.target.name]: e.target.value });
  };

  //Botao Salvar
  const saveUpdateCooperador = async (e) => {
    e.preventDefault();

    if (!(await validate())) return;

    const formData = new FormData();
    formData.append("imgUrl", imgUrl);
    formData.append("nome", nome);
    formData.append("sobrenome", sobrenome);
    formData.append("email", email);
    formData.append("telefone", telefone);
    formData.append("bi", bi);
    formData.append("senha", senha);
    formData.append("endereco", endereco);
    formData.append("website", website);
    formData.append("data", data);

    //
    await createCooperadorRapido(formData)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        props.mudarPai("fazenda");
        // cooperadorId.addIdCooperador = response.data.codigo
        console.log(response.data.codigo);
        props.verIdCop(response.data.codigo);
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
      });
  };

  //Buscar imagem
  const showPreview = (e) => {
    if (e.target.files.length > 0) {
      setImgUrl(e.target.files[0]);
      var src = URL.createObjectURL(e.target.files[0]);
      var preview = document.getElementById("file-ip-1-preview");
      preview.src = src;
      preview.style.display = "block";
    }
  };

  async function validate() {
    let schema = yup.object().shape({
      nome: yup
        .string("O campo nome é obrigatório!")
        .required("O campo nome é obrigatório!"),
      email: yup
        .string("O campo email é obrigatório!")
        .required("O campo email é obrigatório!")
        .email("Erro: e-mail inválido"),
      senha: yup
        .string("Erro: Necessário preencher a senha!")
        .required("Erro: Necessário preencher a senha!")
        .min(6, "Erro: A senha deve ter no mínimo 6 caracteres!"),
    });
    try {
      await schema.validate(cooperador);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }

  //
  return (
    <>
      <form
        enctype="multipart/form-data"
        onSubmit={(e) => saveUpdateCooperador(e)}
      >
        {status.type === "error" ? (
          <p style={{ color: "#ff0000" }}>{status.mensagem}</p>
        ) : (
          ""
        )}
        {/* COOPERADOR */}
        <div className="row">
          <div className="col-6">
            <div className="input-group">
              <div className="preview">
                <img id="file-ip-1-preview" />
              </div>
              {/* </div> */}
              <label className="custom-btn" for="file-ip-1">
                {i18n.t("messages.carregar_foto")}
              </label>
              <input
                type="file"
                id="file-ip-1"
                accept="image/*"
                name="imgUrl"
                className="form-control cc-cvc"
                onChange={(e) => showPreview(e)}
                hidden
              ></input>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <input
                name="nome"
                type="text"
                className="form-control cc-exp"
                placeholder={i18n.t("messages.nome")}
                value={nome}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="input-group">
              <input
                name="sobrenome"
                type="sobrenome"
                className="form-control cc-exp"
                placeholder={i18n.t("messages.sobrenome")}
                value={sobrenome}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="input-group">
              <input
                name="email"
                type="email"
                className="form-control cc-exp"
                placeholder={i18n.t("messages.email")}
                value={email}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <input
                type="text"
                name="bi"
                className="form-control cc-exp"
                placeholder="BI"
                value={bi}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <input
                type="date"
                name="data"
                className="form-control cc-exp"
                placeholder=""
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="input-group">
              <input
                id=""
                name="telefone"
                type="text"
                className="form-control cc-cvc"
                placeholder={i18n.t("messages.telefone")}
                value={telefone}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <input
                name="endereco"
                type="text"
                className="form-control"
                placeholder={i18n.t("messages.endereco")}
                value={endereco}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="input-group">
              <input
                name="website"
                type="text"
                className="form-control"
                placeholder={i18n.t("messages.website")}
                value={website}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-addon">
                  <i className="fa fa-asterisk"></i>
                </div>
                <input
                  name="senha"
                  type="password"
                  className="form-control"
                  placeholder={i18n.t("messages.senha")}
                  value={senha}
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="input-group">
              <div className="input-group-addon">
                <i className="fa fa-asterisk"></i>
              </div>
              <input
                name=""
                type="password"
                className="form-control"
                placeholder={i18n.t("messages.confirmar_senha")}
              />{" "}
            </div>
          </div>
        </div>
        {/*  */}
        <div className="modal-footer">
          <button
            type="button"
            className="btn"
            data-dismiss="modal"
            style={{
              backgroundColor: "#b46739",
              color: "#fff",
            }}
          >
            {i18n.t("buttons.cancelar")}
          </button>
          <button
            type="submit"
            className="btn"
            style={{
              backgroundColor: "#9ba239",
              color: "#fff",
            }}
          >
            <span className="lar la-save">Seguinte</span>
          </button>
        </div>
        {/*  */}
        {/* FIM COOPERADOR */}
      </form>
    </>
  );
};

export default CadastrarCooperadorModal;
