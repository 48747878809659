const messages = {
    pt: {
        translations: {
            titles: {
                app: 'Minha Aplicação'
            },
            messages: {
                itWorks: 'Está funcionamndo corretamente como esperado, com vários idiomas!',
                smallText: 'Um pequeno texto',
                inicio: 'Início',
                menus: 'Menus',
                cooperativas: 'Cooperativas',
                cooperador: 'Cooperados',
                cooperadores: 'Cooperadores',
                fazendas: 'Fazendas',
                fazenda: 'Fazenda',
                animais: 'Animais',
                animal: 'Animal',
                leilao: 'Leilão',
                lance: 'Lance ',
                licitador: 'Licitador',
                comprovativos: 'Comprovativos',
                item: 'Item',
                exclusivo: 'Exclusivo',
                atividades: 'Atividades',
                vendas: 'Vendas',
                vendas_stock: 'Vendas | Stock',
                servicos: 'Serviços',
                agendas: 'Agendas',
                adicionar: 'Outros Ítens',
                funcionario: 'Funcionário',
                clientes: 'Clientes',
                novo: 'Cadastrar',
                funcionario: 'Funcionário',
                Pesquisar_por_nome: 'Pesquisar por nome...',
                Pesquisar_por_animal: 'Pesquisar por Animal...',
                buscar_por_fazenda: 'Buscar por Fazenda...',
                nome: 'Nome',
                email: 'E-mail',
                telefone: 'Telefone',
                endereco: 'Endereço',
                opcao: 'Opção',
                cadastrar: 'Cadastrar',
                cadastrar_automovel: 'Cadastrar Automóvel',
                atualizar: 'Atializar',
                website: 'Website (Opcional)',
                senha: 'Senha',
                confirmar_senha: 'Confirmar Senha',
                carregar_foto: 'Carregar Foto',
                carregar_foto_do_automovel: 'Carregar Foto do automóvel',
                peso: 'Peso',
                idade: 'Idade',
                raca: 'Raça',
                sexo: 'Sexo',
                proveniencia: 'Proveniencia',
                provincia: 'Província',
                tempo_de_duracao: 'Tempo de duração',
                especie: 'Espécie',
                selecionar_sexo: 'Selecionar o Sexo do Animal',
                macho_I: 'Macho Inteiro',
                macho_C: 'Macho Castrado',
                novilho: 'Novilho',
                novilha: 'Novilha',
                femea: 'Fêmea',
                touro: 'Touro',
                associar_fazenda: 'Associar a sua Fazenda',
                associar_cooperador: 'Associar Cooperador',
                localizacao: 'Localização',
                area: 'Área',
                preco: 'Preço',
                quota: 'Quota',
                data_venda: 'Data da Venda',
                linhas: 'Linhas por Página',
                terminar: 'Termimar Sessão',
                meu_perfil: 'Meu Perfil',
                notificacao: 'Notificações',

                visitante: 'Visitante',
                usuarios: 'Usuários',

                // Transporte
                transporte: 'Transporte',
                motorista: 'Motorista',
                modelo: 'Modelo',
                marca: 'Marca',
                tipo_de_carga: 'Tipo de Carga',
                preco_transporte: 'Preço do Transporte',
                entrega: 'Entrega',
                matricula: 'Matricula',
                proprietario: 'Proprietário',
                origem: 'Origem',
                destino: 'Destino',
                precoTransporte: 'Preço do Transporte',
                selecionar_pais: 'Selecionar País',
                selecionar_provincia: 'Selecionar Provincia',
                selecionar_municipio: 'Selecionar Município',
                trajetoria: 'Trajetória',
                // GRÁFICOS & RELATÓRIOS

                estatisticas: 'Estatísticas',
                graficos: 'Gráficos',
                titulo_grafico: 'CONTROLE DE DADOS CWAM',
                titulo_grafico_vendas: 'CONTROLE DE VENDAS DA CWAM',
                relatorios: 'Relatórios',
                todos: 'Todos',

                texto_relatorio: 'Dados Estatísticos Relacionado a Relatórios',

                relatorio_leilao: 'Relatório de Leilão',
                relatorio_vendas: 'Relatório de Vendas',
                relatorio_cooperador: 'Relatório de Cooperadores',
                relatorio_clientes: 'Relatório de Clientes',
                relatorio_servicos: 'Relatório de Serviços',
                relatorio_animais: 'Relatório de Animais',
                relatorio_agendas: 'Relatório de Agendas',
                relatorio_fazendas: 'Relatório de Fazendas',

                nif: 'NIF',
                seguro_social: 'Nº de seguro social',
                fundacao_fazenda: 'Fundação da Fazenda',
                cood_cooperador: 'Coodigo do Cooperador',
                buscar_cooperador: 'Buscar cooperador...',

                //Novos dados da Fazenda

                latitude: 'Latitude',
                longitude: 'Longuitude',
                selecionar_provincia: 'Selecionar Província',
                municipio: 'Município',

                voltar_leilao: 'Voltar ao Leilão',

                paginas: 'Páginas',

                //PAIS
                pais: 'País',

                // PROVENCIAS

                Huila: 'Huila',
                Luanda: 'Luanda',
                Cunene: 'Cunene',
                Huambo: 'Huambo',
                Namibe: 'Namibe',
                Benguela: 'Benguela',

                bi: 'BI',
                carta_de_conducao: 'Carta de Condução',

                //LEILAO

                horario: 'Horário',
                localidade: 'Localidade',
                lote: 'Lote',
                valor_inicial: 'Valor Inicial',
                status: 'Status',
                tipo: 'Tipo',
                selecionar_fazenda: 'Selecionar Fazenda',
                selecionar_cooperador: 'Selecionar Cooperador',
                data_realizar: 'Data a Realizar',

                // Leilão

                leilao_item: 'Leilão Ítem',
                dia: 'Dia',
                valor: 'Valor',
                MACHO: 'Macho',
                FEMEA: 'Fêmea',
                quantidade_fixa: 'Quantidade Fixa',
                // Agenda

                descricao: 'Descrição',

                // VENDA
                iva: 'IVA',
                stock: 'Stock',

                // Servicos

                titulo: 'Título',
                data: 'Data',

                // VIVITANTE    

                nascimento: 'Nascimento',

                // ENTREGA
                lista_entregas: 'Lista de Entregas',
                transportador: 'Transportador',
                precoTotal: 'Preço Total',
                valorDistancia: 'Valor da Distância',


                // ANIMAL

                selecionar_proveniente: 'Selecionar Proveniência',
                selecionar_especie: 'Selecionar Espécie',
                Bovinos: ' Bovinos',
                Caprinos: 'Caprinos',
                Suinos: 'Suinos',
                Buffalos: 'Buffalos',
                Esquideos: 'Esquideos',
                Asininos: 'Asininos',
                Ovinos: 'Ovinos',

                // Novas Alteraões de Idiomas
 
                itens_leilao: 'Itens do Leilão',
                mensagens: 'Mensagens',
                assunto: 'Assunto',
                menssagem: 'Mensagem',
                carrinho: 'Carrinho',
                quantidade: 'Quantidade',
                fornecedor: 'Fornecedor',
                fornecedores: 'Fornecedores',
                morada: 'Morada',
                categoria: 'Categoria',
                produtos: 'Produtos',
                produto: 'Produto',
                codigo: 'Código',
                voltar_leilao_exclusivo: 'Voltar ao leilão exclusivo',
                comissao: 'Comissão',
                imposto: 'Imposto',
                preco_inicial: 'Preço Inicial',
                preco_de_venda: 'Preço de venda',
                empresa: 'Empresa',

                //Recentes
                distancia: 'Distancia',
                stockProduto: 'Stock de Produtos',
                provincia_inicial: 'Província Inicial',
                provincia_final: 'Província final',
                itens_lote: 'Itens ao Lote',
                estado: 'Estado',
                voltar_lote: 'Voltar ao Lote',
                itens_do_lote: 'Intens do Lote',
                sobrenome: 'Sobre Nome',
                notificacoes: 'Notificações',
            },


            buttons: {
                save: 'Salvar',
                cancelar: 'Cancelar',
                pesquisar: 'Pesquisar',
            },


            h4: {
                texto_grafico_home: 'Efetivo animal estimado da região sul de angola'
            },


            span: {
                especie_1: 'BOVINO',
                especie_2: 'SUINOS',
                especie_3: 'CAPRINOS',
            },


            p: {
                descricao_grafico_1: 'Cerca de 95%  dos bovinos  de angola, são explorados pelos criadores  camponeses  (aproximadamente / 4.000.000 de cabeças)',
                descricao_grafico_2: 'A maior concentração, do efetivo pecuário do País, com um valor a rondar os 90%, está no Sul de Angola (aproximadamente 3.500.000 de cabeças).',
            },

            msg_secundaria: {
                deseja_eliminar: 'Deseja Eliminar ?',
                erro_salvar: 'Erro ao Salvar',
            }

        }

    }

}

export { messages }