
import Menu from "../../components/layout/Menu"; 
import ListDistancia from "../../components/listTable/ListDistancia";

const Distancia = () => {
  //
  return (
    <>
      <Menu />
      {/*  */}
      <div>
        <ListDistancia />
      </div>
      {/*  */}
    </>
  );
};

export default Distancia;



