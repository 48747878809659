import { data } from "jquery";
import api from "./api";

export const getTag = async () => {
  try {
    return await api.get("Tag");
  } catch (error) {
    console.log(error.message);
  }
};
export const createTag = async (data) => {
  try {
    return await api.post("criar_tag", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateTag = async (codigo, data) => {
  try {
    return await api.put(`tag/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeTag = async (codigo) => {
  try {
    return await api.delete(`tag/${codigo}`, data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getTagCodigo = async (codigo) => {
  try {
    return await api.get(`tag/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getAllListTag = async (params) => {
  try {
    return await api.get("tag", { params });
  } catch (error) {
    console.log(error.message);
  }
};
