import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getComprovativoCodigo, getCarrinhoCodigo, getAprovarCarrinhoCodigo, getPendenteCarrinhoCodigo, getNegarCarrinhoCodigo } from "../../service/ComprovativoService";
import {
  getCodigoProduto,
  getTotalAnimaisCart,
  getTotalLoteCartVisi,
  getTotalProdutoCart,
  getTotalProdutosCart,
} from "../../service/CarrinhoService";

// import { Document, Page, pdfjs } from "react-pdf";
import Menu from "../../components/layout/Menu";
import "@react-pdf-viewer/core/lib/styles/index.css";
// default layout plugin
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// Import styles of default layout plugin
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import * as messages from "../../components/message/toastr";

import { i18n } from "../../Traducao/translate/i18n";

//import { Document, Page, pdfjs } from 'react-pdf';
import redirects from "../../components/redirect";

const ViewComprovativo = (props) => {
  const { comprovativos } = redirects();
  const I18N_STORAGE_KEY = "i18nextLng";

  //
  const [comprovativo, setComprovativo] = useState({
    imgUrl: "",
    data: "",
    status: "",
    visitante: "",
  });

  const { codigo } = useParams();

  //
  useEffect(() => {
    ViewComprovativo();
    getAllItemsCarrinhoProduto();
    getAllItemsCarrinhoGlobal();
    VerOtotalProduto();
    VerOtotalAnimais();
    VerOtotalLote();
    VerOtotalProdutos();
    // viewCarrinho();
    pendenteCarrinho();
    aprovarCarrinho();
    negarCarrinho();

  }, []);

  //
  const ViewComprovativo = () => {
    if (codigo) {
      getComprovativoCodigo(codigo).then((response) => {
        setComprovativo(response.data);
        console.log(response.data);
      });
    }
  };


  //LISTAR


  // Itens do cariinho global LOte
  const [getItemsCarrinhoGlobal, setgetItemsCarrinhoGlobal] = useState([]);
  const getAllItemsCarrinhoGlobal = () => {
    getCarrinhoCodigo(codigo)
      .then((response) => {
        setgetItemsCarrinhoGlobal(response.data);
      })
      .catch((error) => { });
  };
  //SOMAR TOTAL DO CARRINHO

  var total = getItemsCarrinhoGlobal.reduce(getTotal, 0);
  function getTotal(total, item) {
    return total + (item.value * item.artigo.preco);
  }
  console.log(total);

  var total1 = getItemsCarrinhoGlobal.reduce(getTotal, 0);
  function getTotal(total, item) {
    return total + (item.value + item.artigo.preco);
  }
  console.log(total1);

  //


  //Pendente Compra do visitante
  const pendenteCarrinho = async (cod) => {
    getPendenteCarrinhoCodigo(cod)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        // viewCarrinho();
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
        console.log(error);
      });
  };

  //Aprovar Compra do visitante
  const aprovarCarrinho = async (cod) => {
    getAprovarCarrinhoCodigo(cod)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        // viewCarrinho();
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
        console.log(error);
      });
  };

  // Negar Compra do Visitante
  const negarCarrinho = async (cod) => {
    getNegarCarrinhoCodigo(cod)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
        // viewCarrinho();
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
        console.log(error);
      });
  };


  //Carrinho Global Produto
  const [getItemsCarrinhoProduto, setgetItemsCarrinhoProduto] = useState([]);
  const getAllItemsCarrinhoProduto = () => {
    getCodigoProduto(codigo)
      .then((response) => {
        setgetItemsCarrinhoProduto(response.data);
      })
      .catch((error) => { });
  };



  //SOMAR O CARRINHO LOTE E PRODUTO
  const [valorL, setValorL] = useState(0);

  const VerOtotalLote = async () => {
    await getTotalLoteCartVisi(codigo).then((response) => {
      setValorL(response.data);
    });
  };

  const [valorProdutos, setValorProdutos] = useState(0);

  const VerOtotalProdutos = async () => {
    await getTotalProdutoCart(codigo).then((response) => {
      setValorProdutos(response.data);
    });
  };
  //


  const [valor, setValor] = useState(0);

  const VerOtotalAnimais = async () => {
    await getTotalAnimaisCart(codigo).then((response) => {
      setValor(response.data);
    });
  };

  const [valorProduto, setValorProduto] = useState(0);

  const VerOtotalProduto = async () => {
    await getTotalProdutoCart(codigo).then((response) => {
      setValorProduto(response.data);
    });
  };

  //SOMA TOTALA DE CARRINHO GLOBAL

  // creating new plugin instance
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // pdf file onChange state
  const [pdfFile, setPdfFile] = useState(null);

  // pdf file error state
  const [pdfError, setPdfError] = useState("");

  // handle file onChange event
  const allowedFiles = ["application/pdf"];
  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    console.log(selectedFile);
    if (selectedFile) {
      if (selectedFile && allowedFiles.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = (e) => {
          setPdfError("");
          setPdfFile(e.target.result);
        };
      } else {
        setPdfError("Not a valid pdf: Please select only PDF");
        setPdfFile("");
      }
    } else {
      console.log("please select a PDF");
    }
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);


  //ESTADO CARRIMHO GLOBAL

  const PendenteCarrinhoCodigo = async () => {
    await getPendenteCarrinhoCodigo(codigo)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
      });
  };

  const AprovarCarrinhoCodigo = async () => {
    await getAprovarCarrinhoCodigo(codigo)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
      });
  };

  const NegarCarrinhoCodigo = async () => {
    await getNegarCarrinhoCodigo(codigo)
      .then((response) => {
        messages.mensagemSucesso("Salvo com sucesso", response.data);
      })
      .catch((error) => {
        messages.mensagemErro("Erro ao salvar", error);
      });
  };
  //

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              <div className="col-xs-12 col-sm-12">

                <Link
                  to="/comprovativos"
                  className="btn btn-flat m-b-30 m-t-30"
                  style={{ background: "#b46739", color: "#fff" }}
                >
                  <span className="fa fa-reply"></span>
                </Link>

                <div className="btns-estados-carrinho">
                  <button
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#444",
                      color: "#fff",
                      marginLeft: "20px",
                      fontSize: "1rem",
                      fontFamily: "Arial, Helvetica, sans-serif"
                    }}
                  >
                    <span className="las">Ver Comprovativo</span>
                  </button>

                  {/* /Status da Compra do Visitante/ */}

                  <a
                    onClick={() => PendenteCarrinhoCodigo()}
                    className="btn btn-success"
                    style={{
                      border: "none",
                      outline: "none",
                      fontWeight: "bold",
                      background: "#afaf1b",
                      color: "#fff",
                      marginLeft: "20px",
                      fontSize: "1rem",
                      fontFamily: "Arial, Helvetica, sans-serif"
                    }}
                  >
                    Pendente
                  </a>

                  {/*  */}
                  <a
                    onClick={() => AprovarCarrinhoCodigo()}
                    className="btn btn-success"
                    style={{
                      border: "none",
                      outline: "none",
                      fontWeight: "bold",
                      color: "#fff",
                      marginLeft: "20px",
                      fontSize: "1rem",
                      fontFamily: "Arial, Helvetica, sans-serif"
                    }}
                  >
                    Aprovar
                  </a>
                  {/*  */}

                  <a
                    onClick={() => NegarCarrinhoCodigo()}
                    className="btn btn-negar"
                    style={{
                      color: "#000",
                    }}
                  >
                    Negar
                  </a>
                </div>

                <Link to="/factura" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                      fontFamily: "arial"
                    }}
                    title="Relatórios"
                  >
                    <span className="fa fa-print"></span>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      Itens do carrinho do cliente - Lotes / Produtos
                    </strong>
                  </div>

                  <div className="col-xs-12 col-sm-12">
                    <div className="row">
                      <div className="col-sm-8">
                        <div className="card">
                          <div className="card-body">
                            {/* Carrinho Lote */}
                            <table className="table table-striped responsiveTable">
                              <thead>
                                <tr>
                                  <th scope="col">Descrição</th>
                                  <th scope="col">Quantidade</th>
                                  <th scope="col">Valor Unitario</th>
                                </tr>
                              </thead>
                              <tbody>
                                {getItemsCarrinhoGlobal.map((items) => (
                                  <tr key={items.codigo}>
                                    <td data-label="Nome:">
                                      {items.artigo.lote.nome}
                                    </td>

                                    <td data-label="Quantidade:">
                                      {items.artigo.lote.quantidade}
                                    </td>

                                    <td data-label="Preço:">
                                      {items.artigo.preco}
                                    </td>

                                  </tr>
                                ))}
                                {/*  */}
                                <tr
                                  style={{
                                    borderRadius: "10px",
                                    background: "#b46739",
                                    color: "white",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                    fontFamily: "Arial, Helvetica, sans-serif",
                                  }}
                                >
                                  {/* <td colSpan={3}></td> */}
                                  <td
                                    data-label="Total do Carrinho:"
                                    className="g_total"
                                    style={{
                                      textAlign: "left",
                                      borderRadius: "10px 0 0 0",

                                    }}
                                  >
                                    Total do carrinho:{" "}
                                  </td>
                                  <td
                                    className="g_total"
                                    style={{ borderRadius: "0 0 10px 0" }}
                                  >
                                    {(valorL).toLocaleString(
                                      "pt-br",
                                      {
                                        minimumFractionDigits: 2,
                                      }
                                    )}{" "}
                                    Kz
                                  </td>
                                </tr>


                              </tbody>
                            </table>
                            <br />
                            <br />
                            {/* Carrinho Produto */}
                            <table className="table table-striped responsiveTable">
                              <thead>
                                <tr>
                                  <th scope="col">Categoria</th>
                                  <th scope="col">Descrição</th>
                                  <th scope="col">Quantidade</th>
                                  <th scope="col">Valor Unitario</th>
                                </tr>
                              </thead>
                              <tbody>
                                {getItemsCarrinhoProduto.map((itemsP) => (
                                  <tr key={itemsP.codigo}>
                                    <td data-label="Categoria:">
                                      {itemsP.artigoProduto.produto.categoria.nome}
                                    </td>

                                    <td data-label="Produto:">
                                      {itemsP.artigoProduto.produto.nome}
                                    </td>

                                    <td data-label="Quantidade:">
                                      {itemsP.artigoProduto.quantidade_fixa}
                                    </td>

                                    <td data-label="Preço:">
                                      {itemsP.artigoProduto.preco}
                                    </td>

                                  </tr>
                                ))}
                                {/*  */}
                                <tr
                                  style={{
                                    borderRadius: "10px",
                                    background: "#b46739",
                                    color: "white",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {/* <td colSpan={3}></td> */}
                                  <td
                                    data-label="Total do Carrinho:"
                                    className="g_total"
                                    style={{
                                      textAlign: "left",
                                      borderRadius: "10px 0 0 0",
                                    }}
                                  >
                                    Total do carrinho:{" "}
                                  </td>
                                  <td
                                    className="g_total"
                                    style={{ borderRadius: "0 0 10px 0" }}
                                  >
                                    {(valorProdutos).toLocaleString(
                                      "pt-br",
                                      {
                                        minimumFractionDigits: 2,
                                      }
                                    )}{" "}
                                    Kz
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            {/* {getItemsCarrinhoProduto === 0 ?  : <h1>Sem Produtos</h1>} */}
                            {getItemsCarrinhoProduto === false ?
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">Categoria</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Quantidade</th>
                                    <th scope="col">Valor Unitario</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {getItemsCarrinhoProduto.map((itemsP) => (
                                    <tr key={itemsP.codigo}>
                                      <td data-label="Categoria:">
                                        {itemsP.artigoProduto.produto.categoria.nome}
                                      </td>

                                      <td data-label="Nome:">
                                        {itemsP.artigoProduto.produto.nome}
                                      </td>

                                      <td data-label="Quantidade:">
                                        {itemsP.artigoProduto.quantidade_fixa}
                                      </td>

                                      <td data-label="Preço:">
                                        {itemsP.artigoProduto.preco}
                                      </td>

                                    </tr>
                                  ))}
                                  {/*  */}
                                  <tr>
                                    <td colSpan={3}></td>
                                    <td
                                      data-label="Total do Carrinho:"
                                      className="g_total"
                                      style={{
                                        textAlign: "right",
                                        borderRadius: "10px 0 0 0",
                                      }}
                                    >
                                      Total do carrinho:{" "}
                                    </td>
                                    <td
                                      className="g_total"
                                      style={{ borderRadius: "0 0 10px 0" }}
                                    >
                                      {(valorProdutos).toLocaleString(
                                        "pt-br",
                                        {
                                          minimumFractionDigits: 2,
                                        }
                                      )}{" "}
                                      Kz
                                    </td>
                                  </tr>
                                </tbody>
                              </table> :
                              ""
                            }

                          </div>
                        </div>
                      </div>

                      <div className="col-sm-4">
                        <div className="card">
                          <div className="card" style={{ width: "100%", height: "100px", }}>
                            <ul className="list-group list-group-flush">
                              {/*     
                              {getItemsCarrinhoGlobal.map((items) => (
                                  <tr key={items.codigo}>
                                    <td>
                                      {items.carrinhoGlobal.statusCarrinhoGlobal}
                                    </td>
                                  </tr>
                                ))}   */}

                              <br />
                              <tr>
                                <td colSpan={3}></td>
                                <td
                                  className="g_total"
                                  style={{
                                    textAlign: "right",
                                    borderRadius: "10px 0 0 0",
                                  }}
                                >
                                  Total a pagar: {" "}
                                </td>
                                <td
                                  className="g_total"
                                  style={{ borderRadius: "0 0 10px 0", color: "#f00", fontWeight: "bold" }}
                                >
                                  {(valorL + valorProdutos).toLocaleString(
                                    "pt-br",
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}{" "}
                                  Kz
                                </td>
                              </tr>
                              {/*  */}

                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>
      {/*  */}

      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                Comprovativo
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
              {/* INICIO VER COMPROVATIVO */}
              <iframe
                src={`https://webcwam-polished-dust-7716.fly.dev/api/exibirpdfComprovativo/${comprovativo.imgUrl}`}
                style={{ marginLeft: "30px" }}
                width="700px"
                height="500px"
                frameborder="0"
              ></iframe>

              {/* <!-- .content --> */}

              <div className="container">
                {/* Upload PDF */}
                {/* <form>

                  <label><h5>Upload PDF</h5></label>
                  <br></br>

                  <input type='file' className="form-control"
                    onChange={handleFile}></input>

                  we will display error message in case user select some file
  other than pdf
                  {pdfError && <span className='text-danger'>{pdfError}</span>}

                </form> */}

                {/* View PDF */}
                {/* <h5>View PDF</h5>
                <div className="viewer">

                  render this if we have a pdf file
                  {pdfFile && (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
                      <Viewer fileUrl={pdfFile}
                        plugins={[defaultLayoutPluginInstance]}></Viewer>
                    </Worker>
                  )}

                  render this if we have pdfFile state null
                  {!pdfFile && <>Nehum arquivo foi selecionado</>}

                </div> */}
              </div>
              {/* FIM VER COMPRAVATIVO */}
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewComprovativo;
