import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { getArtigos } from "../../service/VendaService";
import logo from "../../assets/img/logo/logo1.jpg";

function App() {
  const componentRef = useRef();
  const [dados, setDados] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Relatorios",
  });

  const handleCancel = () => {
    window.location.href = "/vendas";
  };

  useEffect(() => {
    const busca = async () => {
      const { data } = await getArtigos();
      setDados(data);
    };
    busca();
  }, []);

  const [data, setData] = useState("");
  const dataTime = () => {
    setData(new Date().toLocaleString());
  };
  const [totalTime, setTotalTime] = useState([60]);
  useEffect(() => {
    setTimeout(() => {
      setTotalTime(totalTime - 1);
    }, 1000);
    dataTime();
  }, [totalTime]);

  return (
    <>
      <div className="Formatacao-Relatorio" ref={componentRef}>
        <div className="App_header">
          <div className="data_esq">
            <img src={logo} />
            <h6>Vagne Zau Lelo</h6>
            <small>{data}</small>
          </div>
          <div className="data_dir">
            <h6>Cooperativa Wapoma Agro-pecuária e Mineira</h6>
            <small>Huíla Lubango</small>
            <small>cooperativacwamahuila@gmail.com</small>
            <small>www.cwam.com</small>
          </div>
        </div>
        <header className="App_h1">
          <h4 className="txt-centro">Relatório de Stock</h4>
        </header>
        <div className="data_header">
          <div className="App_body">
            <div className="table_data">
              <table className="table">
                <thead>
                  <th className="th">Codigo</th>
                  <th className="th">Fazenda</th>
                  <th className="th">Animal</th>
                  <th className="th">Quantidade</th>
                  <th className="th">Tipo de Imposto</th>
                  <th className="th">Comissão</th>
                  <th className="th">Total do Cooperador</th>
                  <th className="th">Data</th>
                  <th className="th">Total</th>
                </thead>
                {dados.map((row) => (
                  <>
                    <tbody key={row.codigo}>
                      <tr className="tr">
                        <td className="td">{row.artigoId}</td>
                        <td className="td">{row.fazenda?.nome}</td>
                        <td className="td">{row.animal?.nome}</td>
                        <td className="td">{row.quantidade_fixa}</td>
                        <td className="td">{row.totalValorIva == 0
                          ? "Sem Iva"
                          : `${row.totalValorIva}kz (${row.imposto?.iva}%)`}</td>
                        <td className="td">{row.totalCooperativa} ({row.comissao?.quota}%)</td>
                        <td className="td">{row.totalCooperador}Kz</td>
                        <td className="td">{row.dataArtigo}</td>
                        <td className="td">{row.totalValorCrescinto}Kz</td>
                      </tr>
                    </tbody>
                  </>
                ))}
                <thead>
                  <th className="th">Total Global: {dados.length}</th>
                </thead>
              </table>
              <div className="data_total">
                <small>Total: {dados.length} Stocks </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="data_funny">
        <button onClick={handleCancel} className="btn botao_return">
          <span className="fa fa-reply"></span>
        </button>
        <button onClick={handlePrint} className="btn botao_print">
          <span className="fa fa-print"></span>
        </button>
      </div>
    </>
  );
}

export default App;
