import api from "./api";

export const getLeilaoItens = async () => {
  try {
    return await api.get("LeilaoItem");
  } catch (error) {
    console.log(error.message);
  }
}; 
export const createLeilaoItem = async (data) => {
  try {
    return await api.post("criar_leilaoItem", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getLeilaoItemCodigo = async (codigo) => {
  try {
    return await api.get(`ItemPLeilao/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
// Iniciar Leilao
export const getStartLeilaoItemCodigo = async (codigo) => {
  try {
    return await api.get(`startLote/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
// 

// Stop Leilao
export const getSopLeilaoItemCodigo = async (codigo) => {
  try {
    return await api.get(`stopLote/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
// 


export const getItemLeilaoCodigo = async (codigo) => {
  try {
    return await api.get(`ItemDetail/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getLeilaoCodigoItem = async (codigo) => {
  try {
    return await api.get(`pegarleilao/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateLeilaoItem = async (data) => {
  try {
    return await api.put("", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeLeilaoItem = async (codigo) => {
  try {
    return await api.delete(`${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
