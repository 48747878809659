import api from "./api";

export const getImpostos = async () => {
  try {
    return await api.get("impostos");
  } catch (error) {
    console.log(error.message);
  }
};
export const createImposto = async (data) => {
  try {
    return await api.post("imposto", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getImpostoCodigo = async (codigo) => {
  try {
    return await api.get(`imposto/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateImposto = async (data) => {
  try {
    return await api.put("imposto", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeImposto = async (codigo) => {
  try {
    return await api.delete(`imposto/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
