import api from "./api";

export const getParticipanteCodigo = async (codigo) => {
  try {
    return await api.get(`leilaoParticipante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getPendenteParticipanteCodigo = async (codigo) => {
  try {
    return await api.get(`pendenteParticipante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};


export const getPendenteParticipanteCodigo2 = async (codigo) => {
  try {
    return await api.get(`pendenteParticipantes2/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getReprovarParticipanteCodigo = async (codigo) => {
  try {
    return await api.get(`ReprovarParticipante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getParticipantesAceitesCodigo = async (codigo) => {
  try {
    return await api.get(`participanteAceites/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const getAprovarParticipanteCodigo = async (codigo) => {
  try {
    return await api.get(`AprovarParticipante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};

export const removeParticipante = async (codigo) => {
  try {
    return await api.delete(`participante/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};