import api from "./api";

export const getAll = async () => {
  try {
    return await api.get("transportadores");
  } catch (error) {
    console.log(error.message);
  }
};
export const create = async (data) => {
  try {
    return await api.post("transportador", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const update = async (data) => {
  try {
    return await api.put("transportador", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const remove = async (codigo) => {
  try {
    return await api.delete(`/transportador/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getImagem = async (imagem) => {
  try {
    return await api.get(`exibirImagemTransporte/${imagem}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getCodigo = async (codigo) => {
  try {
    return await api.get(`transportador/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getAllList = async (params) => {
  try {
    return await api.get("transportador", { params });
  } catch (error) {
    console.log(error.message);
  }
};
