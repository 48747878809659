import "./App.css";
import "toastr/build/toastr.min.js";
import "toastr/build/toastr.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import 'animate.css';

import AppRoutes from "./AppRoutes";

function App() {
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
