import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import {
  createEspecie,
  getEspecieCodigo,
  getEspecies,
  removeEspecie,
  updateEspecie,
} from "../../service/EspecieService";
import * as messages from "../message/toastr";
import * as yup from "yup";

import Swal from "sweetalert2";

import { i18n } from "../../Traducao/translate/i18n";
const I18N_STORAGE_KEY = "i18nextLng";

const ListEspecie = () => {
  const { codigo } = useParams();
  const [especieAnimals, setEspecies] = useState([]);

  //
  useEffect(() => {
    getAllEspecies();
  }, []);

  const getAllEspecies = () => {
    getEspecies()
      .then((response) => {
        setEspecies(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const deleteEspecie = (codigo) => {
    Swal.fire({
      // title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#9ba236",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeEspecie(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllEspecies();
        });
      }
    });
  };

  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };

  //SALVAR
  const [AnimalEspecies, setEspecie] = useState({
    especieAnimal: "",
  });

  const { especieAnimal } = AnimalEspecies;

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });
  //

  const onInputChange = (e) => {
    setEspecie({ ...AnimalEspecies, [e.target.name]: e.target.value });
  };

  //Limpar Campos
  const handleClearn = () => {
    setEspecie({
      especieAnimal: "",
    });
  };
  //Botao Salvar
  const saveUpdateEspecies = async (e) => {
    e.preventDefault();


    if (!(await validate())) return;


    if (codigo) {
      await updateEspecie(AnimalEspecies)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllEspecies();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createEspecie(AnimalEspecies)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllEspecies();
          handleClearn();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loadEspecie();
  }, []);
  //
  const loadEspecie = () => {
    if (codigo) {
      getEspecieCodigo(codigo)
        .then((response) => {
          setEspecie(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };
  //

  //
  async function validate() {
    let schema = yup.object().shape({

      especieAnimal: yup
        .string("Informe a espécie do animal!")
        .required("Informe a espécie do animal!"),
    });
    try {
      await schema.validate(AnimalEspecies);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //

  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/addespecies" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus"></span>
                  </button>
                </Link>
                <Link to="/relatorioespecies" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{
                      background: "#b46739",
                      color: "#fff",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="fa fa-print"></span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button>
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.especie")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">{i18n.t("messages.especie")}</th>

                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {especieAnimals.map((especies) => (
                          <tr key={especies.codigo}></tr>
                        ))}
                      </tbody>
                    </table>
                    <table className="table table-striped responsiveTable">
                      <thead></thead>
                      <tbody>
                        {especieAnimals.map((especie) => (
                          <tr key={especie.codigo}>
                            <td data-label="Espécie:">{especie.especieAnimal}</td>
                            <td data-label="Adicionais:">
                              <Link
                                to={`/editespecie/${especieAnimal.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "10px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>

                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() =>
                                  deleteEspecie(especieAnimal.codigo)
                                }
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            <div className="card-body">
              <div className="default-tab">
                <form
                  enctype="multipart/form-data"
                  onSubmit={(e) => saveUpdateEspecies(e)}
                >
                  {status.type === "error" ? (
                    <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
                  ) : (
                    ""
                  )}
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="especieAnimal"
                          className="form-control cc-exp"
                          placeholder="Descrição"
                          value={especieAnimal}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                      style={{
                        backgroundColor: "#b46739",
                        color: "#fff",
                      }}
                    >
                      {i18n.t("buttons.cancelar")}
                    </button>
                    <button
                      type="submit"
                      className="btn"
                      style={{
                        backgroundColor: "#9ba239",
                        color: "#fff",
                      }}
                    >
                      {i18n.t("buttons.save")}
                    </button>
                  </div>
                  {/*  */}
                  {/* FIM distancia */}
                </form>

                {/*  */}
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListEspecie;
