import api from "./api";

export const getMensanges = async () => {
  try {
    return await api.get("Messagem");
  } catch (error) {
    console.log(error.message);
  }
};
export const createMensangem = async (data) => {
  try {
    return await api.post("criar_messagem", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getMensangeCodigo = async (codigo) => {
  try {
    return await api.get(`messagem/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateMensangem = async (data) => {
  try {
    return await api.put("messagem", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeMensangem = async (codigo) => {
  try {
    return await api.delete(`messagems/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
