import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { createDistancia, getDistanciaCodigo, getDistancias, removeDistancia, updateDistancia } from "../../service/DistanciaService";
import { getProvincias } from "../../service/ProvinciaService";
import * as messages from "../message/toastr";
import * as yup from "yup";
import Swal from "sweetalert2";

import { i18n } from "../../Traducao/translate/i18n";

const I18N_STORAGE_KEY = "i18nextLng";

const ListDistancia = ({ vetor }) => {
  const { codigo } = useParams();
  const [distancias, setDistancias] = useState([]);
  const [provinciaOrigems, setProvinciaOrigem] = useState([]);
  const [provinciaDestinos, setProvinciaDestino] = useState([]);

  //
  useEffect(() => {
    getAllDistancias();
    getAllProvinciaOrigem();
    getAllProvinciaDestino();
  }, []);

  //LISTAR A DISTANCIA A PROVINCIA ORIGEM E DESTINO
  const getAllDistancias = () => {
    getDistancias()
      .then((response) => {
        setDistancias(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllProvinciaOrigem = () => {
    getProvincias()
      .then((response) => {
        setProvinciaOrigem(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const getAllProvinciaDestino = () => {
    getProvincias()
      .then((response) => {
        setProvinciaDestino(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  //APAGAR
  const deletedistancia = (codigo) => {
    Swal.fire({
      title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
      text: "Realmente deseja eliminar!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        removeDistancia(codigo).then((response) => {
          Swal.fire("Eliminado!", "Eliminado.", "success");
          getAllDistancias();
        });
      }
    });
  };

  //
  const titulo = () => {
    if (codigo) {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.atualizar")} </h3>
      );
    } else {
      return (
        <h3 style={{ color: "#fff" }}> {i18n.t("messages.cadastrar")} </h3>
      );
    }
  };


  //SALVAR
  const [distancia, setDistancia] = useState({
    provinciaOrigem: {
      codigo:""
    },
    provinciaDestino: {
      codigo:""
    },
    Valor: "",
    trajectoria: "",
  });
  //Limpar os campos
  const handleClear = () => {
    setDistancia({
      provinciaOrigem: "",
      provinciaDestino: "",
      Valor: "",
      trajectoria: "",
    })
  }
  //
  const { provinciaOrigem, provinciaDestino, Valor, trajectoria } = distancia;
  //

  //
  const [status, setStatus] = useState({
    type: "",
    mensagem: "",
  });


    //
    const [provinciaO, setprovinciaO] = useState();
    const [provinciaD, setprovinciaD] = useState();
  //
  const onInputChange = (e) => {
    setDistancia({ ...distancia, [e.target.name]: e.target.value });
        // 
          if (e.target.name === 'provinciaOrigem') {
            setprovinciaO(e.target.value)
          }
          // 
          if (e.target.name === 'provinciaDestino') {
            setprovinciaD(e.target.value)
          }
          // 
  };

  //Botao Salvar
  const saveUpdatedistancia = async (e) => {
    e.preventDefault();


    if (!(await validate())) return;


    // const data = new FormData();
    // formData.append("provinciaOrigem", provinciaOrigem);
    // formData.append("provinciaDestino", provinciaDestino);
    // formData.append("Valor", Valor);
    // formData.append("trajectoria", trajectoria);

    const data = {
      provinciaOrigem:{
        codigo: provinciaO
      },
      provinciaDestino:{
        codigo: provinciaD
      },
      Valor,
      trajectoria

    }

    //
    if (codigo) {
      await updateDistancia(data)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          getAllDistancias();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //
      await createDistancia(data)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          getAllDistancias();
          handleClear();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };
  //
  useEffect(() => {
    loaddistancia();
  }, []);
  //
  const loaddistancia = () => {
    if (codigo) {
      getDistanciaCodigo(codigo)
        .then((response) => {
          setDistancia(response.data);
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao buscar dados");
        });
    }
  };
  //

  //
  async function validate() {
    let schema = yup.object().shape({

      trajectoria: yup
        .string("Selecione a trajetória para esta distância!")
        .required("Selecione a trajetória para esta distância!"),

      Valor: yup
        .string("Adicione o valor para esta distância!")
        .required("Adicione o valor para esta distância!"),

      provinciaDestino: yup
        .string("Selecione a província de destino!")
        .required("Selecione a província de destino!"),

      provinciaOrigem: yup
        .string("Selecione a província de origem!")
        .required("Selecione a província de origem!"),

    });
    try {
      await schema.validate(distancia);
      return true;
    } catch (err) {
      setStatus({
        type: "error",
        mensagem: err.errors,
      });
      return false;
    }
  }
  //
  return (
    <>
      <Menu />
      <div id="right-panel" className="right-panel">
        {/*  */}
        <div className="breadcrumbs">
          <div className="breadcrumbs-inner">
            <div className="row m-0">
              {/* <div className="col-xs-12 col-sm-12"> */}
              <div className="col-xs-6 col-sm-6">
                <Link to="/adddistancias" id="event-modal">
                  <button
                    type="submit"
                    className="btn btn-flat m-b-30 m-t-30"
                    data-toggle="modal"
                    data-target="#largeModal"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <span className="las la-plus">
                    </span>
                  </button>
                </Link>
              </div>
              {/*  */}
              <div className="col-xs-6 col-sm-6">
                <form className="d-flex">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder={i18n.t("messages.Pesquisar_por_nome")}
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-success" type="submit">
                    {i18n.t("buttons.pesquisar")}
                  </button>
                </form>
              </div>
              {/*  */}
            </div>
          </div>
        </div>

        {/*  */}
        <div className="content">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{ background: "#b46739", color: "#fff" }}
                  >
                    <strong className="card-title">
                      {i18n.t("messages.distancia")}
                    </strong>
                  </div>
                  <div className="card-body">
                    <table className="table table-striped responsiveTable">
                      <thead>
                        <tr>
                          <th scope="col">
                            {i18n.t("messages.provincia_inicial")}
                          </th>

                          <th scope="col">
                            {i18n.t("messages.provincia_final")}
                          </th>

                          <th scope="col">Distancia</th>
                          {/* <th scope="col">{i18n.t("messages.valor")}</th> */}

                          <th scope="col">{i18n.t("messages.trajectoria")}</th>

                          <th scope="col">{i18n.t("messages.opcao")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {distancias.map((distancia) => (
                          <tr key={distancia.codigo}>
                            <td data-label="Origem:">{distancia.provinciaOrigem.nome}</td>
                            <td data-label="Destino:">{distancia.provinciaDestino.nome}</td>
                            <td data-label="Valor:">{distancia.valor}Km</td>
                            <td data-label="Trajetória:">{distancia.trajectoria}</td>
                            <td data-label="Adicionais:">

                              <Link
                                to={`/distancia/editdistancia/codigo/${distancia.codigo}`}
                                id="event-modal"
                                style={{ marginLeft: "5px" }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-warning"
                                  data-toggle="modal"
                                  data-target="#largeModal"
                                >
                                  <span className="las la-edit link-add"></span>
                                </button>
                              </Link>

                              <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() =>
                                  deletedistancia(distancia.codigo)
                                }
                                style={{ marginLeft: "5px" }}
                              >
                                <span className="las la-trash link-add"></span>
                              </button>

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {/*  */}
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-end">
                        <li className="page-item disabled">
                          <a
                            className="page-link"
                            href="#"
                            tabindex="-1"
                            aria-disabled="true"
                          >
                            Previous
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            Next
                          </a>
                        </li>
                      </ul>
                    </nav>
                    {/*  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- .animated --> */}
        </div>
        {/* <!-- .content --> */}

        <div className="clearfix"></div>
        {/*  */}
      </div>

      {/* <!--------Inicio Cadastrar------> */}
      <div
        className="modal fade"
        id="largeModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="largeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{ backgroundColor: "#9ba236" }}
            >
              <h5 className="modal-title" id="largeModalLabel">
                {titulo()}
              </h5>
            </div>

            {/*  */}
            <div className="card-body">
              <div className="default-tab">
                {/*  */}

                <form
                  onSubmit={(e) => saveUpdatedistancia(e)}
                >

                  {status.type === "error" ? (
                    <p style={{ color: "#ff0000", fontFamily: "arial", textAlign: "center", fontWeight: "100" }}>{status.mensagem}</p>
                  ) : (
                    ""
                  )}

                  {/* distancia */}
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <select
                          data-placeholder="Selecionar Proprietario..."
                          className="form-control cc-exp"
                          tabindex="1"
                          name="provinciaOrigem"
                          value={provinciaOrigem}
                          onChange={(e) => onInputChange(e)}
                        >
                          <option
                            value=""
                            label="Selecionar a provincia de origem..."
                          ></option>
                          {provinciaOrigems.map((proprietarioO) => (
                            <option
                              key={proprietarioO.codigo}
                              value={proprietarioO.codigo}
                            >
                              {proprietarioO.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <select
                          data-placeholder="Selecionar Proprietario..."
                          className="form-control cc-exp"
                          tabindex="1"
                          name="provinciaDestino"
                          value={provinciaDestino}
                          onChange={(e) => onInputChange(e)}
                        >
                          <option
                            value=""
                            label="Selecionar a provincia de destino..."
                          ></option>
                          {provinciaDestinos.map((proprietarioD) => (
                            <option
                              key={proprietarioD.codigo}
                              value={proprietarioD.codigo}
                            >
                              {proprietarioD.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="Valor"
                          className="form-control cc-exp"
                          placeholder="Valor"
                          value={Valor}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <select
                          name="trajectoria"
                          value={trajectoria}
                          className="form-control cc-exp"
                          onChange={(e) => onInputChange(e)}
                        >
                          <option>Trajectoria</option>
                          <option value="DISPONIVEL">Disponível</option>
                          <option value="INDISPONIVEL">Indisponível</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn"
                      data-dismiss="modal"
                      style={{
                        backgroundColor: "#b46739",
                        color: "#fff",
                      }}
                    >
                      {i18n.t("buttons.cancelar")}
                    </button>
                    <button
                      type="submit"
                      className="btn"
                      style={{
                        backgroundColor: "#9ba239",
                        color: "#fff",
                      }}
                    >

                      {i18n.t("buttons.save")}
                    </button>
                  </div>
                  {/*  */}
                  {/* FIM distancia */}
                </form>

                {/*  */}
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
      {/* <!--------Fim Cadastrar------> */}
    </>
  );
};

export default ListDistancia;
