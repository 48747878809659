import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { getAnimalCodigo } from "../../service/AnimalService";
import { useParams } from "react-router-dom";
import logo from "../../assets/img/logo/logo1.jpg";
import redirects from "../../components/redirect";

function App() {
  const componentRef = useRef();
  const { codigo } = useParams();
  const { animais } = redirects();

  const [dados, setDados] = useState({
    peso: "",
    especie: "",
    raca: "",
    pais: "",
    sexoAnimal: "",
    tempoDeDuracao: "",
    animalId: "",
    fazenda: "",
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Relatorios",
  });

  useEffect(() => {
    busca();
  }, []);

  const busca = async () => {
    const { data } = await getAnimalCodigo(codigo);
    setDados(data);
  };

  // useEffect(() => {
  //   const numberOne = async () => {
  //     const { data } = await AnimalService.getCodigo(codigo);
  //     setDados({
  //       codigo: data.codigo,
  //       cod: data.cod,
  //       nome: data.descricao,
  //       fazenda: data.fazenda.nome,
  //       cooperador: data.fazenda.cooperador.nome,
  //       nifFazenda: data.fazenda.nif,
  //       peso: data.peso,
  //       especie: data.especie,
  //       raca: data.raca,
  //       pais: data.pais,
  //       sexoAnimal: data.sexoAnimal,
  //     });
  //     console.log(data);
  //   };
  //   numberOne();
  // }, [codigo]);

  const [data, setData] = useState("");
  const dataTime = () => {
    setData(new Date().toLocaleString());
  };
  const [totalTime, setTotalTime] = useState([60]);
  useEffect(() => {
    setTimeout(() => {
      setTotalTime(totalTime - 1);
    }, 1000);
    dataTime();
  }, [totalTime]);

  return (
    <>
      <div className="Formatacao-Relatorio" ref={componentRef}>
        <div className="App_header">
          <div className="data_esq">
            <img src={logo} />
            <h6>Vagne Zau Lelo</h6>
            <small>{data}</small>
          </div>
          <div className="data_dir">
            <h6>Cooperativa Wapoma Agro-pecuária e Mineira</h6>
            <small>Huíla Lubango</small>
            <small>cooperativacwamahuila@gmail.com</small>
            <small>www.cwam.com</small>
          </div>
        </div>
        <header className="App_h1">
          <h3>Relatório do Animal</h3>
        </header>

        <div className="data_header">
          <div className="data_cab">
            <div className="data_esq">
              <p>Dados da Fazenda</p>
              <p>Nome: {dados.fazenda?.nome}</p>
              <p>NIF: {dados.fazenda?.nif}</p>
            </div>
            <div className="data_esq">
              <p>Proprietario: {dados.fazenda?.cooperador?.nome}</p>
              <p>Localização {dados.fazenda?.municipio?.nome}</p>
              <p>Área: {dados.fazenda?.area} Ha</p>
            </div>
          </div>

          <div className="App_body">
            <div className="table_data">
              <table className="table">
                <thead>
                  <th className="th">Codigo</th>
                  {/* <th className="th">Espécie</th> */}
                  <th className="th">Fazenda</th>
                  <th className="th">Idade</th>
                  <th className="th">Peso</th>
                  <th className="th">Raça</th>
                  <th className="th">Sexo do Animal</th>
                  <th className="th">Proveniência</th>
                </thead>
                <tbody>
                  <tr className="tr">
                    <td className="td">{dados.animalId}</td>
                    <td className="td">{dados.fazenda?.nome}</td>
                    <td className="td">
                      {dados.idade + " "}
                      {dados.idade >= 2 ? "anos" : "ano"}
                    </td>
                    <td className="td">{dados.peso} Kg</td>
                    <td className="td">{dados.raca.racaAnimal}</td>
                    <td className="td">{dados.sexoAnimal}</td>
                    <td className="td">{dados.pais?.nome}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="data_funny">
        <button onClick={animais} className="btn botao_return">
          <span className="fa fa-reply"></span>
        </button>
        <button onClick={handlePrint} className="btn botao_print">
          <span className="fa fa-print"></span>
        </button>
      </div>
    </>
  );
}

export default App;
