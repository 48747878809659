import Menu from "../../components/layout/Menu"; 
import ListFuncionario from "../../components/listTable/ListFuncionario";

const Funcionario = () => {
  //
  return (
    <>
      <Menu />
      {/*  */} 
      <div>
        <ListFuncionario /> 
      </div>
      {/*  */}
    </>
  );
};

export default Funcionario;
