import api from "./api";

export const getEntregas = async () => {
  try {
    return await api.get("Entrega");
  } catch (error) {
    console.log(error.message);
  }
};
export const createEntrega = async (data) => {
  try {
    return await api.post("criar_entrega", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getEntregaCodigo = async (codigo) => {
  try {
    return await api.get(`entrega/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateEntrega = async (data) => {
  try {
    return await api.put("entrega", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeEntrega = async (codigo) => {
  try {
    return await api.delete(`entrega/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
