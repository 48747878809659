import React, { useState, useEffect } from "react";
import { i18n } from "../../Traducao/translate/i18n";
import { createArtigo, updateArtigo } from "../../service/VendaService";
import { getFazendas } from "../../service/FazendaService";
import { getComissaoCodigo, getComissoes } from "../../service/ComissaoService";
import { getImpostoCodigo, getImpostos } from "../../service/ImpostoService";
import {
  getAnimalLote,
  getLoteFazenda,
  getloteRapidoByLote,
} from "../../service/LoteItemService";
import { getLoteCodigo } from "../../service/LoteItemService";

import * as messages from "../message/toastr";
import { Link, useParams } from "react-router-dom";
const I18N_STORAGE_KEY = "i18nextLng";

const StokLote = () => {
  // Buscar por ID
  const { codigo } = useParams();
  //SALVAR
  const [venda, setVenda] = useState({
    preco: "",
    comissao: "",
    imposto: "",
    lote: "",
  });

  const [verFazenda, setverFazenda] = useState([]);
  const [verComissao, setverComissao] = useState([]);
  const [verImposto, setverImposto] = useState([]);
  useEffect(() => {
    getAllFazenda();
    getAllComissao();
    getAllImposto();
    // verTotalVendaCalculo();
  }, []);

  const getAllFazenda = () => {
    getFazendas()
      .then((response) => {
        setverFazenda(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };
  const getAllComissao = () => {
    getComissoes()
      .then((response) => {
        setverComissao(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const getAllImposto = () => {
    getImpostos()
      .then((response) => {
        setverImposto(response.data);
      })
      .catch((error) => {
        messages.mensagemErro(error);
      });
  };

  const [verAnimal, setverAnimal] = useState([]);
  const [VerLoteAnimal, setVerLoteAnimal] = useState([]);
  const [VerLoteRapido, setVerLoteRapido] = useState([]);
  const verOsAniamis = (e) => {
    getLoteFazenda(e.target.value).then((response) => {
      setverAnimal(response.data);
      console.log(response.data);
    });
  };

  const [verFichaAnimal, setverFichaAnimal] = useState({
    nome: "",
    peso: "",
    idade: "",
    raca: "",
    especie: "",
    sexoAnimal: "",
    pais: "",
  });

  const { preco, comissao, imposto, lote } = venda;
  const [verPrecoS, setverPrecoS] = useState();
  const [verImpostoS, setverImpostoS] = useState(0);
  const [verImpostoSelect, setverImpostoSelect] = useState(0);
  const [verComissaoS, setverComissaoS] = useState(0);
  const [verComissaoSelect, setverComissaoSelect] = useState(0);
  const [vertotalS, setvertotalS] = useState(0);
  const [EscolhaCAtegoria, setEscolhaCAtegoria] = useState(0);

  const [EscolhaVerItemsLote, setEscolhaVerItemsLote] = useState("");

  //
  const onInputChange = (e) => {
    setVenda({ ...venda, [e.target.name]: e.target.value });
    if (e.target.name === "lote") {
      getLoteCodigo(e.target.value).then((response) => {
        // setverAnimal(response.data);

        if (response.data.geral && !response.data.existente) {
          getloteRapidoByLote(e.target.value)
          .then((response) => {
            setVerLoteRapido(response.data);
          })
          .catch((err)=>{
            console.log("Ocorreu um erro ==> "+err)
          })
          setEscolhaVerItemsLote("rapido");

        }
        if (!response.data.geral && response.data.existente) {
          // getAnimalLote(e.target.value).then((response) => {
          //   setVerLoteAnimal(response.data);
          // });
          setEscolhaVerItemsLote("existente");

          console.log("Listar Lote Existente");
        }
        if (!response.data.geral && !response.data.existente) {
          // getAnimalLote(e.target.value).then((response) => {
          //   setVerLoteAnimal(response.data);
          // });
          setEscolhaVerItemsLote("sem");
          console.log("Adicione Animais a este lote");
        }
      });

      // getAnimalLote(e.target.value).then((response) => {
      //   setVerLoteAnimal(response.data);
      // });
    }
    if (e.target.name === "preco") {
      setverPrecoS(parseInt(e.target.value));
      if (verImpostoS === 0 && verComissaoS === 0) {
        setvertotalS(parseInt(e.target.value));
      } else {
        const impostoN = parseInt(
          (parseInt(e.target.value) * verImpostoSelect) / 100
        );
        setverImpostoS(impostoN);

        const comissaoN = parseInt(
          ((parseInt(e.target.value) + impostoN) * verComissaoSelect) / 100
        );
        setverComissaoS(comissaoN);

        setvertotalS(parseInt(parseInt(e.target.value + parseInt(impostoN))));
      }
    }

    if (e.target.name === "imposto") {
      getImpostoCodigo(e.target.value)
        .then((response) => {
          const imposto = parseInt((verPrecoS * response.data.iva) / 100);
          setverImpostoS(imposto);
          setverImpostoSelect(response.data.iva);
          setvertotalS(parseInt(verPrecoS + imposto));
        })
        .catch((error) => {
          messages.mensagemErro(error);
        });
    }

    if (e.target.name === "comissao") {
      getComissaoCodigo(e.target.value)
        .then((response) => {
          if (verImpostoS === 0) {
            const comissao = parseInt((verPrecoS * response.data.quota) / 100);
            setverComissaoS(comissao);
          } else {
            const comissao = parseInt(
              ((verPrecoS + verImpostoS) * response.data.quota) / 100
            );
            setverComissaoS(comissao);
            setverComissaoSelect(response.data.quota);
            setvertotalS(parseInt(verPrecoS + verImposto));
          }
        })
        .catch((error) => {
          messages.mensagemErro(error);
        });
    }
  };

  //Botao Salvar
  const saveUpdateVenda = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("lote", lote);
    formData.append("preco", preco);
    formData.append("comissao", comissao);
    formData.append("imposto", imposto);

    const datas = {
      lote: {
        codigo: lote,
      },
      preco: preco,
      comissao: comissao,
      imposto: imposto,
    };

    //
    if (codigo) {
      await updateArtigo(formData)
        .then((response) => {
          messages.mensagemSucesso("Actualizado com sucesso", response.data);
          // retrievecooperadores();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao actualizar", error);
        });
    } else {
      //

      await createArtigo(formData)
        .then((response) => {
          messages.mensagemSucesso("Salvo com sucesso", response.data);
          // retrievecooperadores();
        })
        .catch((error) => {
          messages.mensagemErro("Erro ao salvar", error);
        });
    }
  };

  //   const loadVenda = () => {
  //     if (codigo) {
  //       VendaService.get(codigo)
  //         .then((response) => {
  //           setVenda(response.data);
  //         })
  //         .catch((error) => {
  //           messages.mensagemErro("Erro ao buscar dados");
  //         });
  //     }
  //   };

  return (
    <div>
      {/* LOTE */}
      <div>
        <form
          //enctype="multipart/form-data"
          onSubmit={(e) => saveUpdateVenda(e)}
        >
          <div className="row">
            <div className="col-6">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar..."
                  className="form-control cc-exp"
                  tabindex="1"
                  name="fazenda"
                  // value={fazenda}
                  onChange={(e) => verOsAniamis(e)}
                  placeholder={i18n.t("messages.fazenda")}
                >
                  <option>Selecionar Fazenda</option>
                  {verFazenda.map((fazenda) => (
                    <option value={fazenda.codigo}>{fazenda.nome}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-6">
              <div className="input-group">
                <select
                  data-placeholder="Selecionar..."
                  className="form-control cc-exp"
                  // tabindex="1"
                  name="lote"
                  // value={lote}
                  onChange={(e) => onInputChange(e)}
                  placeholder={i18n.t("messages.animal")}
                >
                  <option>Selecionar Lote</option>
                  {verAnimal.map((animal) => (
                    <option key={animal.codigo} value={animal.codigo}>
                      Lote {animal.codigo}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {VerLoteAnimal === [] ? (
            ""
          ) : (
            <div>
              <h5 style={{ padding: "10px" }}>ANIMAIS DESTE LOTE</h5>

              {EscolhaVerItemsLote === "rapido" ? (
                <div>
                  <table className="table">
                    <tr>
                      <tr>
                        {/* <th>Especie</th> */}
                        {/* <th>Raça</th> */}
                        <th>Qtd.</th>
                        <th>Peso Minimo</th>
                        {/* <th>Especie</th> */}
                        <th>Peso Maximo</th>
                        <th>Idade Minima</th>
                        <th>Idade Maxima</th>
                      </tr>
                      {VerLoteRapido.map((loteItem) => (
                        <tr>
                          <td>{loteItem.qtd}</td>
                          <td>{loteItem.pesomin}Kg</td>
                          <td>{loteItem.pesomaxi}Kg</td>
                          <td>{loteItem.idademin}</td>
                          <td>{loteItem.idademax}</td>
                        </tr>
                      ))}
                    </tr>
                  </table>
                </div>
              ) : (
                ""
              )}
              {EscolhaVerItemsLote === "existente" ? (
                <div>
                  <table className="table">
                    <tr>
                      <tr>
                        <th>Descrição</th>
                        <th>Raça</th>
                        <th>Peso</th>
                        {/* <th>Especie</th> */}
                        <th>Proveniencia</th>
                        <th>Idade</th>
                      </tr>
                      {VerLoteAnimal.map((loteItem) => (
                        <tr>
                          <td>{loteItem.animal.nome}</td>
                          <td>{loteItem.animal.raca}</td>
                          <td>{loteItem.animal.peso}</td>
                          {/* <td>{loteItem.animal.especie.especieAnimal}</td> */}
                          <td>{loteItem.animal.pais.nome}</td>
                          <td>{loteItem.animal.idade}</td>
                        </tr>
                      ))}
                    </tr>
                  </table>
                </div>
              ) : (
                ""
              )}
              {EscolhaVerItemsLote === "sem" ? (
                <h3>Por favor adicione Animais a este Lote</h3>
              ) : (
                ""
              )}

              <h5 style={{ padding: "10px" }}>CADASTRAR STOCK DO LOTE</h5>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <input
                      id=""
                      name="preco"
                      type="number"
                      className="form-control cc-exp"
                      value={preco}
                      placeholder={i18n.t("messages.preco")}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </div>
              </div>

              {/*  */}

              <div className="row">
                {/* <div className="col-6">
                <div className="form-group">
                  <select
                    data-placeholder="Selecionar..."
                    className="form-control cc-exp"
                    tabindex="1"
                    name="lote"
                    value={lote}
                    onChange={(e) => onInputChange(e)}
                    placeholder={i18n.t("messages.lote")}
                  >
                    <option>Selecionar Lote</option>
                    <option value="1">1 Animal</option>
                    <option value="2">2 Animais</option>
                    <option value="3">3 Animais</option>
                    <option value="4">4 Animais</option>
                    <option value="5">5 Animais</option>
                  </select>
                </div>
              </div> */}

                <div className="col-6">
                  <div className="form-group">
                    <select
                      data-placeholder="Selecionar..."
                      className="form-control cc-exp"
                      tabindex="1"
                      name="imposto"
                      // value={fazenda}
                      onChange={(e) => onInputChange(e)}
                      // placeholder={i18n.t("messages.fazenda")}
                    >
                      <option>Selecionar Imposto</option>
                      {verImposto.map((imposto) => (
                        <option value={imposto.codigo}>
                          {imposto.iva} % <span></span>
                          {imposto.descricao}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <div className="input-group">
                      <select
                        data-placeholder="Selecionar..."
                        className="form-control cc-exp"
                        tabindex="1"
                        name="comissao"
                        // value={fazenda}
                        onChange={(e) => onInputChange(e)}
                        // placeholder={i18n.t("messages.fazenda")}
                      >
                        <option>Selecionar Comissão</option>
                        {verComissao.map((comissao) => (
                          <option value={comissao.codigo}>
                            {comissao.quota} %
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <h5 style={{ padding: "10px" }}>Calculos</h5>
              <div className="row">
                <div className="col-3">
                  <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                    {" "}
                    Preco inicial
                  </h6>
                  <div className="form-group">
                    <input
                      id=""
                      type="number"
                      className="form-control cc-exp"
                      value={verPrecoS}
                      // onChange={(e) => onInputChange(e)}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-3">
                  <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                    {" "}
                    Imposto
                  </h6>
                  <div className="form-group">
                    <input
                      id=""
                      type="text"
                      className="form-control cc-exp"
                      value={verImpostoS}
                      // onChange={(e) => onInputChange(e)}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-3">
                  <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                    {" "}
                    Comissao
                  </h6>
                  <div className="form-group">
                    <input
                      id=""
                      type="text"
                      className="form-control cc-exp"
                      value={verComissaoS}
                      // onChange={(e) => onInputChange(e)}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-3">
                  <h6 style={{ textAlign: "center", marginBottom: "7px" }}>
                    {" "}
                    Valor de Venda
                  </h6>
                  <div className="form-group">
                    <input
                      id=""
                      type="text"
                      className="form-control cc-exp"
                      value={vertotalS}
                      // onChange={(e) => onInputChange(e)}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {/*  */}
            </div>
          )}
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
              style={{
                backgroundColor: "#b46739",
                color: "#fff",
              }}
            >
              {i18n.t("buttons.cancelar")}
            </button>
            <button
              type="submit"
              className="btn"
              style={{
                backgroundColor: "#9ba239",
                color: "#fff",
              }}
            >
              <span>{i18n.t("buttons.save")}</span>
            </button>
          </div>
        </form>
      </div>
      {/* FIM LOTE */}
    </div>
  );
};

export default StokLote;
