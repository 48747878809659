import api from "./api";

export const getServicos = async () => {
  try {
    return await api.get("Servico");
  } catch (error) {
    console.log(error.message);
  }
};
export const createServico = async (data) => {
  try {
    return await api.post("criar_servico", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const getServicoCodigo = async (codigo) => {
  try {
    return api.get(`servico/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const updateServico = async (data) => {
  try {
    return await api.put("servico", data);
  } catch (error) {
    console.log(error.message);
  }
};
export const removeServico = async (codigo) => {
  try {
    return await api.delete(`Servico/${codigo}`);
  } catch (error) {
    console.log(error.message);
  }
};
export const getImagem = async (imagem) => {
  try {
    return await api.get(`exibirImagemServico/${imagem}`);
  } catch (error) {
    console.log(error.message);
  }
};
