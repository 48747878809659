import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Menu from "../layout/Menu";
import { getEntregas } from "../../service/EntregaService";
import * as messages from "../message/toastr";
import Swal from "sweetalert2";
import { i18n } from '../../Traducao/translate/i18n';
const I18N_STORAGE_KEY = 'i18nextLng';

const ListEntrega = () => {
    const { codigo } = useParams();
    const [entregas, setEntregas] = useState([]);
    // const [mostrarEntrega, setMostrarEntrega] = useState([]);

    //
    useEffect(() => {
        getAllEntregas();
    }, []);

    //LISTAR
    const getAllEntregas = () => {
        getEntregas()
            .then((response) => {
                setEntregas(response.data);
            })
            .catch((error) => {
                messages.mensagemErro(error);
            });
    };

    // //APAGAR
    // const deleteEntregas = (codigo) => {
    //     Swal.fire({
    //         title: "{i18n.t('msg_secundaria.deseja_eliminar')}",
    //         text: "Realmente deseja eliminar!",
    //         icon: "warning",
    //         showCancelButton: true,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#d33",
    //         confirmButtonText: "Sim, eliminar!",
    //         cancelButtonText: "Cancelar",
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             removeEntrega(codigo).then((response) => {
    //                 Swal.fire("Eliminado!", "Eliminado.", "success");
    //                 getAllEntregas();
    //             });
    //         }
    //     });
    // };


    //
    const titulo = () => {
        if (codigo) {
            return <h3 style={{ color: "#fff" }}> {i18n.t('messages.atualizar')} </h3>;
        } else {
            return <h3 style={{ color: "#fff" }}> {i18n.t('messages.cadastrar')} </h3>;
        }
    };


    //
    return (
        <>
            <Menu />
            <div id="right-panel" className="right-panel">
                {/*  */}
                <div className="breadcrumbs">
                    <div className="breadcrumbs-inner">
                        <div className="row m-0">
                            <div className="col-xs-6 col-sm-6">
                                {/* <Link to="/usuarios/addVisitante" id="event-modal">
                                    <button
                                        type="submit"
                                        className="btn btn-flat m-b-30 m-t-30"
                                        data-toggle="modal"
                                        data-target="#largeModal"
                                        style={{ background: "#b46739", color: "#fff" }}
                                    >
                                        <span className="las la-plus">
                                            {i18n.t('messages.novo')}
                                        </span>
                                    </button>
                                </Link> */}
                            </div>
                            {/*  */}
                            <div className="col-xs-6 col-sm-6">
                                <form className="d-flex">
                                    <input
                                        className="form-control me-2"
                                        type="search"
                                        placeholder={i18n.t('messages.Pesquisar_por_nome')}
                                        aria-label="Search"
                                    />
                                    <button className="btn btn-outline-success" type="submit">
                                        {i18n.t('buttons.pesquisar')}
                                    </button>
                                </form>
                            </div>
                            {/*  */}
                        </div>
                    </div>
                </div>

                {/*  */}
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div
                                        className="card-header"
                                        style={{ background: "#b46739", color: "#fff" }}
                                    >
                                        <strong className="card-title">
                                            {i18n.t('messages.lista_entregas')}
                                        </strong>
                                    </div>
                                    <div className="card-body">
                                        <table className="table table-striped responsiveTable">
                                            <thead>
                                                <tr>
                                                    <th scope="col">
                                                        {i18n.t('messages.motorista')}
                                                    </th>

                                                    <th scope="col">
                                                        {i18n.t('messages.usuarios')}
                                                    </th>

                                                    <th scope="col">
                                                        {i18n.t('messages.origem')}
                                                    </th>

                                                    <th scope="col">
                                                        {i18n.t('messages.destino')}
                                                    </th>

                                                    <th scope="col">
                                                        {i18n.t('messages.valorDistancia')}
                                                    </th>

                                                    <th scope="col">
                                                        {i18n.t('messages.precoTotal')}
                                                    </th>


                                                    {/* 
                                                    <th scope="col">
                                                        {i18n.t('messages.opcao')}
                                                    </th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {entregas.map((entrega) => (
                                                    <tr key={entrega.codigo}>

                                                        <td>{entrega.transportador.nomeMotorista}</td>
                                                        <td>{entrega.visitante.nome}</td>
                                                        <td>{entrega.valorDistancia.provinciaOrigem.nome}</td>
                                                        <td>{entrega.valorDistancia.provinciaDestino.nome}</td>
                                                        <td>{entrega.valorDistancia.valor} KM</td>
                                                        <td>{entrega.precoTotal} KZ</td>
                                                        <td>

                                                            {/*  */}
                                                            {/* <Link
                                                                to={`/entrega/editEntrega/codigo/${entrega.codigo}`}
                                                                id="event-modal"
                                                                style={{ marginLeft: "10px" }}
                                                            >
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-warning"
                                                                    data-toggle="modal"
                                                                    data-target="#largeModal"
                                                                >
                                                                    <span className="las la-edit"></span>
                                                                </button>
                                                            </Link> */}

                                                            {/* <button
                                                                type="submit"
                                                                className="btn btn-danger"
                                                                onClick={() =>
                                                                    deleteEntregas(entrega.codigo)
                                                                }
                                                                style={{ marginLeft: "10px" }}
                                                            >
                                                                <span className="las la-trash"></span>
                                                            </button> */}
                                                            {/*  */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {/*  */}
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-end">
                                                <li className="page-item disabled">
                                                    <a
                                                        className="page-link"
                                                        href="#"
                                                        tabindex="-1"
                                                        aria-disabled="true"
                                                    >
                                                        Previous
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#">
                                                        1
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#">
                                                        2
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#">
                                                        3
                                                    </a>
                                                </li>
                                                <li className="page-item">
                                                    <a className="page-link" href="#">
                                                        Next
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                        {/*  */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- .animated --> */}
                </div>
                {/* <!-- .content --> */}

                <div className="clearfix"></div>
                {/*  */}
            </div>


            {/* <!--------Inicio Cadastrar------> */}


            {/* <!--------Fim Cadastrar------> */}
        </>
    );
};

export default ListEntrega;
